<template>
    <div class="acms-v-quantity">
        <button class="acms-v-quantity-btn acms-v-quantity-btn-minus"
                :disabled="locState.value == props.min || locState.value == minValueCanBe"
                @mouseup="mouseupHandle"
                @click="decrementHandle"
        ></button>
        <input type="number" class="form-control" maxlength="8"
               :value="locState.value"
               @input="inputHandle"
               v-bind="$attrs"
               :disabled="disabled"
               :name="name"
               @keypress="keypressHandle"
               placeholder="0"
               @blur="blurHandle"


               @keydown.up="incrementHandle"
               @keydown.down="decrementHandle"
               :class="{'text-gray': locState.value == 0}"
        />
        <button class="acms-v-quantity-btn acms-v-quantity-btn-plus"
                @click="incrementHandle"></button>
    </div>
</template>

<script>
export default {
    name: 'AcmsVQuantity',
};
</script>

<script setup>
import {defaultAttributesProps} from '../field.props';
import {onMounted, reactive, watch} from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    ...defaultAttributesProps,

    max: Number,
    min: Number,
    modelValue: Number,
    minValueCanBe: {
        type: Number,
        default: 0
    }
});

const locState = reactive({
    value: null,
    mouseIsDown: false,
    mousedownInterval: null,
    mousedownTimeout: null,
});

watch(() => locState.value, (val) => {
    emitValue();
});

const emitValue = () => emit('update:modelValue', Number(locState.value));

const keypressHandle = (event) => {
    if ([',', '.'].includes(event.key)) {
        event.preventDefault()
    }
};
const cleanValue = (val) =>String(val).replace(/[^0-9]/g, '');

const getCorrectValue = (value) => {
    const cleanedValue =  cleanValue(value)
    const parsedValue = parseInt(cleanedValue)
    return parsedValue
}
const setCorrectValue = (value) => {
    locState.value = getCorrectValue(value)
}

const blurHandle = (e) => {
    setTimeout( () => {
        setCorrectValue(e.target.value)
    }, 100);

}
const inputHandle = (e) => {
    setCorrectValue(e.target.value)
}

const mouseupHandle = () => {
    locState.mouseIsDown = false;
    clearInterval(locState.mousedownInterval);
};
const decrementHandle = () => {
    if (props.min) {
        if (locState.value > props.min) {
            locState.value--;
        }
    } else {
        if (locState.value != 0) {
            locState.value--;
        }
    }
};
const incrementHandle = () => {
    if (props.max && locState.value == props.max) {

    } else {
        locState.value++;
    }
};
onMounted(() => {
    locState.value = cleanValue(props.modelValue != undefined && props.modelValue > -1 ? props.modelValue : 0)
    emitValue();
})

</script>

<style lang="scss">
$icon-minus: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5 8C14.5 8.55313 14.0531 9 13.5 9H2.5C1.94687 9 1.5 8.55313 1.5 8C1.5 7.44687 1.94687 7 2.5 7H13.5C14.0531 7 14.5 7.44687 14.5 8Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
$icon-plus: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66699 1.5C7.66699 0.946875 7.22012 0.5 6.66699 0.5C6.11387 0.5 5.66699 0.946875 5.66699 1.5V6H1.16699C0.613867 6 0.166992 6.44688 0.166992 7C0.166992 7.55312 0.613867 8 1.16699 8H5.66699V12.5C5.66699 13.0531 6.11387 13.5 6.66699 13.5C7.22012 13.5 7.66699 13.0531 7.66699 12.5V8H12.167C12.7201 8 13.167 7.55312 13.167 7C13.167 6.44688 12.7201 6 12.167 6H7.66699V1.5Z' fill='%236C757D'/%3E%3C/svg%3E%0A");

.acms-v-quantity {
    position: relative;
    height: 38px;
    input {
        text-align: center;
    }
    &-btn {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;

        margin-top: auto;
        margin-bottom: auto;
        background-position: center;
        border-radius: 4px;

        &:disabled {
            pointer-events: none;
            cursor: pointer;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.11);
        }

    }
    &-btn-minus {
        left: 0;
        background-image: $icon-minus;
    }
    &-btn-plus {
        right: 0;
        background-image: $icon-plus;
    }
}

</style>
