<template>
    <v-container v-if="editor" style="max-height: 600px!important; padding: 0!important;">
        <v-toolbar dense class="d-flex flex-wrap" style="gap: 4px;">
            <!-- Кнопки форматирования -->
            <v-btn
                :color="isActive('bold') ? 'primary' : undefined"
                @click="toggleBold"
                icon
                x-smal
            >
                <v-icon small>mdi-format-bold</v-icon>
            </v-btn>
            <v-btn
                :color="isActive('italic') ? 'primary' : undefined"
                @click="toggleItalic"
                icon
                x-small
            >
                <v-icon small>mdi-format-italic</v-icon>
            </v-btn>
            <v-btn
                :color="isActive('strike') ? 'primary' : undefined"
                @click="toggleStrike"
                icon
                x-small
            >
                <v-icon small>mdi-format-strikethrough-variant</v-icon>
            </v-btn>
            <v-divider vertical class="mx-2"></v-divider>

            <!-- Списки -->
            <v-btn
                :color="isActive('bulletList') ? 'primary' : undefined"
                @click="toggleBulletList"
                icon
                x-small
            >
                <v-icon small>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
                :color="isActive('orderedList') ? 'primary' : undefined"
                @click="toggleOrderedList"
                icon
                x-small
            >
                <v-icon small>mdi-format-list-numbered</v-icon>
            </v-btn>
            <v-divider vertical class="mx-2"></v-divider>

            <!-- Заголовки -->
            <v-menu v-model="showHeadingMenu" offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" x-small @click="showHeadingMenu = true">
                        <v-icon small>mdi-format-header-pound</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="heading in headings" :key="heading.level" @click="setHeading(heading.level)">
                        <v-list-item-title :style="{ fontSize: heading.size + 'px' }">
                            {{ heading.label }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- Выбор шрифта -->
            <v-menu v-model="showFontMenu" offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" x-small @click="showFontMenu = true">
                        <v-icon small>mdi-format-font</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="font in fonts" :key="font.value" @click="setFontFamily(font.value)">
                        <v-list-item-title :style="{ fontFamily: font.value }">
                            {{ font.label }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-divider vertical class="mx-2"></v-divider>

            <!-- Блок цитирования -->
            <v-btn @click="blockQuote" icon x-small>
                <v-icon small>mdi-format-quote"></v-icon>
            </v-btn>
            <v-divider vertical class="mx-2"></v-divider>

            <!-- Кнопки отмены и повтор -->
            <v-btn @click="undo" icon x-small>
                <v-icon small>mdi-undo</v-icon>
            </v-btn>
            <v-btn @click="redo" icon x-small>
                <v-icon small>mdi-redo</v-icon>
            </v-btn>

            <v-divider vertical class="mx-2"></v-divider>

            <!-- Выбор цвета текста -->
            <v-menu v-model="showTextColorMenu" offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" x-small @click="showTextColorMenu = true">
                        <v-icon small>mdi-format-color-text</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-row>
                        <v-col
                            v-for="(color, index) in textColorPalette"
                            :key="index"
                            cols="2"
                            class="d-flex justify-center"
                        >
                            <div
                                :style="{ backgroundColor: color }"
                                class="color-swatch"
                                @click="setTextColor(color)"
                            ></div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-menu>

            <!-- Выбор цвета фона текста -->
            <v-menu v-model="showBackgroundColorMenu" offset-y>
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" x-small @click="showBackgroundColorMenu = true">
                        <v-icon small>mdi-format-color-highlight</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-row>
                        <v-col
                            v-for="(color, index) in backgroundColorPalette"
                            :key="index"
                            cols="2"
                            class="d-flex justify-center"
                        >
                            <div
                                :style="{ backgroundColor: color }"
                                class="color-swatch"
                                @click="setBackgroundColor(color)"
                            ></div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-menu>
        </v-toolbar>

        <v-card outlined height="380px">
            <v-card-text style="padding: 6px!important; overflow-y: auto; max-height: 100%;">
                <editor-content :editor="editor" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color'; // Для изменения цвета текста
import Highlight from '@tiptap/extension-highlight'; // Для изменения цвета фона текста
import Heading from '@tiptap/extension-heading';

// Получаем модельную привязку от родителя
const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
});

// Объявляем emit для передачи изменений в родительский компонент
const emit = defineEmits(['update:modelValue']);



// Инициализация редактора
const editor = useEditor({
    content: props.modelValue,
    extensions: [
        StarterKit.configure({
            hardBreak: false, // Disable HardBreak
        }),
        TextStyle,
        FontFamily.configure({ types: ['textStyle'] }),
        BulletList,
        OrderedList,
        Color,
        Highlight.configure({
            multicolor: true,
        }),
        Heading.configure({
            levels: [1, 2, 3, 4, 5],
        }),
    ],
    onUpdate({ editor }) {
        emit('update:modelValue', editor.getHTML());
    },
});

// Следим за изменениями modelValue и обновляем содержимое редактора
watch(
    () => props.modelValue,
    (newValue) => {
        if (editor.value && newValue !== editor.value.getHTML()) {
            editor.value.commands.setContent(newValue);
        }
    }
);

// Доступные заголовки
const headings = [
    { label: 'Heading 1', level: 1, size: 24 },
    { label: 'Heading 2', level: 2, size: 22 },
    { label: 'Heading 3', level: 3, size: 20 },
    { label: 'Heading 4', level: 4, size: 18 },
    { label: 'Heading 5', level: 5, size: 16 },
];

// Доступные шрифты
const fonts = [
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Courier New', value: '"Courier New", monospace' },
    { label: 'Times New Roman', value: '"Times New Roman", serif' },
];

// Управление меню выбора заголовков и шрифтов
const showHeadingMenu = ref(false);
const showFontMenu = ref(false);

// Методы для установки заголовков
const setHeading = (level) => {
    if (editor.value) {
        editor.value.chain().focus().toggleHeading({ level }).run();
        showHeadingMenu.value = false; // Закрыть меню после выбора
    }
};

// Методы для выбора шрифта
const setFontFamily = (font) => {
    if (editor.value) {
        editor.value.chain().focus().setFontFamily(font).run();
        showFontMenu.value = false; // Закрыть меню после выбора
    }
};

// Управление меню выбора цветов
const showTextColorMenu = ref(false);
const showBackgroundColorMenu = ref(false);

// Палитра доступных цветов текста и фона
const textColorPalette = ['#000000', '#FF0000', '#FF9900', '#FFFF00', '#00FF00', '#008000', '#0000FF', '#800080', '#FF00FF'];
const backgroundColorPalette = ['#FFFFFF', '#FFCCCC', '#FFCC99', '#FFFF99', '#99FF99', '#99FFCC', '#99CCFF', '#CC99FF', '#FF99CC'];

// Методы для изменения цвета текста
const setTextColor = (color) => {
    if (editor.value) {
        editor.value.chain().focus().setColor(color).run();
        showTextColorMenu.value = false; // Закрыть меню после выбора
    }
};

// Методы для изменения цвета фона текста
const setBackgroundColor = (color) => {
    if (editor.value) {
        editor.value.chain().focus().toggleHighlight({ color }).run();
        showBackgroundColorMenu.value = false; // Закрыть меню после выбора
    }
};

// Методы форматирования
const toggleBold = () => {
    editor.value.chain().focus().toggleBold().run();
};

const toggleItalic = () => {
    editor.value.chain().focus().toggleItalic().run();
};

const toggleStrike = () => {
    editor.value.chain().focus().toggleStrike().run();
};

const toggleBulletList = () => {
    editor.value.chain().focus().toggleBulletList().run();
};

const toggleOrderedList = () => {
    editor.value.chain().focus().toggleOrderedList().run();
};

const undo = () => {
    editor.value.chain().focus().undo().run();
};

const redo = () => {
    editor.value.chain().focus().redo().run();
};

const blockQuote = () => {
    editor.value.chain().focus().toggleBlockquote().run();
};

const isActive = (format, options = {}) => {
    return editor.value && editor.value.isActive(format, options);
};
// onMounted(() => {
//     const oneUICssLink = document.getElementById('css-main');
//     if (oneUICssLink) {
//         oneUICssLink.disabled = true; // Отключаем стили oneui.css
//     }
// });
//
// onBeforeUnmount(() => {
//     const oneUICssLink = document.getElementById('css-main');
//     if (oneUICssLink) {
//         oneUICssLink.disabled = false; // Включаем стили oneui.css обратно, если компонент демонтируется
//     }
// });
</script>

<style scoped>
.editor-content {
    height: 100%;
    width: 100%;
}

:deep(.tiptap p) {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.2 !important;
}
/* Уменьшаем размер иконок в кнопках */
.v-btn {
    padding: 0!important;
}

/* Стили для палитры цветов */
.color-swatch {
    width: 24px;
    height: 24px;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 2px;
}

.v-select {
    min-width: 180px;
}

.my-custom-paragraph {
    margin: 0;
}
:deep(.editor-content) p {
    margin: 0 !important;
    padding: 0 !important;
}
</style>

