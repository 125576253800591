import {defineStore} from "pinia";

export const useBoxLinkedContactStore = defineStore('boxLinkedContactStore', {

    state: () => ({
        form:null,
        formData:null,
        formIsDirty: null,
    }),

    actions: {
        setFormData(formObject) {
            this.form = formObject
            this.formData = formObject?.data?.() ?? formObject
            this.formIsDirty = formObject?.isDirty
        },
    },
})
