<template>
    <div class="acms-v-form-input-with-dropdown d-flex">
        <template v-if="selectOptions || selectOptions?.length > 0">
            <acms-v-select
                :options="Array.isArray(selectOptions)? selectOptions : undefined"
                viewTemplate="withCode"
                :optionKeyTitle="optionKeyTitle ?? 'country'"
                :toggleKeyTitle="toggleKeyTitle"
                :toggleKeyCode="toggleKeyCode"
                :placeholder="placeholderSelect"
                :search="search"
                :searchAsync="searchAsync"
                :name="uniqueId('code-select')"
                v-model="locState.value[keySelect]"
                :class="{'acms-v-select--focused': locState.inputFocused}"
                :selectedOptionOnInit="selectedOptionOnInit"
                :disabled="Boolean(mode && modelValue?.id) && disabledStateFun()"
                :minSymbolsForSearch="minSymbolsForSearch"
            />
        </template>
        <template v-if="(mode && modelValue?.id) && disabledStateFun()">
            <input class="form-control disabled"
                   disabled
                   :value="getSecurizedValue"
                   style="height: 38px"
            />
        </template>
        <template v-else>

            <input class="form-control"
                   v-bind="$attrs"
                   :required="required"
                   :disabled="disabled"
                   :placeholder="placeholder"
                   :name="name"
                   :type="type"
                   :value="locState.value.text"
                   @keydown="onKeydownHandle"
                   @change="(e)=>onKeydownHandle(e)"
                   @input="(e)=>onInputHandle(e)"
                   @focus="locState.inputFocused=true"
                   @blur="locState.inputFocused=false"
                   @wheel.stop.prevent
            >
        </template>
        <template v-if="dropdownOptions">
            <div class="dropdown-block" v-click-outside="hideDropdown">
                <button type="button" class="btn dropdown-toggle d-flex align-items-center input-group-text h-100"
                        :class="[classes.dropdownToggle,{'focus': locState.inputFocused}]"
                        @click="showDropdown"
                >
                    <acms-v-icon class="me-2" :name="getIcon"/>
                </button>
                <div class="dropdown-menu dropdown-menu-end" v-if="locState.dropdownShow"
                     :class="{show: locState.dropdownShow}">
                    <template v-for="(option, key) in dropdownOptions">
                        <div v-if="option?.divider" role="separator" class="dropdown-divider"></div>
                        <button class="dropdown-item" v-else-if="option?.canSetMain"
                                :class="classes.mainOption"
                                @click="()=>selectDropdownOptionHandle(option)"
                        >{{ getMainOptionTitle(option) }}
                        </button>
                        <button class="dropdown-item" v-if=" option.type =='option'"
                                :class="[classes.defaultOption(option), option?.className]"
                                @click="()=>selectDropdownOptionHandle(option)"
                        >
                            {{ option.title }}
                        </button>
                        <template v-if="option.type =='action'">

                            <button class="dropdown-item"
                                    v-if="modelValue?.id && dropdownOptionShowFun(option)"
                                    :class="[classes.defaultOption(option), option?.className]"
                                    @click="()=>selectDropdownOptionHandle(option, modelValue)"
                            >
                                {{ option.title }}
                            </button>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AcmsVInputWithDropdown',
};
</script>

<script setup>
import {computed, defineAsyncComponent, nextTick, onBeforeMount, onMounted, reactive, watch} from 'vue';

import {
    defaultAttributesProps,
} from '../field.props';
import {uniqueId} from 'lodash';
import AcmsVIcon from "@ui/Icon.vue";
import AcmsVSelect from "@ui/FormField/elements/Select/Select.vue";
import {getHiddenEmail, getHiddenPhone} from "@helpers/commonHelpers";
import Input from "@ui/FormField/elements/Input.vue";

const emit = defineEmits(['update:modelValue', 'clickOnDropdownOption',]);

const props = defineProps(
    {
        ...defaultAttributesProps,

        dropdownOptions: Array,
        selectOptions: [Array, Boolean],
        keyDropdown: String,
        keySelect: String,
        phoneCode: Boolean,
        mode: {
            type: String,
        },
        modelValue: Object,
        //for select
        placeholderSelect: String,
        search: [Boolean, String],
        searchAsync: String,
        selectedOptionOnInit: [Number, String],
        optionKeyTitle: [String,Function],
        toggleKeyTitle: [String, Array],
        toggleKeyCode: [String, Array],
        minSymbolsForSearch: [Number, String],
        dropdownOptionShowFun: {
            type: Function,
            default: () => {
                return true
            }
        },
        disabledStateFun: {
            type: Function,
            default: () => {
                return true
            }
        }
    },
);

const keyDropdown = props.keyDropdown ?? 'optionDropdown'
const keySelect = props.keySelect ?? 'optionSelect'

const getSecurizedValue = computed(() => {
    if (props.mode == 'email') {
        return getHiddenEmail(props.modelValue)
    }
    if (props.mode == 'phone') {
        return getHiddenPhone(props.modelValue)
    }
})
const getIcon = computed(() => {
    const optionIcon = locState.value?.[keyDropdown]?.toggleIcon;
    if (optionIcon) {
        locState.toggleIcon = optionIcon;
    }
    return optionIcon ?? locState.toggleIcon;
});

const initValue = () => {
    const defObject = {
        text: '',
    };
    let result = {...defObject};
    if (props.dropdownOptions?.length > 0) {
        result[keyDropdown] = props.dropdownOptions[0]
    }
    return result;
};

const locState = reactive({
    value: null,
    toggleIcon: null,
    dropdownShow: false,
    inputFocused: false,
});

watch(() => props.modelValue, (val) => {
    setTimeout(() => {
        if (props.mode == 'phone') {
            if (val?.pivot) {
                const {dial_code, pivot, phone, id} = val
                locState.value = {
                    phoneCode: props.selectOptions.find(item => item.code == dial_code),
                    type: pivot ? props.dropdownOptions.find(item => item.id == pivot?.type_id) : locState.value?.type,
                    main: pivot ? pivot?.main : locState.value.main,
                    text: phone,
                    id
                }
            } else {
                locState.value = val;
            }
        } else if (props.mode == 'email') {

            if (val?.pivot) {
                const {email, pivot, id} = val
                locState.value = {
                    type: pivot ? props.dropdownOptions.find(item => item.id == pivot?.type_id) : locState.value.type,
                    main: pivot ? pivot?.main : locState.value.main,
                    text: email,
                    id
                };
            } else {
                locState.value = val;
            }
        } else {
            locState.value = val;
        }

    }, 0);

}, {deep: true});

const hideDropdown = () => {
    locState.dropdownShow = false;
};
const showDropdown = () => {
    locState.dropdownShow = !locState.dropdownShow;
};

const getMainOptionTitle = (option) => locState.value?.main ? option.title_main : option.title;

const classes = computed(() => {
    return {
        mainOption: locState.value?.main ? 'selected disabled' : '',
        defaultOption: (option) => ({
            selected: option?.title == locState.value?.[keyDropdown]?.title,
        }),
        dropdownToggle: locState.value?.main ? 'btn-primary' : 'btn-dark',
    };
});
const emitUpdatedValue = () => {
    emit('update:modelValue', locState.value);
};

const onKeydownHandle = (e) => {
    if (props.mode === 'phone') {
        if (e.key == 'e') {
            e.preventDefault()
        }
        if (e.key == '0' && e.target.value == '') {
            e.preventDefault()
        }
    }
}
const onInputHandle = (e) => {

    const val = e.target.value
    if (props.mode === 'phone') {
        e.preventDefault()
        if (val?.charAt(0) == 0) {
            locState.value.text = val.substring(1, val.length)
        } else if (val.includes('e')) {
            locState.value.text = Number(val.replace('e', ''));
        } else {
            locState.value.text = val
        }
    } else {
        locState.value.text = val;
    }
    emitUpdatedValue();
};

const selectDropdownOptionHandle = (option, value) => {
    emit('clickOnDropdownOption', {option, value});

    if(option?.canSelect){
        locState.value[keyDropdown] = option;
    }
    hideDropdown()
};

onBeforeMount(() => {
    const {modelValue} = props
    locState.value = (modelValue?.[keyDropdown] || modelValue?.[keySelect]) ? modelValue : initValue();
});

</script>

<style lang="scss">
.dropdown-toggle {
    &.focus {
        border-color: #4c78dd !important;
    }
}

.acms-v-select--focused {
    .acms-v-select-toggle {
        border-color: #4c78dd !important;
    }
}

.input-group-text {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-divider {
    margin: 0;
}

.dropdown-menu {
    width: 200px;
    box-shadow: 0px 0px 24px rgba(31, 41, 55, 0.1);
    border-radius: 8px;
    padding: 0;

    &.show {
        position: absolute;
        inset: 0px 0px auto auto;
        margin: 0px;
        transform: translate(0px, 40px);
    }
}

.dropdown-item {
    padding: 8px 8px 8px 28px;
    font-size: 14px;
    line-height: 20px;

    &.selected {
        color: #4C78DD;
        background-position: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='%234C78DD'/%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='black' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        pointer-events: none;
    }

    &.disabled {
        opacity: 0.5;
    }
}

.acms-v-select[data-v-select-settings="withCode"] {
    .acms-v-select-dropdown {
        width: 320px !important;
    }
}

[data-v-form-field="inputWithDropdown"] {
    .acms-v-select {
        margin-right: -1px;
        max-width: max-content;

        &-dropdown {
            width: 400px;
            left: 0;
        }

        &-toggle {
            border-radius: 4px 0 0 4px;
        }

        &-toggle-angle {
        }

        & + .form-control {
            border-radius: 0 0 4px 4px;
        }
    }
}
</style>
