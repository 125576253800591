import {format} from "date-fns";
export const convertTimeHHMMSS = (val) => {
    let hhmmss = new Date(val * 1000).toISOString().substr(11, 8)
    return (hhmmss.indexOf('00:') === 0) ? hhmmss.substr(3) : hhmmss
}
export const getUppercaseDates = (array) => {
    const [first, second] = array;
    const getFormattedDate = (date) => {
        const [year, month, day] = date.split('-');
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        return `${day}${months[parseInt(month, 10) - 1]}`;
    }
    return `${getFormattedDate(first)}${second ? ' - ' + getFormattedDate(second) : ''}`;
}
