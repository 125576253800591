export const demoSalesTableData = [

    {
        id: '353ksdf',
        created_at: '03/12/22, 02:30AM',
        time_passed: '1h',
        local_time: '09:30PM',
        flight_details: { date: '03/25/22', airports: ['NYC', 'FRA', 'KIV'] },
        passengers: { adult: 12, child: 2, infant: 1 },
        pq: null,
        cabin_class: 'Business',

    },
    {
        id: '546fgja',
        created_at: '03/12/22, 02:30AM',
        time_passed: '5h',
        local_time: '09:30PM',
        flight_details: { date: '15DEC21', airports: ['NYC', 'FRA', 'KIV', 'DXB'] },
        passengers: { adult: 12 },
        pq: ['DL', 'AA', '9U'],

        cabin_class: 'Premium Economy',
    },
    {
        id: '564jhta',
        created_at: '03/12/22, 02:30AM',
        time_passed: '6h',
        local_time: '09:30PM',
        flight_details: { date: '03/25/22', airports: ['NYC', 'FRA', 'KIV'] },
        passengers: { adult: 12, 'infant': 1 },
        pq: ['DL', 'AA', '9U'],

        cabin_class: 'First Class',

    },
    {
        id: '883wakq',
        created_at: '03/12/22, 02:30AM',
        time_passed: '24h',
        local_time: '09:30PM',
        flight_details: { date: '03/25/22', airports: ['NYC', 'FRA', 'KIV'] },
        passengers: { adult: 2 },
        pq: ['DL', 'AA', '9U'],
        cabin_class: 'Business',

    },
    {
        id: '120owal',
        created_at: '03/12/22, 02:30AM',
        time_passed: '199h',
        local_time: '09:30PM',
        flight_details: { date: '03/25/22', airports: ['NYC', 'FRA', 'KIV'] },
        passengers: { adult: 2, 'child': 2, 'infant': 1 },
        pq: null,
        cabin_class: 'Business',
    },
];

export const demoSalePassengersTable = [
    {
        id:'1',
        ptc:'Adult',
        first_name:'John',
        middle_name:'Peter',
        last_name:'Smith',
        dob:'05/11/1984',
        gender:'Male',
        passport: '58391294',
        expire: '11/2026',
        issuing_country: 'United States',
        nationality: 'India',
        mileage: '78564256',
        redress: '111222',
        ktn: '11177d222',

        data_status: 'check data',


        error_keys: 'first_name'
    },
    {
        id:'12',
        ptc:'Child',
        first_name:'Child',
        middle_name:'Jessica',
        last_name:'Parker',
        dob:'11/25/1974',
        gender:'Female',
        passport: '93423911',
        expire: '10/2024',
        issuing_country: 'United States',
        nationality: 'India',
        mileage: '23654125',
        redress: '111238',
        ktn: '11177d222',

        data_status: 'verified'
    },
    {
        id:'2',
        ptc:'Infant',
        first_name:'John',
        middle_name:'Mikels',
        last_name:'Greenwald',
        dob:'05/11/2021',
        gender:'Female',
        passport: '93423911',
        expire: '11/2026',
        issuing_country: 'Germany',
        nationality: 'United States',
        mileage: '23654125',
        redress: '111438',
        ktn: '11177d222',

        data_status: 'verified',
    },
    {
        id:'3',
        ptc:'Infant',
        first_name:'John',
        middle_name:'Mikels',
        last_name:'Greenwald',
        dob:'05/11/2021',
        gender:'Male',
        passport: '93423911',
        expire: '11/2026',
        issuing_country: 'Germany',
        nationality: 'United States',
        mileage: '23654125',
        redress: '111438',
        ktn: '11177787222',

        data_status: 'not-verified',
    },
]


