export const NOT_FILLED_REQUEST_ALERT = {
    title: 'Request details missing',
    content: 'Your request is not finished!\n' +
        'Save only client details?'
}
export const NOT_FILLED_LINKED_CONTACT_ALERT = {
    title: 'Linked contact details missing',
    content: 'Your linked contact is not finished!\n' +
        'Save only client details?'
}

export const NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT = {
    title: 'Request and linked contact details missing',
    content: 'Your request and linked contact is not finished!\n' +
        'Save only client details?'
}

export const FILLED_REQUEST_AND_NOT_FILLED_LINKED_CONTACT_ALERT = {
    title: 'Linked contact details missing',
    content: 'Your linked contact is not finished!\n' +
        'Save client details and request?'
}

export const FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT = {
    title: 'Request details missing',
    content: 'Your request is not finished!\n' +
        'Save client details and linked contact?'
}


export const REDIRECT_INDEX = 'clients'
export const REDIRECT_EDIT = 'clients.edit'
