<template>
    <acms-v-modal
        :show="show"
        size="med-sm"
        title="Add Label"
        @hide="$emit('update:show', false)"
    >
        <template #content>
            <acms-v-switcher
                type-radio
                multiple
                :options="PQ_LABELS_WITH_ICONS_OPTIONS"
                contentClass="flex-column w-100"
                itemClass="mb-4"
                itemLabelClass="w-100"
                class="w-100"
                optionKeyValue="id"
                v-model="selectedLabel">
                <template #item="{item,title}">
                    <div class="d-flex justify-content-between align-items-center ">
                        <div>{{ title }}</div>
                        <div style="width: 170px;">
                            <template v-if="item?.color">
                                <ElementPqLabel :item="item"/>
                            </template>
                        </div>
                    </div>
                </template>
            </acms-v-switcher>
        </template>

        <template #footer-buttons>
            <acms-v-btn title="Cancel" class="btn-border btn-sm" @click="$emit('update:show', false)"/>
            <acms-v-btn title="Add label" class="btn-primary btn-sm" @click="$emit('update:modelValue', selectedLabel)"/>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalAddOrChangeLabel"
}
</script>
<script setup="">


import {PQ_LABELS_WITH_ICONS_OPTIONS} from "../constants";
import ElementPqLabel from "../elements/ElementPqLabel.vue";
import {ref, watch} from "vue";

const emit = defineEmits(['update:show', 'update:modelValue'])
const props = defineProps({
    show: Boolean,
    modelValue:[String,Number]
})
const selectedLabel = ref(props.modelValue ?? null)
watch(()=>props.show, (val)=>{
    if(!val){selectedLabel.value = null}
})
</script>
<style scoped lang="scss">


:deep(.acms-v-switcher:not(.form-switch) .acms-v-switcher-multi-item:not(:first-child)  .form-check-input) {
    margin-top: 6px;
}

:deep(.acms-v-switcher-multi-item){
    margin-right: 0;
}
</style>
