<template>
    <div class="box-names" >
        <div class="box-names-items" :class="{'box-names-items--row': templateRow}">
            <FieldFirstName
                class="box-names-item"
                :disabled="names.no_name"
                v-model="names.first_name"
                :errors="errors"
            />
            <template v-if="!names.no_name">
                <FieldLastName
                    class="box-names-item"
                    v-model="names.last_name"
                    :errors="errors"
                />
                <FieldMiddleName
                    v-if="names.middle_name !=null || showMiddle"
                    class="box-names-item"
                    v-model="names.middle_name"
                    :errors="errors"
                />
            </template>
        </div>
        <div class="d-flex  ">
            <template v-if="!showMiddle">
                <acms-v-btn
                    v-if="!names.no_name && names.middle_name == null "
                    title="Add middle name"
                    preset="simple-primary"
                    icon="plus"
                    @click="()=>names.middle_name = ''"
                />
            </template>
            <acms-v-switcher v-if="!toggleHide"
                :class="!showMiddle ? 'ms-auto' : ''"
                :title="toggleTitle"
                :model-value="noNameBool"
                @update:model-value="noNameHandler"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxNames',
};
</script>

<script setup="">
import {onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import FieldFirstName from "@components/app/fields/FieldFirstName.vue";
import FieldLastName from "@components/app/fields/FieldLastName.vue";
import FieldMiddleName from "@components/app/fields/FieldMiddleName.vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: Object,
    initValue: Object,
    errors: Object,
    showMiddle: Boolean,
    toggleTitle: {
        type: String,
        default: 'No name'
    },
    onToggleGenerateName: Boolean,
    templateRow:Boolean,
    toggleHide:Boolean,
});

const initValue = {
    first_name: '',
    last_name: '',
    middle_name: null,
    no_name: false,
}

const names = ref({...initValue});
const historyNames = ref({...initValue})
const noNameBool = ref(false)

watch(names, (val) => {
    emit('update:modelValue', val);
}, {deep: true});

watch(noNameBool, (val, prevVal) => {
    if (val && !prevVal) {
        const {first_name, last_name, middle_name} = names.value
        historyNames.value = {first_name, last_name, middle_name}
    }
    if (!val && prevVal) {
        const {first_name, last_name, middle_name} = historyNames.value
        names.value = {first_name, last_name, middle_name}
    }
})

const noNameHandler = (val) => {
    names.value.no_name = val
    noNameBool.value = val
    setTimeout(() => {
        if (val) {
            names.value.first_name = 'Customer';
            names.value.last_name = null;
            names.value.middle_name = null;
        }
    }, 0);
}

onBeforeMount(() => {
   if (props.modelValue) {
        names.value = {...props.modelValue}
    }
    if (props.showMiddle && !names.value.middle_name) {
        names.value.middle_name = null
    }
})

</script>

<style scoped>
.box-names-items--row {
        display: flex;
        gap: 16px;
    .box-names-item {
        width: 33%;
    }
}
</style>
