<template>
    <acms-v-modal :show="show"
                  @hide="$emit('hide')"
                  cleanTemplate
                  size="small"
    >
        <template #content>
            <acms-v-datepicker
                inline
                timePicker
                submitText="Set follow up"
                :model-value="date"
                @update:model-value="updateDateHandle"
                @submitDate="submitDateHandle"
                :dayClass="getDayClass"
            />
        </template>
    </acms-v-modal>
</template>

<script>

export default {
    name: 'PartialFollowUpCalendar',
};
</script>

<script setup="">
import { onMounted, ref } from 'vue';
import { isAfter, isSameDay } from 'date-fns';
const props = defineProps({
    modelValue: Date,
    show: Boolean,
});

const emit = defineEmits(['hide', 'update:modelValue']);
const date = ref(props.modelValue);
const getDayClass = (dateToCompare) => {
    if(isSameDay(date.value,dateToCompare )){
        if(isAfter( new Date(), date.value,  )){
            return 'overdue-date-cell';
        }
    }

    return '';
};
const updateDateHandle = (val) => {
    date.value = val
}
const submitDateHandle = (val) => {
    setTimeout(() => {
        emit('update:modelValue', date.value)
        emit('hide');
    }, 0);
};
onMounted(()=>{
    date.value = props.modelValue
})

</script>
<style lang="scss">
    .overdue-date-cell{
        background-color: #DC2626;
        opacity: 0.25;
        color: white;
        border-radius: 50% !important;
        &.dp__active_date  {
            background-color: #DC2626 !important;
        }
    }
</style>
