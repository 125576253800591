<template>
    <div class="page-request-edit-partial-contact-info">
        <template v-if="tabContactInfoState.formEditShow || tabContactInfoState.formCreateShow">
            <FeatureLinkedContactsCreateAndEdit
                :mainClientId="client.data.id"
                :state="tabContactInfoState"
                @cancel="tabContactInfoHandlers.cancel"
                :responseOnlyKey="responseOnlyKey"
            />
        </template>
        <template v-else>
            <acms-v-btn icon="plus" class="btn-primary ms-auto"
                        @click="tabContactInfoHandlers.create()"
                        title="Add Contact"/>

<!--            <teleport to="#request-page-head-tab-contact-info">-->
<!--                <acms-v-btn icon="plus" class="btn-sm btn-primary ms-auto"-->
<!--                            icon-size="12"-->
<!--                            @click="tabContactInfoHandlers.create()"-->
<!--                            title="Add Contact"/>-->
<!--            </teleport>-->
            <h5>Client contacts</h5>
            <EntityRequestContactInfoTable
                :tableProps="TABLE_CLIENT_CONTACTS_MAIN_PROPS"
                :dataObject="client"
                @edit="tabContactInfoHandlers.edit"
                actions="email,phone,edit"
                isMainClient
            />
            <h5 class="mt-4">Linked Contacts</h5>

            <EntityRequestContactInfoTable
                :tableProps="getTableContactProps"
                :currentRequestId="currentRequestId"
                :dataObject="linkedContactsRest"
                emptyStateTitle="Linked Contacts"
                @edit="tabContactInfoHandlers.edit"
                @delete="tabContactInfoHandlers.wantsToDelete"
            />
        </template>

        <AlertDelete v-if="tabContactInfoState.modalDelete"
                     v-model:show="tabContactInfoState.modalDelete"
                     type="linkedContact"
                     @delete="tabContactInfoHandlers.delete"
        />
    </div>
</template>

<script>
export default {
    name: "PartialContactInfo"
}
</script>

<script setup="">
import {TABLE_CLIENT_CONTACTS_MAIN_PROPS, TABLE_CLIENT_CONTACTS_PROPS} from "../constants";
import {FeatureLinkedContactsCreateAndEdit, useFeatureLinkedContactsCreateAndEdit} from "@features";
import EntityRequestContactInfoTable from "@entities/request/EntityRequestContactInfoTable.vue";
import AlertDelete from "@alerts/AlertDelete.vue";
import {computed} from "vue";
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({
    client: Object,
    linkedContactsRest: Object,
    currentRequestId: [String, Number],
    responseOnlyKey: [String, Array]
})
const {
    linkedContactsHandlers: tabContactInfoHandlers,
    linkedContactsState: tabContactInfoState
} = useFeatureLinkedContactsCreateAndEdit()

const {backendPermissions} = useBackendMiddlewarePropsService()

const getTableContactProps = computed(() => {
    let actions = "email,phone,edit"
    if (backendPermissions?.allowDeleteLinkedContact) {
        actions = actions + ',delete'
    }
    return {
        ...TABLE_CLIENT_CONTACTS_PROPS,
        actions
    }
})




</script>

<style scoped>

</style>
