<template>
    <div class="requests-pq-code-table position-relative">
        <div class="d-flex ">
            <template v-if="!simpleView && !modalMode">
                <div class="d-flex flex-column">
                    <template v-for="item in data?.pq_flight_legs">
                        <div class="pq-code-table-flight-airplane"
                             style="margin-top: 13px; margin-bottom: 13px"
                             v-if="getRowSpanHeight(item.order)"
                             :style="`--height:calc(35px * ${getRowSpanHeight(item.order).rowspan != 1 ? getRowSpanHeight(item.order).rowspan : 1.3})`"
                        >
                            <div></div>
                            <div></div>
                        </div>
                    </template>
                </div>
            </template>
            <div class="overflow-auto pb-2">
                <table class="desktop-pq-table"
                       style="width: 1530px;"
                       :class="[{'pq-table-simple-view white': simpleView}, tableClass]">
                    <thead>
                    <tr v-if="withAdditionalPqColumns">
                        <template v-for="item in [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]">
                            <th></th>
                        </template>
                        <th style="display: flex; justify-content: center" @click="$emit('addBaggage')"  @contextmenu.prevent="$emit('removeBaggage')">
<!--                            <acms-v-icon class="ms-auto me-auto" name="suitcase"/>-->
                            <span style="align-items: center">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25" viewBox="0 0 100 100">
                                <g>
                                    <!-- gray luggage -->
                                    <path fill="#6e767e" d="M75.7,42.9c1.6,0,3.2,0.1,4.8,0.4V27.7c0-3.9-3.2-7.1-7.1-7.1H55.6v-5.2c0-5-4.1-9.1-9.1-9.1H36.4c-5,0-9.1,4.1-9.1,9.1v5.2H9.6c-3.9,0-7.1,3.2-7.1,7.1v39.9c0,3.9,3.2,7.1,7.1,7.1h37.3c-0.1-0.9-0.1-1.9-0.1-2.8C46.8,55.9,59.7,42.9,75.7,42.9z M34.5,15.4c0-1.1,0.9-2,1.9-2h10.1c1.1,0,2,0.9,2,2v5.2h-14V15.4z"/>
                                    <!-- green plus -->
                                    <path fill="#0f815a" d="M75.7,50c-12,0-21.8,9.8-21.8,21.8c0,12,9.8,21.8,21.8,21.8s21.8-9.8,21.8-21.8C97.5,59.8,87.7,50,75.7,50z M85.3,75.4h-6v6c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6v-6h-6c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6h6v-6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6v6h6c2,0,3.6,1.6,3.6,3.6S87.2,75.4,85.3,75.4z"/>
                                </g>
                            </svg>
                                </span>
                        </th>
                        <th class="text-center" style="width: 76px">
                            <acms-v-icon size="25" class="ms-auto me-auto" name="ticket"/>
                        </th>
                        <th class="text-center fz-14">TYPE</th>
                        <th class="text-center fz-14" style="padding: 0!important;">PROGRAM/PCC</th>
                        <th class="text-center fz-14">FARE TYPE</th>
                        <th class="text-center fz-14">IS IT REAL?</th>
                        <th class="text-center fz-14">CABIN</th>
                    </tr>
                    </thead>
                    <tbody class="pq-table">
                    <template v-if="data">
                        <template v-if="!simpleView">
                            <template v-if="modalMode && data?.pq_flight_legs">
                                <template v-for="(item, itemIndex) in  data.pq_flight_legs">
                                    <template v-if="item.flight_details && item.flight_details.length > 0">
                                        <tr style="height: 58px" v-for="(detail, detailIndex) in item.flight_details">
                                            <td class="text-uppercase text-center">{{detailIndex === 0 ? detail.order ?? item.order : '' }}</td>
                                            <td>{{ detailIndex === 0 ? detail.airline ?? item.airline ?? '' : '' }}
                                                <template  v-if="detail?.operatedBy">
                                                    <span v-tooltip.right="'OPERATED BY: ' +detail.operatedBy" style="color: red"><b>!</b></span>
                                                </template>
                                            </td>
                                            <td>{{  detailIndex === 0  ? detail.flightNumber ?? item.flight_number : '' }}</td>
                                            <td>{{ detailIndex === 0 ? item?.booking_class : '' }}</td>
                                            <td>{{ detailIndex === 0  ? item?.departure_day_of_week : '' }}</td>
                                            <td>{{  detailIndex === 0 ? detail.departureDate ?? item.departure_date :  detail.departureDate ?? '' }} <sup style="color: red"><b>{{getDaysDifference(detail.departureDate, item?.arrival_date)}}</b></sup></td>
                                            <td>{{  detail.from ?? item.from ?? '' }}</td>
                                            <td>{{  detail.to  ?? item.to ?? '' }}</td>
                                            <td>{{  item?.status ?? ""  }}</td>
                                            <td>{{ item?.quantity ?? ""}}</td>
                                            <td>{{  detail.timeFrom ?? item.time_from ?? '' }}</td>
                                            <td>{{  detail.timeTo ?? item.time_to ?? ''  }}</td>
                                            <td>{{  detail.flightDuration ?? item.time_travel ?? ''  }}</td>
<!--                                            <td class="text-center "-->
<!--                                                :class="{'bg-warning-light': detailIndex > 0 }">-->
<!--                                                <template v-if="detailIndex > 0">-->
<!--                                                    {{ calculateWaitTime(detail.timeFrom, item.flight_details[detailIndex-1].timeTo ) }}-->
<!--                                                </template>-->

<!--                                            </td>-->
<!--                                            <td>-->
<!--                                                123-->
<!--                                            </td>-->
                                            <td
                                                class="text-center"
                                                v-if="itemIndex === 0 && detailIndex === 0">
                                                <!-- Ваше содержимое здесь, если нужно -->
                                            </td>

                                            <!-- Ячейка 2: для условия detailIndex > 0 -->
                                            <td
                                                class="text-center"
                                                :class="{'bg-warning-light': calculateWaitTimeModal(detail, item.flight_details[detailIndex-1]) !== null }"
                                                v-else-if="detailIndex > 0 && detail.timeFrom && item.flight_details[detailIndex-1].timeTo">
                                                {{ calculateWaitTimeModal(detail, item.flight_details[detailIndex-1]) }}
                                            </td>

                                            <!-- Ячейка 3: для условия detailIndex === 0 и itemIndex !== 0 -->
                                            <td
                                                class="text-center"
                                                :class="{'bg-warning-light':calculateWaitTimeModal(detail, data.pq_flight_legs[itemIndex-1].flight_details[data.pq_flight_legs[itemIndex-1].flight_details.length-1]) !== null }"
                                                v-else-if="detailIndex === 0 && itemIndex !== 0 && detail.timeFrom && data.pq_flight_legs[itemIndex-1].flight_details.length > 0 && data.pq_flight_legs[itemIndex-1].flight_details[0].timeTo">
                                                {{ calculateWaitTimeModal(detail, data.pq_flight_legs[itemIndex-1].flight_details[data.pq_flight_legs[itemIndex-1].flight_details.length-1]) }}
                                            </td>
                                            <td v-else>
                                            </td>
                                            <template v-if="getRowSpanHeight(item.order)">
                                                <template v-if="detailIndex === 0">
                                                <td style="border-bottom: 1px solid  #E9ECEF" class="bg-info-light text-center"
                                                    :rowspan="getRowSpanHeight(item.order).rowspan">
                                                    {{ getRowSpanHeight(item.order).duration }}
                                                </td>
                                                </template>
                                            </template>
                                            <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                                <td></td>
                                            </template>
                                            <template v-if="withAdditionalPqColumns && detailIndex === 0 ">
                                                <td class="p-0" style="width: 120px !important;">
                                                    <div style="width: 90px">
                                                    <acms-v-form-field
                                                        :modeSelect="['not selected','0','1','2','3','4']"
                                                        placeholder="Choose"
                                                        hideHintInit
                                                        class="pq-table-row-select small-select small-select-baggage"
                                                        :modelValue="additionalDataBaggage[item.order].baggage_qnt"
                                                        @update:modelValue="(val)=>updateAdditionalDataBaggage(item.order,   val)"
                                                    />
                                                    </div>
                                                </td>
                                                <td class="p-0">
                                                    <div class="mx-auto" style="width: 76px;min-height: 42px">
                                                        <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                           :modeSelect="{
                                                                        options:getTicketsOptions,
                                                                        hideSelectedOption:true
                                                                    }"
                                                                           hideHintInit
                                                                           placeholder="0"
                                                                           class="pq-table-row-select small-select"
                                                                           :modelValue="locState.data[item.order].ticket"
                                                                           @update:modelValue="(val)=>updateAdditionalData(item.order,'ticket',  val)"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="p-0" style="padding: 0!important;">
                                                    <div style="width: 110px;min-height:42px">
                                                        <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                           :modeSelect="backendOptions.pqTypesOptions"
                                                                           @update:modelValue="(val)=>updateAdditionalData(item.order,'type',  val)"
                                                                           :name="getErrorKey('type', itemIndex )"
                                                                           hideHintInit
                                                                           v-bind="getRowSelectPropsByKey(item, 'type')"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="p-0" style="padding: 0!important;">
                                                    <div style="width: 110px;min-height: 42px">
                                                        <template v-if="locState.tableSelectsShow">
                                                            <template v-if="getRowType(item)">
                                                                <template v-if="getRowType(item) == '1'">

                                                                    <acms-v-form-field
                                                                        :modeSelect="{
                                                                    search:true,
                                                                    searchAsync: 'infohub.getPccs',
                                                                    placeholderSelect:'Search',
                                                                    minSymbolsForSearch:2,
                                                                    optionKeyTitle:'pcc',
                                                                    toggleKeyTitle:'pcc',
                                                                    options:backendOptions.firstFivePccOptions,
                                                                   }"
                                                                        hideHintInit
                                                                        :name="getErrorKey('pcc', itemIndex )"
                                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'pcc',  val)"
                                                                        v-bind="getRowSelectPropsByKey(item, 'pcc')"
                                                                    />
                                                                </template>
                                                                <template v-if="getRowType(item) == '2'">
                                                                    <div style="width: 110px">
                                                                    <acms-v-form-field
                                                                        :modeSelect="{
                                                                    search:true,
                                                                    minSymbolsForSearch:2,
                                                                    searchAsync: 'infohub.getMileagesList',
                                                                    options:backendOptions.firstFiveMileageOptions,
                                                                   }"
                                                                        hideHintInit
                                                                        :name="getErrorKey('mileage', itemIndex )"
                                                                        v-bind="getRowSelectPropsByKey(item, 'mileage')"
                                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'mileage',  val)"
                                                                    />
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <acms-v-form-field
                                                                    :modeSelect="[]"
                                                                    placeholder="Choose "
                                                                    class="pq-table-row-select  small-med-select"
                                                                    :name="getErrorKey('program-pcc', itemIndex)"
                                                                    :errors="additionalDataErrors"
                                                                    disabled
                                                                    hideHintInit
                                                                />
                                                            </template>
                                                        </template>
                                                    </div>
                                                </td>
                                                <td class="p-0" style="padding: 0!important;">
                                                <template v-if="locState.tableSelectsShow">
                                                        <template v-if="getRowType(item)">
                                                            <template v-if="getRowType(item) == '1'">
                                                                <div style="width: 110px;">
                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    options:['PVT', 'PUB', 'TUR', 'CRP', 'PUB/C']
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('fare_type', itemIndex )"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'fare_type',  val)"
                                                                    v-bind="getRowSelectPropsByKey(item, 'fare_type')"
                                                                />
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </td>
                                                <td class="p-0" style="width: 120px !important; padding: 0!important;">
                                                    <div style="width: 110px;">
                                                    <acms-v-form-field
                                                        :modeSelect="{
                                                                    options:['Real', 'Fake', 'Magic', 'Upgrade']
                                                                   }"
                                                        hideHintInit
                                                        :name="getErrorKey('is_real', itemIndex )"
                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'is_real',  val)"
                                                        v-bind="getRowSelectPropsByKey(item, 'is_real')"
                                                    />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style="display: flex; justify-content: center">
                                                        <!-- Кнопка с динамическим содержимым и цветом -->
                                                        <button
                                                            :class="['custom-button', buttonColor[itemIndex]]"
                                                            @click="changeButtonContent(item, itemIndex)"
                                                        >
                                                            {{ buttonText[itemIndex] }}
                                                            <span v-if="showDot[itemIndex]" class="dot"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </template>
                                        </tr>
                                    </template>
                                    <tr v-else style="height: 36px">
                                        <td class="text-uppercase text-center">{{ item.order }}</td>
                                        <td>{{ item.airline }}</td>
                                        <td>{{ item?.flight_number ?? '' }}</td>
                                        <td>{{ item?.booking_class ?? '' }}</td>
                                        <td>{{ item?.departure_day_of_week ?? '' }}</td>
                                        <td style="white-space: nowrap"> {{ item?.departure_date ?? '' }} <sup style="color: red;"><b>{{getDaysDifference(item.departure_date, item.arrival_date)}}</b></sup></td>
                                        <td>{{ item?.from }}</td>
                                        <td>{{ item?.to }}</td>
                                        <td>{{ item?.status ?? '' }}</td>
                                        <td>{{ item?.quantity ?? '' }}</td>
                                        <td>{{ item.time_from ?? '' }}</td>
                                        <td>{{ item.time_to ?? '' }}</td>
<!--                                        <td>{{ item.arrival_date ?? '' }}</td>-->
<!--                                        <td>{{ item.arrival_day_of_week ?? '' }}</td>-->
<!--                                        <td>{{ item.time_travel ?? '' }}</td>-->
                                        <td>{{ item.flight_details?.flightDuration ?? item.time_travel ?? '' }}</td>
<!--                                        <td class="text-center "-->
<!--                                            :class="{'bg-warning-light': item?.time_connection?.type?.name == 'LAYOVER'}">-->
<!--                                            <template v-if="item?.time_connection?.type?.name == 'LAYOVER'">-->
<!--                                                {{ item?.time_connection?.duration ?? '' }}-->
<!--                                            </template>-->

<!--                                        </td>-->
                                        <td
                                            class="text-center">
                                           ERR
                                        </td>
                                        <template v-if="getRowSpanHeight(item.order)">
                                            <td class="bg-info-light text-center"
                                                :rowspan="getRowSpanHeight(item.order).rowspan">
                                                {{ getRowSpanHeight(item.order).duration }}
                                            </td>
                                        </template>
                                        <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                            <td></td>
                                        </template>
                                        <template v-if="withAdditionalPqColumns ">
                                            <td class="p-0" style="width: 120px !important;">
                                                <div style="width: 90px">
                                                <acms-v-form-field
                                                    :modeSelect="['not selected','0','1','2','3','4']"
                                                    placeholder="Choose"
                                                    hideHintInit
                                                    class="pq-table-row-select small-select small-select-baggage"
                                                    :modelValue="additionalDataBaggage[item.order]?.baggage_qnt ?? 0"
                                                    @update:modelValue="(val)=>updateAdditionalDataBaggage(item.order,   val === 'not selected' ? null : val)"
                                                />
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div class="mx-auto" style="width: 76px;min-height: 42px">
                                                    <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                       :modeSelect="{
                                                                        options:getTicketsOptions,
                                                                        hideSelectedOption:true
                                                                    }"
                                                                       hideHintInit
                                                                       placeholder="0"
                                                                       class="pq-table-row-select small-select"
                                                                       :modelValue="locState.data[item.order]?.ticket"
                                                                       @update:modelValue="(val)=>updateAdditionalData(item.order,'ticket',  val)"
                                                    />
                                                </div>
                                            </td>
                                            <td class="p-0" style="padding: 0!important;">
                                                <div style="width: 110px;min-height:42px">
                                                    <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                       :modeSelect="backendOptions.pqTypesOptions"
                                                                       @update:modelValue="(val)=>updateAdditionalData(item.order,'type',  val)"
                                                                       :name="getErrorKey('type', itemIndex )"
                                                                       hideHintInit
                                                                       v-bind="getRowSelectPropsByKey(item, 'type')"
                                                    />
                                                </div>
                                            </td>
                                            <td class="p-0" style="padding: 0!important;">
                                                <div style="width: 110px;min-height: 42px">
                                                    <template v-if="locState.tableSelectsShow">
                                                        <template v-if="getRowType(item)">
                                                            <template v-if="getRowType(item) == '1'">

                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    search:true,
                                                                    searchAsync: 'infohub.getPccs',
                                                                    placeholderSelect:'Search',
                                                                    minSymbolsForSearch:2,
                                                                    optionKeyTitle:'pcc',
                                                                    toggleKeyTitle:'pcc',
                                                                    options:backendOptions.firstFivePccOptions,
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('pcc', itemIndex )"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'pcc',  val)"
                                                                    v-bind="getRowSelectPropsByKey(item, 'pcc')"
                                                                />
                                                            </template>
                                                            <template v-if="getRowType(item) == '2'">
                                                                <div style="width: 110px">
                                                                    <acms-v-form-field
                                                                        :modeSelect="{
                                                                    search:true,
                                                                    minSymbolsForSearch:2,
                                                                    searchAsync: 'infohub.getMileagesList',
                                                                    options:backendOptions.firstFiveMileageOptions,
                                                                   }"
                                                                        hideHintInit
                                                                        :name="getErrorKey('mileage', itemIndex )"
                                                                        v-bind="getRowSelectPropsByKey(item, 'mileage')"
                                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'mileage',  val)"
                                                                    />
                                                                </div>

                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <acms-v-form-field
                                                                :modeSelect="[]"
                                                                placeholder="Choose "
                                                                class="pq-table-row-select  small-med-select"
                                                                :name="getErrorKey('program-pcc', itemIndex)"
                                                                :errors="additionalDataErrors"
                                                                disabled
                                                                hideHintInit
                                                            />
                                                        </template>
                                                    </template>
                                                </div>
                                            </td>
                                            <td class="p-0" style="padding: 0!important;">
                                                <template v-if="locState.tableSelectsShow">
                                                    <template v-if="getRowType(item)">
                                                        <template v-if="getRowType(item) == '1'">
                                                            <div style="width: 110px;">
                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    options:['PVT', 'PUB', 'TUR', 'CRP', 'PUB/C']
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('fare_type', itemIndex )"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'fare_type',  val)"
                                                                    v-bind="getRowSelectPropsByKey(item, 'fare_type')"
                                                                />
                                                            </div>
                                                        </template>
                                                    </template>
                                                </template>
                                            </td>
                                            <td class="p-0" style="width: 120px !important; padding: 0!important;">
                                                <div style="width: 110px;">
                                                    <acms-v-form-field
                                                        disabled="false"
                                                        :modeSelect="{
                                                                    options:['Real', 'Fake', 'Magic', 'Upgrade']
                                                                   }"
                                                        hideHintInit
                                                        :name="getErrorKey('is_real', itemIndex )"
                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'is_real',  val)"
                                                        v-bind="getRowSelectPropsByKey(item, 'is_real')"
                                                    />
                                                </div>
                                            </td>
                                            <td >
                                                <div style="display: flex; justify-content: center">
                                                    <!-- Кнопка с динамическим содержимым и цветом -->
                                                    <button
                                                        :class="['custom-button', buttonColor[itemIndex]]"
                                                        @click="changeButtonContent(item, itemIndex)"
                                                    >
                                                        <span v-if="buttonText[itemIndex] !== ''"> {{buttonText[itemIndex]}}</span>
                                                        <span v-else style="display: flex; justify-content: center; align-items: center"><svg xmlns="http://www.w3.org/2000/svg" width="23"  height="23" viewBox="-5.0 -10.0 110.0 135.0">
                                                        <path d="m96.102 76.898-37.102-64.199c-4-7-14-7-18.102 0l-37 64.199c-4 6.8984 1 15.602 9 15.602h74.102c8.1016 0 13.102-8.6992 9.1016-15.602zm-46.102-48.199c3.1992 0 5.6992 2.6016 5.6992 5.6992v0.39844l-1.8984 28.602c-0.10156 2-1.8008 3.6016-3.8008 3.6016s-3.6992-1.6016-3.8008-3.6016l-1.8984-28.602c-0.19922-3.2969 2.3984-6.0977 5.6992-6.0977zm0 53.402c-3.3008 0-6-2.6992-6-6 0-3.3008 2.6992-6 6-6s6 2.6992 6 6c0 3.2969-2.6992 6-6 6z" fill="#EA580C"/>
                                                        </svg></span>
                                                        <span v-if="showDot[itemIndex]" class="dot"></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="(item, itemIndex) in data.pq_flight_legs">
                                    <template v-if="item.flight_details && item.flight_details.length > 0">
                                        <tr style="height: 43px!important;"  v-for="(detail, detailIndex) in item.flight_details">
                                            <td class="text-uppercase text-center">{{ detailIndex === 0 ? detail.order ?? item.order : ''}}</td>
                                            <td>
                                            <span>{{detailIndex === 0 ? detail?.airline ?? item.airline ?? '' : ''}}</span>
                                                <template  v-if="detail?.operated_by">
                                                       <span v-tooltip.right="'OPERATED BY: ' +detail.operated_by" style="color: red"><b>!</b></span>
                                                </template>
                                            </td>
                                            <td>{{ detailIndex === 0 ? detail.flight_number ?? item.flight_number : '' }}</td>
                                            <td>{{ detailIndex === 0 ? item?.booking_class : '' }}</td>
                                            <td>{{  detailIndex ===0 ? item?.departure_day_of_week : '' }}</td>
                                            <td style="white-space: nowrap"> {{detailIndex === 0 ? detail.departure_date ?? item?.departure_date : detail.departure_date  }} <sup style="color: red;"><b>{{getDaysDifference( detail.departure_date ?? item.departure_date, item.arrival_date)}}</b></sup></td>
                                            <td>
                                                <span>{{ detail?.from ?? item.from ?? '' }}</span>
                                            </td>
                                            <td>
                                                <span>{{ detail?.to ?? item.to ?? '' }}</span>

                                            </td>
                                            <td>{{  item?.status ?? ""  }}</td>
                                            <td>{{ item?.quantity ?? ""}}</td>
                                            <td>{{  detail.time_from ?? item.time_from  ?? '' }}</td>
                                            <td>{{  detail.time_to ?? item.time_to ?? '' }}</td>
<!--                                            <td>{{  ""  }}</td>-->
<!--                                            <td>{{  ""  }}</td>-->
                                            <td>{{  detail.flight_duration ?? item.time_travel ?? '' }}</td>
<!--                                            <td style="border: 1px solid #E9ECEF" class="text-center "-->
<!--                                                :class="{'bg-warning-light': detailIndex > -1 }">-->
<!--                                                <template v-if="itemIndex === 0 && detailIndex === 0">-->
<!--                                                </template>-->
<!--                                                <template v-else-if="detailIndex > 0 && detail.time_from && item.flight_details[detailIndex-1].time_to">-->
<!--                                                    {{  calculateWaitTime(detail, item.flight_details[detailIndex-1])}}-->
<!--                                                </template>-->
<!--                                                <template v-else-if="detailIndex === 0 && itemIndex!== 0 && detail.time_from &&  data.pq_flight_legs[itemIndex-1].flight_details.length >0 && data.pq_flight_legs[itemIndex-1].flight_details[0].time_to">-->
<!--                                                    {{  calculateWaitTime(detail , data.pq_flight_legs[itemIndex-1].flight_details[data.pq_flight_legs[itemIndex-1].flight_details.length-1])}}-->
<!--                                                </template>-->

<!--                                            </td>-->
                                            <td
                                                class="text-center"
                                                v-if="itemIndex === 0 && detailIndex === 0">
                                                <!-- Ваше содержимое здесь, если нужно -->
                                            </td>

                                            <!-- Ячейка 2: для условия detailIndex > 0 -->
                                            <td
                                                class="text-center"
                                                :class="{'bg-warning-light': calculateWaitTime(detail, item.flight_details[detailIndex-1]) !== null }"
                                                v-else-if="detailIndex > 0 && detail.time_from && item.flight_details[detailIndex-1].time_to">
                                                {{ calculateWaitTime(detail, item.flight_details[detailIndex-1]) }}
                                            </td>

                                            <!-- Ячейка 3: для условия detailIndex === 0 и itemIndex !== 0 -->
                                            <td
                                                class="text-center"
                                                :class="{'bg-warning-light':calculateWaitTime(detail, data.pq_flight_legs[itemIndex-1].flight_details[data.pq_flight_legs[itemIndex-1].flight_details.length-1]) !== null }"
                                                v-else-if="detailIndex === 0 && itemIndex !== 0 && detail.time_from && data.pq_flight_legs[itemIndex-1].flight_details.length > 0 && data.pq_flight_legs[itemIndex-1].flight_details[0].time_to">
                                                {{ calculateWaitTime(detail, data.pq_flight_legs[itemIndex-1].flight_details[data.pq_flight_legs[itemIndex-1].flight_details.length-1]) }}
                                            </td>
                                            <td v-else>

                                            </td>
                                            <template v-if="getRowSpanHeight(item.order)">
                                                <template v-if="detailIndex === 0">
                                                    <td class="bg-info-light text-center"
                                                        style="border-bottom: 1px solid #E9ECEF"
                                                        :rowspan="getRowSpanHeight(item.order).rowspan">
                                                        {{ getRowSpanHeight(item.order).duration }}
                                                    </td>
                                                </template>
                                            </template>
                                            <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                                <td></td>
                                            </template>
                                            <td v-if="item.type.id == 1" v-tooltip.right="'PCC: '+item?.pcc?.pcc">
                                                <div class="ticket-icon-wrapper-r">
                                                    <span class="revenue-ticket">
                                                        R
                                                         <span :class="getTicketsClass(item.ticket)">{{item.ticket}}</span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td v-else-if="item.type.id == 2" v-tooltip.right="'Mileage Program: '+item?.mileage?.title">
                                                <div class="ticket-icon-wrapper-a">
                                                    <span class="award-ticket">
                                                        A
                                                        <span :class="getTicketsClass(item.ticket)">{{item.ticket}}</span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td v-else-if="item.type.id == 3" v-tooltip.right="'Online ticket'">
                                                <div class="ticket-icon-wrapper-o" style="border-color: #2563EB!important;">
                                                    <span class="online-ticket">
                                                        O
<!--                                                        <span :class="item.ticket === 1 ? 'number-circle-two' : 'number-circle-one'">{{item.ticket}}</span>-->
                                                        <span :class="getTicketsClass(item.ticket)">{{item.ticket}}</span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                {{item.fare_type}}
                                            </td>
                                            <td>
                                                <acms-v-icon class="ms-auto me-auto   "
                                                             size="20"
                                                             :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                             :quantity="item?.baggage"
                                                             quantityShow
                                                             quantitySmall
                                                             name="suitcase"/>
                                            </td>
                                            <td v-html="getStatusIcon(item.is_real)"></td>
<!--                                            <td v-if="pccOrProgram">-->
<!--                                                <acms-v-icon class="ms-auto me-auto"-->
<!--                                                             size="20"-->
<!--                                                             quantitySmall-->
<!--                                                             :quantity="item?.ticket"-->
<!--                                                             name="ticket"/>-->
<!--                                            </td>-->
<!--                                            <td v-if="pccOrProgram">-->
<!--                                            <span class="white-space-nowrap">-->
<!--                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}-->
<!--                                            </span>-->
<!--                                            </td>-->
                                            <td  v-if="item?.flight_details[0]?.cabin_type && !item?.flight_details[0]?.changed_cabin_class">
                                                <div :style="'width: 20px; height: 20px; border: 1px solid;' + cabinColor(item?.flight_details[0]?.cabin_type)+'; color:'+cabinColor(item?.flight_details[0]?.cabin_type)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip.right="'CABIN TYPE : ' + item?.flight_details[0]?.cabin_type" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.cabin_type)}}
                                            </span>
                                                </div>

                                            </td>
                                            <td  v-else-if="item?.flight_details[0]?.changed_cabin_class">
                                                <div class="custom-button-request" :style="cabinColor(item?.flight_details[0]?.changed_cabin_class)+'; color:'+cabinColor(item?.flight_details[0]?.changed_cabin_class)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip.right="'INITIAL CABIN TYPE: ' + item?.flight_details[0]?.cabin_type +' \nCURENT CABIN TYPE: ' + item?.flight_details[0]?.changed_cabin_class" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.changed_cabin_class)}}
                                            </span>
                                                    <span v-if="item?.flight_details[0]?.changed_cabin_class !== item?.flight_details[0]?.cabin_type" class="dot-request"></span>
                                                </div>
                                            </td>

                                            <td v-else v-tooltip.right="'Cabin type was not defined based on the VI* output. Please copy VI* output again in full and add a new PQ'" style="padding-left: 7px!important;">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23"  height="23" viewBox="-5.0 -10.0 110.0 135.0">
                                                 <path d="m96.102 76.898-37.102-64.199c-4-7-14-7-18.102 0l-37 64.199c-4 6.8984 1 15.602 9 15.602h74.102c8.1016 0 13.102-8.6992 9.1016-15.602zm-46.102-48.199c3.1992 0 5.6992 2.6016 5.6992 5.6992v0.39844l-1.8984 28.602c-0.10156 2-1.8008 3.6016-3.8008 3.6016s-3.6992-1.6016-3.8008-3.6016l-1.8984-28.602c-0.19922-3.2969 2.3984-6.0977 5.6992-6.0977zm0 53.402c-3.3008 0-6-2.6992-6-6 0-3.3008 2.6992-6 6-6s6 2.6992 6 6c0 3.2969-2.6992 6-6 6z" fill="#EA580C"/>
                                                </svg>
                                            </span>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr style="height: 40px" v-else>
                                        <td class="text-uppercase text-center">{{ item.order }}</td>
                                        <td>
                                <span
                                    style="white-space: nowrap">{{ item?.airline ?? '' }}  <b v-if="item?.flight_details[0]?.operated_by" style="color: red">!</b></span>
                                        </td>
                                        <td class=" ">{{ item?.flight_number ?? '' }}</td>
                                        <td class=" ">

                                            <span>
                                                 {{ item?.booking_class ?? '' }}
                                            </span>

                                        </td>
                                        <td class=" ">{{ item?.departure_day_of_week ?? '' }}</td>
                                        <td style="white-space: nowrap"> {{ item?.departure_date ?? '' }} <sup style="color: red;"><b>{{getDaysDifference(item.departure_date, item.arrival_date, item.created_at)}}</b></sup></td>
                                          <td class=" ">
                                <span>{{ item?.from ?? '' }}</span>
                                        </td>
                                        <td class="">
                              <span>{{ item?.to ?? '' }}</span>
                                        </td>
                                        <td class=" ">{{ item?.status ?? '' }}</td>
                                        <td class=" ">{{ item?.quantity ?? '' }}</td>
                                        <td class=" ">{{ item.time_from ?? '' }}</td>
                                        <td class=" ">{{ item.time_to ?? '' }}</td>
<!--                                        <td class=" ">{{ item.arrival_date ?? '' }}</td>-->
<!--                                        <td class=" ">{{ item.arrival_day_of_week ?? '' }}</td>-->
                                        <td class=" ">{{ item.time_travel ?? '' }}</td>
                                        <td v-if="!item.flight_details" class="text-center "
                                            :class="{'bg-warning-light': item?.time_connection?.type?.name == 'LAYOVER'}">
                                            <template v-if="item?.time_connection?.type?.name == 'LAYOVER'">
                                                {{ item?.time_connection?.duration ?? '' }}
                                            </template>
                                        </td>
                                        <td v-else class="text-center "
                                            :class="'bg-warning-light'">
                                            {{detailIndex}}
                                            <template v-if="detailIndex > -1 && detail.time_from && item.flight_details[detailIndex-1].time_to">
                                                {{ calculateWaitTime(detail, item.flight_details[detailIndex-1])}}
                                            </template>
                                        </td>

                                        <template v-if="getRowSpanHeight(item.order)">
                                            <td class="bg-info-light text-center"
                                                :rowspan="getRowSpanHeight(item.order).rowspan ">
                                                {{ getRowSpanHeight(item.order).duration }}
                                            </td>
                                        </template>
                                        <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                            <td></td>
                                        </template>
                                        <td class="text-center">
                                           {{item.fare_type}}
                                        </td>

                                        <td>
                                            <acms-v-icon class="ms-auto me-auto   "
                                                         size="20"
                                                         :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                         :quantity="item?.baggage"
                                                         quantityShow
                                                         quantitySmall
                                                         name="suitcase"/>
                                        </td>
                                        <td v-html="getStatusIcon(item.is_real)"></td>
                                        <td v-if="pccOrProgram">
                                            <acms-v-icon class="ms-auto me-auto"
                                                         size="20"
                                                         quantitySmall
                                                         :quantity="item?.ticket"
                                                         name="ticket"/>
                                        </td>
                                        <td v-if="pccOrProgram">
                                            <span class="white-space-nowrap">
                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}
                                            </span>
                                        </td>
                                        <td  v-if="item?.flight_details[0]?.cabin_type && !item?.flight_details[0]?.changed_cabin_class">
                                            <div :style="'width: 18px; height: 18px; border: 1px solid;' + cabinColor(item?.flight_details[0]?.cabin_type)+'; color:'+cabinColor(item?.flight_details[0]?.cabin_type)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip.right="'CABIN TYPE : ' + item?.flight_details[0]?.cabin_type" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.cabin_type)}}
                                            </span>
                                            </div>

                                        </td>
                                        <td  v-else-if="item?.flight_details[0]?.changed_cabin_class">
                                            <div v-if="item?.flight_details[0]?.changed_cabin_class !== item?.flight_details[0]?.cabin_type" class="custom-button-request" :style="cabinColor(item?.flight_details[0]?.changed_cabin_class)+'; color:'+cabinColor(item?.flight_details[0]?.changed_cabin_class)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip.right="'INITIAL CABIN TYPE: ' + item?.flight_details[0]?.cabin_type +' \nCURENT CABIN TYPE: ' + item?.flight_details[0]?.changed_cabin_class" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.changed_cabin_class)}}
                                            </span>
                                                <span v-if="item?.flight_details[0]?.changed_cabin_class !== item?.flight_details[0]?.cabin_type" class="dot-request"></span>
                                            </div>
                                            <div v-else class="custom-button-request" :style="cabinColor(item?.flight_details[0]?.changed_cabin_class)+'; color:'+cabinColor(item?.flight_details[0]?.changed_cabin_class)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip.right="'CABIN TYPE: ' + item?.flight_details[0]?.changed_cabin_class" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.changed_cabin_class)}}
                                            </span>
                                                <span v-if="item?.flight_details[0]?.changed_cabin_class !== item?.flight_details[0]?.cabin_type" class="dot-request"></span>
                                            </div>
                                        </td>

                                        <td v-else v-tooltip.right="'Cabin type was not defined based on the VI* output. Please copy VI* output again in full and add a new PQ'" style="padding-left: 7px!important;">
                                            <span>
<svg xmlns="http://www.w3.org/2000/svg" width="23"  height="23" viewBox="-5.0 -10.0 110.0 135.0">
 <path d="m96.102 76.898-37.102-64.199c-4-7-14-7-18.102 0l-37 64.199c-4 6.8984 1 15.602 9 15.602h74.102c8.1016 0 13.102-8.6992 9.1016-15.602zm-46.102-48.199c3.1992 0 5.6992 2.6016 5.6992 5.6992v0.39844l-1.8984 28.602c-0.10156 2-1.8008 3.6016-3.8008 3.6016s-3.6992-1.6016-3.8008-3.6016l-1.8984-28.602c-0.19922-3.2969 2.3984-6.0977 5.6992-6.0977zm0 53.402c-3.3008 0-6-2.6992-6-6 0-3.3008 2.6992-6 6-6s6 2.6992 6 6c0 3.2969-2.6992 6-6 6z" fill="#EA580C"/>
</svg>
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template v-if="simpleView && getSimpleViewData">
                            <template v-for="(item) in getSimpleViewData">
                                <tr>
                                    <td class="text-uppercase text-center">{{ item.order }}</td>
                                    <td>{{ item?.airline_info ?? '' }}</td>
                                    <td>{{ item?.departure_date ?? '' }}</td>
                                    <td>{{ item?.departure_day_of_week ?? '' }}</td>
                                    <td>{{ item?.fromTo ?? '' }}</td>
                                    <td>{{ item.statusQnt }}</td>
                                    <td>{{ item.time_from }}</td>
                                    <td>{{ item?.time_to ?? '' }}</td>
                                    <td>{{ item?.arrival_date ?? '' }}</td>
                                    <td>
                                        <acms-v-icon class="ms-auto me-auto"
                                                     size="20"
                                                     :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                     :quantity="item?.baggage"
                                                     quantityShow
                                                     name="suitcase"/>
                                    </td>
                                    <td v-if="pccOrProgram">
                                        <acms-v-icon class="ms-auto me-auto"
                                                     size="20"
                                                     quantitySmall
                                                     :quantity="item?.ticket"
                                                     name="ticket"/>
                                    </td>
                                    <td v-if="pccOrProgram">
                                            <span class="white-space-nowrap">
                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}
                                            </span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>export default {name: 'PartialParsedPqCodeTable'};</script>
<script setup="">

import {computed, onMounted, reactive, ref, watch} from 'vue';
import AcmsVFormField from "@ui/FormField/FormField.vue";
import {getRoute} from "@plugins/useRoutes";
import {cloneDeep} from "lodash";
import useBackendMiddlewarePropsService from "@services/BackendMiddlewarePropsService";
const props = defineProps({
    data: [Object, Array],
    simpleView: Boolean,
    tableClass: String,

    selectProgramOptions: Array,
    selectedPrograms: Object,
    selectedProgramsErrors: Object,
    selectedProgramsName: String,

    withAdditionalPqColumns: Boolean,
    additionalData: Object,
    additionalDataBaggage: Object,
    additionalDataErrors: Object,
    currentStep: [String, Number],

    showFromBack: Boolean,
    pccOrProgram: Boolean,

    modalMode: Boolean,
    modeDuplicate: Boolean,
    modeDraft: Boolean,
});
const getStatusIcon = (status) => {
    switch (status) {
        case 'Real':
            return `<svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
              </svg>`;
        case 'Fake':
            return `<svg width="19" height="19" fill="#EA580C" viewBox="-1.7 0 20.4 20.4" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"><path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-6.804.01 3.032-3.033a.792.792 0 0 0-1.12-1.12L8.494 9.173 5.46 6.14a.792.792 0 0 0-1.12 1.12l3.034 3.033-3.033 3.033a.792.792 0 0 0 1.12 1.119l3.032-3.033 3.033 3.033a.792.792 0 0 0 1.12-1.12z"></path></g>
              </svg>`;
        case 'Magic':
            return `<svg  width="19" height="19" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ECC049">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M15.224 15.508l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z"></path> </g> </g>
              </svg>`;
        case 'Upgrade':
            return `<svg width="19" height="19" fill="#3276C3" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <path d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 1l3 3h-2v3h-2v-3h-2l3-3z"></path> </g>
              </svg>`;
        default:
            return '';
    }
};

const emit = defineEmits(['update:selectedPrograms',
    'update:additionalData',
    'update:additionalDataBaggage',
    'addBaggage',
    'removeBaggage'
]);
const {backendOptions} = useBackendMiddlewarePropsService()

const buttonText = ref([]);  // Массив текстов кнопок
const showDot = ref([]);     // Массив видимости точек
const buttonColor = ref([]); // Массив цветов кнопок
const initialState = ref([]);
// Инициализация состояния кнопок для каждого элемента
const   initializeButtonState = (items) => {
    items.forEach((item, index) => {
        const flightDetails = item.flight_details;

        if (flightDetails) {
// Найдём первый элемент с cabinType
            const lengthFD = flightDetails.length;
            const firstFlightWithCabinType = flightDetails.find(flight => flight.cabinType);
// Проверим, был ли найден такой элемент
            if (firstFlightWithCabinType) {
                // Обновляем значения только на основе первого найденного cabinType
                buttonText.value[index] = getCabinCode(firstFlightWithCabinType.cabinType);
                buttonColor.value[index] = getCabinClassColor(firstFlightWithCabinType.cabinType);
            }
            showDot.value[index] = false;
            if (locState.data) {
                const currentData = locState.data[index + 1];
                const hasCabinClass = currentData.cabin_class;
                const effectiveCabinClass = hasCabinClass ? currentData.cabin_class : currentData.initial_cabin_class;

                if (lengthFD < 2) {
                    initialState.value[index] = currentData.initial_cabin_class;
                    buttonText.value[index] = getCabinCode(effectiveCabinClass);
                    buttonColor.value[index] = getCabinClassColor(effectiveCabinClass);
                    showDot.value[index] = hasCabinClass && (currentData.initial_cabin_class !== currentData.cabin_class);
                } else {
                    if ((props.modeDraft && props.modalMode) || props.modeDuplicate) {
                        const curCab = currentData.cabin_class !== ''  ? currentData.initial_cabin_class : currentData.initial_cabin_class
                        initialState.value[index] = firstFlightWithCabinType.cabinType;
                        buttonText.value[index] = getCabinCode(curCab); // redundant, but kept for clarity
                        buttonColor.value[index] = getCabinClassColor(curCab); // redundant, kept for symmetry
                        showDot.value[index] = firstFlightWithCabinType.cabinType !== curCab;
                    } else {
                        initialState.value[index] = buttonText.value[index];
                        buttonText.value[index] = buttonText.value[index]; // redundant, but kept for clarity
                        buttonColor.value[index] = buttonColor.value[index]; // redundant, kept for symmetry
                        showDot.value[index] = false;
                    }
                }
            } else {
                initialState.value[index] = buttonText.value[index];
            }
        }
        else {
            initialState.value[index] = '';
            buttonText.value[index] = '';
            buttonColor.value[index] = '';
            showDot.value[index] = false;
        }
    });
};

// Метод для изменения состояния кнопки
const changeButtonContent = (item, index) => {
    // Меняем текст кнопки и цвет на основе текущего значения

    switch (buttonText.value[index]) {
        case '' :
            buttonText.value[index] = 'E';  // Снова Эконом (Economy)
            buttonColor.value[index] = 'orange-button'; // Зелёный цвет для эконома
            break;
        case 'E':  // Эконом (Economy)
            buttonText.value[index] = 'P';  // Премиум (Premium)
            buttonColor.value[index] = 'blue-button'; // Синий цвет для премиума
            break;
        case 'P':  // Премиум (Premium)
            buttonText.value[index] = 'B';  // Бизнес (Business)
            buttonColor.value[index] = 'green-button'; // Оранжевый цвет для бизнеса
            break;
        case 'B':  // Бизнес (Business)
            buttonText.value[index] = 'F';  // Первый класс (First)
            buttonColor.value[index] = 'red-button'; // Красный цвет для первого класса
            break;
        case 'F':  // Первый класс (First)
            buttonText.value[index] = '';  // По умолчанию Эконом
            buttonColor.value[index] = '';
            break;
        default:
            buttonText.value[index] = '';  // По умолчанию Эконом
            buttonColor.value[index] = '';
    }

    // Переключаем видимость точки
    if (locState && locState.data && locState.data['1']) {
        locState.data[index+1].cabin_class = getCabinName(buttonText.value[index])
        showDot.value[index] = initialState.value[index] !== locState.data[index+1].cabin_class;
        emitUpdatedAdditionData();
    } else {
        showDot.value[index] = initialState.value[index] !== buttonText.value[index];
    }

    // emit('up')
};

const getErrorKey = (key, index) => `additional_data.${index}.${key}`

const getSimpleViewData = computed(() => {
    const array = props.data.pq_flight_legs
    if (!props.modalMode && array.length > 0) {

        return array.map((item) => {
            const {
                order = '',
                airline = '',
                flight_number = '',
                booking_class = '',
                departure_date = '',
                departure_day_of_week = '',
                from = '',
                to = '',
                status = '',
                quantity = '',
                time_from = '',
                time_to = '',
                arrival_date = '',
                baggage,
                ticket,
                mileage, pcc,
                flight_details = [],
            } = item

            return {
                order,
                airline_info: airline + flight_number + booking_class,
                departure_date,
                departure_day_of_week,
                fromTo: from + to,
                statusQnt: status + quantity,
                time_from,
                time_to,
                arrival_date,
                baggage,
                ticket,
                mileage, pcc,
                flight_details,
            }
        })
    }
    return false
})

const locState = reactive({
    data: cloneDeep(props.additionalData),
    dataBaggage: props.additionalDataBaggage,
    firstTicketOrder: {},
    tableSelectsShow: true,
    tableLoadingShow: false,
});
const setOrderForTickets = () => {
    locState.firstTicketOrder = {}
    Object.entries(locState.data).forEach(([itemOrderKey, itemValue]) => {
        if (!locState.firstTicketOrder[itemValue.ticket]) {
            locState.firstTicketOrder[itemValue.ticket] = itemOrderKey
        }
    })
}
const processDayOfWeek = (data) => {
    const daysOfWeekMap = {
        'M': 'MO',
        'T': 'TU',
        'W': 'WE',
        'Q': 'TH',
        'F': 'FR',
        'J': 'SA',
        'S': 'SU',
    };

    data.forEach(obj => {
        if (obj.departure_day_of_week) {
            obj.departure_day_of_week = daysOfWeekMap[obj.departure_day_of_week] || '';
        }
        if (obj.arrival_day_of_week) {
            obj.arrival_day_of_week = daysOfWeekMap[obj.arrival_day_of_week] || '';
        }
    });

    return data;
};
watch(() => props.additionalData, (val) => {
    if (Object.keys(val).length === 0) {
        locState.data = {}
        locState.dataBaggage = {}
    }
}, {deep: true})

const findBookingClass = (bookingCode, airlineData) => {
    if (airlineData && bookingCode) {
        // Перечисляем ключи, соответствующие различным классам бронирования
        const bookingClasses = ['basic_economy', 'business', 'economy', 'first', 'premium_economy'];

        for (let className of bookingClasses) {
            const classCodes = airlineData[className];
            if (classCodes && classCodes.includes(bookingCode)) {
                // Определяем код и полное название для возврата
                let code, tooltip;

                switch (className) {
                    case 'basic_economy':
                        code = 'BE';
                        tooltip = 'BASIC ECONOMY';
                        break;
                    case 'premium_economy':
                        code = 'PE';
                        tooltip = 'PREMIUM ECONOMY';
                        break;
                    default:
                        code = className.charAt(0).toUpperCase();
                        tooltip = className.toUpperCase();
                        break;
                }

                // Возвращаем объект с кодом и полным названием
                return { code, tooltip };
            }
        }
    }



    // Если код не найден возвращаем null
    return { code : '', tooltip: '' };
};

const getFlightSegmentRoutes = computed(()=>{
    return props?.data?.flight_segment_routes
})

const getPQFlightLegs = computed(()=>{
    return props?.data?.pq_flight_legs
})

const getRowSpanHeight = (order) => {
    const segments = getFlightSegmentRoutes.value
    const pqFlightLegs = getPQFlightLegs.value
    let additionalLength = 0;
    if (segments?.length > 0) {
        const find = segments.find((item => order == item.first_segment_number))
        let duration2 = undefined;
        if (find) {
            const {first_segment_number, last_segment_number, duration} = find
            if (duration.includes('h') && duration.includes('m')) {
                /* end of additional logic */
                const parts = duration.split(' ');
                const hours = parts[0].replace('h', '');
                const minutes = parts[1].replace('m', '');
                duration2 = `${hours}:${minutes}`;
            }

            for (let i = first_segment_number; i <= last_segment_number; i++) {
                const matchedPqFlightLegs = pqFlightLegs.filter((item) => item.order === i);
                matchedPqFlightLegs.forEach((item) => {
                    if (item.flight_details && item.flight_details.length > 1) {
                        additionalLength += item.flight_details.length - 1;
                    }
                });
            }



            return {
                rowspan: (last_segment_number - (first_segment_number - 1)) + additionalLength,
                duration: duration2 ?? duration
            }
        }
    }

    return false
}

const getRowSpanHeightAir = (leg) => {
    const segments = getFlightSegmentRoutes.value
    const pqFlightLegs = getPQFlightLegs.value
    let additionalLength = 0;
    if (segments?.length > 0) {
        const find = segments.find((item => order == item.first_segment_number))
        let duration2 = undefined;
        if (find) {
            const {first_segment_number, last_segment_number, duration} = find
            if (duration.includes('h') && duration.includes('m')) {
                /* end of additional logic */
                const parts = duration.split(' ');
                const hours = parts[0].replace('h', '');
                const minutes = parts[1].replace('m', '');
                duration2 = `${hours}:${minutes}`;
            }

            for (let i = first_segment_number; i <= last_segment_number; i++) {
                const matchedPqFlightLegs = pqFlightLegs.filter((item) => item.order === i);
                matchedPqFlightLegs.forEach((item) => {
                    if (item.flight_details && item.flight_details.length > 1) {
                        additionalLength += item.flight_details.length - 1;
                    }
                });
            }



            return {
                rowspan: (last_segment_number - (first_segment_number - 1)) + additionalLength,
                duration: duration2 ?? duration
            }
        }
    }

    return false
}


const getTicketsOptions = computed(() => {
    const ticketsArr = [1, 2, 3, 4, 5]
    return ticketsArr.filter(item => item <= props.data.pq_flight_legs.length)
})
function calculateWaitTime(flight2, flight1) {
    // Конвертация времени в минуты
    function timeToMinutes(time) {
        let hours, minutes;
        if (time.endsWith('A') || time.endsWith('P')) {
            // 12-часовой формат с AM/PM
            hours = parseInt(time.slice(0, -3)) + (time.endsWith('P') ? 12 : 0);
            minutes = parseInt(time.slice(-3, -1));
        } else {
            // 24-часовой формат без AM/PM
            hours = parseInt(time.slice(0, -2));
            minutes = parseInt(time.slice(-2));
        }
        // Корректировка для полуночи и полудня в 12-часовом формате
        if (time.endsWith('A') && hours === 12) {
            hours = 0;
        }
        if (time.endsWith('P') && hours === 12) {
            hours = 12;
        }
        hours = hours === 24 ? 12 : hours;
        return { hours, minutes };
    }

    // Конвертация строки даты и времени в объект Date
    function parseFlightDate(dateStr, timeStr, addDays = 0) {
        const [day, month, year] = [dateStr.slice(0, 2), dateStr.slice(2, 5), new Date().getFullYear()];
        const date = new Date(`${month} ${day} ${year}`);

        // Добавляем дни к дате
        date.setDate(date.getDate() + addDays);

        // Получаем часы и минуты из timeStr и устанавливаем их в дату
        const { hours, minutes } = timeToMinutes(timeStr);
        date.setHours(hours, minutes, 0, 0); // Устанавливаем часы, минуты и обнуляем секунды и миллисекунды

        return date;
    }

    function calculateArrivalMinutes(flight, dep_date) {
        const arrivalDate = parseFlightDate(flight?.departure_date ?? dep_date, flight.time_to, parseInt(flight.add_days) || 0);
        return arrivalDate.getTime() / (1000 * 60); // Переводим дату и время в минуты с начала эпохи
    }

    function calculateDepartureMinutes(flight, dep_date) {
        const departureDate = parseFlightDate(flight?.departure_date !== null ? flight?.departure_date : dep_date, flight.time_from,  0);
        return departureDate.getTime() / (1000 * 60); // Переводим дату и время в минуты с начала эпохи
    }

    // Рассчитываем минуты для прилета первого рейса и вылета второго рейса
    const arrivalMinutes = calculateArrivalMinutes(flight1, !flight1.departure_date ? flight2.departure_date : null);
    const departureMinutes = calculateDepartureMinutes(flight2, !flight2.departure_date ? flight1.departure_date : null);

    // Преобразуем минуты обратно в даты для удобного вывода
    // const arrivalDate = new Date(arrivalMinutes * 60 * 1000);
    // const departureDate = new Date(departureMinutes * 60 * 1000);
    // console.log('Arrival Date:', arrivalDate.toLocaleString());   // Вывод даты и времени прилета
    // console.log('Departure Date:', departureDate.toLocaleString()); // Вывод даты и времени вылета

    // Рассчитываем разницу во времени ожидания в минутах
    let difference = departureMinutes - arrivalMinutes;

    // Если разница отрицательная, это значит, что вылет второго рейса на следующий день
    if (difference < 0) {
        difference += 24 * 60; // Добавляем минуты следующего дня
    }

    // Переводим разницу обратно в часы и минуты
    const hours = Math.floor(difference / 60);
    const minutes = difference % 60;
    if (hours > 24 || hours < 0) {return null}
    // Форматируем результат
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


function calculateWaitTimeModal(flight2, flight1) {
    // Конвертация времени в минуты
    function timeToMinutes(time) {
        let hours, minutes;
        if (time.endsWith('A') || time.endsWith('P')) {
            // 12-часовой формат с AM/PM
            hours = parseInt(time.slice(0, -3)) + (time.endsWith('P') ? 12 : 0);
            minutes = parseInt(time.slice(-3, -1));
        } else {
            // 24-часовой формат без AM/PM
            hours = parseInt(time.slice(0, -2));
            minutes = parseInt(time.slice(-2));
        }
        // Корректировка для полуночи и полудня в 12-часовом формате
        if (time.endsWith('A') && hours === 12) {
            hours = 0;
        }
        if (time.endsWith('P') && hours === 12) {
            hours = 12;
        }
        hours = hours === 24 ? 12 : hours;
        return { hours, minutes };
    }

    // Конвертация строки даты и времени в объект Date
    function parseFlightDate(dateStr, timeStr, addDays = 0) {
        const [day, month, year] = [dateStr.slice(0, 2), dateStr.slice(2, 5), new Date().getFullYear()];
        const date = new Date(`${month} ${day} ${year}`);

        // Добавляем дни к дате
        date.setDate(date.getDate() + addDays);

        // Получаем часы и минуты из timeStr и устанавливаем их в дату
        const { hours, minutes } = timeToMinutes(timeStr);
        date.setHours(hours, minutes, 0, 0); // Устанавливаем часы, минуты и обнуляем секунды и миллисекунды

        return date;
    }

    function calculateArrivalMinutes(flight, dep_date) {
        const arrivalDate = parseFlightDate(flight?.departureDate ?? dep_date, flight.timeTo, parseInt(flight.addDays) || 0);
        return arrivalDate.getTime() / (1000 * 60); // Переводим дату и время в минуты с начала эпохи
    }

    function calculateDepartureMinutes(flight, dep_date) {
        const departureDate = parseFlightDate(flight?.departureDate !== null ? flight?.departureDate : dep_date, flight.timeFrom,  0);
        return departureDate.getTime() / (1000 * 60); // Переводим дату и время в минуты с начала эпохи
    }

    // Рассчитываем минуты для прилета первого рейса и вылета второго рейса
    const arrivalMinutes = calculateArrivalMinutes(flight1, !flight1.departureDate ? flight2.departureDate : null);
    const departureMinutes = calculateDepartureMinutes(flight2, !flight2.departureDate ? flight1.departureDate : null);

    // Преобразуем минуты обратно в даты для удобного вывода
    // const arrivalDate = new Date(arrivalMinutes * 60 * 1000);
    // const departureDate = new Date(departureMinutes * 60 * 1000);
    // console.log('Arrival Date:', arrivalDate.toLocaleString());   // Вывод даты и времени прилета
    // console.log('Departure Date:', departureDate.toLocaleString()); // Вывод даты и времени вылета

    // Рассчитываем разницу во времени ожидания в минутах
    let difference = departureMinutes - arrivalMinutes;

    // Если разница отрицательная, это значит, что вылет второго рейса на следующий день
    if (difference < 0) {
        difference += 24 * 60; // Добавляем минуты следующего дня
    }

    // Переводим разницу обратно в часы и минуты
    const hours = Math.floor(difference / 60);
    const minutes = difference % 60;
    if (hours > 24 || hours < 0) {return null}
    // Форматируем результат
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


// Пример использования функции:



// function calculateWaitTime(detail1, detail2) {
//     const firstTime = detail1.time_from;
//     const secondTime = detail2.time_to;
//     // Конвертируем время в минуты
//     function timeToMinutes(time) {
//         let hours, minutes;
//         if (time.endsWith('A') || time.endsWith('P')) {
//             // 12-часовой формат с AM/PM
//             hours = parseInt(time.slice(0, -3)) + (time.endsWith('P') ? 12 : 0);
//             minutes = parseInt(time.slice(-3, -1));
//         } else {
//             // 24-часовой формат без AM/PM
//             hours = parseInt(time.slice(0, -2));
//             minutes = parseInt(time.slice(-2));
//         }
//
//         // Корректировка для полуночи и полудня в 12-часовом формате
//         if (time.endsWith('A') && hours == 12) {
//             hours = 0;
//         }
//         if (time.endsWith('P') && hours == 12) {
//             hours = 12;
//         }
//
//         // Возвращаем общее количество минут
//         return hours * 60 + minutes;
//     }
//
//     const firstMinutes = timeToMinutes(firstTime);
//     const secondMinutes = timeToMinutes(secondTime);
//
//     // Вычисляем разницу в минутах
//     let difference = firstMinutes - secondMinutes;
//
//     // Если разница отрицательная, прибавляем количество минут в сутках
//     if (difference < 0) {
//         difference += 24 * 60;  // 24 * 60 - количество минут в сутках
//     }
//
//     // Переводим разницу обратно в часы и минуты
//     const hours = Math.floor(difference / 60);
//     const minutes = difference % 60;
//     // Возвращаем результат в формате HH:MM
//     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
// }

const getRowType = (item) => {
    const dataOrder = locState.data[item.order]
    return dataOrder?.type?.id
}
const getRowSelectPropsByKey = (item, key) => {
    const orderItem = locState.data[item.order]
    const ticket = orderItem?.ticket

    let modelValue = orderItem?.[key];
    let disabled = false
    if (locState.firstTicketOrder[ticket]) {
        disabled = locState.firstTicketOrder[ticket] != item.order
    }

    if (key == 'mileage' && props.modeDuplicate) {
        disabled = false
    }

    if (key == 'type' && props.modeDuplicate) {
        disabled = false
    }
    if (key == 'mileage' && props.modeDraft) {
        disabled = false
    }
    if (key == 'type' && props.modeDraft) {
        disabled = false
    }
    if (key === 'is_real') {
        disabled = false
    }


    return {
        modelValue,
        disabled,
        placeholder: "Choose",
        errors: props.additionalDataErrors,
        class: 'pq-table-row-select  small-med-select',
    }
}

const collectAndFormatData = ({orderKey, dataKey, dataValue}) => {
    const copyLocData = cloneDeep(locState.data);
    const locDataEntries = Object.entries(copyLocData);
    if (dataKey === 'ticket') {
        const existTicketIndex = locDataEntries.findIndex(([itemOrderKey, itemValue]) => itemValue[dataKey] === dataValue);
        if (existTicketIndex > -1) {
            const existTicketValue = locDataEntries[existTicketIndex]?.[1];
            const newEntries = locDataEntries.map((item, index) => {
                const [itemOrderKey, itemValue] = item;
                if (Number(itemOrderKey) === Number(orderKey)) {
                    return [itemOrderKey, { ...existTicketValue }];
                }
                return item;
            });
            return Object.fromEntries(newEntries);
        } else {
            const ticketItem = cloneDeep(copyLocData[orderKey])
            ticketItem[dataKey] = dataValue

            if (backendOptions?.pqTypesOptions?.[0]) {
                ticketItem.type = backendOptions.pqTypesOptions?.[0]
            }
            ticketItem.pcc = null
            ticketItem.mileage = null
            ticketItem.fare_type = null
            ticketItem.is_real = "Real"
            copyLocData[orderKey] = ticketItem
            return copyLocData
        }

    }
    else {
        // Обработка изменения других ключей кроме 'ticket'
        copyLocData[orderKey][dataKey] = dataValue;

        if (dataKey === 'pcc' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {

                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].fare_type = copyLocData[orderKey].pcc.default_fare_type ?? null;
                    copyLocData[idx+1].pcc = copyLocData[orderKey].pcc ?? null;
                }
            });
        }
        if (dataKey === 'fare_type' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].fare_type = copyLocData[orderKey].fare_type ?? null;
                }
            });
        }

        // if (dataKey === 'is_real' && dataValue !== null) {
        //     // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
        //     const currentTicket = copyLocData[orderKey].ticket;
        //     Object.values(copyLocData).forEach((item, idx) => {
        //         if (item.ticket === currentTicket) {
        //             copyLocData[idx+1].is_real = copyLocData[orderKey].is_real ?? "Real";
        //         }
        //     });
        // }

        if (dataKey === 'type' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].type = copyLocData[orderKey].type ?? null;
                    if (dataValue === 'Online') {
                        copyLocData[idx+1].mileage = null;
                        copyLocData[idx+1].pcc = null;
                        copyLocData[idx+1].is_real = null;
                        copyLocData[idx+1].fare_type = null;
                    }
                }
            });
        }

        if (dataKey === 'mileage' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].mileage = copyLocData[orderKey].mileage ?? null;
                }
            });
        }
        return copyLocData;
    }
};


const getDaysDifference = (dateStr1, dateStr2, created_at = new Date()) => {
    if (!dateStr1 || !dateStr2) {
        return '';
    }

    // Проверяем, если created_at - строка, преобразуем её в объект Date
    if (typeof created_at === 'string') {
        created_at = new Date(created_at);
    }

    // Получаем год, месяц и день из created_at
    let baseYear = created_at.getFullYear();
    const createdMonth = created_at.getMonth();
    const createdDay = created_at.getDate();

    const parseDate = (dateStr, year) => {
        const months = {
            JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
            JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
        };
        const day = parseInt(dateStr.slice(0, 2), 10);
        const month = months[dateStr.slice(2).toUpperCase()];
        return new Date(year, month, day);
    };

    let date1 = parseDate(dateStr1, baseYear);
    let date2 = parseDate(dateStr2, baseYear);

    // Проверка: если обе даты меньше даты created_at по месяцу и дню
    if (
        (date1.getMonth() < createdMonth || (date1.getMonth() === createdMonth && date1.getDate() < createdDay)) &&
        (date2.getMonth() < createdMonth || (date2.getMonth() === createdMonth && date2.getDate() < createdDay))
    ) {
        // Увеличиваем baseYear на 1, если обе даты меньше
        baseYear += 1;
        date1 = parseDate(dateStr1, baseYear);
        date2 = parseDate(dateStr2, baseYear);
    }

    // Проверка только по месяцу между date1 и date2
    if (date2.getMonth() < date1.getMonth()) {
        date2.setFullYear(date2.getFullYear() + 1); // Добавляем год, если месяц date2 меньше
    }

    // Устанавливаем время на полночь для обеих дат
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    // Вычисление разницы в днях
    const diffTime = date2 - date1;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (diffDays === 0) {
        return '';
    }

    return diffDays > 0 ? `+${Math.floor(diffDays)}` : `${Math.ceil(diffDays)}`;
};


const cabinColor = cabin => {
    switch (cabin) {
        case "ECONOMY":
            return "#D97706";
        case "BUSINESS":
            return "#556B2F";
        case "PREMIUM":
            return "#2563EB";
        case "PREMIUM ECONOMY":
            return "#2563EB";
        case "FIRST":
            return "#d92626";
        default:
            return "";
    }
}


const getCabinCode = cabin => {
    switch (cabin) {
        case "ECONOMY":
            return "E";
        case "BUSINESS":
            return "B";
        case "PREMIUM":
            return "P";
        case "PREMIUM ECONOMY":
            return "P";
        case "FIRST":
            return "F";
        default:
            return "";
    }
};

const getCabinName = code => {
    switch (code) {
        case "E":
            return "ECONOMY";
        case "B":
            return "BUSINESS";
        case "P":
            return "PREMIUM ECONOMY"; // Для P выбираем "PREMIUM ECONOMY", так как это более специфичный случай
        case "F":
            return "FIRST";
        default:
            return "";
    }
};
const getCabinClassColor = cabin => {
    switch (cabin) {
        case "ECONOMY":
            return "orange-button";
        case "BUSINESS":
            return "green-button";
        case "PREMIUM":
            return "blue-button";
        case "PREMIUM ECONOMY":
            return "blue-button";
        case "FIRST":
            return "red-button";
        default:
            return "";
    }
};
const emitUpdatedAdditionData = () => {
    emit('update:additionalData', locState.data)
};
const updateAdditionalDataBaggage = (order, value) => {
    locState.dataBaggage[order].baggage_qnt = value;
    emit('update:additionalDataBaggage', locState.dataBaggage)
}
const updateAdditionalData = (order, key, value, index = null) => {
    if (['mileage', 'pcc', 'type', 'ticket', 'fare_type', 'is_real', 'initial_cabin_clas'].includes(key)) {
        locState.tableSelectsShow = false
        locState.tableLoadingShow = true

        locState.data = collectAndFormatData(
            {
                orderKey: order,
                dataKey: key,
                dataValue: value
            })
        setOrderForTickets()
        setTimeout(() => {
            locState.tableSelectsShow = true
            emitUpdatedAdditionData()
        }, 0);
    }
}
onMounted(() => {
    if (props.additionalData) {
        // console.log('props', props.additionalData)
        if (!Object.values(props.additionalData).every(item => !item.pcc && !item.mileage)) {
            setOrderForTickets()
        }
    }

    if (props.modeDuplicate) {
        emitUpdatedAdditionData()
    }
    if (props.modeDraft) {
        emitUpdatedAdditionData()
    }
    initializeButtonState(props.data.pq_flight_legs);
})


const ticketTypes = {
    'R': { letter: "R", colorClass: "revenue-ticket", tooltip: (item) => `PCC: ${item.pcc}` },
    'A': { letter: "A", colorClass: "award-ticket", tooltip: (item) => `Award: ${item.awardProgramName}` },
    'O': { letter: "O", colorClass: "online-ticket", tooltip: () => `Online ticket` }
};
const getTicketsClass = (itemTicket) => {
    if (props.data.pq_flight_legs.length > 0 && props.data.pq_flight_legs.every(i => i.ticket == 1)) {
        return 'number-circle-solo-ticket';
    }
    console.log('hrere',itemTicket);
    if (itemTicket == 1) {
        return  'number-circle-one';
    }
    if (itemTicket == 2) {
        return  'number-circle-two';
    }
    if (itemTicket == 3) {
        return  'number-circle-three';
    }
    if (itemTicket > 3) {
        return  'number-circle-others';
    }
};
const getTicketNumberStyle = (ticketNumber) => {
    const colorMap = {
        1: "#dc6535",
        2: "#961b3c",
        3: "#ff8c01",
        other: "#E0115F",
    };
    return {
        color: ticketNumber === 1 ? "#000000" : "#FFFFFF",
        backgroundColor: ticketNumber === 1 ? "#cbcfd2" : colorMap[ticketNumber] || colorMap.other,
    };
};

// Пример добавления компонента в таблицу с номерами билетов и иконками
const getTicketIcon = (item) => {
    console.log('item', item)
    const type = item.type.title;  // Обновите поле под ваш кейс
    const ticketNumber = item.ticket || 1; // Пример: номер билета
    let letter = 'R';
    if (item.type.id === 1) {
        letter = 'R'
    }
    if (item.type.id === 2) {
        letter = 'A'
    }
    if (item.type.id === 3) {
        letter = 'O'
    }
    return `<div class="ticket-icon-wrapper">
                <span  class="${ticketTypes[letter].colorClass}">
                    ${letter}
                    <span :style="getTicketNumberStyle(${ticketNumber})" class="number-circle">
                    ${ticketNumber}
                </span>
                </span>

            </div>`;
};

</script>
<style lang="scss">
.revenue-ticket { color:  #000000; border-color:  #000000 }
.award-ticket { color: #D97706; }
.online-ticket { color: #2563EB; border-color: #2563EB }
.requests-pq-code-table th {
    padding: 12px !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.desktop-pq-table {
    tr {
        td, th{
            padding: 8px 10px !important;
        }
    }
}

.pq-table-row-select {
    padding: 8px;

    &.small-select {
        .acms-v-select-toggle {
            width: 60px;
            min-width: 60px;
        }
        .acms-v-select-dropdown{
            width: 60px !important;
            min-width: 60px !important;
        }
    }
    &.small-select-baggage{
        .acms-v-select-toggle {
            width: 80px;
            min-width: 80px;
        }
        .acms-v-select-dropdown{
            width: 80px !important;
            min-width: 80px !important;
        }
    }

    &.small-med-select {
        .acms-v-select-toggle {
            width: 128px;
            min-width: 60px;
        }
    }

    .acms-v-select-toggle-placeholder {
        color: #4B5563;
    }
}

.ticket-icon-wrapper-o {
    position: relative; /* Для позиционирования кружка с номером относительно буквы */
    display: inline-flex; /* Располагает элементы горизонтально */
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Размер букв "R", "A", "O" */
    border: 1px solid #2563EB;
    border-radius: 3.2px;
    width: 20px; /* Размер контейнера для букв */
    height: 20px;
}
.ticket-icon-wrapper-a {
    position: relative; /* Для позиционирования кружка с номером относительно буквы */
    display: inline-flex; /* Располагает элементы горизонтально */
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Размер букв "R", "A", "O" */
    border: 1px solid #D97706;
    border-radius: 3.2px;
    width: 20px; /* Размер контейнера для букв */
    height: 20px;
}
.ticket-icon-wrapper-r {
    position: relative; /* Для позиционирования кружка с номером относительно буквы */
    display: inline-flex; /* Располагает элементы горизонтально */
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Размер букв "R", "A", "O" */
    border: 1px solid  #000000;
    border-radius: 3.2px;
    width: 20px; /* Размер контейнера для букв */
    height: 20px;
}
.number-circle-solo-ticket {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color:  #cbcfd2;
    color: #000000;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}

.number-circle-others {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color:   #E0115F;
    color: #FFFFFF;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}

.number-circle-three {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color:   #E0115F;
    color: #FFFFFF;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}

.number-circle-two {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color:  #961b3c;
    color: #FFFFFF;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}
.number-circle-one {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color: #dc6535;
    color: #FFFFFF;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}
.custom-button {
    width: 20px;
    height: 20px;
    position: relative; /* Добавляем относительное позиционирование для контейнера */
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: white;
    border-radius: 3.2px;
    border: 1px solid;
}
.custom-button-request {
    width: 20px;
    height: 20px;
    position: relative; /* Добавляем относительное позиционирование для контейнера */
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: white;
    border-radius: 3.2px;
    border: 1px solid;
}
/* Зеленая кнопка */
.green-button {
    border-color: #4CAF50;
    color: #4CAF50;
}

/* Красная кнопка */
.red-button {
    border-color: #f44336;
    color: #f44336;
}

.blue-button {
    border-color: #2563EB;
    color: #2563EB;
}
.orange-button {
    border-color: #D97706;
    color: #D97706;
}

/* Стили для точки */
.dot {
    position: absolute; /* Абсолютное позиционирование для точки */
    top: 1px;          /* Смещаем точку выше текста */
    left: 1px;         /* Смещаем точку левее центра */
    width: 4px;
    height: 4px;
    background-color: red;
    border-radius: 50%;
}

.dot-request {
    position: absolute; /* Абсолютное позиционирование для точки */
    top: 2px;          /* Смещаем точку выше текста */
    left: 1px;         /* Смещаем точку левее центра */
    width: 3px;
    height: 3px;
    background-color: red;
    border-radius: 50%;
}
.custom-button:hover {
    opacity: 0.8;
}
</style>
