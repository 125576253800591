<template>
    <div class="box-card-request-client-details">
        <acms-v-card title="Client details">
            <template #header-right>
                <acms-v-badge v-if="backendPermissions?.allowViewClientStatus" title="New prospect">New prospect</acms-v-badge>
            </template>
            <template #default>
                <div class="client-details-info" style="font-weight: bolder">
                    <div class="client-details-info-row">
                        <div class="flex-container">
                            <div class="client-name">
                                <div class="client-overview-info-title text-uppercase text-primary-dark fw-bold mb-2">
                                    Name
                                </div>
                                <div class="client-overview-info-content text-gray-darker fz-14 fw-normal">
                                    {{ getFullName(client) }}
                                </div>
                            </div>
                            <acms-v-btn style="width: 32px; height: 32px; margin-top: 25px" class="acms-v-collapse-toggle-status bg-gray-light" icon="pen" @click="openAltAccordion('contact-info')"></acms-v-btn>
                        </div>
                    </div>
                    <!-- Остальное содержимое остается без изменений -->
                    <template v-for="[blockKey, blockArray] in Object.entries(requestClientDetails)">
                        <div class="row gap-row-3">
                            <template v-for="item in blockArray">
                                <div :class="item?.full ? 'col-12' : 'col-6'">
                                    <div class="client-overview-info-title text-uppercase text-primary-dark fw-bold mb-2">
                                        {{ item.title }}
                                    </div>
                                    <div class="client-overview-info-content text-gray-darker fz-14 fw-normal">
                                        <template v-if="typeof item.content == 'string'">
                                            {{ item.content }}
                                        </template>
                                        <template v-if="Array.isArray(item.content)">
                                            <div class="row">
                                                <div class="col-6" v-for="col in item.content">
                                                    {{ col }}
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-for="[blockKey, blockArray] in Object.entries(clientAbout)">
                        <div class="client-overview-info-row">
                            <div class="row" :class="{'gap-row-3': blockKey != 'info'}" :style="blockKey == 'info' ? 'padding-bottom:8px' : ''">
                                <template v-for="(item, key) in blockArray">
                                    <SimpleClientInfoBlock :class="[item?.full ? 'col-12' : 'col-6', item.order ? `order-${item.order}` : '']" :style="blockKey == 'info' && item?.content ? 'padding-bottom:12px' : ''" :item="item">
                                        <template v-if="isObject(item.content)">
                                            <div class="d-flex">
                                                <div class="me-auto text-gray-darker fz-14 fw-normal" :class="item.content?.class">
                                                    <template v-if="item.canToggleObscure">
                                                        <template v-if="item.content.obscure">
                                                            {{item.content.obscureValue}}
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="item.contact_type === 'phone'">
                                                                <a :href="'tel:'+item.content.originalValue"> {{ item.content.originalValue }}</a>
                                                            </template>
                                                            <template
                                                            v-else>
                                                                {{ item.content.originalValue }}
                                                            </template>

                                                        </template>

                                                    </template>
                                                    <template v-else>
                                                        {{ item.content.text }}
                                                    </template>
                                                </div>
                                                <template v-if="item?.canToggleObscure">
                                                    <acms-v-btn class="p-0 me-3 text-primary-dark" iconSize="20" :disabled="item.content?.loading || !!item.content?.originalValue" v-tooltip.right="getTooltipRevealsLeft" @click="obscureContactHandle(item)" :icon="item.content.obscure ? 'eye-off' : 'eye'"/>
                                                </template>
                                            </div>
                                        </template>
                                    </SimpleClientInfoBlock>
                                </template>
                            </div>
                        </div>
                    </template>
                    <PartialRequestClientDetailsActions emailCount="2" phoneCount="2" pqCount="2" @followUpClick="detailsActions.followUp.show = true" class="mt-4" :followUpValue="detailsActions.followUp.date" />
                </div>
                <div class="client-details-info-row mt-3">
                    <div class="text-primary-dark fw-bold mb-2">Agents</div>
                    <div v-for="agent in agent" :key="agent.id" class="agent-details d-flex align-items-center">
                        <acms-v-btn class="bg-gray-light p-1 pv-2 d-flex align-items-center justify-content-between" content-class="d-flex">
                            <acms-v-icon name="user" class="p-1 bg-white radius-50 me-1" color="text-gray-darker"/>
                            <span>{{ getFullName(agent) }} </span>
                            <acms-v-icon v-if="backendPermissions.allowUnAssignAgents" name="close" style="padding-top: 4px" class="ms-2 text-danger" @click.stop="confirmRemoveAgent(agent)" />
                        </acms-v-btn>
                    </div>
                </div>
                <div class="client-details-info-row mt-3">
                    <div class="text-primary-dark fw-bold mb-3">Flight Details</div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                        <div class="client-details-flights-list mb-3">
                            <template v-for="item in currentRequest.flight_legs">
                                <div class="d-flex mb-1 text-gray-darker fz-14 fw-normal">
                                    <div :style="'width: 18px; height: 18px; border: 1px solid; align-self: center;' + cabinColor(item?.cabin_class_id)+'; color:'+cabinColor(item?.cabin_class_id)+ '; border-radius: 3.2px; align-items: center; justify-content: center; display: flex'">
                                                  <span v-tooltip="'CABIN TYPE : ' + item?.cabin_class_id" class="white-space-nowrap">
                                                {{cabin(item.cabin_class_id)}}
                                            </span>
                                    </div>
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div class="me-1" v-tooltip="item.departure_airport_object?.title ?? item.departure_airport_id">
                                        {{ item.departure_airport_id }}
                                    </div>
                                    <div class="me-1">→</div>
                                    <div class="d-flex">
                                        <div class="me-1" v-tooltip="item.arrival_airport_object?.title ?? item.arrival_airport_id">
                                            {{ item.arrival_airport_id }}
                                        </div>
                                        <span class="text-uppercase" style="margin-left: 10px; margin-right: 3px">{{ getFlightDates(item).dates }}</span>
<!--                                        <div class="text-dark-gray ms-1" v-tooltip="getFlightDates(item).cabinClass.full">-->
<!--                                            {{ getFlightDates(item).cabinClass.short }}-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div>
                            <acms-v-btn style="width: 32px; height: 32px" class="acms-v-collapse-toggle-status bg-gray-light" icon="pen" @click="openAccordion(currentRequest.id)"></acms-v-btn>
                        </div>
                    </div>
                    <EntityCellPassengers class="fz-16" :data="getPassengersCount"/>
                </div>
                <div class="client-details-info-row mt-3">
                    <div class="text-primary-dark fw-bold mb-3">Preferences</div>
                    <div class="client-details-Preferences-list mb-3">
                        <div class="table-mini">
                            <div class="table-mini-row d-flex ph-2" :class="{'bg-gray-lighter' : index % 2}" v-for="(item, index) in preferencesTableData">
                                <div class="table-mini-cell text-gray-60 text-uppercase">{{ item.title }}</div>
                                <template v-if="Array.isArray(item.content)">
                                    <template v-for="(itm, key) in item.content">
                                        <template v-if="key > 0">, </template>
                                        <div class="table-mini-cell" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                            <span v-tooltip="itm.tooltipContent">{{ itm.title }}</span>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div v-tooltip="item.content" class="table-mini-cell" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                                        {{ item.content }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </acms-v-card>
    </div>

    <PartialFollowUpCalendar v-model="detailsActions.followUp.date" :show="detailsActions.followUp.show" @hide="hideFollowUpCalendarHandle"/>

    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title class="headline">Unassignment</v-card-title>
            <v-card-text>Would you like to unassigned agent “{{ selectedAgentName }}” from this request?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="removeAgent">Unassign</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script setup>
import {ref, computed, onBeforeMount, reactive, watch, watchEffect} from 'vue';
import axios from 'axios'; // Добавьте этот импорт для использования axios
import {PartialRequestClientDetailsActions, PartialFollowUpCalendar} from './partials';
import {usePageRequestShowStore} from '@store/pages/page-request-edit';
import {EntityCellPassengers} from '@entities';
import {getFullName, getHiddenEmail, getHiddenPhone, getSecurizedPhone} from "@helpers/commonHelpers";
import {useBackendMiddlewarePropsService} from "@services";
import {getUppercaseDates} from "@helpers/dateHelpers";
import AcmsVBtn from "@ui/Btn.vue";
import SimpleClientInfoBlock from "@boxes/client/BoxCardClientOveriview/partials/SimpleClientInfoBlock.vue";
import httpClient from "@services/HttpService";
import {getRoute} from "@plugins/useRoutes";
import {isObject} from "lodash";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

const props = defineProps({
    client: Object,
    currentRequest: Object,
    agent: Array
});

const requestShowStore = usePageRequestShowStore();
const {backendOptions, backendPermissions} = useBackendMiddlewarePropsService();
const clientAbout = ref({});
const requestClientDetails = ref({info: {}});
const preferencesTableData = ref([]);
const detailsActions = reactive({
    followUp: {
        show: false,
        date: null
    },
});
const currentRevealsLeft = ref(0);
const dialog = ref(false);
const selectedAgent = ref(null);
const selectedAgentName = ref(null); // Добавьте этот ref для имени агента

const getTooltipRevealsLeft = computed(() => String(currentRevealsLeft.value) + ' reveals left');

const obscureContactHandle = (item) => {
    const {contact_id, contact_type} = item;
    const client_id = props.client.id;
    const sendObject = {contact_id, contact_type, client_id};
    if (!item.content.originalValue) {
        item.content.loading = true;
        httpClient.post(getRoute('clients.contact.get'), sendObject).then(({data}) => {
            if (data?.success) {
                item.content.loading = false;
                item.content.originalValue = data?.data;
                item.content.obscure = false;
                if (data?.count_remain) {
                    currentRevealsLeft.value = data?.count_remain;
                }
            } else if (data?.error) {
                // Handle error
            }
        });
    }
};

watch(() => props.currentRequest, () => {
    setSidebarData();
});

const getInfo = computed(() => {
    return {};
});


const cabin = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'B';
        case 2:
            return 'E';
        case 3:
            return 'F';
        case 4:
            return 'P';
        default:
            return 'B';
    }
}


const cabinColor = cabin => {
    switch (cabin) {
        case 2:
            return "#D97706";
        case 1:
            return "#556B2F";
        case 4:
            return "#2563EB";
        case 3:
            return "#d92626";
        default:
            return "#556B2F";
    }
}
const getPassengersCount = computed(() => {
    const {adults, children, infants} = props.currentRequest;
    return {adults, children, infants};
});

const emit = defineEmits(['open-accordion']);
const openAccordion = (index) => {
    emit('open-accordion', index);
};
const openAltAccordion = (type) => {
    emit('open-alt-accordion', type);
};
const getFlightDates = (item) => {
    const findCabin = backendOptions.cabinClassOptions.find(i => i.id == item.cabin_class_id);
    const cabinClass = () => {
        let short = 'B';
        let full = 'Business';
        if (findCabin) {
            short = findCabin.title?.substring(0, 1);
            full = findCabin.title;
        }
        return {short, full};
    };
    return {
        dates: getUppercaseDates([item.flight_date_from, item.flight_date_to]),
        cabinClass: cabinClass()
    };
};

const getMails = computed(() => {
    return props.client?.email?.reduce((acc, item) => {
        const sample = {
            title: ((item?.pivot?.type_id ?? item.id) === 1 ? 'Personal' : 'Shared') + ' email',
            full: true,
            order: 2,
            canToggleObscure: true,
            contact_id: item.id,
            contact_type: 'email',
            content: {
                obscure: true,
                obscureValue: item?.email,
                originalValue: null,
                text: item?.email ?? item.text,
                loading: false
            },
        };
        const isMain = item.pivot?.main ?? item.main;
        if (isMain) {
            sample.order = 1;
            sample.title = sample.title + ' (Main)';
        }
        if (sample.content.text?.length > 0) {
            acc.push(sample);
        }
        return acc;
    }, []);
});

const getPhones = computed(() => {
    return props.client?.phone?.reduce((acc, item) => {
        const sample = {
            title: ((item?.pivot?.type_id ?? item.id) == 1 ? 'Personal' : 'Shared') + ' phone',
            order: 2,
            full: true,
            contact_id: item.id,
            contact_type: 'phone',
            canToggleObscure: getSecurizedPhone(item)?.includes('*'),
            content: {
                obscure: true,
                obscureValue: (item.dial_code ?? item.dial_code_object?.code) + ' ' + item?.phone,
                originalValue: null,
                text: (item.dial_code ?? item.dial_code_object?.code) + ' ' + item?.phone,
                loading: false
            },
        };
        const isMain = item.pivot?.main ?? item.main;
        if (isMain) {
            sample.title = sample.title + ' (Main)';
            sample.order = 1;
        }
        if (sample.content.text?.length > 0) {
            acc.push(sample);
        }
        return acc;
    }, []);
});

const setClientAbout = () => {
    const mails = getMails.value;
    const phones = getPhones.value;
    clientAbout.value = {};
    if (mails?.length > 0) {
        clientAbout.value.mails = mails;
    }
    if (phones?.length > 0) {
        clientAbout.value.phones = phones;
    }
};

const setClientInfo = () => {
    const {best_time_to_call} = props.client;
    const info = [];
    const contactBy = {
        title: 'Contact by',
        content: props.client.contact_by_id ? backendOptions.contactSocialOptions.find(item => item.id == props.client.contact_by_id).title : null,
    };

    if (contactBy.content) {
        info.push(contactBy);
    }
    if (best_time_to_call.from) {
        const from = `${best_time_to_call.from.time} ${best_time_to_call.from.type}`;
        const to = `${best_time_to_call.to.time} ${best_time_to_call.to.type}`;
        const bestTime = {
            title: 'Best time to call',
            content: `${from} - ${to}`,
            full: true,
        };
        info.push(bestTime);
    }

    return {info};
};

const setPreferencesTableInfo = () => {
    const {
        number_of_stops_id,
        purpose_of_travel_id,
        max_client_budget,
        values,
        client_need_a_hotel,
        seats_array,
        notes,
        airlines
    } = props.currentRequest;

    const list = [];
    const airlinesObj = {
        title: 'Airlines',
        content: airlines?.map(item => {
            const {iata, title} = item.airline;
            return {title: iata ?? 'No iata', tooltipContent: title};
        })
    };

    if (airlinesObj.content.length > 0) {
        list.push(airlinesObj);
    }

    const stops = {
        title: 'Stops',
        content: backendOptions.numberOfStopsOptions?.find(i => i.id == number_of_stops_id)?.title ?? null
    };
    const purpose = {
        title: 'Purpose',
        content: backendOptions.purposeOfTravelsOptions?.find(i => i.id == purpose_of_travel_id)?.title ?? null
    };
    const budget = {
        title: 'Budget',
        content: `$${max_client_budget}`
    };
    const valuesObj = {
        title: 'Values',
        content: values.map(item => {
            const find = backendOptions.clientMainValuesOptions?.find(i => i.id == item);
            return find?.title ?? item;
        }).join(', ')
    };
    const hotelObj = {
        title: 'HOTEL',
        content: client_need_a_hotel > 0 ? 'Need' : 'No need'
    };
    const seatsObj = {
        title: 'Seats',
        content: seats_array?.map(item => item.title).join(', ')
    };
    const notesObj = {
        title: 'Req. Notes',
        content: notes
    };
    [stops, purpose].forEach(item => {
        if (item.content) {
            list.push(item);
        }
    });
    if (max_client_budget) {
        list.push(budget);
    }

    if (values?.length > 0) {
        list.push(valuesObj);
    }

    if (client_need_a_hotel != null) {
        list.push(hotelObj);
    }

    if (seatsObj.content.length > 0) {
        list.push(seatsObj);
    }

    if (notesObj.content) {
        list.push(notesObj);
    }

    return list;
};

const setSidebarData = () => {
    requestClientDetails.value = setClientInfo();
    preferencesTableData.value = setPreferencesTableInfo();
};

const hideFollowUpCalendarHandle = () => {
    detailsActions.followUp.show = false;
    requestShowStore.toggleShowFollowUp(false);
};

watch(() => requestShowStore.followUpCalendarShow, (bool) => {
    if (bool) {
        detailsActions.followUp.show = true;
    }
});

onBeforeMount(() => {
    setSidebarData();
    setClientAbout();
});
watchEffect(() => {
    setClientAbout();
});

const confirmRemoveAgent = (agent) => {
    selectedAgent.value = agent;
    selectedAgentName.value = getFullName(agent); // Установите имя агента
    dialog.value = true;
};
const AlertsManager = useAlertsManagerStore();
const removeAgent = () => {
    if (selectedAgent.value) {
        axios.delete(`/requests/${props.currentRequest.id}/agents/${selectedAgent.value.id}`)
            .then(response => {
                console.log('response', response.data);
                if (response.data.success) {
                    // Ensure props.currentRequest.agents is defined
                    if (Array.isArray(props.agent)) {
                        const index = props.agent.findIndex(a => a.id === selectedAgent.value.id);
                        console.log('Agent index:', index); // Logging index
                        if (index !== -1) {
                            props.agent.splice(index, 1);
                            console.log('Updated agents:', props.agent); // Logging updated agents array
                        } else {
                            console.error('Agent not found in currentRequest.agents');
                        }
                    } else {
                        console.error('agents array is not defined or not an array in currentRequest');
                    }
                    // Notify user of successful removal
                    AlertsManager.add('Agent was successfully removed from request', 'success');
                } else {
                    // Handle error if agent cannot be removed
                    alert(response.data.message);
                }
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null; // Очистите имя агента
            })
            .catch(error => {
                // Handle request error
                console.error('Failed to remove agent from request:', error);
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null; // Очистите имя агента
                AlertsManager.add('Cannot delete agent from request', 'danger');
            });
    }
};

</script>

<style lang="scss">
.box-card-request-client-details {
    .client-overview-info-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .client-overview-info-content {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 5px;
    }

    .client-overview-info-row {
        padding-bottom: 20px;

        &:not(:first-child) {
            border-top: 1px solid #CED4DA;
            padding-top: 20px;
        }
    }

    .client-details-info-row {
        &:not(:last-child) {
            border-bottom: 1px solid #E9ECEF;
            padding-bottom: 15px;
        }
    }

    .flex-container {
        display: flex;
        align-items: center;
        justify-content: space-between; // Настройте по необходимости
    }

    .client-name {
        flex-grow: 1; // Убедитесь, что текст имени занимает доступное пространство
    }

    .acms-v-collapse-toggle-status {
        margin-left: 10px; // Настройте отступы по необходимости
    }
}

.table-mini {
    font-size: 14px;
}

.table-mini-cell:first-child {
    width: 106px;
    margin-right: 8px;
    flex-shrink: 0;
    font-weight: 600;
}

.table-mini-row {
    width: 100%;
}
</style>
