import {computed, defineAsyncComponent, nextTick, reactive, ref} from 'vue';
import {SELECT_VIEW_TEMPLATES} from '@components/ui/FormField/constants';
import selectComputed from '@ui/FormField/elements/Select/select.computed';
import selectWatchers from '@ui/FormField/elements/Select/select.watchers';
import selectHooks from '@ui/FormField/elements/Select/select.hooks';
import {createPopper} from '@popperjs/core';
import {cloneDeep} from 'lodash';

export default function ({props, emit}) {
    const locState = reactive({
        listShow: props.selectListShow ?? false,  //false
        listWasOpened: false,
        currentValueObject: null, // if modelValue is number or string , this will be object
        tempOptions: null,
        popper: null,
        search: '',
        searchStringOld: '',
        searchIsProcessing: false,
        searchHasErrors: false,
        focusedOption: null,
    });

    const {getOptions} = selectComputed({props, locState});

    const toggleElRef = ref();
    const listElRef = ref();
    const searchInputElRef = ref();

    const setTemplateForPartials = () => {
        const {optionTemplate, toggleTemplate, viewTemplate, multiselect, multi} = props;
        const currentTemplate = {
            toggle: viewTemplate ? viewTemplate : toggleTemplate ? toggleTemplate : 'base',
            option: viewTemplate ? viewTemplate : optionTemplate ? optionTemplate : 'base',
        };

        if (multiselect || multi) {
            currentTemplate.toggle = currentTemplate.toggle == 'base' ? 'multi' : currentTemplate.toggle;
            currentTemplate.option = currentTemplate.option == 'base' ? 'multi' : currentTemplate.option;
        }

        const templateVariants = [...SELECT_VIEW_TEMPLATES, 'base', 'multi'];

        const option = currentTemplate.option && templateVariants.includes(currentTemplate.option)
            ? defineAsyncComponent(() => import(`./optionTemplates/${currentTemplate.option}.vue`))
            : false;

        const toggle = currentTemplate.toggle && templateVariants.includes(currentTemplate.toggle)
            ? defineAsyncComponent(() => import(`./toggleTemplates/${currentTemplate.toggle}.vue`))
            : false;

        return {
            option,
            toggle,
            templateName: currentTemplate,
        };

    };

    const {isMultiselect} = selectComputed({props, locState});

    const updateDropdownPosition = () => {
        setTimeout(() => {
            locState.popper?.update();
        }, 0);
    };

    const createNewPopperWithSettings = (toggle, list) => {
        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({state}) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({state}) => {
                state.elements.popper.style.width = `${
                    state.elements.reference.offsetWidth
                }px`;
            }
        };

        return createPopper(toggle, list, {
            strategy: 'fixed',
            placement: props.optionsListPlacement,
            modifiers: [
                sameWidth,
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 0,
                        boundary: toggleElRef.value,
                        tether: false, // false by default

                    },
                },
            ],
        });
    }

    const hideListHandle = () => {
        locState.listShow = false;
        locState.search = ''
        locState.tempOptions = null
        locState.focusedOption = null
    };
    const showListHandle = () => {

        if (!props.disabled) {
            locState.listShow = true;
            updateDropdownPosition();

            setTimeout(() => {
                locState.popper = createNewPopperWithSettings(
                    toggleElRef.value,
                    listElRef.value
                )
            }, 0);
        }

    };
    const toggleListHandle = () => {
        if (!locState.listShow) {
            showListHandle();

            setTimeout(() => {
                searchInputElRef?.value?.focus();
            }, 100);

        } else {
            hideListHandle();
        }

    };

    const selectOptionAndEmit = (currentSelectedValue) => {
        const {outValueKey} = props;
        let valueForEmit = currentSelectedValue

        if (currentSelectedValue) {
            if (Array.isArray(currentSelectedValue)) {
                if (outValueKey) {
                    valueForEmit = currentSelectedValue.map(item => item[outValueKey] ?? item)
                }
            } else {
                if (outValueKey && outValueKey in currentSelectedValue) {
                    valueForEmit = currentSelectedValue?.[outValueKey]
                }
            }
            locState.currentValueObject = cloneDeep(currentSelectedValue)

            emitChanges(valueForEmit);
        }
    }

    const emitChanges = (value) => {
        nextTick(() => {
            emit('update:modelValue', value);
            emit('select', value);
        });
    };

    const removeSelectedTagHandle = (option) => {
        let currentOptions = cloneDeep(props.modelValue);
        const cloneOptions = cloneDeep(props.options);
        const {optionKeyTitle, optionKeyValue, outValueKey} = props

        const existOptionItemAny = props.options?.find(item => item[optionKeyTitle] == 'Any') ?? false
        const existSelectedItemAny = currentOptions?.find(item => item[optionKeyTitle] == 'Any')

        let findOption = null;
        if (!option?.[optionKeyValue] && outValueKey) {
            findOption = cloneOptions.find(item => item[outValueKey] == option)
        }

        if (existOptionItemAny && (existOptionItemAny?.[optionKeyValue] == option || findOption?.title == 'Any')) {
            return
        } else {
            const index = currentOptions.findIndex(item => (item?.[optionKeyValue] ?? item) == (option?.[optionKeyValue] ?? option));

            if (index > -1) {
                currentOptions.splice(index, 1);
                if (currentOptions.length == 0 && existOptionItemAny && !existSelectedItemAny) {
                    nextTick(() => {
                        selectOptionHandle(existOptionItemAny)
                    })
                }
            }
        }
        emitChanges(currentOptions);
        updateDropdownPosition();
    };

    const selectOptionHandle = (option) => {
        const {
            viewTemplate,
            modelValue,
            limit,
            options,
            optionKeyTitle,
            optionTemplate,
            optionKeyValue,
            outValueKey,
            keyPathForComparison,
            optionsListHasGroups,
            optionsListGroupTitleKey,
            optionsListGroupItemsKey
        } = props;

        const updateSimpleOptions = (currentOptions = []) => {
            const index = currentOptions.findIndex(item => item == option);
            if (index > -1) {
                currentOptions.splice(index, 1);
            } else {
                if (!limit || limit && modelValue?.length < Number(limit) || !modelValue) {
                    currentOptions.push(option);
                }
            }
            emitChanges(currentOptions);
        };

        if (isMultiselect.value) {
            let currentOptions = cloneDeep(props.modelValue) ?? [];
            let cloneOptions = cloneDeep(getOptions.value);

            if (optionsListHasGroups) {
                cloneOptions = cloneOptions.reduce((acc, item) => {
                    const groupOptionsList = item?.[optionsListGroupItemsKey]
                    if (groupOptionsList) {
                        acc = [...acc, ...groupOptionsList]
                    }
                    return acc
                }, [])
            }

            const isMultiCheckbox = viewTemplate == 'multiCheckbox' || optionTemplate == 'multiCheckbox'

            if (isMultiCheckbox) {

                const optionsHasItemAny = cloneOptions.map(item => item[optionKeyTitle]).includes('Any')
                const anyItem = cloneOptions.find(item => item[optionKeyTitle] == 'Any')

                const existAnyIndex = currentOptions?.findIndex(item => (outValueKey ? item : item?.[optionKeyValue]) == anyItem?.[optionKeyValue])

                if (option?.[optionKeyTitle]) {

                    if (option?.[optionKeyTitle] == 'Any') {

                        if (existAnyIndex < 0) {
                            selectOptionAndEmit([option])
                        }
                    } else {
                        const findOptionIndex = currentOptions?.findIndex(item => option?.[optionKeyValue] == (item?.[optionKeyValue] ?? item))

                        if (existAnyIndex > -1) {
                            currentOptions.splice(existAnyIndex, 1)
                            currentOptions.push(option)
                        } else {
                            if (findOptionIndex > -1) {
                                currentOptions.splice(findOptionIndex, 1)
                                if (currentOptions.length == 0 && optionsHasItemAny) {
                                    currentOptions.push(anyItem)
                                }

                            } else {
                                //limit
                                if (!limit || limit && modelValue?.length < Number(limit) || !modelValue) {
                                    currentOptions.push(option)
                                }
                            }
                            if (optionsHasItemAny && currentOptions.length == cloneOptions.length - 1) {
                                currentOptions = [anyItem]
                            }
                        }

                        selectOptionAndEmit(currentOptions)
                    }

                } else {
                    if (option == 'Any' && !modelValue?.includes('Any')) {
                        emitChanges(cloneOptions);
                    } else {

                        updateSimpleOptions(currentOptions);
                    }
                }
            } else {
                if (modelValue !== null && Array.isArray(modelValue)) {

                    if (typeof option == 'string' || typeof option == 'number') {

                        updateSimpleOptions(currentOptions);

                    } else if (option?.[optionKeyValue]) {

                        const index = currentOptions.findIndex(item => {
                            if (keyPathForComparison) {
                                const [firstKey, secondKey] = keyPathForComparison.split('.')
                                if (secondKey && item?.[firstKey]) {
                                    return item?.[firstKey]?.[secondKey] == option[optionKeyValue]
                                }
                            }
                            return item[optionKeyValue] == option[optionKeyValue]
                        });
                        if (index > -1) {
                            currentOptions.splice(index, 1);
                        } else {

                            if (!limit || limit && modelValue?.length < Number(limit) || !modelValue) {
                                currentOptions.push(option);
                            }
                        }


                            emitChanges(currentOptions);



                    } else {
                        emitChanges([option]);
                    }

                } else {
                    emitChanges([option]);
                }
            }
            updateDropdownPosition();
        } else {
            selectOptionAndEmit(option)
            hideListHandle();
        }
    };
    const searchInputKeyHandle = (event) => {
        const key = event.key
        let focusedIndex = null
        if (['ArrowUp', 'ArrowDown'].includes(key)) {
            event.preventDefault()
            if (locState.focusedOption) {
                focusedIndex = getOptions.value.findIndex(item => item[props.optionKeyTitle] == locState.focusedOption[props.optionKeyTitle])
            }
        }
        const optionsLength = getOptions.value?.length
        if (key == 'ArrowUp') {
            if (optionsLength > 0) {
                if (locState.focusedOption == null) {
                    locState.focusedOption = getOptions.value[optionsLength - 1]
                } else if (locState.focusedOption) {
                    if (focusedIndex > -1) {
                        if (optionsLength - 1 >= focusedIndex - 1) {
                            if (getOptions.value[focusedIndex - 1]) {
                                locState.focusedOption = getOptions.value[focusedIndex - 1]
                            }
                        }
                    } else {
                        locState.focusedOption = getOptions.value[0]
                    }
                }
            }
        } else if (key == 'ArrowDown') {
            if (optionsLength > 0) {
                if (locState.focusedOption == null) {
                    locState.focusedOption = getOptions.value[0]
                } else if (locState.focusedOption) {
                    if (focusedIndex > -1) {
                        if (focusedIndex + 1 <= optionsLength - 1) {
                            if (getOptions.value[focusedIndex + 1]) {
                                locState.focusedOption = getOptions.value[focusedIndex + 1]
                            } else {
                            }
                        }
                    } else {
                        locState.focusedOption = getOptions.value[0]
                    }
                }
            }
        } else if (key == 'Enter') {
            if (locState.focusedOption) {
                if (focusedIndex > -1) {
                    selectOptionHandle(locState.focusedOption)
                }
            }
        }
    }

    selectWatchers({props, locState});
    selectHooks({
        props, locState, emit,
        computed: {isMultiselect},
        methods: {selectOptionHandle, emitChanges}
    });

    return {
        locState, toggleElRef, listElRef,
        searchInputElRef,
        setTemplateForPartials, selectOptionHandle,
        removeSelectedTagHandle, toggleListHandle, hideListHandle,
        searchInputKeyHandle,
    };
}
