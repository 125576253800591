import {computed} from 'vue';
import {cloneDeep} from 'lodash';
import {toggleProps, optionProps} from './select.props';
import {selectUtils} from './select.utils';

export default function SelectComputed({props, locState}) {
    const isMultiselect = computed(() => {
        const {multi, multiselect, multiple} = props;
        return multi || multiselect || multiple;
    });

    const getToggleProps = computed(() => {
        return selectUtils.retrieveSelectedProps(props, toggleProps);
    });

    const getOptionProps = computed(() => {
        return selectUtils.retrieveSelectedProps(props, optionProps);
    });

    const getPlaceholder = computed(() => {
        const {placeholderSelect, placeholder} = props;
        return placeholderSelect ? placeholderSelect : placeholder;
    });

    const getOptions = computed(() => {
        const {options} = props;
        return cloneDeep(locState?.tempOptions ?? options)
    });

    const getSelected = computed(() => {
        const {
            modelValue,
            optionKeyTitle,
            subOptionKeyTitle,
            subOptionKeyCode,
            options,
            optionKeyValue,
            outValueKey,
            initialValue,
            toggleKeyTitle,
            optionsListGroupTitleKey,
            optionsListGroupItemsKey
        } = props;

        if (toggleKeyTitle && typeof toggleKeyTitle == "function") {
            return toggleKeyTitle?.(
                {modelValue, initialValue, currentValueObject: locState?.currentValueObject}
            )
        }
        if (initialValue) {
            if (Array.isArray(initialValue)) {
                return initialValue
            }
            if (initialValue?.[optionKeyValue] == modelValue) {
                return initialValue?.[optionKeyTitle]
            }
        }

        if (typeof modelValue === 'string' || typeof modelValue === 'number') {
            if (outValueKey && locState.currentValueObject?.[outValueKey] == modelValue) {

                return locState.currentValueObject[optionKeyTitle] ?? locState.currentValueObject[toggleKeyTitle]
            }
            const options = getOptions.value

            if (options && options?.length > 0) {

                if (options[0]?.[optionKeyValue] != undefined) {
                    const find = options.find(item => item[optionKeyValue] == modelValue)
                    if (find) {
                        return find?.[optionKeyTitle]
                    }
                }

            }
            return (String(modelValue)?.length > 0 || !isNaN(modelValue)) ? String(modelValue) : null;
        }
        if (typeof modelValue === 'boolean') {
            const find = options.find(item => item[optionKeyValue] == (modelValue ? '1' : '0'))
            if (find) {
                return find?.[optionKeyTitle]
            }
        }
        if (Array.isArray(modelValue)) {
            return modelValue.length > 0 ? modelValue : null;
        }
        if (modelValue?.subOption) {
            const {subOption} = modelValue
            if (subOption?.[subOptionKeyCode]) {
                return `${modelValue.option[optionKeyTitle]} (${subOption?.[subOptionKeyTitle]}) (${subOption?.[subOptionKeyCode]})`;
            }
            return `${modelValue.option[optionKeyTitle]} (${subOption?.[subOptionKeyTitle]})`;
        }
        if (modelValue?.[subOptionKeyCode]) {
            return `${modelValue[optionKeyTitle]} (${modelValue?.[subOptionKeyCode]})`
        }


        if (Array.isArray(toggleKeyTitle)) {
            const findKey = toggleKeyTitle.find(key => modelValue?.[key])
            return modelValue[findKey] ?? null

        } else {
            return modelValue?.[toggleKeyTitle] ?? null;
        }


    });
    return {
        getToggleProps,
        getOptionProps,
        getPlaceholder,
        getOptions,
        getSelected,
        isMultiselect,
    };
}
