
export const defaultAttributesProps = {
    modelValue: [Number, String, Boolean, Array, Object],
    name: {
        type: [String,Array],

    },
    label: String,
    labelClasses:[String, Array,Object],
    required:Boolean,
    readonly:Boolean,
    placeholder: String,
    style: Object,
    disabled: Boolean,
    type: {
        type: String,
        default: 'text',
    },

};

export const modesProps = {
    withIcon:Boolean,

    modeDatepicker: {
        type: [Boolean,Object],
    },
    modeTimePicker: {
        type:  [Boolean,Object],
    },
    modeInputWithDropdown: {
        type: [Boolean,Object],
    },
    modeQuantity: {
        type: [Boolean,Object],
    },
    modeSelect: {
        type:  [Boolean,Object, Array],
    },
    modeInput: {
        type: [Boolean,Object],
    },
    modeTextarea: {
        type: [Boolean,Object],
    },
    modeTextEditor: {
        type: [Boolean,Object],
    },
    modeSwitcher: {
        type: [String,Boolean, Object],
    },
};

const feedbackProps = {
    errors: [Object,Array,String],
    hint:{
        type: String,
        default:null
    },
    hintValid:{
        type:Boolean,
        default:false
    },
    hintWarning:{
        type:Boolean,
        default:false
    },
    hideHint:Boolean,
    hideHintInit:Boolean,

    hintIcon:String,
    hintIconClass:String,
    hintIconSize:String,
}

const classNames = {
    parentClass:String,
    wrapClass:String,
    elementClass:String,
}

export const iconProps = {
    icon: String,
    iconSize: String,
    iconClass: String,
}

export const  customProps = {
    singleOption: {
        type: Object,
    },
    switchSingle: {
        type: Boolean,
    },
};

const fieldProps = {
    ...defaultAttributesProps,
    ...modesProps,

    ...iconProps,
    ...customProps,

    ...classNames,
    ...feedbackProps,

    debug:Boolean,
};
export default fieldProps;


