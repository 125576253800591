export default {
    data: {
        type: [Array,Object],
        default: () => ([]),
    },

    rowChildrenKey:String,
    links: Array,
    columns: {
        type: Object,
        default: () => ({
            'key': 'title',
        }),
    },
    columnsWidth: {
        type: Object,
        default: () => {
        },
    },
    columnsClass: {
        type: Object,
        default: () => {
        },
    },
    columnsInput: [Array, String],

    bodyRowClass: String,
    columnsTitleClass: {
        type: Object,
        default: () => ({}),
    },
    columnsCellClass: {
        type: Object,
        default: () => ({}),
    },
    rowHoverClass:String,
    sortKeys: {
        type: [Boolean, Array, String],
    },
    sortKeysId:Boolean, // ?
    tableClass: String,
    showNumber: Boolean,
    contentClass: String,
    actions: String,
    actionsShowFun: Function,
    hideHead: Boolean,
    eventsList: [Array, String],
    horizontalScroll: Boolean,
    dynamicRowClass: Function,
    keyForPublished: {
        type: String,
        default: 'published'
    },
    page: [Number, String],
    emptyStateTitle:String,
    showHeaderAlways:Boolean,
    search:String,
    entriesLimit:[String,Number],
    currentPage:[String,Number],
}
