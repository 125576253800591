import {useBoxListClientRequestsStore, useBoxListLinkedContactsStore} from "@store/boxes";
import {FLIGHT_LEG_KEYS_WITHOUT_CABIN} from "@models/request.model";
import {LINKED_CONTACT_MODEL_SIMPLE_KEYS} from "@models/client.model";

export default function () {

    const BoxListClientRequestsStore = useBoxListClientRequestsStore()
    const BoxListLinkedContactsStore = useBoxListLinkedContactsStore()

    const checkIsFilledRequiredFields = () => {
        const requestsList = BoxListClientRequestsStore.list
        const linkedContactList = BoxListLinkedContactsStore.list

        let requestIsFilled = null
        let linkedContactIsFilled = null
        let linkedContactIsFilledNotRequiredFields = null

        const checkIfHasFilledLinkedContactsFields = (item) => {
            const {names, ff_programs} = item
            const {no_name, first_name, ...restNames} = names

            const arrayOfBooleans = [
                ...LINKED_CONTACT_MODEL_SIMPLE_KEYS.map(key => !!item[key]),
                ...Object.values(restNames).map(item => !!item),
            ]
            if (ff_programs) {
                ff_programs?.forEach((item) => arrayOfBooleans.push(!!item.text))
            }
            return arrayOfBooleans.some(item => item);
        };
        if (requestsList) {
            const checkFirstItem = () => {
                const item = requestsList[0].flightLegs[0];

                const check = FLIGHT_LEG_KEYS_WITHOUT_CABIN.map(key => {

                    if (Array.isArray(item[key])) {
                        return !!item[key][0]
                    } else {
                        return !!item[key]
                    }
                }).every(item => item)

                return check
            }

            requestIsFilled = checkFirstItem() || requestsList.length > 1

        } else {
            requestIsFilled = false
        }

        if (linkedContactList?.length > 0) {
            const {names, phone_numbers, emails} = linkedContactList?.[0]
            linkedContactIsFilled = !!names.first_name || !!phone_numbers?.[0]?.text || !!emails?.[0]?.text
            linkedContactIsFilledNotRequiredFields = checkIfHasFilledLinkedContactsFields(linkedContactList?.[0])

        } else {
            linkedContactIsFilled = false
        }

        return {
            requestIsFilled, linkedContactIsFilled, linkedContactIsFilledNotRequiredFields
        }
    }
    return {
        checkIsFilledRequiredFields
    }
}
