<template>


    <AppMainLayout
        title="Sales"
    >

        <template #content>

            <div class="page-sales p-1 bg-white">
                <acms-v-table
                    class="p-4"
                    show-number
                    :columns="{
                        'request-id' : 'Request ID',
                        'created-at' : 'Creation date',
                        'time-passed' : 'time passed',
                        'local-time' : 'Local time',
                        'flight-details' : 'Flight details',
                        'passengers' : 'Passengers',
                        'pq' : 'PQ',
                        'cabin-class': 'Cabin class'
                      }"
                    :columns-width="{
                        '#': '52px',
                        'request-id' : '120px',
                        'created-at' : '160px',
                        'time-passed' : '130px',
                        'local-time' : '120px',
                        'flight-details' : '310px',
                        'passengers' : '200px',
                        'pq' : '120px',
                        'cabin-class': '160px'
                                  }"
                    :data="demoSalesTableData"
                >

                    <template #header>
                        <AcmsVTableHeader
                            config="search,entries"
                            v-model:search="locState.tableSearch"
                            v-model:entries="locState.tableCount"

                        />
                    </template>
                    <template #bodyCell-request-id="{item}">
                        <a href="/sales/show" class="text-primary text-uppercase">{{ item.id }}</a>
                    </template>
                    <template #bodyCell-created-at="{item}">
                        <span class="">{{ item.created_at }}</span>
                    </template>
                    <template #bodyCell-time-passed="{item}">
                        <div class="text-end">{{ item.time_passed }}</div>
                    </template>
                    <template #bodyCell-local-time="{item}">
                        <div class="text-end">{{ item.local_time }}</div>
                    </template>
                    <template #bodyCell-flight-details="{item}">
                        <div class="d-flex white-space-nowrap">
                            <div class="">{{ item.flight_details.date }}</div>

                            <div class="vertical-line mx-2"></div>
                            <div class="d-flex">
                                {{ item.flight_details.airports.join('—>') }}
                            </div>
                        </div>

                    </template>
                    <template #bodyCell-passengers="{item}">

                        <EntityCellPassengers
                            :data="item.passengers"
                        />

                    </template>
                    <template #bodyCell-pq="{item}">
                        <span class="">{{ item?.pq?.join(' ') ?? '-' }}</span>
                    </template>
                    <template #bodyCell-cabin-class="{item}">
                        <span class="">{{ item.cabin_class }}</span>
                    </template>


                </acms-v-table>



            </div>

        </template>

    </AppMainLayout>

</template>

<script>
export default {
    name: 'SalesPage',
};
</script>
<script setup="">

import AcmsVTableHeader from '@ui/Table/partials/TableHeader.vue';

import { demoSalesTableData } from '@demo/demoSales';
import { ref } from 'vue';
import EntityCellPassengers from '@components/app/entities/EntityCellPassengers.vue';
import AppMainLayout from "@layouts/AppMainLayout.vue";

const props = defineProps({});


const locState  = ref({

    tableSearch: '',
    tableCount: 20,
});



const sortHandle = (tableSort) => {
    console.log(' tableSort ', tableSort);
}

</script>
<style scoped>

</style>
