<template>
    <label class="acms-v-label form-label " :class="classes">
        <span class="fw-bold me-1" :class="labelTitleClass">{{ getLabel }}</span>
        <span v-if="required" class="text-danger">*</span>
        <span v-if="after" class="text-gray-dark fw-normal">{{after}}</span>
    </label>
</template>

<script>
export default {
    name: 'AcmsVLabel',
};
</script>

<script setup>

import { computed } from 'vue';

const props = defineProps({
    label: String,
    title: String,
    after: String,
    classes:[String,Array,Object],
    required: Boolean,
    labelTitleClass:String,
});

const getLabel = computed(() => {
    const { label, title } = props;
    const labelToDisplay = label ? label : title ? title : ''
    return  labelToDisplay.charAt(0).toUpperCase() + labelToDisplay.slice(1);
});

</script>
<style lang="scss">
.acms-v-label {
    font-size: 14px;
    &.disabled{
        opacity: 0.5;
    }
}
</style>
