<template>
    <div class="acms-v-bottom-actions ">
        <div class="acms-v-bottom-actions-buttons d-flex justify-content-end gap-3">
            <template v-if="!$slots.default">
                <acms-v-btn title="Cancel"  v-if="!hideCancel" class="btn-border btn-outline-light text-gray-darker" @click="cancelHandle"/>
                <acms-v-btn title="Save and exit"
                            v-if="!hideSaveAndExit"
                            :disabled="disabledSave"
                            :class="[hideSave ? 'btn-primary' : 'btn-border bg-gray-light' ]"
                            @click="$emit('saveAndExit')"

                />
                <acms-v-btn :title="saveTitle"
                            v-if="!hideSave" class="btn-primary"
                            @click=" $emit('save')"
                            :disabled="disabledSave"/>
            </template>
            <slot></slot>
        </div>
        <AlertCancel v-if="alertForCancel"
            v-model:show="locState.modalAlertShow"
            :type="modalAlertCancelType"
            :leftButtonCreate="modalAlertCancelLeftButtonCreate"
            @cancel="modalAlertCancelHandle"
        />
<!--        type="request"-->
<!--        @cancel="requestsHandlers.delete"-->
<!--        <div style="height: 74px"></div>-->
    </div>
</template>
<script>
export default {
    name: 'AcmsVBottomActions',
};
</script>

<script setup="">
import {computed, onMounted, onUnmounted, reactive} from "vue";
import AlertCancel from "../app/alerts/AlertCancel.vue";

const props = defineProps({
    hideCancel:Boolean,
    hideSaveAndExit:Boolean,
    hideSave:Boolean,
    positionAbsolute:Boolean,
    disabledSave:Boolean,
    alertForCancel:[Boolean,Function],
    modalAlertCancelType:String,
    modalAlertCancelLeftButtonCreate:Boolean,
    saveTitle:{
        type:String,
        default:'Save'
    }
});

const emit = defineEmits(['modalAlertCancel', 'save', 'saveAndExit'])
const locState = reactive({
    modalAlertShow:false,
})

const getPosition = computed(()=>{
    return props.positionAbsolute ? 'sticky' : 'fixed'
})

const hideAlertModalHandle = () => {
    locState.modalAlertShow = null
}
const modalAlertCancelHandle = (e) => {
    emit('modalAlertCancel', {hideAlertModalHandle})
}
const cancelHandle = () => {
    const {alertForCancel} = props
    if (alertForCancel) {
        const bool = typeof alertForCancel == 'function'
            ? alertForCancel?.()
            : alertForCancel
        if (bool) {
            locState.modalAlertShow = true
        } else {
            modalAlertCancelHandle()
        }
    } else {
        emit('cancel')
    }
};
onMounted(()=>{
    const main = document.querySelector('main')
        if(main){
            main.style.marginBottom = '74px'
        }
})
onUnmounted(()=>{
    const main = document.querySelector('main')
    if(main){
        main.style.marginBottom = ''
    }
})
</script>
<style lang="scss">
.acms-v-bottom-actions-buttons {
    height: 74px;
    padding: 16px;
    position: fixed;
    background: #fff;
    bottom: 0;
    right: 0;
    left: 62px;
    z-index: 1000;
}
</style>
