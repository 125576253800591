<template>
    <div v-bind="$attrs" class="box-client-connection-actions d-flex  " style="gap:11px">
<!--        <acms-v-btn icon="email" icon-size="20" :updatesCount="emailCount"-->
<!--                    @click="locState.sendEmailModalShow = true"-->
<!--                    class="text-white bg-primary-dark-op p-1  "/>-->

        <ModalSendEmail v-if="showEmail"/>
        <acms-v-btn icon="phone" icon-size="20" :updatesCount="phoneCount"
                    @click="locState.callLogsModalShow = true"
                    class="text-white bg-primary-dark-op p-1  "/>

    </div>
    <ModalFollowupSendEmail
        :show="locState.sendEmailModalShow"
        @hide="locState.sendEmailModalShow = false"
    />
    <ModalClientCallLogs
        :show="locState.callLogsModalShow"
        @hide="locState.callLogsModalShow=false"

    />
</template>
<script>
export default {
    name: 'BoxClientConnectionActions',
};
</script>
<script setup="">
import {reactive} from 'vue';
import ModalClientCallLogs from './modals/ModalClientCallLogs.vue';
import ModalFollowupSendEmail from './modals/ModalFollowupSendEmail.vue';
import ModalSendEmail from "@boxes/client/BoxClientConnectionActions/modals/ModalSendEmail.vue";

const props = defineProps({
    emailCount: [Number, String],
    phoneCount: [Number, String],
    showEmail: {
        type: Boolean,
        default: true
    }

});

const emit = defineEmits(['emailClick', 'phoneClick',]);
const locState = reactive({
    sendEmailModalShow: false,
    callLogsModalShow: false,
})
</script>

<style scoped>

</style>
