const defaultSettings = {
    preserveScroll: true
}

export const getSettingsForUseForm = (params = {}) => {

    const {only: onlyParam, ...currentSettings} = params

    const settings = {
        ...defaultSettings,
        ...currentSettings,
    }

    const only = typeof onlyParam === "string" ? [onlyParam] : Array.isArray(onlyParam) ? onlyParam : null;
    if (only) {
        settings.only = [...only, 'flash', 'global', 'errors']
    }

    return settings
}
