
export default {
    beforeMount(el) {
        function formatInput(value) {
            // Regular expression to match numbers with up to 2 decimal places
            const regex = /^\d*([.,]?\d{0,2})$/;

            // Test if the value matches the regular expression
            if (!regex.test(value)) {
                // If not, remove invalid characters
                value = value.replace(/[^0-9.,]/g, '');
                // Further limit the value to 2 decimal places
                const parts = value.split(/[.,]/);
                if (parts.length > 1) {
                    value = `${parts[0]}.${parts[1].substring(0, 2)}`;
                }
            }
            return value;
        }

        function handleInput(event) {
            const value = event.target.value;
            const formattedValue = formatInput(value);
            if (value !== formattedValue) {
                event.target.value = formattedValue;
                el.dispatchEvent(new Event('input'));
            }
        }

        el.__handleInput__ = handleInput;
        el.addEventListener('input', handleInput);
    },
    unmounted(el) {
        el.removeEventListener('input', el.__handleInput__);
        delete el.__handleInput__;
    }
};