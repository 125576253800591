import {defineStore} from "pinia";

export const useBoxListClientRequestsStore = defineStore('boxListClientRequestsStore', {
    state: () => ({
        queueIsFinished:false,
        list:null,
    }),

    actions: {
        setList(list){
            this.list = list
        },
        setQueueFinishedStatus(bool) {
            this.queueIsFinished = bool
            setTimeout( () => {
                this.queueIsFinished = false
            }, 1000);
        },
    },
})
