<template>
    <div class="field-remarks-int-and-ext col  w-100">
        <div class="row">
            <div class="col">
                <acms-v-form-field
                    mode-textarea
                    label="Remark Int"
                    name="remark_int"
                    label-classes="fz-14"
                    placeholder="Internal Remark"
                    type="textarea"
                    :modelValue="remarkInt"
                    @update:modelValue="$emit('update:remarkInt', $event)"
                />
            </div>
            <div class="col">
                <acms-v-form-field
                    mode-textarea
                    label="Remark Ext"
                    label-classes="fz-14"
                    name="remark_ext"
                    :modelValue="remarkExt"
                    @update:modelValue="$emit('update:remarkExt', $event)"
                    placeholder="External Remark"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FieldRemarksIntAndExt"
}
</script>

<script setup="">
const props = defineProps({
      remarkInt:String,
      remarkExt:String,
})

</script>
<style scoped>

</style>
