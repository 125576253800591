<template>
    <acms-v-modal
        :show="show"
        @hide="hideModalHandle"
        :size="type == 'email' ? 'small' : 'sm-lg'"
        :title="'Request ' +type+ ' edit'"
        :alertForCancel="()=>checkShowAlert"
        @modalAlertCancel="tryModalHideHandle"
        modalAlertCancelType="standard"
    >
        <template #content>

            <div class="row">

                <template v-if="type=='phone'">

                    <acms-v-form-field
                        label="New phone number"
                        name="phone_number"
                        required
                        :class="'phone-select-block'"
                        placeholder="(XXX) XXX XX XX"
                        :modeInputWithDropdown="{
                                     selectOptions: getInitialDialCodeOption,
                                     selectedOptionOnInit:0,
                                     phoneCode:true,
                                     keyDropdown:'type',
                                     keySelect:'phoneCode',
                                     mode:'phone',
                                      search:true,
                                      searchAsync: 'infohub.getDialCodes',
                                      placeholderSelect:'Search',

                                }"
                        type="number"
                        v-model="locState.form[type]"
                        :errors="locState.form.errors?.['phone.phone']"
                    />

                </template>
                <template v-if="type == 'email'">
                    <acms-v-form-field
                        :name="type"
                        modeInput
                        required
                        type="email"
                        :label="'New '+type"
                        v-model="locState.form[type]"
                        :errors="locState.form.errors?.['email']"
                    />
                </template>
                <acms-v-form-field
                    :modeTextarea="{minHeight:96, resize:true}"
                    label="Comment"
                    name="comment"
                    v-model="locState.form.comment"
                    :errors="locState.form.errors?.comment"
                />
            </div>
        </template>
        <template #footer="{hideHandle}">
            <button @click="sendHandle(hideHandle)" class="btn ms-auto btn-primary btn-sm">Send request</button>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalRequestEditEmailOrPhone"
}
</script>
<script setup="">
import {computed, reactive, ref, watch} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {useBackendMiddlewarePropsService} from "@services";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getFormattedPhoneForBackend} from "@services/FormattedDataForServerService";

const emit = defineEmits(['update:show', ''])
const props = defineProps({
    show: Boolean,
    type: String,
    data: Object,
})
const $page = usePage()
const getInitialDialCodeOption = computed(() => [$page.props.optionsForSelect?.dialCodeOption])

const {backendFlashSuccess} = useBackendMiddlewarePropsService()
const AlertsManager = useAlertsManagerStore()
const initObject = {
    comment: null,
    type: props.type,
    id: props.data?.id ?? null,
}

const locState = reactive({
    form: useForm({
        [props.type]: null,
        ...initObject
    })
})
watch(() => props.show, (val) => {
    if (!val) {
        locState.form = useForm({
            ...initObject,
            [props.type]: null
        })
    } else {
        locState.form.id = props.data?.id ?? null

    }
})

const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}

const checkShowAlert = computed(() => {
    const value = locState.form[props.type]
    if (props.type == 'phone') {
        return !!value?.['text']
    } else {
        return !!value
    }
})

watch(checkShowAlert, (val) => {
    if (val) {
        window.onbeforeunload = alertYourChangesNotBeSaved
    } else {
        window.onbeforeunload = null
    }
})

const tryModalHideHandle = ({hideAlertModalHandle}) => {
    hideAlertModalHandle?.()
    hideModalHandle()
}

const hideModalHandle = () => {
    emit('update:show', false)
}

const sendHandle = (hide) => {

    const transformData = (data) => {
        if (props.type == 'phone') {
            const type = props.type
            if (data?.[type]) {
                const {id} = data
                const phone = data[type]
                return {
                    ...data,
                    id,
                    type,
                    [type]: {
                        phone: phone?.text,
                        dial_code: phone?.phoneCode?.id,
                        id: id ?? null
                    }
                }
            }
        }
        return data
    }
    locState.form
        .transform(transformData)
        .post(getRoute('clients.contact.requestEdit'),
            {
                preserveScroll:true,
                onSuccess() {
                    if (backendFlashSuccess.value) {
                        AlertsManager.add(backendFlashSuccess.value)
                        hideModalHandle()
                    }
                }
            })
}

</script>

<style scoped>

</style>
