<template>
    <div class="box-emails-list ">
        <div class="d-flex flex-column">
            <template v-for="(item, key) in valueList">
                <acms-v-form-field
                    :label="getLabel(key, 'Main email', 'Email')"
                    :labelClasses="{'disabled': Boolean(valueList[key]?.id) && !modeCreate}"
                    required
                    :name="`emails.${key}`"
                    :class="getOrderClass(key)"
                    placeholder="ex. john_doe@gmail.com"
                    :modeInputWithDropdown="{
                            dropdownOptions,
                            keyDropdown:'type',
                            mode:'email',
                            dropdownOptionShowFun:dropdownOptionShowFun,
                            disabledStateFun:itemDisabledStateFun,

                          }"
                    @clickOnDropdownOption="(e)=>clickOnDropdownOption(e, key)"
                    v-model="valueList[key]"
                    :errors="errors"
                />
            </template>
        </div>
        <acms-v-btn
            title="Add email"
            preset="simple-primary"
            icon="plus"
            @click="add"
        />
        <teleport to="body">
            <ModalRequestEditEmailOrPhone
                v-model:show="modalsState.modalRequestEditShow"
                :data="modalsState.modalRequestEditData"
                @update:show="onModalHideHandle"
                type="email"
            />
            <ModalRequestDeleteEmailOrPhone
                v-model:show="modalsState.modalRequestDeleteShow"
                :data="modalsState.modalRequestDeleteData"
                @update:show="onModalHideHandle"
                type="email"
            />

            <ModalEditEmailOrPhone
                v-model:show="modalsState.modalEditShow"
                :data="modalsState.modalEditData"
                @update:show="onModalHideHandle"
                type="email"
                title="Email"
            />

            <AlertDelete
                v-model:show="modalsState.modalDeleteShow"
                @delete="()=>onDeleteContactHandle('email')"
                type="email"
            />
        </teleport>
    </div>
</template>

<script>
export default {
    name: 'BoxEmailsList',
};
</script>

<script setup="">
import useCommonEmailsAndPhones from './composables/useCommonEmailsAndPhones';
import {inputDropdownOptions} from '@demo/demoOptionsForSelect';
import {computed, reactive} from 'vue';
import { cloneDeep } from 'lodash';
import ModalRequestEditEmailOrPhone from "./partials/ModalRequestEditEmailOrPhone.vue";
import ModalRequestDeleteEmailOrPhone from "./partials/ModalRequestDeleteEmailOrPhone.vue";
import ModalEditEmailOrPhone from "@boxes/common/partials/ModalEditEmailOrPhone.vue";
import {useBackendMiddlewarePropsService} from "@services";
import AlertDelete from "@alerts/AlertDelete.vue";

const props = defineProps({
    modelValue: Array,
    updatedData: [Array, Object],
    errors:Object,
    modeCreate:Boolean,
});

const emit = defineEmits(['update:modelValue']);

const {backendPermissions} = useBackendMiddlewarePropsService()
const actionEdit = { 'title': 'Edit' , type:'action', action: 'edit'};
const actionRequestEdit = { 'title': 'Request edit' , type:'action', action: 'request-edit'};

const actionDelete =  { 'title': 'Delete', 'className': 'text-danger', type:'action', action: 'request-delete'}
const actionRequestDelete =  { 'title': 'Request delete', 'className': 'text-danger', type:'action', action: 'delete'}


const dropdownOptions = computed(()=>{
    const actions = [
        { 'divider': true, type:'divider' }
    ]

    actions.push(backendPermissions?.allowUpdateClientEmail ? actionEdit : actionRequestEdit)
    actions.push(backendPermissions?.allowDeleteClientEmail ? actionDelete : actionRequestDelete)

    return [
        { 'title': 'Set as main', 'canSetMain': true, 'title_main': 'Main email' },
        ...inputDropdownOptions,
        ...actions
    ];
})


const cleanEmail = {
    main: false,
    type: cloneDeep(dropdownOptions.value?.[2]) ,
    text: null,
    // id:null,
};
const {
    valueList, clickOnDropdownOption,
    getOrderClass,
    add,
    getLabel,
    modalsState,
    dropdownOptionShowFun,itemDisabledStateFun,
    onModalHideHandle,
    onDeleteContactHandle,
} = useCommonEmailsAndPhones({
    props,
    cleanItem: cleanEmail,dropdownOptions
}, emit);
</script>

<style scoped>

</style>
