<template>
    <div class="box-flight-legs">
        <template v-for="(item, key) in flightLegs">
            <div class="box-flight-legs-row row g-3  ">
                <div class="box-flight-legs-directions col-12  col-xl-6  ">
                    <div class="d-flex ">
                        <acms-v-form-field
                            label="From"
                            required
                            class="flight-leg-select flight-leg-select--from"
                            placeholder="City/Airport"
                            v-model="flightLegs[key].from"
                            :errors="errors?.[`flight_leg.${key}.departure_airport_id`]"
                            with-icon
                            icon="plane"
                            icon-class="text-gray-40"
                            :modeSelect="{
                                searchPlaceholder:  'Enter min 3 characters to search',
                                search:true,
                                searchAsync: 'infohub.getAirportAndCityList',
                                viewTemplate:'cityAirport',
                                optionKeyArray:'airports',
                                subOptionKeyCode:'iata',
                                toggleKeyTitle:getCorrectFlightLegTitle
                        }"
                        />

                        <acms-v-form-field
                            label="To"
                            required
                            class="flight-leg-select flight-leg-select--to"
                            v-model="flightLegs[key].to"
                            placeholder="City/Airport"
                            :modeSelect="{
                            searchPlaceholder: 'Enter min 3 characters to search',
                            search:true,
                            searchAsync: 'infohub.getAirportAndCityList',
                            viewTemplate:'cityAirport',
                            optionKeyArray:'airports',
                            subOptionKeyCode:'iata',
                            toggleKeyTitle:getCorrectFlightLegTitle
                        }"
                            :errors="errors?.[`flight_leg.${key}.arrival_airport_id`] "
                        />
                    </div>
                </div>
                <div class="box-flight-legs-dates col-6 col-xl-3 ">
                    <acms-v-form-field
                        label="Date range"
                        required
                        placeholder="Select flight period"
                        :modeDatepicker="{
                             datesRange:true,
                             minDate:getMinDate(key),
                             startDate: getMinDate(key),
                         }"
                        v-model="flightLegs[key].dates_range"
                        :errors="(errors?.[`flight_leg.${key}.flight_date_from`] ||  errors?.[`flight_leg.${key}.flight_date_to`]) "
                    />
                </div>
                <div class="box-flight-legs-cabin col-6 col-xl-3">
                    <div class="w-100 d-flex">
                        <acms-v-form-field
                            class="w-100 "
                            hide-hint
                            required
                            :modeSelect="{
                                options: $page.props?.optionsForSelect?.cabinClassOptions ?? [],
                                selectedOptionOnInit: 0,
                                outValueKey:'id'
                            }"
                            label="Cabin class"
                            v-model="flightLegs[key].cabin_class_id"
                            :errors="errors?.[`flight_leg.${key}.cabin_class_id`] "
                        />
                        <div class="ms-3" v-if="(key == 0 && getRowFilledState(key)) || (key > 0)">
                            <acms-v-label/>
                            <acms-v-btn
                                icon="close"
                                class="bg-gray-light align-self-end   btn-row-action"
                                @click="()=>removeRow(key)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <acms-v-btn
            title="Add flight leg"
            class="btn-sm btn-outline-primary  mt-3 box-flight-legs-row-add"
            preset=""
            icon="plus"
            @click="addRow"
        />
    </div>

</template>

<script>
export default {
    name: 'BoxFlightLegs',
};
</script>

<script setup="">
import {onBeforeMount, ref, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";
import {FLIGHT_LEG_KEYS, flightLegModel} from "@models/request.model";
const $page = usePage();
const emit = defineEmits(['update:modelValue', ''])
const props = defineProps({
    modelValue: Array,
    errors: Object,
});

const initValue = {...flightLegModel};
const flightLegs = ref([]);
const addRow = () => {
    flightLegs.value.push({...initValue, from: flightLegs.value?.at(-1)?.to ?? null, to: flightLegs.value?.at(-1)?.from ?? null });
};
watch(flightLegs, (val) => {
    emit('update:modelValue', val)
})

const removeRow = (key) => {
    if (key > 0) {
        flightLegs.value.splice(key, 1);
    } else {
        flightLegs.value = [{...initValue}]
    }
};

const getMinDate = (index) => {
    if(index == 0){
        return new Date()
    }else if (index > 0){
        const prevLeg = flightLegs.value[index-1]
        if(prevLeg){
            if(prevLeg.dates_range?.[1]){
                return  prevLeg.dates_range?.[1]
            }
            else if(prevLeg.dates_range?.[0]){
                return  prevLeg.dates_range?.[0]
            }else {
                return new Date()
            }
        }
        return new Date()
    }
    return new Date()
}

const getRowFilledState = (formKey) => {
    const item = flightLegs.value[formKey]
    const checkEvery = [];

    FLIGHT_LEG_KEYS.forEach(key => {
        if (key == 'dates_range') {
            return checkEvery.push(item[key]?.[0] != '' && item[key]?.[1] != '')
        }
        return checkEvery.push(!!item[key])
    })

    return checkEvery.every(item => item)
}

const getCorrectFlightLegTitle = (params) => {
    const {modelValue} = params
    if (modelValue) {
        if (typeof modelValue == 'string') {
            return modelValue
        }
        const {iata, title, country} = modelValue
        if (country) {
            return `(${iata})  ${title}, ${country.title} `
        } else {
            return `(${iata}) ${title} `
        }
    }
}

onBeforeMount(() => {
    flightLegs.value = props.modelValue?.length > 0 ? props.modelValue : [{...initValue}];
});
</script>

<style lang="scss">
.box-flight-legs {
    .container {
        max-width: 100%;
    }
}
.box-flight-legs-row {
    margin-top: unset !important;
    position: relative;
    &:nth-child(even) {
        //background-color: #F8F9FA;;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: -20px;
        margin-right: -20px;
    }
}
.box-flight-legs-directions {
    @media only screen and (max-width: 1630px) {
        width: 100%;
    }
}
@media only screen and (max-width: 1630px) {
    .box-flight-legs-dates {
        width: 50%;
    }
    .box-flight-legs-cabin {
        width: 50%;
    }
}
.flight-leg-select--from {
    width: 55%;
}
.flight-leg-select--to {
    width: 45%;
    .acms-v-select {
        width: 100%;
    }
    .acms-v-select-toggle {
        border-radius: 0 4px 4px 0;
        border-left: 0;
    }
}
.flight-leg-select {
    .acms-v-select-toggle-selected {
        max-width: 202px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
