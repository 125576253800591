<template>
    <acms-v-modal-alert
        :show="Boolean(show)"
        @update:show="$emit('update:show', $event)"
        :title="getTitleAndText.title"
        :text="getTitleAndText.text"
        leftButtonTitle="Cancel"
        rightButtonTitle="Yes, delete"
        rightButtonClass="btn-danger"
        @leftButtonClick="$emit('update:show', null)"
        @rightButtonClick="$emit('delete')"
    />
</template>

<script>
export default {
    name: "AlertDelete",
}
</script>
<script setup="">
import {ALERT_DELETE_TITLE_TEXT} from "./constants";
import {computed} from "vue";
const emit = defineEmits(['cancel', 'update:show','delete'])
const props = defineProps({
    show: [Boolean,Array,Object],
    type: {
        type: String,
        validator(value) {
            return Object.keys(ALERT_DELETE_TITLE_TEXT).includes(value)
        },
        default:'request'
    }
})
const getTitleAndText = computed(()=>{
    return ALERT_DELETE_TITLE_TEXT[props.type] ?? {title:'', text:''}
})

</script>
<style scoped>

</style>
