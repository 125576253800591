<template>
    <div class="box-flight-preferences">
        <h5>Flight preferences</h5>
        <div class="row  g-12">
            <acms-v-form-field
                class="box-flight-preferences-item col-4"
                placeholder="Enter airlines"
                :mode-select="{
                                multi:true,
                                search:true,
                                minSymbolsForSearch:2,
                                searchAsync: 'infohub.getAirlinesList',
                                toggleTagKeyTitle: ['iata', 'airline.iata', 'airline.title'],
                                keyPathForComparison:'airline.id',
                                orderSelectedOptions:true,
                            }"
                label="Airlines"
                v-model="flightPreferences.airlines"
                :errors="errors?.airlines"
            />

            <acms-v-form-field
                class="box-flight-preferences-item col-4"
                placeholder="Not specified"
                :modeSelect="{
                options: $page.props?.optionsForSelect?.numberOfStopsOptions ?? [],
                outValueKey:'id',
                }"
                label="Number of stops"
                v-model="flightPreferences.number_of_stops_id"
                :errors="errors?.number_of_stops_id"
            />
            <acms-v-form-field
                class="box-flight-preferences-item col-4"
                :modeSelect="{
                    options:$page.props?.optionsForSelect?.purposeOfTravelsOptions ?? [],
                    outValueKey:'id',
                }"
                label="Purpose of travel"
                placeholder="Not specified"
                v-model="flightPreferences.purpose_of_travel_id"
                :errors="errors?.purpose_of_travel_id"
            />
            <acms-v-form-field
                class="box-flight-preferences-item col-4"
                label="Max.client budget ($)"
                type="number"
                placeholder="0"
                v-model="flightPreferences.max_client_budget"
                :mode-input="{ limitAfterDecimalPoint:2,symbolBeforeContent:'$'}"
                :errors="errors?.max_client_budget"
            />
            <acms-v-form-field
                class="box-flight-preferences-item col-4"
                :modeSelect="{
                                options: $page.props?.optionsForSelect?.clientMainValuesOptions ?? [],
                                optionTemplate:'multiCheckbox',
                                multi:true,
                                outValueKey:'id',
                            }"
                label="Client main values"
                placeholder="Not specified"
                v-model="flightPreferences.values"
                :errors="errors?.values"
            />
            <div class="box-flight-preferences-item col-4">
                <acms-v-form-field
                    hide-hint
                    label="Client need a hotel?"
                    :mode-switcher="{
                     typeRadio:true,
                     options:[{label:'Yes', id : 1},  {label:'No', id : 0}],
                     multiple:true,
                     optionKeyTitle: 'label',
                     toggleKeyTitle: 'label',
                     optionKeyValue: 'id'
                    }"
                    v-model="flightPreferences.client_need_a_hotel"
                    :errors="errors?.client_need_a_hotel"
                />
            </div>

        </div>
        <div class="row  g-12"></div>
            <div class="row g-12">
                <acms-v-form-field
                    class="box-flight-preferences-item col-4"
                    :modeSelect="{
                                options: $page.props?.optionsForSelect?.seatOptions ?? [],
                                optionTemplate:'multiCheckbox',
                                multi:true,
                                outValueKey:'id',
                                selectedOptionOnInit:{key: 'title', value: 'Any'},

                            }"
                    label="Seats"
                    placeholder="Not specified"
                    v-model="flightPreferences.seats"
                    :errors="errors?.seats"
                />
            </div>
            <slot></slot>
        </div>

</template>

<script>
export default {
    name: 'BoxFlightPreferences',
};
</script>
<script setup="">
import {onBeforeMount, ref} from 'vue';;
import AcmsVFormField from '@ui/FormField/FormField.vue';
import {usePage} from "@inertiajs/vue3";

const props = defineProps({modelValue: Object, errors: Object});
const $page = usePage()
const flightPreferences = ref();
onBeforeMount(() => {
    flightPreferences.value = props.modelValue;
});
</script>

<style scoped lang="scss">
.box-flight-preferences-item{
    @media only screen and (max-width: 1630px) {
        width: 50%;
    }
}
</style>
