<template>

    <div class="ui-loading" >
        <div class="ui-loading-content">{{text}}</div>


        <div class="ui-loading-animation"></div>
    </div>
</template>

<script>
export default {
    name: "AcmsVLoading",

    props:{
        text:{
            type:String,
            default: 'Loading'
        },
    }
}
</script>

<style >
.ui-loading {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 26%;
    right: 1px;
    left: unset;
    top: 26px;
    bottom: 1px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .ui-loading-animation{
        position: absolute;
        flex-shrink: 0;
        border: 2px solid transparent; /* Light grey */
        border-top: 3px solid #4c78dd; /* Blue */
        border-radius: 50%;
        width: 92px;
        height: 92px;
        animation: spin 2s linear infinite;
        margin: auto;
        inset: 0;
    }

    .ui-loading-content {
        position: absolute;
        inset: 0;
        width: max-content;
        height: max-content;
        font-size: 16px;
        margin: auto;
    }

}
</style>
