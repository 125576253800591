<template>
    <acms-v-modal
        :show="show"
        @hide="hideModalHandle"
        size="sm-lg"
        :title="title+ ' edit'"
        :alertForCancel="()=>checkShowAlert"
        @modalAlertCancel="tryModalHideHandle"
        modalAlertCancelType="standard"
    >
        <template #content>
            <template v-if="type == 'phone'">
                <acms-v-form-field
                    label="New phone number"
                    name="phone_number"
                    required
                    :class="'phone-select-block'"
                    placeholder="(XXX) XXX-XXXX"
                    :modeInputWithDropdown="{
                                     selectOptions: getInitialDialCodeOption,
                                     selectedOptionOnInit:0,
                                     phoneCode:true,
                                     keyDropdown:'type',
                                     keySelect:'phoneCode',
                                     mode:'phone',
                                      search:true,
                                      searchAsync: 'infohub.getDialCodes',
                                      placeholderSelect:'Search',

                                }"
                    type="text"
                    v-mask="'(###) ###-####'"
                    v-model="locState.form[type]"
                    :errors="locState.form.errors?.['phone.phone']"
                />
            </template>
            <template v-if="type == 'email'">
                <acms-v-form-field
                    :name="type"
                    modeInput
                    required
                    type="email"
                    label="New email"
                    v-model="locState.form[type]"
                    :errors="locState.form.errors?.['email']"
                />
            </template>
        </template>
        <template #footer="{hideHandle}">
            <button @click="saveHandle(hideHandle)" class="btn btn-primary ms-auto btn-sm">Save</button>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalEditEmailOrPhone"
}
</script>
<script setup="">
import {computed, reactive, ref, watch} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {getFormattedEmailForBackend, getFormattedPhoneForBackend} from "@services/FormattedDataForServerService";
import {useBackendMiddlewarePropsService} from "@services";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";


const emit = defineEmits(['update:show', ''])
const props = defineProps({
    show: Boolean,
    type: String,
    data: Object,
    title: String,
})
const initObject = {
    type: props.type,
    id: props.data?.id ?? null
}
const $page = usePage()
const getInitialDialCodeOption = computed(() => [$page.props.optionsForSelect?.dialCodeOption])

const {backendFlashSuccess} = useBackendMiddlewarePropsService()
const AlertsManager = useAlertsManagerStore()
const getInitialData = () => {
    return {
        id: props.data?.id,
        [props.type]: null,
        ...initObject
    }
}

const locState = reactive({
    form: useForm(getInitialData()),
    alertShow: false,
})

const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}

const checkShowAlert = computed(() => {
    const value = locState.form[props.type]
    if (props.type == 'phone') {
        return !!value?.['text']
    } else {
        return !!value
    }
})

const hideModalHandle = () => {
    emit('update:show', false)
}

const tryModalHideHandle = ({hideAlertModalHandle}) => {
    hideAlertModalHandle?.()
    hideModalHandle()
}

watch(checkShowAlert, (val) => {
    if (val) {
        window.onbeforeunload = alertYourChangesNotBeSaved
    } else {
        window.onbeforeunload = null
    }
})

watch(() => props.show, (val) => {
    if (!val) {
        locState.form = useForm(getInitialData())
    } else {
        locState.form.id = props.data?.id ?? null
        window.onbeforeunload = null
    }
})

const saveHandle = (hide) => {
    const transformData = (data) => {
        const {type, id} = data
        if (type == 'email') {
            return {
                id,
                type,
                [type]: data[type]
            }
        }
        if (type == 'phone') {
            return {
                id,
                type,
                [type]: getFormattedPhoneForBackend({
                    ...props.data,
                    ...(data?.[type] ?? {}),
                    text: data[type]?.['text']
                })
            }
        }
    }
    locState.form.transform(transformData).post(getRoute('clients.contact.edit'), {
        preserveScroll:true,
        onSuccess() {
            if(backendFlashSuccess.value){
                AlertsManager.add(backendFlashSuccess.value)
                hideModalHandle()
            }
        }
    })
}

</script>

<style scoped>

</style>
