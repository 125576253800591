<template>
    <div class="box-table-requests">
        <acms-v-table
            :class="tableClass"
            show-number
            :columns="{
                'id': 'Request ID',
                'client': 'Client',
                'agents': 'Agents',
                'created-at': 'Creation date',
                'time-passed': 'Time passed',
                'local-time': 'Local time',
                'flight-details': 'Flight details',
                'passengers': 'Passengers',
                'pq': 'PQ',
                'cabin-class': 'Cabin class'
            }"
            :columns-width="{
                '#': '52px',
                'id': '124px',
                'client': '170px',
                'agents': '160px',
                'created-at': '150px',
                'time-passed': '135px',
                'local-time': '135px',
                'flight-details': '280px',
                'passengers': '200px',
                'pq': '120px',
                'cabin-class': '160px'
            }"
            :columns-cell-class="{
                '#': 'white-space-nowrap',
                'time-passed': 'justify-content-end',
                'local-time': 'justify-content-end'
            }"
            :columns-title-class="{
                'time-passed': 'justify-content-end',
                'local-time': 'justify-content-end'
            }"
            rowChildrenKey="request_children"
            :data="collection?.data ?? []"
            :links="collection?.meta?.links ?? []"
            :page="page"
            @update:page="$emit('update:page', $event)"
            sortKeys="id,created-at"
            @update:sort="handleSort"
            emptyStateTitle="Requests"
            :search="search"
            :entriesLimit="collection.meta.per_page"
            :currentPage="collection.meta.current_page"
            @update:selectedRows="$emit('update:selectedRows', $event)"
            @handle:assignAgent="$emit('handle:assignAgent', $event)"
        >
            <template #header>
                <AcmsVTableHeader
                    :config="getHeaderConfig"
                    :search="search"
                    @update:search="$emit('update:search', $event)"
                    :entries="entries"
                    @update:entries="$emit('update:entries', $event)"
                    :agents="agents"
                    addTitle="Add request"
                    @add="$emit('addButtonClick', $event)"
                    :hide-assign-agent-btn="hideAssignAgentBtn"
                    @assign-agent="$emit('handle:assignAgent', $event)"
                />
            </template>
            <template #bodyCell-id="{ item }">
                <Link :href="getRoute('requests.edit', item.id)" class="text-primary text-center fw-semibold">
                    {{ item.id }}
                </Link>
            </template>
            <template #bodyCell-client="{ item }">
                <Link :href="getRoute('clients.edit', item.client_id)" class="text-primary text-center fw-semibold">
                    {{ item.client_full_name }}
                </Link>
            </template>
            <template #bodyCell-agents="{item}">
                <span v-for="(agent, index) in item.agents" :key="agent.agent_id">
            {{ agent.first_name }}<span v-if="index < item.agents.length - 1">, </span>
        </span>
            </template>
            <template #bodyCell-created-at="{ item }">
                <span>{{ item.created_at }}</span>
            </template>
            <template #bodyCell-time-passed="{ item }">
                <div class="text-end">{{ item.time_passed }}</div>
            </template>
            <template #bodyCell-local-time="{ item }">
                <div class="text-end">{{ item.local_time }}</div>
            </template>
            <template #bodyCell-flight-details="{ item }">
                <EntityFlightDetails :details="item.flight_details"/>
            </template>
            <template #bodyCell-passengers="{ item }">
                <EntityCellPassengers :data="{ adults: item.adults, children: item.children, infants: item.infants }"/>
            </template>
            <template #bodyCell-pq="{ item }">
                <span>{{ item.pqs_count ?? '0' }}</span>
            </template>
            <template #bodyCell-cabin-class="{ item }">
                <span>{{ item.cabin_class_id ? getCabinClassString(item.cabin_class_id) : '-' }}</span>
            </template>
        </acms-v-table>
    </div>
</template>

<script>
import AcmsVTableHeader from '@ui/Table/partials/TableHeader.vue';
import { computed } from 'vue';
import { Link } from "@inertiajs/vue3";
import EntityFlightDetails from "../../entities/EntityFlightDetails.vue";
import EntityCellPassengers from "../../entities/EntityCellPassengers.vue";
import { getCabinClassString } from "@helpers/commonHelpers";
import AcmsVTable from "@ui/Table/Table.vue";

export default {
    name: 'BoxTableRequests',
    components: {
        AcmsVTableHeader,
        EntityFlightDetails,
        EntityCellPassengers,
        AcmsVTable,
        Link
    },
    props: {
        collection: [Object, Array],
        hideAddBtn: Boolean,
        addTitle: String,
        search: String,
        entries: [String, Number],
        page: [String, Number],
        tableClass: [String, Object, Array],
        agents: Array,
        hideAssignAgentBtn: Boolean // Новый пропс для скрытия кнопки назначения агента
    },
    emits: ['update:modelValue', 'sortChange', 'update:page', 'update:search', 'update:entries', 'addButtonClick', 'update:selectedRows', 'handle:assignAgent'],
    setup(props, { emit }) {
        const handleSort = (e) => {
            emit('sortChange', e);
        };

        const getHeaderConfig = computed(() => {
            let config = 'search,entries';
            if (!props.hideAddBtn) {
                config += ',add';
            }
            if (!props.hideAssignAgentBtn) {
                config += ',assign-agent';
            }
            return config;
        });

        return {
            handleSort,
            getHeaderConfig,
            getCabinClassString
        };
    }
};
</script>

<style scoped>
/* Your styles here */
</style>
