export const ALERT_CANCEL_TITLE_TEXT = {
    'standard':{
        title: 'Cancel process?',
        text: 'Canceling will reset everything to the initial state.\n' +
            'Cancel ?'
    },
    'pageClientCreate':{
        title: 'Cancel process?',
        text: 'Canceling will reset everything and redirect to Index page.\n' +
            'Cancel ?'
    },
    'passenger': {
        title: 'Passengers details missing',
        text: 'You did not complete any passenger details.\n' +
            'Cancel ?'
    },
    'request': {
        title: 'Requests details missing',
        text: 'You did not complete any requests details.\n' +
            'Cancel ?'
    },
    'linkedContact': {
        title: 'Linked contact details missing',
        text: 'You did not complete any linked contacts details.\n' +
            'Cancel ?'
    },

    'priceQuoteRevenue': {
        title: 'Cancel creation?',
        text: 'Any entered data will be lost, and the process will return to its initial state.\n' +
            'Cancel ?'
    },
    'priceQuoteAward': {
        title: 'Cancel creation?',
        text: 'Any entered data will be lost, and the process will return to its initial state..\n' +
            'Cancel ?'
    },

    'edit': {
        title: 'Cancel editing?',
        text: 'Any unsaved changes will be \n'+'discarded, and the process will return to its initial state.'
    },
}
export const ALERT_DELETE_TITLE_TEXT = {
    'phone': {
        title: 'Delete phone',
        text: 'All information about this phone will be deleted'
    },
    'email': {
        title: 'Delete email',
        text: 'All information about this email will be deleted'
    },
    'passenger': {
        title: 'Delete passenger',
        text: 'All information about this passenger will be deleted'
    },
    'request': {
        title: 'Delete request',
        text: 'All information about this request will be deleted'
    },
    'requestFields': {
        title: 'Delete request',
        text: 'This request has filled fields, all information about this request will be deleted'
    },
    'linkedContact': {
        title: 'Delete linked contact',
        text: 'All information about this linked contact will be deleted'
    },
    'linkedContactFields': {
        title: 'Delete linked contact',
        text: 'This linked contact has filled fields, all information about this linked contact will be deleted'
    },
    'passengerFields': {
        title: 'Delete passenger',
        text: 'This passenger has filled fields, all information about this request will be deleted'
    }
}
