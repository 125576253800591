<template>
    <acms-v-modal title="Price Quotes"
                  :show="getShow"
                  @hide="hideHandle"
                  size="super-large"
                  :alertForCancel="checkHasOneFilledField"
                  @modalAlertCancel="tryModalHideHandle"
                  modalAlertCancelType="priceQuoteAward"
                  class="modal-create-update-common-pq">
        <template #content>
            <div class="stepOneData" v-if="locState.currentStep == 1">
                <div class="row">
                    <acms-v-form-field
                        :modeTextarea="{autoHeight:true, minHeight:96, maxHeight:360}"
                        label="Code"
                        name="code"
                        v-model="forms.firstStep.code"
                        :errors="forms.firstStep.errors"
                        v-bind="getPQHasParsed"
                    />
                </div>
            </div>
            <div class="stepTwo" v-if="locState.currentStep == 2">
                <div class="d-flex mb-3 gap-3">
                    <div class="left-column">
                        <acms-v-label label-title-class="fz-16" style="width: 600px;" label="PQ Code"/>
                        <div class="d-flex w-100">

                            <PartialParsedPqCodeTable
                                :data="locState.pqCodeTableData"
                                modalMode
                                withAdditionalPqColumns
                                @addBaggage="addBaggage"
                                @removeBaggage="removeBaggage"
                                v-model:additionalData="forms.secondStep.additional_data"
                                @update:additional-data="reCollectPricesTaxesBlocks"
                                v-model:additionalDataBaggage="forms.secondStep.additional_data_baggage"
                                :additionalDataErrors="forms.secondStep.errors"
                                :modeDuplicate="modeDuplicate"
                                :modeDraft="modeDraft"
                            />
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-3" v-if="canShowPriceBlock">
                    <div class="right-column w-100">
                        <div class="pq-modal-selected-airlines-taxes d-flex gap-3 mb-3">
                            <div class="overflow-auto" :class="{'pb-2': forms.secondStep.prices?.length > 3, 'w-50': forms.secondStep.prices?.length === 1, 'w-100': forms.secondStep.prices?.length > 1}" >

                                <div class="d-flex gap-3"
                                     :style="forms.secondStep.prices?.length > 1 ? 'max-width: 1182px': ''">
                                    <template v-for="(priceItem, index) in forms.secondStep.prices">
                                        <PartialPqTaxesSingleItem
                                            class="pq-pcc-or-millage-block"
                                            v-if="(priceItem.type?.id == '1' && priceItem?.pcc) || priceItem.type?.id == '3' "
                                            :passengers="getPassengers"
                                            label="Net"
                                            v-model="forms.secondStep.prices[index].data"
                                            :root-name="`prices.${index}`"
                                            style="max-width: 100%; min-width: 110px;width: 100%;"
                                            :errors="forms.secondStep.errors"
                                            :title="priceItem.type?.id == '1' ? `#${priceItem?.ticket} ${priceItem?.pcc?.pcc}` : `#${priceItem?.ticket} Online`"
                                        />
                                        <PartialPqAirlineTaxesItem
                                            v-if="priceItem.type?.id == '2' && priceItem?.mileage"
                                            class="pq-pcc-or-millage-block"
                                            :passengers="getPassengers"
                                            :priceItem="priceItem.data"
                                            :priceItemIndex="index"
                                            style="max-width: 100%; min-width: 350px;width: 100%;"
                                            v-model="forms.secondStep.prices[index].data"
                                            :errors="forms.secondStep.errors"
                                            :root-name="`prices.${index}`"
                                            :titlePrev="'#'+priceItem?.ticket"
                                        />
                                    </template>
                                </div>
                            </div>
                            <div class="d-flex ms-auto gap-3 align-items-start" :class="{'w-50': forms.secondStep.prices?.length === 1, 'w-100': forms.secondStep.prices?.length > 1}">
                                <PartialPqTaxesSingleItem
                                    v-if="forms.secondStep.prices.length > 1"
                                    :passengers="getPassengers"
                                    title="Total net"
                                    label="Net"
                                    placeholder="min. $1650"
                                    :style="forms.secondStep.prices?.length > 1 ? 'width: 50%' : 'width: 100%'"
                                    v-model="forms.secondStep.total_net_prices"
                                    root-name="total_net_prices"
                                    :errors="forms.secondStep.errors"
                                    disabledInputs
                                />
                                <PartialPqTaxesSingleItem
                                    v-if="forms.secondStep.prices.length > 0"
                                    :passengers="getPassengers"
                                    title="Sell Price"
                                    label="Sell"
                                    :style="forms.secondStep.prices?.length > 1 ? 'width: 50%' : 'width: 100%'"
                                    v-model="forms.secondStep.sell_prices"
                                    :minPricesForSell="locState.minPricesObjectForSell"
                                    @update:model-value="recalculateTotalForSellPrices"
                                    root-name="sell_prices"
                                    :errors="forms.secondStep.errors"
                                />
                            </div>
                        </div>
                        <div class="pq-modal-mark-up d-flex justify-content-end w-100">
                            <div class="d-flex ms-auto gap-3">
                                <div class="p-3   d-flex fw-bold flex-column" style="width: 160px; min-width: 160px">
                                    <div class="">
                                        <template v-if="forms.secondStep.prices.length > 1">
                                            ${{ getTotals.totalNet }}
                                        </template>
                                    </div>
                                    <div class="mt-auto">Mark-up:</div>
                                </div>
                                <div class="p-3   fw-bold" style="width: 160px; min-width: 160px">
                                    <div> ${{ getTotals.totalSell }}</div>
                                    <div> ${{ getTotals.totalMarkUp }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                                        <div>
                                            <div class="pq-taxes-passenger-icons">
                                                <template v-for="([itemKey, itemValue], key) in getPassengers.entries">
                                                    <acms-v-icon v-if="itemValue > 0"
                                                                 :name="itemKey"
                                                                 size="24"
                                                                 class="btn bg-light">
                                                        x {{ itemValue }}
                                                    </acms-v-icon>
                                                </template>
                                            </div>
                                        </div>
                </div>
                <div class="d-flex gap-3">
                    <FieldRemarksIntAndExt
                        v-model:remark-int="forms.secondStep.remark_int"
                        v-model:remark-ext="forms.secondStep.remark_ext"
                    />
                </div>
            </div>
        </template>
        <template #footer>
                <div style="display:flex;">
                    <v-switch v-if="locState.currentStep === 2 && showDraftButton && !modeDraft" v-model="forms.secondStep.draftMode" density="compact" color="green" label="Draft mode"></v-switch>
                    <v-switch v-if="locState.currentStep === 2"  v-model="forms.secondStep.promoFare" density="compact" color="purple" label="SSF" style="  width: 90px"></v-switch>
                    <v-switch v-if="locState.currentStep === 2"  v-model="forms.secondStep.is_cfar" density="compact" color="blue" label="CFAR" style="width: 90px"></v-switch>
                    <v-switch v-if="locState.currentStep === 2"  v-model="forms.secondStep.allow_split" density="compact" color="red" label="Split Payment" style="width: 142px"></v-switch>
                </div>
        </template>
        <template #footer-buttons>
            <div class="d-flex">
                <button class="btn btn-border me-2" v-if="locState.currentStep == 2 && !modeDuplicate && !modeDraft"

                        @click="navigationControl.back"
                        :disabled="locState.isSubmitting"
                >Back
                </button>
                <button class="btn btn-primary"
                        :disabled="!locState.pqCodeHasParsed"
                        v-if="locState.currentStep == 1 && !modeDuplicate "
                        @click="navigationControl.next"
                >Continue
                </button>
                <acms-v-btn class="btn btn-primary"
                            :disabled="forms.secondStep.prices.length == 0 || locState.isSubmitting "
                            v-if="locState.currentStep == 2"
                            @click="navigationControl.save"
                            :submitting="locState.isSubmitting"
                >Save
                </acms-v-btn>
            </div>
        </template>
    </acms-v-modal>

    <template v-if="locState.duplicateModeLoading">
        <Teleport to="body">
            <AcmsVLoading class="modal-create-pq-loading" text="Duplicate"></AcmsVLoading>
        </Teleport>
    </template>

    <template v-if="locState.draftModeLoading">
        <Teleport to="body">
            <AcmsVLoading class="modal-create-pq-loading" text="Refactor"></AcmsVLoading>
        </Teleport>
    </template>

</template>
<script>export default {name: 'ModalCreateCommonPq'};</script>

<script setup="">
import {computed, ref} from "vue";
import PartialParsedPqCodeTable from '../../partials/PartialParsedPqCodeTable.vue';
import {PartialPqAirlineTaxesItem, PartialPqTaxesSingleItem} from "./partials";
import {FieldRemarksIntAndExt} from "../fields";
import useCreateCommonPq from "./createCommonPq.component";
import AcmsVFormField from "@ui/FormField/FormField.vue";
import AcmsVBtn from "@ui/Btn.vue";
import AcmsVLoading from "@ui/Loading.vue";
import {useRef} from "react";
import useBackendMiddlewarePropsService from "../../../../../../../../services/BackendMiddlewarePropsService";


// Пример вызова функции

const emit = defineEmits(['hide']);
const props = defineProps({
    activeRequest: Object,
    clientId: [String, Number],
    title: {type: String,},

    modeDuplicate: Boolean,
    modeDraft: Boolean,
    show: Boolean,
    duplicateFromPq: Object,
    draftedPq: Object,
    updateMode: Boolean

});
const {
    getShow,
    locState, forms,
    getPassengers,
    navigationControl,
    getTotals,
    getPQHasParsed,
    reCollectPricesTaxesBlocks, recalculateTotalForSellPrices,
    tryModalHideHandle, checkHasOneFilledField, showCommonPQModal
} = useCreateCommonPq({props, emit})

const backPerms = useBackendMiddlewarePropsService();
const showDraftButton = ref(backPerms.backendPermissions.allowUseDraftMode);
const hideHandle = (val) => {
    if (!props.modeDuplicate || !props.modeDraft) {
        showCommonPQModal(false)
    }

}

const canShowPriceBlock = computed(() => {
    return forms.secondStep.prices.some(item => item.pcc || item.mileage || item.type.id === 3)
})


const addBaggage = () => {
    const baggageData = forms.secondStep.additional_data_baggage;
    const maxBaggage = 4; // Устанавливаем максимальное значение

    // Проходим по всем ключам объекта
    for (const key in baggageData) {
        const currentQuantity = parseInt(baggageData[key].baggage_qnt, 10);

        // Если baggage_qnt не null и меньше максимума, увеличиваем, иначе ставим 1
        if (!isNaN(currentQuantity)) {
            baggageData[key].baggage_qnt = Math.min(currentQuantity + 1, maxBaggage);
        } else {
            baggageData[key].baggage_qnt = 1;
        }
    }

}

const removeBaggage = () => {
    const baggageData = forms.secondStep.additional_data_baggage;
    const minBaggage = 0; // Устанавливаем минимальное значение

    // Проходим по всем ключам объекта
    for (const key in baggageData) {
        const currentQuantity = parseInt(baggageData[key].baggage_qnt, 10);

        // Если baggage_qnt не null и больше минимального значения, уменьшаем
        if (!isNaN(currentQuantity)) {
            baggageData[key].baggage_qnt = Math.max(currentQuantity - 1, minBaggage);
        } else {
            baggageData[key].baggage_qnt = minBaggage; // Если значение было null, устанавливаем 0
        }
    }

}
</script>

<style lang="scss">
.left-column {
    width: 60px;
    flex-shrink: 0;
}

.pq-pcc-or-millage-block {
    input {
        width: 100%;
    }
}

.pq-taxes-passenger-icons {
    margin-top: 73px;

    .acms-v-icon {
        margin-bottom: 43px;
        padding: 7px 6px;
        border-radius: 5px;
        white-space: nowrap;
    }
}

.modal-create-update-common-pq {
    table .acms-v-select-toggle-selected {
        max-width: 86px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.modal-create-pq-loading {
    width: 100% !important;
    position: fixed;
    inset: 0 !important;
    background-color: rgba(0, 0, 0, 0.55) !important;

    z-index: 10000 !important;
    .ui-loading-content{
        width: 100px !important;
        height: 100px!important;
        background-color: rgba(255, 255, 255, 0.95);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 700;
        //color: #fff;
    }

}
</style>
