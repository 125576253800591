<template>
    <div class="box-linked-contact-edit">
        <BoxLinkedContact
            v-model="locState.form"
            :errors="locState.form.errors"
        />
        <acms-v-bottom-actions
            alertForCancel
            modal-alert-cancel-type="edit"
            @modalAlertCancel="cancel"
            :modal-alert-cancel-left-button-create="!locState.form?.id"
            @save="update(true)"
            @saveAndExit="()=>update(false,true)"
            :disabledSave="locState.submitProcessing"
        />
    </div>
</template>
<script>
export default {
    name: "BoxLinkedContactEdit"
}
</script>
<script setup="">
import BoxLinkedContact from "@boxes/common/BoxLinkedContact.vue";
import {getFormattedLinkedContactItemFor} from "@services/FormattedDataForServerService";
import {getRoute} from "@plugins/useRoutes";
import {reactive} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getSettingsForUseForm} from "@services/FormService";

const AlertsManager = useAlertsManagerStore()
const props = defineProps({
    modelValue: Object,
    mainClientId: [String, Number],
    responseOnlyKey: [String, Array],
})

const emit = defineEmits(['cancel',])

const locState = reactive({
    form: useForm(getFormattedLinkedContactItemFor.frontend(props.modelValue)),
    submitProcessing: false,
})
const cancel = () => {
    emit('cancel')
}
const update = (hide = false, exit = false) => {
    const paramsForForm = getSettingsForUseForm( {only: props.responseOnlyKey})
    locState.submitProcessing = true;
    locState.form?.transform(data => {
        return getFormattedLinkedContactItemFor.backend(data, props.mainClientId)
    })
        .put(getRoute('clients.link', props.mainClientId), {
            ...paramsForForm,

            onSuccess() {
                locState.submitProcessing = false;

                AlertsManager.add('Linked contact successfully updated')
                if (hide) {
                    cancel()
                }
                if (exit) {
                    router.get(getRoute('clients'))
                }
            },
            onError() {
                locState.submitProcessing = false;
                AlertsManager.add('Linked contact has errors', 'danger')
            }
        })
}
</script>
<style scoped>
</style>
