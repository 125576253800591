<template>
    <div class="box-passport-data  ">
        <div class="d-flex justify-content-between align-items-start" v-if="!hideHead">
            <h4>Passport data</h4>
            <acms-v-btn v-if="passportData?.id && showButtons?.toLowerCase().includes('delete')"
                        icon="close"
                        color="text-primary-darker"
                        class="bg-gray-light"
                        style="padding: 7px"
                        @click="$emit('delete', passportData)"/>
        </div>
        <div class=" client-info-block-content row g-3">
            <FieldFirstName
                class="box-passport-data-field col-4"
                v-model="passportData.first_name"
                :errors="errors"
            />
            <FieldMiddleName
                class="box-passport-data-field col-4"
                v-model="passportData.middle_name"
                :errors="errors"
            />
            <FieldLastName
                class="box-passport-data-field col-4"
                v-model="passportData.last_name"
                :errors="errors"
                :required="!disableRequiredFields"
            />
            <acms-v-form-field
                class="box-passport-data-field col-4"
                modeInput
                label="Passport number"
                name="passport_number"
                v-model="passportData.passport_number"
                :errors="errors"
                placeholder="XXXXXXXXXX"
            />

            <FieldSelectCountry
                class="box-passport-data-field col-4"
                v-model="passportData.issuing_country_id"
                :initValue="passportData.issuing_country"
                label="Issuing country"
                name="issuing_country_id"
                :errors="errors"
            />
            <acms-v-form-field
                class="box-passport-data-field col-4"
                :mode-datepicker="{minDateCurrent:true}"
                label="Expiration date"
                name="expiration_date"
                v-model="passportData.expiration_date"
                placeholder="MM/DD/YYYY"
                :errors="errors"
            />
            <acms-v-form-field
                class="box-passport-data-field col-4"
                :mode-datepicker="{maxDateCurrent:true}"
                label="Date of birth"
                name="birth_date"
                v-model="passportData.birth_date"
                placeholder="MM/DD/YYYY"
                :errors="errors"
                :required="!disableRequiredFields"
            />
            <FieldSelectGender
                class="col-4"
                v-model="passportData.gender_id"
                :errors="errors"
                :required="!disableRequiredFields"
            />
            <FieldSelectCountry
                v-model="passportData.nationality_id"
                :initValue="passportData.nationality"
                label="Nationality"
                name="nationality_id"
                :errors="errors"
                class="col-4"
                placeholder="ex. Moldova"
            />
        </div>
        <div class="mt-2" v-if="primaryPassenger">
            <acms-v-switcher
                type-checkbox
                title="Primary passenger"
                v-model="passportData.is_main_passenger"
            />
        </div>
        <div class="box-passport-data-ktn"  v-if="passportData?.ktn">
            <h5 class="mt-3">Trusted Traveler Program</h5>
            <div class="row g-3">

                <div class="col-4">
                    <acms-v-label label="KTN"/>
                    <div class="d-flex  " >
                        <acms-v-form-field
                            placeholder="Select Program"
                            v-model="passportData.ktn.type_id"
                            hide-hint
                            :modeSelect="{
                                class: 'option-select',
                                outValueKey: 'id',
                                options: $page.props?.optionsForSelect?.ktnOptions ?? []
                            }"
                            :errors="errors?.['ktn.type_id']"
                        />
                        <acms-v-form-field placeholder="XXXXXXXXXX"
                                           v-model="passportData.ktn.code"
                                           class="w-100"
                                           hide-hint  :errors="errors?.['ktn.code']"/>
                    </div>
                    <acms-v-hint :name="['ktn.code', 'ktn.type_id']"  :errors="errors"/>
                </div>

                <div class="col-4">
                    <acms-v-form-field
                        label="Redress"
                        v-model="passportData.ktn.redress"
                        :errors="errors?.['ktn.redress']"
                        placeholder="ex. 74834"
                    />
                </div>
            </div>
        </div>
        <template v-else>
            <h3>Ktn Error</h3>
        </template>
        <template v-if="backendPermissions.allowUpdatePassengers">
            <acms-v-btn v-if="passportData?.id && showButtons?.toLowerCase().includes('update')"
                        title="Update" @click="updateHandle"
                        class="btn-primary ms-auto me-auto"/>
        </template>

        <slot name="footer"></slot>
    </div>
</template>

<script setup="">
import {onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
import {useForm, usePage} from "@inertiajs/vue3";
import FieldFirstName from "@components/app/fields/FieldFirstName.vue";
import FieldMiddleName from "@components/app/fields/FieldMiddleName.vue";
import FieldLastName from "@components/app/fields/FieldLastName.vue";
import FieldSelectGender from "@components/app/fields/FieldSelectGender.vue";
import FieldSelectCountry from "@components/app/fields/FieldSelectCountry.vue";
import {clientPassportDataModel} from "@models/client.model";
import AcmsVFormField from "@ui/FormField/FormField.vue";
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({
    modelValue: Object,
    errors: Object,
    showButtons: String,
    primaryPassenger: Boolean,
    hideHead:Boolean,
    disableRequiredFields:Boolean,
    noAlert:Boolean,
})
const {backendPermissions} = useBackendMiddlewarePropsService()
const $page = usePage();
const emit = defineEmits(['update:modelValue', 'update', 'delete'])
const passportData = ref(null)

watch(passportData, (val) => {
    emit('update:modelValue', val)
}, {deep:true })

const updateHandle = () => emit('update')

onBeforeMount(() => {
    const {modelValue} = props
    passportData.value = modelValue ?? useForm(clientPassportDataModel)
})


const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}
onMounted(()=>{
    if(!props.noAlert){
        window.onbeforeunload = alertYourChangesNotBeSaved
    }
})
onUnmounted(()=>{
    window.onbeforeunload = null
})
</script>
<style lang="scss">
.option-select {
    width: 160px;
}
@media only screen and (max-width: 1630px) {
    .box-passport-data-ktn{
        .col-4{
            width: 50%;
        }
    }
}
</style>
