<template>
    <Teleport to="main">
        <div class="acms-v-audio-player-fixed-bottom" v-if="show">
            <div class=" player-fixed-bottom-timeline " @click.stop="changeCurrentTime">
                <div class=" player-fixed-bottom-timeline-progress "
                     :style="'--timeline-progress:'+ playerState?.progress ?? 0 "></div>
            </div>
            <div class="player-fixed-bottom-controls">
                <div class="player-fixed-bottom-select-playback-rate">
                    <acms-v-select
                        :model-value="currentSpeed"
                        :options="['0.5 x','0.75 x','1.0 x','1.25 x','1.5 x','1.75 x','2.0 x']"
                        @select="selectSpeedHandle"
                        optionsListPlacement="top"
                        class=""
                    />
                </div>
                <div class="controls d-flex align-items-center">
                    <button class="control-backward d-flex align-items-center"
                            @click="()=>playerControls?.backward(10)"
                    >
                        <span class=" fw-bold f-12">10s</span>
                        <acms-v-icon class="ms-1" name="rotate-left" size="24"/>
                    </button>
                    <button class="control-state me-2 ms-2">
                        <acms-v-icon
                            :name="'player-'+ (playerState?.isPlaying ? 'pause' : 'play')"
                            @click="()=>playerControls?.[playerState?.isPlaying ? 'pause' : 'play']()"
                            size="36"/>
                    </button>
                    <button class="control-forward d-flex align-items-center"
                            @click="()=>playerControls?.forward(10)"
                    >
                        <acms-v-icon name="rotate-right" size="24"/>
                        <div class="ms-1 fw-bold  f-12">10s</div>
                    </button>
                </div>
                <div class="player-fixed-bottom-close cursor-pointer" @click="closeHandle">
                    <acms-v-icon size="24" name="close"/>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
export default {
    name: 'AcmsVAudioPlayerFixedBottom',
};
</script>


<script setup="">
import {computed, onMounted, reactive, ref, watch} from 'vue';
import AcmsVSelect from '../FormField/elements/Select/Select.vue';

const props = defineProps({
    player: Object,
    playersGroupId: String,
});

const playerState = computed(() => {
    return props.player?.playerState ?? {};
});

const playerControls = computed(() => {
    return props.player?.playerControls ?? {};
});
const show = ref(false);
const currentSpeed = ref('1.0 x');

watch(() => props.player, (val) => {

    show.value = true;
    if (val?.playerState?.currentPlaybackRate) {
        currentSpeed.value = val.playerState.currentPlaybackRate + ' x';
    }

});
const closeHandle = () => {
    playerControls.value.stop();
    setTimeout(() => {
        show.value = false;
    }, 50);
};

const selectSpeedHandle = (val) => {
    currentSpeed.value = val;
    playerControls.value?.changePlaybackRate(Number(val.replace(' x', '')));
};

const changeCurrentTime = (e) => {
    const timeLineEl = document.querySelector('.player-fixed-bottom-timeline');
    const timeLineWidth = window.getComputedStyle(timeLineEl).width;
    const newCurrentTime = e.offsetX / parseInt(timeLineWidth) * playerState.value.totalDuration;

    playerControls.value?.changeCurrentTime(newCurrentTime);
};
</script>

<style lang="scss">
@import "@scssVars";

$lines: url("data:image/svg+xml,%3Csvg width='28' height='2' viewBox='0 0 28 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-0.5' x2='4' y2='-0.5' transform='matrix(1 0 0 -1 0 1)' stroke='%234B5563'/%3E%3Cline y1='-0.5' x2='6' y2='-0.5' transform='matrix(1 0 0 -1 8 1)' stroke='%234B5563'/%3E%3Cline y1='-0.5' x2='10' y2='-0.5' transform='matrix(1 0 0 -1 18 1)' stroke='%234B5563'/%3E%3C/svg%3E%0A");
.acms-v-audio-player-fixed-bottom {
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;

    .player-fixed-bottom-timeline {
        width: 100%;
        cursor: pointer;
        height: 4px;
        background-color: $bg-gray;
        border-radius: 4px;
        display: flex;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 8px;
        }

        &-progress {
            --timeline-progress: 0;
            background-color: $bg-success;
            width: var(--timeline-progress);
            height: 4px;
        }
    }

    .player-fixed-bottom-controls {
        display: flex;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        padding: 20px 27px;
        justify-content: space-between;
    }
}

.player-fixed-bottom-select-playback-rate {
    position: relative;
    width: 35px;

    .acms-v-select-toggle-inner {
        padding: 0;
        min-height: 28px;
    }

    .acms-v-select-toggle-angle {
        display: none;
    }

    .acms-v-select-toggle-selected {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 6px 0;
        text-align: center;
        color: $c-primary-dark;
    }

    .acms-v-select-toggle {
        min-width: 35px;
    }

    &:before, &:after {
        content: "";
        position: absolute;
        background-image: $lines;
        left: 0;
        right: 0;
        height: 2px;
        background-size: cover;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    .acms-v-select-toggle {
        border: none;
    }
}

.dark-mode {
    .acms-v-audio-player-fixed-bottom {
        .player-fixed-bottom-controls {
            background-color: #1f2937;
        }
        .control-state, .control-backward, .control-forward {
            color: #fff;

            span {
                color: #fff;
            }
        }
        .player-fixed-bottom-select-playback-rate .acms-v-select-toggle-selected {
            color: #fff;
        }
    }
}

</style>
