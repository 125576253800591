import {computed, reactive, ref, watch} from 'vue';
import {demoAgentsData} from "@demo/demoPageRequestShow";

export default function (params = {},) {
    const {emit, props} = params

    const locState = reactive({
        notes_about_client: '',
        mainTabsActiveTab: 'price-quotes',
        requestsTabsActiveTab: null,
        requestOpenedIndex: -1
    })

    const getClientId = computed(() => props.client.data.id)
    const getCurrentRequest = computed(() => props.currentRequest?.data)

    const getCurrentRequestWithChildren = computed(() => {
        return [getCurrentRequest.value, ...props.requestChildren.data]
    })

    const getCurrentActiveRequest = computed(() => {
        if (!locState.requestsTabsActiveTab) {
            return getCurrentRequestWithChildren.value[0]
        }

        return getCurrentRequestWithChildren.value.find(item => item.id == locState.requestsTabsActiveTab)
    })

    const getCurrentActiveRequestItem = computed(() =>{
        return getCurrentActiveRequest.value ?? getCurrentRequest.value
    })

    const getCurrentActiveRequestPqsArray = computed(()=>{
        return props.pqs?.[getCurrentActiveRequestItem.value.id] ?? []
    })

    const getCurrentActiveRequestAgentPqsArray = computed(()=>{
        return props.agent_pqs?.[getCurrentActiveRequestItem.value.id] ?? []
    })
    watch(()=>locState.mainTabsActiveTab, (val)=>{
        if(!['price-quotes'].includes(val)){
            locState.requestsTabsActiveTab = getCurrentRequestWithChildren.value?.[0]?.id
        }
    })

    const tabAgentsData = reactive({
        tableData: demoAgentsData,
    });

    const onChangeTabHandle = (tabKey) => {
            locState.mainTabsActiveTab = tabKey
    };

    const tabPassengers = reactive({
        modalSelectShow: false,
        formShow: false,
        formData: null,
    });

    const tabPassengersHandlers = {
        cancel() {
            tabPassengers.formData = null
            tabPassengers.formShow = false
        },
        create() {
            tabPassengers.formData = null
            tabPassengers.formShow = true
        },
        edit(object) {
            tabPassengers.formData = object
            tabPassengers.formShow = true
        },
    }
    return {

        locState, getClientId, getCurrentRequest, getCurrentRequestWithChildren, getCurrentActiveRequest,

        getCurrentActiveRequestItem,getCurrentActiveRequestPqsArray,
        getCurrentActiveRequestAgentPqsArray,
        tabPassengers, tabPassengersHandlers,
        tabAgentsData,

        onChangeTabHandle,
    }
}
