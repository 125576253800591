<template>
    <acms-v-modal class="modal-followup-send-email"
                  :show="show"
                  @hide="$emit('hide')"
                  title="Send email"
                  size="large"
                  leftTitle="Follow-up templates"
                  leftClass="modal-followup-send-email-templates"
    >
        <template #left-content>
            <acms-v-form-field
                hide-hint
                modeInput
                v-model="locState.templateSearch"
                element-class="bg-gray-light"
                placeholder="Search templates"
                class="mb-4"
            >
                <template #right><acms-v-icon name="search"/></template>
            </acms-v-form-field>
            <div class="followup-templates-list d-flex flex-column">
                <div class="followup-templates-list-item" v-for="item in locState.emailTemplates">
                    <div class="item-title">{{ item.title }}</div>
                    <acms-v-icon name="question" color="text-primary"
                                 v-tooltip="{text:item.helpText, placement:'right'}"
                    />
                </div>
            </div>
        </template>
        <template #content>
            <div class="d-flex align-items-baseline">
                <div class="w-100 me-3">
                    <acms-v-form-field
                        label="Client email"
                        :modeSelect="demoEmailsList"
                        v-model="locState.form.email"
                    />
                </div>
                <div class=" me-1  d-flex flex-column  white-space-nowrap">
                    <acms-v-label/>
                    <div class="text-warning mt-1">5 reveals left</div>
                </div>
                <div class="col ">
                    <acms-v-label/>
                    <acms-v-btn class="" icon-size="20" icon-color="text-primary-dark" icon="eye-off"  />
                </div>
            </div>
            <acms-v-form-field
                label="Subject"
                v-model="locState.form.subject"
            />
            <acms-v-form-field
                modeTextEditor
                v-model="locState.form.message"
            />
        </template>
        <template #footer>
            <div class="email-template-partials-actions d-flex gap-4">
                <button class="email-template-partials-actions-btn d-flex align-items-center"
                        @click="()=>switchShowEmailPartial('header')"
                >
                    <acms-v-icon class="p-2 bg-gray-light me-2 b-r-1"
                                 :name="getPartialIcon('header')"/>
                    <span>Header</span>
                </button>
                <button class="email-template-partials-actions-btn d-flex align-items-center"
                        @click="()=>switchShowEmailPartial('footer')">
                    <acms-v-icon class="p-2 bg-gray-light me-2 b-r-1"
                                 :name="getPartialIcon('footer')"/>
                    <span>Footer</span>
                </button>
            </div>
        </template>
        <template #footer-buttons>
            <acms-v-datepicker v-model="locState.form.sendDate">
                <template #trigger>
                    <acms-v-btn title="Send by date" icon="calendar" class="btn-sm btn-outline-primary"/>
                </template>
            </acms-v-datepicker>
            <acms-v-btn title="Send email" class="btn-sm btn-primary"/>
        </template>
    </acms-v-modal>
</template>
<script>
export default {
    name: 'ModalFollowupSendEmail',
};
</script>
<script setup="">
import {reactive, watch } from 'vue';
import AcmsVFormField from '@components/ui/FormField/FormField.vue';
import {demoSendEmailTemplates, demoEmailsList } from '@demo/demoPageRequestShow';
import AcmsVIcon from '@components/ui/Icon.vue';

const props = defineProps({
    show: Boolean,
});

const locState = reactive({
    show: false,
    templateSearch: '',
    emailTemplates: demoSendEmailTemplates,
    form: {
        email: 'li*****@gmail.com',
        subject: 'Flight',
        message: 'Hello dear client, This is a check email  Best regards, @',
        header: true,
        footer: true,
        sendDate: null,
    },
});

watch(()=> props.show,(val)=>{
    locState.show  = true
}, {deep:true})

const switchShowEmailPartial = (key) => {
    locState.form[key] = !locState.form[key];
};
const getPartialIcon = (key) => {
    return locState.form[key] ? 'eye' : 'eye-off';
};

</script>
<style lang="scss">
@import "@scssVars";
.modal-followup-send-email-templates {
    width: 325px;
    flex-shrink: 0;
}
.followup-templates-list-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid #E9ECEF;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 25, 70, 0.06);
        .item-title {
            color: $c-primary;
        }
    }
    .item-title {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}
.email-template-partials-actions {
    span {
        font-size: 14px;
    }
}
.dark-mode{
    .email-template-partials-actions-btn span{
        color: #fff;
    }
}
.email-template-partials-actions-btn {
    padding: 0;
}
</style>
