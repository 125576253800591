<template>
        <div class="entity-cell-passengers d-flex align-items-center ">
            <template v-if="getArray?.length > 0">
                <template v-for="item in getArray">
                    <div  class="d-flex align-items-center me-4  " v-if="item?.count">
                        <acms-v-icon
                            :name="item.iconKey"
                            size="24"
                        />
                        <div class="me-1">x</div>  <div> {{ item?.count }}</div>
                    </div>
                </template>
            </template>
        </div>
</template>

<script>
export default {
    name: 'EntityCellPassengers',
};
</script>

<script setup="">
import { computed } from 'vue';

const props = defineProps({
    data: Object,
});

const iconsKeys = {
    'children' : 'child', 'infants' : 'infant', 'adults': 'adult'
};

const getArray = computed(() => {
    if(props.data ){
        return Object?.entries(props.data)?.map(([key, count]) => {
            return {
                iconKey: iconsKeys[key] ?? null,
                count,
            };
        });
    }
    return  []
})

</script>

<style scoped></style>
