import { defineStore } from 'pinia'


export const usePageClientCreateStore = defineStore('pageClientCreateStore', {

    state: () => ({
        submitProcessing:false,

        errorsForRequestFieldsShow:null,
        errorsForLinkedContactFieldsShow:null,
    }),

    actions: {
        setSubmitProcessing(bool, num){
            this.submitProcessing = bool;
        },
        setErrorsForRequestFieldsShow(bool){
            this.errorsForRequestFieldsShow = bool;
        },
        setErrorsForLinkedContactFieldsShow(bool){
            this.errorsForLinkedContactFieldsShow = bool;
        }

    },
})
