import {onMounted, onUnmounted} from "vue";
import {router} from "@inertiajs/vue3";


function useControlRedirectService (onAdd= {
    rejectFunctions: []
}){
    let removeBeforeEventListener = () => {}

    const alertYourChangesNotBeSaved =  (e) =>{
        e.preventDefault()
    }
    const alertConfigNavigateAway = (event) => {

        const bool = confirm('Are you sure you want to navigate away?')

        if(!bool){
            onAdd?.rejectFunctions?.forEach((func)=>{
                func?.()
            })
        }

        return bool
    }

    const addOnBeforeUnloadListeners = () => {
        window.onbeforeunload = alertYourChangesNotBeSaved
        removeBeforeEventListener = router.on('before', alertConfigNavigateAway)
    }

    const removeOnBeforeUnloadListeners = () => {
        removeBeforeEventListener()
        window.onbeforeunload = null
    }

    // onMounted(()=>{
    //     addOnBeforeUnloadListeners()
    // })
    onUnmounted(()=>{
        removeOnBeforeUnloadListeners()
    })


    return {
        removeOnBeforeUnloadListeners,
        addOnBeforeUnloadListeners
    }
}

export default useControlRedirectService
