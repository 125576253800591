<template>
    <template v-if="getShow">
        <div class="entity-flight-details d-flex white-space-nowrap  ">
            <div class="">{{ details?.date }}</div>
            <div class="vertical-line flex-shrink-0 mx-2  " style="height: 16px" ></div>
            <div class="d-flex flex-wrap entity-flight-details-dest" v-if="getDestination.length > 0">
                <div v-for="(item, index) in getDestination">
                    <div v-if="item == '*'" style="width: 5px"> </div>
                    <span v-else>{{ item }}</span>
                </div>
            </div>

        </div>
    </template>
    <template v-else>
        -
    </template>
</template>

<script>
export default {
    name: 'EntityFlightDetails',
};
</script>


<script setup="">
import {computed} from "vue";

const props = defineProps({
    details: [Object, Array]

})
const getShow = computed(() => {
    const {details} = props
    return !!(details?.flight_legs && details?.date)
})

const getDestination = computed(() => {
    if (props.details?.flight_legs?.[0]) {
        return props.details?.flight_legs
    }
    return []
})

</script>
<style scoped>
.entity-flight-details {
    font-size: 14px;
    align-items: center;

    @media only screen and (max-width: 1450px) {
        flex-direction: column;
        align-items: flex-start;
        .vertical-line{
            height: 1px !important;
            width: 56px;
            margin-left: 0 !important;
        }
    }
}
</style>
