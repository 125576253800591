import {getRoute} from "../../plugins/useRoutes";
import {useAlertsManagerStore} from "../../store/plugins/alerts-manager";

export default function () {
    const AlertsManager = useAlertsManagerStore()

    const successHandle = (method, text, callback) => {
        return method
            ? method?.()
            : () => {
                AlertsManager.add(text)
                callback?.()
            }
    }
    const errorHandle = (method, text, callback) => {
        return method
            ? method?.()
            : () => {
                AlertsManager.add(text, 'danger')
                callback?.()
            }
    }

    const defaultParams =
        {
            dataObject: {},
            onSuccess: () => {
            },
            onError: () => {
            },
            afterSuccess: () => {
            },
            afterError: () => {
            }
        }

    return {
        update(form,
               params = defaultParams
        ) {
            const {
                onSuccess,
                onError,
                afterSuccess,
                afterError,
                dataObject,
            } = params
            const formParams = {
                preserveScroll: true,
                onSuccess: successHandle(onSuccess, 'Passenger was updated', afterSuccess),
                onError: errorHandle(onError, 'Passenger has errors', afterError)
            };
            return form?.transform(data => ({...data, ...dataObject})).put(getRoute('passengers.update', form.id), formParams)
        },
        save(form, params = defaultParams) {
            const {
                dataObject,
                onSuccess,
                onError,
                afterSuccess,
                afterError,
            } = params
            const formParams = {
                preserveScroll: true,
                onSuccess: successHandle(onSuccess, 'Passenger saved successfully', afterSuccess),
                onError: errorHandle(onError, 'Passenger has errors', afterError)
            };

            const route = dataObject?.request_id
                ? getRoute('requests.createPassenger')
                : getRoute('passengers.store')
            return form.transform(data => ({...data, ...dataObject})).post(route, formParams)
        }
    }
}
