<template>
   <div class="feature-linked-contacts-create-and-edit">
       <template v-if="state.formCreateShow">
           <BoxListLinkedContacts
               :mainClientId="mainClientId"
               @cancel="$emit('cancel')"
               :cancel-trigger="state.cancelTrigger"
               :saveAndExitTrigger="state.saveAndExitTrigger"
               :responseOnlyKey="responseOnlyKey"
               :class="createClass"
           />
       </template>
       <template v-if="state.formEditShow">
           <BoxLinkedContactEdit
               :class="editClass"
               :mainClientId="mainClientId"
               :modelValue="state.formEditData"
               @cancel="$emit('cancel')"
               :responseOnlyKey="responseOnlyKey"
           />
       </template>
   </div>
</template>
<script>
export default {name: "FeatureLinkedContactsCreateAndEdit"}
</script>

<script setup="">
import BoxLinkedContactEdit from "@boxes/common/BoxLinkedContactEdit.vue";
import BoxListLinkedContacts from "@boxes/common/BoxListLinkedContacts.vue";

const props = defineProps({
    createClass:String,
    editClass:String,
    mainClientId: [String, Number],
    formEditData: Object,
    state: Object,
    responseOnlyKey:[String,Array],
})

</script>
<style  lang="scss">
.feature-linked-contacts-create-and-edit{
    .acms-v-multi-row-crud-item{
        margin: 0;
        padding: 0;
        &:not(:first-child){
            margin-top: 20px;
        }
    }
    .acms-v-multi-row-crud-item.closed{
        padding: 24px 0;
    }
}
</style>
