import {useForm} from "@inertiajs/vue3";
import {reactive, watch} from "vue";
import {cloneDeep} from "lodash";

export default function useClientEditForms (props){
    const setClientGeneralInfo = () => {
        return {
            first_name: props.resource?.data?.first_name,
            last_name: props.resource?.data?.last_name,
            middle_name: props.resource?.data?.middle_name,
            gender_id: props.resource.data?.gender_id,
            contact_by_id: props.resource.data?.contact_by_id,
        }
    }

    const getClientDataForForm = () => {
        const {resource, locationTimezone, ffPrograms, contacts} = props

        const phones = contacts.phone_numbers?.data;
        const emails = contacts.emails?.data
        return {
            generalInfo: setClientGeneralInfo(),

            contacts: {
                emails: emails.length > 0 ? emails : null,
                phone_numbers: phones.length > 0 ? phones : null,
                best_time_to_call: contacts.best_time_to_call,
            },
            locationTimezone: {
                ...locationTimezone,
                country_id: resource.data?.country_id,
                state_id: resource.data?.state_id,
                city_id: resource.data?.city_id,
                timezone_id: resource.data?.timezone_id,
            },
            ff_programs: ffPrograms?.data,
            ff_programs_delete_ids: null,
            notes: resource?.data?.notes,
        }
    }

    const getPassportDataForForm = () => {
        let object = {}

        if (props.passport?.data) {
            object = {...props.passport?.data}
            if (!object.ktn) {
                object.ktn = {
                    type_id: null,
                    code: null,
                    redress: null
                }
            }
        } else {
            const {first_name= null, last_name= null, middle_name= null,gender_id= null  } = props.resource?.data
            object = {
                id: null,
                first_name, last_name, middle_name, gender_id,
                passport_number: null,
                issuing_country_id: null,
                expiration_date: null,
                birth_date: null,
                nationality_id: null,
                ktn: {
                    type_id: null,
                    code: null,
                    redress: null
                },
                redress: null,
            }
        }

        return object
    }


    const clientInfoForm = useForm(getClientDataForForm());

    const clientPassportForm = useForm(getPassportDataForForm())

    return {
        setClientGeneralInfo,
        clientInfoForm, clientPassportForm
    }
}
