export const flightLegModel = {
    from: null,
    to: null,
    dates_range: null,
    // dates_range: [new Date, new Date],
    cabin_class_id: null,
}

export const requestModel = {
    peopleQuantity: {
        adults: 1,
        children: 0,
        infants: 0,
    },
    flightLegs: [
        {...flightLegModel}
    ],

    flightPreferences: {
        airlines: null,
        number_of_stops_id: null,
        purpose_of_travel_id: null,
        max_client_budget: null,
        values: null,
        client_need_a_hotel: null,
        seats:null,
    },

    notes: null,

};

export const FLIGHT_LEG_KEYS = ['from', 'to', 'dates_range', 'cabin_class_id']
export const FLIGHT_LEG_KEYS_WITHOUT_CABIN = ['from', 'to', 'dates_range',]
