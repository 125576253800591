<template>
    <div class="partial-passengers-table">
        <acms-v-table class="p-4"
                      v-bind="CLIENT_PASSENGERS_TABLE_SETTINGS"
                      :actions="getTableActions"
                      :data="passengers.data"
                      @update:sort="passengersTableSortHandle"
                      @editAction="$emit('edit', $event)"
                      @deleteAction="passengersHandlers.wantsToDelete"
        >
            <template #header>
                <AcmsVTableHeader
                    config="add"
                    addTitle="Add passenger"
                    @add="$emit('add')"
                />
            </template>
            <template #bodyCell-name="{item}">
                <button class="text-primary fw-semibold text-start row-fullname-ellipsis"
                        @click="$emit('edit', item)"
                >{{ getFullName(item) }}
                </button>
            </template>
            <template #bodyCell-redress="{item}">
                <div class="text-center">{{ item?.ktn?.redress }}</div>
            </template>
            <template #bodyCell-ktn="{item}">
                <div class="text-center">{{ item?.ktn?.code }}</div>
            </template>
        </acms-v-table>

        <AlertDelete
            v-model:show="passengersState.modalDelete"
            type="passenger"
            @delete="passengersHandlers.delete"
        />
    </div>

</template>

<script>
export default {
    name: "PartialPassengersTable"
}
</script>

<script setup="">
import {CLIENT_PASSENGERS_TABLE_SETTINGS} from "../constants/tablesAndTabsSettings";
import useTableSortable from "@ui/Table/useTableSortable";
import {router} from "@inertiajs/vue3";
import {computed, reactive} from "vue";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getFullName} from "@helpers/commonHelpers";
import AlertDelete from "@alerts/AlertDelete.vue";
import AcmsVTableHeader from "@ui/Table/partials/TableHeader.vue";
import {useBackendMiddlewarePropsService} from "@services";

const AlertsManager = useAlertsManagerStore()
const {backendPermissions} = useBackendMiddlewarePropsService()

const emit = defineEmits(['edit', 'add'])
const props = defineProps({
    passengers: Object,
    clientId: [String, Number],
})

const getTableActions = computed(() => {
    let actions = "edit"

    if (backendPermissions?.allowDeletePassengers) {
        actions = actions + ',delete'
    }


    return actions
})
const passengersState = reactive({
    modalDelete: null,
})
const passengersHandlers = {
    wantsToDelete(...args) {
        passengersState.modalDelete = args
    },
    delete() {
        const [item] = passengersState.modalDelete
        const {id} = item
        if (id) {
            router.delete(getRoute('passengers.delete', id), {
                preserveScroll: true,
                onSuccess() {
                    AlertsManager.add('Passenger successfully deleted')
                },
                onFinish() {
                    passengersState.modalDelete = null
                }
            })
        }
    },
}
const {tableForm: passengersTableForm, tableSortHandle: passengersTableSortHandle} = useTableSortable(
    {
        route: getRoute('clients.getClientCollections', {client: props.clientId}),
        sendParams: {
            sendType: 'post',
            only: ['passengers']
        }
    })
</script>
<style scoped>

</style>
