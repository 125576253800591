<template>
    <BoxClientRequest
        v-model="locState.form"
        :errors="locState.form?.errors"
    />
    <acms-v-bottom-actions v-if="backendPermissions?.allowUpdateRequest"
        @save="update"
        @modalAlertCancel="$emit('cancel')"
        :alertForCancel="showCancelAlert"
        modalAlertCancelType="edit"
        saveTitle="Save changes"
        hide-save-and-exit
        :disabled-save="locState.submittingProcess"
    />
</template>
<script>
export default {
    name: "BoxClientRequestEdit",
}
</script>

<script setup>
import BoxClientRequest from "@boxes/common/BoxClientRequest.vue";
import {onMounted, onUnmounted, reactive} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {getFormattedRequestFor} from "@services/FormattedDataForServerService";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {useBackendMiddlewarePropsService} from "@services";

const emit = defineEmits(['cancel', 'saved'])
const AlertsManager = useAlertsManagerStore()
const {backendPermissions} = useBackendMiddlewarePropsService()
const props = defineProps({
    modelValue: Object,
    mainClientId: [Number, String],
    responseOnlyKey:[String,Array]
})
const locState = reactive({
    form: useForm(props.modelValue),
    submittingProcess: false
})
const $page = usePage()

const showCancelAlert = () => {
    return locState.form?.isDirty
}

const update = (hide = false) => {
    locState.submittingProcess = true
    locState.form?.transform(data => {
        return getFormattedRequestFor.backend(data, {
            mainClientId: props.mainClientId,
        })
    })
        .post(getRoute('requests.update', locState.form?.id), {
            preserveScroll:true,
            onSuccess() {
                const existServerError = $page.props.flash?.error;
                if (existServerError) {
                    AlertsManager.add('Request has server errors', 'danger')
                    locState.submittingProcess = false
                    return
                }
                AlertsManager.add('Request successfully updated')
                emit('saved')
            },
            onError() {
                AlertsManager.add('Request has errors', 'danger')
                locState.submittingProcess = false
            },
            onFinish() {
                locState.submittingProcess = false
            }
        })
}

const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}
onMounted(()=>{
    window.onbeforeunload = alertYourChangesNotBeSaved
})
onUnmounted(()=>{
    window.onbeforeunload = null
})

</script>
<style scoped>

</style>
