<template>
    <div class="acms-v-textarea" :class="{'acms-v-textarea--resize': resize }" >
        <textarea
            class="form-control"
            :class="elementClass"
            :required="required"
            :name="name"
            :value="modelValue"
            :placeholder="placeholder"
            @input="$emit('update:modelValue', $event.target.value)"
            ref="textareaRef"
            :disabled="disabled"
            :style="getStyle"
            :readonly="readonly"
        ></textarea>
    </div>
</template>
<script>
export default {
    name: 'AcmsVTextarea',
};
</script>
<script setup>
import {isNumber} from 'lodash';
import {computed, onBeforeMount, reactive, ref, watch} from 'vue';
import  {defaultAttributesProps} from '@components/ui/FormField/field.props';
const props = defineProps(
    {
        ...defaultAttributesProps,
        elementClass: String,
        minHeight: [String, Number],
        maxHeight: [String, Number],
        resize: [Boolean, String],
        autoHeight:Boolean,
    });

const locState = reactive({
    initialHeight: props.minHeight ?? null,
    height: '',
});
const textareaRef = ref();

const getStyle = computed(() => {
    const object = {
        minHeight: locState.initialHeight + 'px !important'
    }
    if (props.autoHeight) {
        object.height = isNumber(locState.height) ? locState.height + 'px' : locState.height
    }

    if(props.maxHeight){
        object.maxHeight = isNumber(props.maxHeight) ? props.maxHeight + 'px' : props.maxHeight
    }
    return object
});

const changeHeightHandle = (val, preVal = '') => {
    if (props.autoHeight) {
        setTimeout(() => {

            if (val == '') {
                locState.height = locState.initialHeight;
            } else {
                const currentHeight = textareaRef.value?.scrollHeight ?? 200;
                if (val?.length != preVal?.length) {
                    locState.height = currentHeight + 3;
                }
            }
        }, 0);
    }
};

watch(() => props.modelValue, (val, preVal) => {
    changeHeightHandle(val, preVal);
}, {immediate: true});

onBeforeMount(() => {
    changeHeightHandle(props.modelValue,);
});

</script>
<style lang="scss">
.acms-v-textarea--resize {
    textarea.form-control {
        resize: vertical;
    }
}
textarea.form-control {
    min-height: 56px !important;
    max-height: 70vh;
    padding: 8px 16px;
    resize: none;
}
</style>
