<template>
    <div class="alert alert-dismissible" role="alert"
         :class="getClass"
    >
        <p class="mb-0 pe-3">
            {{ content }}
        </p>
        <acms-v-icon  class="alert-close" name="close" @click="$emit('remove')"></acms-v-icon>
    </div>
</template>

<script>
export default {
    name: 'AcmsVAlert',
};
const ALERT_TYPES = ['success', 'info', 'warning', 'danger'];
</script>
<script setup="">
import AcmsVIcon from './Icon.vue'

import { computed } from 'vue';
const emit = defineEmits([ 'remove'])
const props = defineProps({
    content: {
        type: String,
        default: 'Alert content here',
    },
    type: {
        type: String,
        default: 'success',
        validator(val){
            return ALERT_TYPES.includes(val)
        }
    },
});

const getClass = computed(() => {
    return 'alert-'+props.type
});
</script>
<style lang="scss">
.alert{
    width: 360px;
    padding: 20px;
}
.alert-close{
    position: absolute;
    cursor: pointer;
    top: 24px;
    right: 20px;

    &:hover {
        opacity: .9;
    }
}
</style>
