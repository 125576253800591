<template>
    <div class="acms-v-audio-player" :data-players-group-id="playersGroupId" v-bind="$attrs">
        <div class="controls">
            <acms-v-icon
                :name="'player-'+ (locState.isPlaying ? 'pause' : 'play')"
                size="24"
                class="cursor-pointer"
                @click="()=>playerControls[locState.isPlaying ? 'pause' : 'play']()"
            />
        </div>
        <div class="acms-v-audio-player-timeline" style="width: 64px">
            <div class="acms-v-audio-player-timeline-progress"></div>
        </div>
        <div class="time" @click="locState.showCurrentTime = !locState.showCurrentTime">
            <div class="current cursor-pointer" :title="'Duration: '+locState.durationTime">{{ getTime }}</div>
        </div>
        <audio controls hidden ref="playerElRef" :id="getPlayerId" preload="auto">
            <source :src="file" :type="getFileType">
        </audio>
    </div>
</template>

<script>
export default {
    name: 'AcmsVAudioPlayer',
};
</script>
<script setup="">
import { computed, onMounted, reactive, ref, onBeforeUnmount, onBeforeMount } from 'vue';
import { convertTimeHHMMSS } from '../../../helpers/dateHelpers';
import { generateUUID } from '../../../helpers/stringHelpers';

const emit = defineEmits(['update:modelValue', 'updateFixedPlayer']);
const props = defineProps({
    file: String,
    fileType: String,
    playersGroupId: String,
});

const playerElRef = ref();

const locState = reactive({

    isMuted: false,
    isLoaded: false,
    isPlaying: false,
    isPaused: true,

    progress: 0,

    totalDuration: -1,
    durationTime: '00:00',
    currentTime: '00:00',
    leftTime: '00:00',
    currentPlaybackRate: 1.0,

    showCurrentTime: false,

});

const playerTimelineProgress = ref();

const getFileType = computed(() => {
    return props.fileType;
});
const getDuration = computed(() => {
    return locState.totalDuration ? convertTimeHHMMSS(locState.totalDuration) : '';
});
const getPlayerId = computed(() => {
    return generateUUID();
});

const getTime = computed(() => {
    const { showCurrentTime, leftTime, currentTime } = locState;
    return showCurrentTime ? currentTime : leftTime;
});

const switchPlayerState = (isPlaying) => {
    locState.isPlaying = isPlaying;
    locState.isPaused = !isPlaying;
};

const playerControls = {

    toggle: () => {},
    play: () => {
        playerElRef.value.play();
        switchPlayerState(true);

    },
    pause: () => {
        playerElRef.value.pause();
        switchPlayerState(false);
    },
    stop: () => {
        playerElRef.value.pause();
        switchPlayerState(false);
        playerElRef.value.currentTime = 0;
    },

    forward: (seconds = 10) => {
        playerElRef.value.currentTime += seconds;
    },
    backward: (seconds = 10) => {
        playerElRef.value.currentTime -= seconds;
    },
    changePlaybackRate: (val) => {
        playerElRef.value.playbackRate = val;
        locState.currentPlaybackRate = val;
    },
    changeCurrentTime:(time)=>{
        locState.currentTime = convertTimeHHMMSS(time)
        playerElRef.value.currentTime = time
    }
};

const updateFixedPlayer = () => {
    if (props.playersGroupId) {
        const group = window._AcmsVPlayer[props.playersGroupId];
        const playerState = { ...locState, progress: playerTimelineProgress.value };
        emit('updateFixedPlayer', { playerState, playerControls });
    }
};

const playerEventsHandlers = {
    timeUpdate: (e) => {
        const player = e.target;
        let currentTime = parseInt(player.currentTime);

        playerTimelineProgress.value = parseInt((currentTime / locState.totalDuration) * 100) + '%';

        locState.currentTime = convertTimeHHMMSS(currentTime);
        locState.leftTime = convertTimeHHMMSS(locState.totalDuration - currentTime);

        updateFixedPlayer();
        if (locState.leftTime === '00:00') {
            playerTimelineProgress.value = 100 + '%';
            setTimeout(() => {
                playerTimelineProgress.value = 0 + '%';
                updateFixedPlayer();
            }, 100);
        }

    },
    loaded: (e) => {
        const player = e.target;
        locState.totalDuration = player.duration;
        const time = convertTimeHHMMSS(locState.totalDuration);
        locState.durationTime = time;
        locState.leftTime = time;
    },
    paused: () => {
        switchPlayerState(false);
        updateFixedPlayer();
    },
    playing: () => {
        switchPlayerState(true);
        updateFixedPlayer();
        const allAudios = document.querySelectorAll(
            `.acms-v-audio-player[data-players-group-id="${props.playersGroupId}"] audio`);
        allAudios.forEach(item => {
            if (item.id != getPlayerId.value && !item.paused) {
                item.pause();
            }
        });
    },
};

const initPlayer = () => {
    const player = playerElRef.value;
    player.addEventListener('timeupdate', playerEventsHandlers.timeUpdate);
    player.addEventListener('loadeddata', playerEventsHandlers.loaded);

    playerElRef.value.addEventListener('pause', playerEventsHandlers.paused);
    playerElRef.value.addEventListener('play', playerEventsHandlers.playing);
};

onMounted(() => {initPlayer();});

onBeforeMount(() => {
    if (props.playersGroupId && !window?._AcmsVPlayer) {
        window._AcmsVPlayer = {
            [props.playersGroupId]: {
                playerState: {},
                playerControls: {},

            },
        };
    }
});

onBeforeUnmount(() => {
    playerElRef.value.removeEventListener('timeupdate', playerEventsHandlers.timeUpdate);
    playerElRef.value.removeEventListener('loadeddata', playerEventsHandlers.loaded);
    playerElRef.value.removeEventListener('pause', playerEventsHandlers.paused);
    playerElRef.value.removeEventListener('play', playerEventsHandlers.playing);
});
</script>

<style lang="scss">
@import "@scssVars";
.acms-v-audio-player {
    width: 160px;
    display: flex;
    align-items: center;
    &-timeline {
        margin-left: 4px;
        margin-right: 4px;
        width: 100%;

        height: 4px;
        background-color: $bg-gray;
        border-radius: 4px;
        display: flex;
        &-progress {
            background-color: $bg-success;
            width: v-bind(playerTimelineProgress);
        }
    }
    .time {
        .current {
            color: $c-gray-darker;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
.dark-mode .acms-v-audio-player{
    .time .current{
        color: #fff;
    }
    .acms-v-icon-player-play{
        color: #fff;
    }
}
</style>
