export const TABLE_PASSENGERS_PROPS = {
    showNumber: true,
    columns: {
        'name': 'Name',
        'type': 'Type',
        'redress': 'Redress',
        'ktn': 'KTN',
        'primary': '',
        'actions': 'Actions',
    },
    columnsWidth: {
        '#': '52px',
        'name': '250px',
        'type': '100px',
        'redress': '180px',
        'ktn': '150px',
        'primary': '60px',
        'actions': '280px',

    }, columnsTitleClass: {
        'actions': 'justify-content-end'
    },
    actions:"edit,delete"
}

export const TABLE_PASSENGERS_PROPS_EMPTY = {
    showNumber: true,
    columns: {
        'name': 'Name',
        'redress': 'Redress',
        'ktn': 'KTN',
        'actions': 'Actions',
    },
    columnsWidth: {
        '#': '52px',
        'name': '30%',
        'redress': '20%',
        'ktn': '25%',
        'actions': '25%',

    }, columnsTitleClass: {
        'actions': 'justify-content-end'
    },

}



export const TABLE_PASSENGERS_SELECT_PROPS = {
    showNumber: true,
    columns: {
        'name' : 'Name',
        'type' : 'Age',
        'main' : 'Type',
    },
    columnsWidth: {
        'name' : '50%',
        'age' : '25%',
        'type' : '20%',
    },
    eventsList:"row",
    bodyRowClass:"cursor-pointer"
}

export const TABLE_LINKED_CONTACTS_PROPS = {
    showNumber: true,
    columns: {
        'name': 'Name',
        'email': 'Email',
        'phone': 'Phone',
        'type': 'Type',
        // 'status': 'Status',
        'actions': 'Actions',
    },
    columnsWidth: {
        '#': '52px',
        'name': '240px',
        'email': '195px',
        'phone': '195px',
        'type': '125px',
        'status': '120px',
        'actions': '180px',
    },
    actions: 'email,phone,edit,delete',

}

export const TABLE_CLIENT_CONTACTS_MAIN_PROPS = {
    showNumber: true,
    columns: {
        'name': 'Name',
        'email': 'Email',
        'phone': 'Phone',
        'type': 'Type',
        // 'status': 'Status',
        'actions': 'Actions',
    },
    columnsWidth: {
        '#': '52px',
        'name': '240px',
        'email': '195px',
        'phone': '195px',
        'type': '125px',
        'status': '120px',
        'actions': '180px',
    },
    actions: 'email,phone,edit',

}


export const TABLE_CLIENT_CONTACTS_PROPS = {
    showNumber: true,
    columns: {
        'name': 'Name',
        'email': 'Email',
        'phone': 'Phone',
        'type': 'Type',
        // 'status': 'Status',
        'actions': 'Actions',
    },
    columnsWidth: {
        '#': '52px',
        'name': '240px',
        'email': '195px',
        'phone': '195px',
        'type': '125px',
        'status': '120px',
        'actions': '180px',
    },
    // actions: 'email,phone,edit',

}






export const TABLE_AGENTS_PROPS = {

    columns: {
        'agent-status' : 'Agent Status',
        'name' : 'Name',
        'phone' : 'Phone',
        'email' : 'Email',
        'team' : 'Team',
        'supervisor' : 'Supervisor',
        'taken' : 'Taken',
        'worked' : 'Worked',
    },
    columnsWidth: {
        'agent-status' : '144px',
        'name' : '114px',
        'phone' : '144px',
        'email' : '184px',
        'team' : '114px',
        'supervisor' : '114px',
        'taken' : '114px',
        'worked' : '114px',
    },
    'columns-title-class':{
        'actions' : 'justify-content-end',
    },
    actions: 'edit,delete',
}


export const TABS_REQUEST_EDIT_PAGE = {
    class: "request-main-tabs",
    items: {
        'price-quotes': 'PQs',
        'request': 'Request',
        'all-requests': 'All Requests',
        'contact-info': 'Contact Info',
        'passengers': 'Passengers',
        'activity-feed': 'Activity Feed',
        'agents': 'Agents'
    },
}



