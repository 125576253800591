<template>
    <acms-v-form-field
        label="First name"
        name="first_name"
        required
        :modeInput="{capitalizeFirstCharacter:true}"
        placeholder="ex. John"
        :class="$attrs.class ?? 'col-3'"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :errors="errors"
        :disabled="disabled"
    />
</template>

<script>
export default {
    name: "FieldFirstName"
}
</script>
<script setup="">
const props = defineProps({
      modelValue:[String],
      errors:Object,
      disabled:Boolean,
})
</script>

<style scoped>

</style>
