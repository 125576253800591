<template>
    <div class="box-list-linked-contacts">
        <acms-v-multi-row-crud
            add-title="Add contact"
            :items="locState.list"
            :itemSample="linkedContactModel"
            :emptyState="emptyState"
            :triggerSaveAllRows="locState.triggerSaveRows"
            v-on="multiRowEvents"
            alertDeleteType="linkedContactFields"
        >
            <template #opened-header="{itemNumber,item, itemKey, itemsLength, removeRow,}">
                <div class="d-flex justify-content-between align-items-start">
                    <h3>
                        <template v-if="item?.id || item?._rowEdit">
                            Linked contact
                            <template v-if="item?.id">ID:</template>
                            <template v-else>#</template>
                            {{ item?.id ?? itemNumber }}
                        </template>
                        <template v-else>
                            New contact
                        </template>
                    </h3>
                    <acms-v-btn v-if="(!item?.id && itemsLength > 1) || emptyState" icon="close"
                                color="text-primary-darker"
                                class="bg-gray-light"
                                style="padding: 7px"
                                @click="()=>handlers.checkIfHasFilledFieldsForRemove(item,removeRow)"
                    />
                </div>
            </template>
            <template #opened="{item}">
                <BoxLinkedContact
                    :modelValue="item"
                    :errors="item?.errors"
                />
            </template>
            <template #closed="{number,item}">
                <div class="text-primary  fw-semibold col-auto row-fullname-ellipsis"
                     :title="getFullName(item.names, 'No name')">
                    {{ getFullName(item.names, 'No name') }}
                </div>
                <div class="vertical-line me-3 ms-3"></div>
                <div class="row w-100  ">
                    <div class="col-3">
                        <div v-if="item.emails?.length > 0 && item.emails[0]?.text?.length > 0">
                            {{ getSecurizedEmail(item.emails) }}
                        </div>
                        <div v-else> -</div>
                    </div>
                    <div class="col-3 ">
                        <div v-if="item.phone_numbers?.length > 0 && item.phone_numbers[0]?.text?.length > 0">
                            {{ getSecurizedPhone(item.phone_numbers) }}
                        </div>
                        <div v-else> -</div>
                    </div>
                    <div class="col-3">
                        <div v-if="item?.type_id"> {{ getClientRelationShipString(item.type_id) }}</div>
                        <div v-else> -</div>
                    </div>
                    <div class="col-2" v-if="!item?.id">
                        <template v-if="!handlers.checkIsFilledRequiredFields(item, true)">
                            <acms-v-badge title="UNFINISHED"/>
                        </template>
                        <template v-else>
                            <acms-v-badge title="Ready for save"/>
                        </template>
                    </div>
                    <div class="col " v-if="item?.id">
                        <acms-v-badge title="Saved"/>
                    </div>
                </div>
            </template>
        </acms-v-multi-row-crud>
        <acms-v-bottom-actions
            v-if="!parentControl"
            alertForCancel
            @modalAlertCancel="handlers.cancel()"
            modal-alert-cancel-left-button-create
            modal-alert-cancel-type="linkedContact"
            @save="handlers.trySave(true)"
            @saveAndExit="handlers.trySave(false,true)"
            :disabledSave="PageClientCreateStore.submitProcessing"
        />
        <AlertDelete
            v-model:show="locState.deleteShow"
            type="linkedContact"
            @delete="handlers.delete"
        />
        <template v-if="!parentControl">
            <AcmsVModalAlert
                v-model:show="locState.saveModalAlert"
                :title="locState.saveModalAlertContent?.title ?? ''"
                :text="locState.saveModalAlertContent?.content ?? ''"
                @left-button-click="locState.saveModalAlert = false"
                @rightButtonClick="()=>isExitModal ? handlers.exit() : handlers.save()"
                left-button-title="Continue editing"
                :right-button-title="isExitModal ? 'Yes, exit' : 'Yes, save'"
                :right-button-class="isExitModal ? 'btn-danger' : 'btn-primary'"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'BoxListLinkedContacts',
};

const NOT_FILLED_LINKED_CONTACT_ALERT = {
    title: 'Linked contact details missing',
    content: 'Your linked contact is not finished! Exit?',
    exit: true,
}
</script>

<script setup="">
import BoxLinkedContact from '@boxes/common/BoxLinkedContact.vue';
import AcmsVMultiRowCrud from '@ui/MultiRowCrud/MultiRowCrud.vue';

import {getClientRelationShipString, getFullName, getSecurizedEmail, getSecurizedPhone} from '@/helpers/commonHelpers';
import {computed, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";
import {getFormattedLinkedContactItemFor,} from "@services/FormattedDataForServerService";
import {getRoute} from "@plugins/useRoutes";
import {cloneDeep} from "lodash";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import AlertDelete from "@components/app/alerts/AlertDelete.vue";
import {useBoxListLinkedContactsStore} from "@store/boxes/box-list-linked-contacts-store";
import {usePageClientCreateStore} from "@store/pages/page-create-client-store";
import {linkedContactModel, LINKED_CONTACT_MODEL_SIMPLE_KEYS} from "@models/client.model";
import useMultiRowCrudService from "@services/ui/MultiRowCrudService";
import AcmsVModalAlert from "@ui/ModalAlert.vue";
import debounce from "@libs/debounce";
import {getSettingsForUseForm} from "@services/FormService";

const props = defineProps({
    mainClientId: [String, Number],
    parentControl: Boolean,
    emptyState: Boolean,
    saveTrigger: Boolean,
    responseOnlyKey:[String,Array]
});

const $page = usePage()
const AlertsManager = useAlertsManagerStore()
const BoxListLinkedContactsStore = useBoxListLinkedContactsStore()
const PageClientCreateStore = usePageClientCreateStore()

const emit = defineEmits(['cancel']);

const methodsForHandlers = {
    checkIsFilledRequiredFields(itemForm, needFullCheck = false) {
        const {names, phone_numbers, emails} = itemForm
        const phoneNumbersList = phone_numbers?.data ?? phone_numbers
        const emailsList = emails?.data ?? emails
        if (needFullCheck) {
            return !!names.first_name && (!!phoneNumbersList?.[0]?.text || !!emailsList?.[0]?.text)
        }
        return !!names.first_name || !!phoneNumbersList?.[0]?.text || !!emailsList?.[0]?.text
    },
    checkIfHasFilledFields(item) {
        const {names, emails, phone_numbers, ff_programs} = item
        const phoneNumbersList = phone_numbers?.data ?? phone_numbers
        const emailsList = emails?.data ?? emails
        const {no_name, ...restNames} = names

        const arrayOfBooleans = [
            ...LINKED_CONTACT_MODEL_SIMPLE_KEYS.map(key => !!item[key]),
            ...Object.values(restNames).map(item => !!item),
        ]

        phoneNumbersList?.forEach((item) => arrayOfBooleans.push(!!item.text))
        emailsList?.forEach((item) => arrayOfBooleans.push(!!item.text))
        if (ff_programs) {
            ff_programs?.forEach((item) => arrayOfBooleans.push(!!item.text))
        }
        return arrayOfBooleans.some(item => item)

    },

    preValidateItem(itemForm, fullCheck = false) {
        itemForm?.clearErrors()

        const isFilledRequiredFields = this.checkIsFilledRequiredFields(itemForm, fullCheck)

        if (!isFilledRequiredFields) {
            const {names, phone_numbers, emails} = itemForm
            const phoneNumbersList = phone_numbers?.data ?? phone_numbers
            const emailsList = emails?.data ?? emails
            if (!names.first_name || names.first_name?.length == 0) {
                itemForm.setError(`first_name`, 'This field is required.',);
            }
            phoneNumbersList?.forEach((item, index) => {
                if (!item.text ) {
                    itemForm.setError(`phone_numbers.${index}.phone`, 'This field is required.',);
                }
            })
            emailsList?.forEach((item, index) => {
                if (!item.text ) {
                    itemForm.setError(`emails.${index}`, 'This field is required.',);
                }
            })
        }

        return isFilledRequiredFields
    },

    save({list, openedItem, openedItemIndex}, callbacks) {
        if (openedItem && openedItem?.id) {
            this.update({item: openedItem, index: openedItemIndex}, callbacks)
        }
        locState.list = list
        locState.listForSave = [];
        locState.list.forEach(item => {
            locState.listForSave.push({
                id: item?.id ?? null,
                formData: cloneDeep(item),
            })
        })

        this.ifNotParentControlRedirect()

        if (!props.mainClientId) {
            return
        }

        const findFirstFormWithoutId = () => locState.list.findIndex(item => !item?.id)

        const sendHandle = (itemIndex = findFirstFormWithoutId()) => {
            const itemForm = locState.list[itemIndex]

            if (itemIndex < 0) {
                BoxListLinkedContactsStore.setQueueFinishedStatus(true)
                return;
            }

            if (itemForm?.id) {
                PageClientCreateStore.setSubmitProcessing(false)
                return;
            }

            const isValidated = this.preValidateItem(itemForm, true)

            if (!isValidated) {
                locState.list[itemIndex] = callbacks.openRow(itemIndex)
                PageClientCreateStore.setSubmitProcessing(false)
                AlertsManager.add('Linked contact has errors', 'danger')
                return
            }

            const paramsForForm = getSettingsForUseForm({only:props.responseOnlyKey} )

            try {
                const route = getRoute('clients.link', props.mainClientId);
                PageClientCreateStore.setSubmitProcessing(true)
                itemForm?.transform(data => getFormattedLinkedContactItemFor
                    .backend(data, props.mainClientId))
                    .put(route, {
                        ...paramsForForm,
                        onSuccess() {
                            const id = $page.props.flash.success?.linked_contact?.data?.id
                            const existServerError = $page.props.flash?.error

                            if (existServerError) {
                                AlertsManager.add('Linked contact has server errors', 'danger')
                                PageClientCreateStore.setSubmitProcessing(false)
                            }

                            if (!id) {
                                PageClientCreateStore.setSubmitProcessing(false)
                                return
                            }
                            locState.list[itemIndex] = cloneDeep(itemForm)
                            locState.list[itemIndex].id = id
                            locState.list[itemIndex] = callbacks.collapseRow(locState.list[itemIndex])
                            AlertsManager.add(`Linked contact ID:${id} successfully created`)

                            if (locState.listForSave?.[itemIndex + 1]) {
                                sendHandle(itemIndex + 1)
                            } else {
                                PageClientCreateStore.setSubmitProcessing(false)
                                BoxListLinkedContactsStore.setQueueFinishedStatus(true)

                                handlers.ifNotParentControlRedirect()
                            }
                        },
                        onError() {
                            PageClientCreateStore.setSubmitProcessing(false)
                            AlertsManager.add('Linked contact has errors', 'danger')

                            const {openRowByIndex, findAndCloseOpenedRow} = callbacks.openRow(itemIndex, true)
                            findAndCloseOpenedRow?.(itemIndex)

                            setTimeout(() => {
                                openRowByIndex?.(itemIndex)
                            }, 0);
                        }
                    })
            } catch (e) {
                PageClientCreateStore.setSubmitProcessing(false)
                AlertsManager.add('Linked contact has errors', 'danger')
            }
        }
        sendHandle()
    },
}

const {locState, handlers, multiRowEvents} = useMultiRowCrudService(
    {
        emit,
        props,
        constants: {notFilled: NOT_FILLED_LINKED_CONTACT_ALERT},
        methods: methodsForHandlers,
    })


const debounceSetList =  debounce((val) => {
    BoxListLinkedContactsStore.setList(val)
}, 100, )

watch(() => locState.list, (val) => {
    debounceSetList(val)
}, {deep: true})


watch(() => PageClientCreateStore.errorsForLinkedContactFieldsShow, (val) => {
    if (val) {
        handlers.preValidateItem(locState.list[0])
    } else if (val == false) {
        locState.list?.[0]?.clearErrors?.()
    }
})

const isExitModal = computed(() => locState.saveModalAlertContent?.exit)

</script>

<style scoped></style>
