<template>
    <AppMainLayout>
       <template #content>
           <div class="page-sale-show">
               <div class="row">

                   <div class="col" v-for="item in locState.infoBlocks">

                       <div class="block block-rounded ">
                           <div class="block-header block-header-default">
                               <h3 class="block-title text-center">
                                   <small>{{ item.title }}</small>
                               </h3>
                           </div>
                           <div class="block-content text-center">
                               <p
                                   :class="item.title == 'Status' ? 'text-success' : ''"
                               >{{ item.content?.length > 0 ? item.content : '-' }}</p>
                           </div>
                       </div>
                   </div>
               </div>
               <acms-v-card title=" Reservation info">
                   <div class="d-flex">
                       <div class="row  w-100">

                           <div class="col-2">
                               <acms-v-form-field
                                   v-model="locState.reservationInfo.gds"
                                   :mode-select="['Apollo']"
                                   label="GDS"
                               />
                           </div>
                           <div class="col-2">
                               <acms-v-form-field
                                   v-model="locState.reservationInfo.award_program"
                                   :mode-select="['Air Canada', 'Ari America']"
                                   label="Award program"
                               />
                           </div>
                           <div class="col-1">
                               <acms-v-form-field
                                   v-model="locState.reservationInfo.pcc"
                                   label="PCC"
                               />
                           </div>
                           <div class="col-1">
                               <acms-v-form-field
                                   v-model="locState.reservationInfo.pnr"
                                   label="PNR"
                               />
                           </div>
                       </div>

                       <div>
                           <acms-v-label/>
                           <acms-v-btn
                               class="btn-border-black me-3"
                               title="Dump"
                           />
                       </div>
                       <div>
                           <acms-v-label/>
                           <acms-v-btn
                               class="btn-warning white-space-nowrap"
                               title="Change PNR"
                           />
                       </div>
                   </div>
                   <div class="reservation-info-table">

                       <table class="table table-sm table-striped table-border">
                           <tbody>
                           <tr v-for="(item, index) in locState.reservationInfo.table">
                               <td v-for="cell in item.array"
                                   class="table-td"
                                   :style="{
                                        width: index < 2 ? '100px' : ''
                                    }"
                               >{{ cell }}
                               </td>
                               <td>
                                   <acms-v-icon name="suitcase" size="24" quantity="2"/>
                               </td>
                               <td style="width: 140px;">
                                   <acms-v-form-field
                                       v-model="item.someText"
                                       hide-hint
                                   />
                               </td>
                               <td style="width: 140px">
                                   <acms-v-select
                                       v-model="item.cabin_class"
                                       :options="selectOptionsCabinClass"
                                   />
                               </td>
                           </tr>
                           </tbody>
                       </table>
                   </div>
               </acms-v-card>
               <acms-v-card title="Passengers">
                   <acms-v-table
                       :data="locState.passengersTable"
                       show-number
                       :columns="{
                        ptc:'PTC',
                        first_name:'First Name',
                        middle_name:'Middle Name',
                        last_name:'Last Name',
                        dob:'Dob',
                        gender:'Gender',
                        passport: 'Passport #',
                        expire: 'Expire',
                        issuing_country: 'Issuing country',
                        nationality: 'Nationality',
                        mileage: 'Mileage',
                        redress: 'Redress',
                        ktn: 'Ktn'
                  }"
                   />
               </acms-v-card>
               <acms-v-card title="Financial info">
                   <div class="col-2">
                       <acms-v-form-field
                           :mode-select="['Credit card', 'Cash']"
                           label="Payment method"
                           v-model="locState.financialInfo.payment_method"
                       />
                   </div>
                   <div class="revenue-financial-table">
                       <acms-v-label title="Revenue financial"/>
                       <acms-v-table
                           :data="locState.financialInfo.revenueTable"
                           class="table-border mb-4"
                           show-number
                           :columns="{
                            ptc:'PTC',
                            single_price: 'Selling price, $',
                            net_price: 'Selling price, $',
                            markup_price: 'Mark-up, $',
                         }">
                           <template #bodyCell-ptc="{item}">
                               <acms-v-select
                                   :options="passengerTypeOptions"
                                   v-model="item.ptc"
                               />
                           </template>
                           <template #bodyCell-single-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.single_price"
                               />
                           </template>
                           <template #bodyCell-net-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.net_price"
                               />
                           </template>
                           <template #bodyCell-markup-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.markup_price"
                               />
                           </template>
                       </acms-v-table>
                   </div>
                   <div class="mileage-financial-table">
                       <acms-v-label title="Mileage financial information"/>
                       <acms-v-table
                           :data="locState.financialInfo.mileageTable"
                           class="table-border mb-4"
                           show-number
                           :columns="{
                            ptc:'PTC',
                            miles: 'Miles',
                            taxes: 'Taxes, $',
                            price_mile: 'Price/Mile, $',
                            single_price: 'Selling price, $',
                            net_price: 'Selling price, $',
                            markup_price: 'Mark-up, $',
                         }">
                           <template #bodyCell-ptc="{item}">

                               <acms-v-select
                                   :options="passengerTypeOptions"
                                   v-model="item.ptc"
                               />
                           </template>
                           <template #bodyCell-miles="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.miles"
                               />
                           </template>
                           <template #bodyCell-taxes="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.taxes"
                               />
                           </template>
                           <template #bodyCell-price-mile="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.price_mile"
                               />
                           </template>
                           <template #bodyCell-single-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.single_price"
                               />
                           </template>
                           <template #bodyCell-net-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.net_price"
                               />
                           </template>
                           <template #bodyCell-markup-price="{item}">
                               <acms-v-input
                                   class="w-100"
                                   v-model="item.markup_price"
                               />
                           </template>
                       </acms-v-table>
                   </div>
               </acms-v-card>
               <acms-v-card
                   title="Upsales">
                   <div class="row">
                       <div class="col-3">
                           <acms-v-switcher
                               title="Travel protection service"
                               type-checkbox
                               v-model="locState.upsales.travel_protection_service"
                           />
                       </div>
                       <div class="col-3">
                           <acms-v-switcher
                               title="Fare drop guarantee"
                               type-checkbox
                               v-model="locState.upsales.fare_drop_guarantee"
                           />
                       </div>
                       <div class="col-3">
                           <acms-v-switcher
                               title="Hotel"
                               type-checkbox
                               v-model="locState.upsales.hotel"
                           />
                       </div>
                       <div class="col-3">
                           <acms-v-switcher
                               title="Bus transfer"
                               type-checkbox
                               v-model="locState.upsales.bus_transfer"
                           />
                       </div>
                       <div class="col-3">
                           <acms-v-switcher
                               title="Ask for passport data"
                               type-checkbox
                               v-model="locState.upsales.ask_for_passport_data"
                           />
                       </div>
                   </div>

               </acms-v-card>
           </div>
       </template>
    </AppMainLayout>
    <acms-v-bottom-actions>
        <acms-v-btn title="Cancel deal" class="btn-border"  />
        <acms-v-btn title="Send to client" class="btn-primary" />
        <acms-v-btn title="Checkout " class="btn-success"  />
    </acms-v-bottom-actions>
</template>

<script>
export default {
    name: 'SaleCreatePage',
};
</script>

<script setup="">
import { reactive } from 'vue';
import { passengerTypeOptions, selectOptionsCabinClass } from '@demo/demoOptionsForSelect';
import { demoSalePassengersTable } from '@demo/demoSales';
import AppMainLayout from "@layouts/AppMainLayout.vue";

const props = defineProps({});

const locState = reactive({

    infoBlocks: [
        {
            title: 'Status',
            content: 'Open',
        },
        {
            title: 'BO ID',
            content: '4084425',
        },
        {
            title: 'CRM ID',
            content: '45642321',
        },
        {
            title: 'Created by',
            content: 'Michael Ranger',
        },
        {
            title: 'Processed by',
            content: '',
        }, {
            title: 'Approved by',
            content: '',
        },
    ],

    reservationInfo: {
        gds: null,
        award_program: null,
        pcc: null,
        pnr: null,

        table: [
            {
                array: [
                    '1', 'TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35',
                ],
                baggage: 2,
                someText: 'ACKN',
                cabin_class: 'Economy',
            },
            {
                array: [
                    '1', 'TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35',
                ],
                baggage: 2,
                someText: 'ACKN',

                cabin_class: 'Business',
            },

        ],
    },

    passengersTable: demoSalePassengersTable,

    financialInfo: {
        payment_method: 'Credit card',
        revenueTable: [
            {
                ptc: 'Adult',
                single_price: '4 750,35',
                net_price: '1 765,00',
                markup_price: '2 905,35',
            },
            {
                ptc: 'Child',
                single_price: '4 750,35',
                net_price: '1 765,00',
                markup_price: '2 905,35',
            },
            {
                ptc: 'Infant',
                single_price: '4 750,35',
                net_price: '1 765,00',
                markup_price: '2 905,35',
            },
        ],
        mileageTable: [
            {
                ptc: 'Adult',
                miles: '100 000',
                taxes: '265,00',
                price_mile: '0,02',
                single_price: '874,35',
                net_price: '75,00',
                markup_price: '25,35',
            },
            {
                ptc: 'Child',
                miles: '100 000',
                taxes: '265,00',
                price_mile: '0,02',
                single_price: '874,35',
                net_price: '75,00',
                markup_price: '25,35',
            },
            {
                ptc: 'Infant',
                miles: '25 000',
                taxes: '55,00',
                price_mile: '0,02',
                single_price: '874,35',
                net_price: '75,00',
                markup_price: '25,35',
            },
        ],
    },

    upsales: {
        travel_protection_service: false,
        fare_drop_guarantee: false,
        hotel: false,
        bus_transfer: false,
        ask_for_passport_data: false,
    },

});

</script>
<style lang="scss">

.page-sale-show{
    table {
        .acms-v-select-toggle-selected {
            padding: 4px 0;
        }

        .acms-v-select-toggle-inner {
            min-height: 24px;
        }

        td {
            font-size: 12px;
            line-height: 16px;
            padding: 8px !important;
        }
    }
}



.reservation-info-table td {
    width: 100px;

    vertical-align: middle;
    text-align: center;
}


</style>
