<template>
<div>
    <div class="client-overview-info-title text-uppercase  text-primary-dark fw-bold mb-2"
         v-if="item.content">
        {{ item.title }}
    </div>

    <div class="client-overview-info-content">
        <template v-if="typeof item.content == 'string'">
            <span v-html="item.content"></span>
        </template>
        <template v-if="Array.isArray(item.content)">
            <div class="row">
                <div class="col-6" v-for="col in item.content">
                    {{ col }}
                </div>
            </div>
        </template>

        <slot></slot>
    </div>
</div>
</template>

<script>
export default {
    name: "SimpleClientInfoBlock",
    props:{
        item:Object,
        required:true,
    }
}
</script>

<style scoped>

</style>
