<template>
    <div class="acms-v-collapse">
        <div class="acms-v-collapse-list">
            <div class="acms-v-collapse-item" v-for="(item, index) in items">
                <div class="acms-v-collapse-toggle d-flex justify-content-between"
                     :class="{active: isActive(index)}"
                     @click="clickRowHandle(item,index)"
                >
                    <div class="acms-v-collapse-toggle-content align-items-center d-flex w-100">
                        <template v-if="!$slots['toggle']">
                            {{ item?.title }}
                        </template>
                        <slot name="toggle" :item="item" :index="index" :number="index+1"></slot>
                    </div>
                    <acms-v-btn :icon="toggleIcon ?? 'angle-down'"
                                class="acms-v-collapse-toggle-status  bg-gray-light"
                                @click.stop="openHandle(item,index)"/>
                </div>
                    <div class="acms-v-collapse-content" v-if="isActive(index)">
                        <keep-alive>
                            <slot name="content" :item="item" :closeHandle="()=>openHandle(item,index)"></slot>
                        </keep-alive>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AcmsVCollapse',
};
</script>
<script setup="">
import {reactive, watch} from 'vue';

const emit = defineEmits(['update:activeIndex', ''])

const props = defineProps({
    items: Array,
    activeIndex: [Number, String],
    activeItem: Object,

    toggleIcon: String,
    activeFullRowToggle: Boolean,
});

const locState = reactive({
    active: props.activeIndex ?? -1,
});

watch(() => props.activeIndex, (val) => {
    locState.active = val
})


const isActive = (index) => {
    return locState.active == index
};

const clickRowHandle = (item, index) => {
    if (props.activeFullRowToggle) {
        openHandle(item, index)
    }
}

const openHandle = (item, index) => {
    if (locState.active == index) {
        locState.active = -1;
    } else {
        locState.active = index;
    }

    emit('update:activeIndex', locState.active)
    console.log('locState',locState.active);
};
</script>
<style lang="scss">
.acms-v-collapse {
    .acms-v-collapse-toggle {
        background: #FFFFFF;
        border-bottom: 1px solid #E9ECEF;
        min-height: 56px;
        padding: 12px;
        align-items: center;
        &.active {
            background-color: #F6F7F9;

            .acms-v-collapse-toggle-status .acms-v-icon {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .acms-v-collapse-toggle-status {
        width: 32px;
        height: 32px;
    }
    .acms-v-collapse-content {
        padding: 12px;
    }
}
</style>
