<template>
    <div class="box-client-request">
        <div class="new-request-people-quantity">
            <BoxPeopleQuantity
                v-model="clientRequest.peopleQuantity"
                :errors="errors"
            />
        </div>
        <div class="new-request-flight-legs mb-5">
            <BoxFlightLegs
                v-model="clientRequest.flightLegs"
                :errors="errors"
            />
        </div>
        <div class="new-request-flight-preferences">
            <BoxFlightPreferences
                v-model="clientRequest.flightPreferences"
                :errors="errors"
            />
        </div>
        <acms-v-form-field
            :modeTextarea="{resize:true, minHeight:96}"
            placeholder="Placeholder"
            label="Request notes"
            v-model="clientRequest.notes"
            :errors="errors?.notes"
        />
        <slot name="after-preferences"></slot>
    </div>
</template>

<script>
export default {
    name: 'BoxClientRequest',
};
</script>

<script setup="">
import {onBeforeMount, ref, watch} from 'vue';
import {sampleRequestForm} from '@demo/demoClient';
import {BoxFlightPreferences, BoxFlightLegs, BoxPeopleQuantity,} from '../request';
import {getFormattedRequestFor} from "@services/FormattedDataForServerService";
import {useBoxClientRequestStore} from "@store/boxes/box-client-request-store";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    modelValue: Object,
    errors: Object,
})
const BoxClientRequestStore = useBoxClientRequestStore()

const emit = defineEmits(['update:modelValue'])

const clientRequest = ref(null)

watch(clientRequest, (val) => {
    BoxClientRequestStore.setFormData(val)
    emit('update:modelValue', val)
}, {deep: true})

onBeforeMount(() => {
    if (props.modelValue?.id) {
        const formattedRequest = getFormattedRequestFor.frontend(props.modelValue)
        clientRequest.value = !props.modelValue?.peopleQuantity ? useForm(formattedRequest) : props.modelValue
    } else {
        clientRequest.value = props.modelValue ? getFormattedRequestFor
            .frontend(props.modelValue) : sampleRequestForm
    }
})

</script>

<style scoped>

</style>
