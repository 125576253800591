<template>
    <acms-v-form-field
        :modeSelect="{
                        options: getSelectOptions.countryOption,
                        outValueKey: 'id',
                        search:true,
                        searchAsync: 'infohub.getCountries',
                        placeholderSelect:'Search',
                        initialValue: initValue
                  }"
        :label="label"
        :name="name"
        :errors="errors"
        :modelValue="modelValue"
        :placeholder="placeholder"
        @update:modelValue="$emit('update:modelValue', $event)"
    />
</template>
<script>
export default {
    name: "FieldSelectCountry",
}
</script>
<script setup="">
import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

const props = defineProps({
    modelValue:[String,Object,Number],
    label:{
        type:String,
        default: 'Country'
    },
    name:{
        type:String,
        default: 'country_id'
    },
    initValue:Object,
    errors:Object,
    placeholder:String,
})

const $page = usePage();

const getSelectOptions = computed(()=>{
    const {countryOption} = $page.props?.optionsForSelect
    return{
        countryOption
    }
})
</script>
<style scoped>

</style>
