import {reactive, watch} from 'vue';
import httpClient from '@/services/HttpService';
import {getRoute} from "@plugins/useRoutes";
import debounce from "@libs/debounce";

export default function ({props, locState}) {
    const {searchAsync, search, options, optionKeyTitle, minSymbolsForSearch} = props;
    const searchState = reactive({
        request: false
    })

    const localSearchHandle = () => {
        if (locState.search?.length > 1) {
            let searchString = locState.search
            if (typeof search == "object") {
                const {keys: searchKeys, excludeSymbols} = search
                if (searchKeys) {
                    if (excludeSymbols) {
                        excludeSymbols.forEach((symbol) => {
                            searchString = searchString.replaceAll(symbol, '')
                        })
                    }
                    locState.tempOptions = options.filter(item => {
                        return searchKeys.some(key => {
                            return String(item[key])?.toLowerCase().includes(searchString.toLowerCase());
                        })
                    })
                }
            }
            locState.tempOptions = options.filter(item => {
                return (typeof options?.[0] == "string" ? item : item[optionKeyTitle])?.toLowerCase().includes(searchString.toLowerCase());
            });
        } else {
            locState.tempOptions = options
        }
    }

    const backendSearchHandle = (val) => {
        locState.searchIsProcessing = true
        locState.searchHasErrors = false
        let params = {search: val}

        if (props.searchAsyncParams) {
            params = {...params, ...props.searchAsyncParams}
        }
        locState.searchStringOld = val

        httpClient.get(getRoute(searchAsync),
            {params}).then(({data}) => {
            if (data) {
                locState.tempOptions = data;

            }
        }).catch(() => {
                locState.searchHasErrors = true
            }).finally(() => {
                locState.searchIsProcessing = false
            });
    }

    const searchDebounce = debounce((val) => {
        if (locState.search?.length < Number(minSymbolsForSearch)) {
            locState.tempOptions = null
            locState.searchIsProcessing = false
            locState.searchStringOld = ''

        } else {
            if (options && search && !searchAsync) {
                localSearchHandle(val)
            }

            if (searchAsync && searchAsync.length > 0) {
                backendSearchHandle(val)
            }
        }
    }, 200)

    watch(() => locState.search, (val) => {
        searchDebounce(val)
    });
}
