<template>
    <div class="box-passport-data-edit">
        <BoxPassportData
            class="p-4"
            v-model="locState.form"
            :errors="locState.form?.errors"
            :showButtons="getShowButtons"
            @delete="tryRemove"
            @update="update"
            @add="save"
            :primary-passenger="primaryPassenger"
        >
            <template #footer>
                <acms-v-btn v-if="!locState.form?.id && showAddButton"
                            icon="plus"
                            title="Add data" @click="save"
                            class="btn-primary ms-auto me-auto mt-4"/>
            </template>

        </BoxPassportData>
        <AlertDelete v-if="getShowButtons.includes('delete')"
                     v-model:show="locState.modalDelete"
                     type="passenger"
                     @delete="remove"
        />

        <template v-if="backendPermissions?.allowUpdatePassengers">
            <acms-v-bottom-actions
                alertForCancel
                @modalAlertCancel="cancel"
                modal-alert-cancel-left-button-create
                @save="()=>locState.form?.id ? update(true): save(true)"
                @saveAndExit="()=>locState.form?.id ? update(false,true): save(false,true)"
                :disabledSave="locState.submitProcessing"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: "BoxPassportDataEdit"
}
</script>

<script setup="">
import BoxPassportData from "@boxes/common/BoxPassportData.vue";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {computed, reactive} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import useApiPassengersService from "@services/Api/useApiPassengersService";
import AlertDelete from "@alerts/AlertDelete.vue";
import {useBackendMiddlewarePropsService} from "@services";

const AlertsManager = useAlertsManagerStore()
const ApiPassengerService = useApiPassengersService()
const {backendPermissions} = useBackendMiddlewarePropsService()
const props = defineProps({
    modelValue: Object,
    mainClientId: [String, Number],
    requestId: [String, Number],
    showAddButton: Boolean,
    showDeleteButton: Boolean,
    primaryPassenger:Boolean,
    additionalFormData:Object,
})

const emit = defineEmits(['cancel',])

const locState = reactive({
    form: props.modelValue ? useForm(props.modelValue) : null,
    submitProcessing: false,
    modalDelete: null
})

const tryRemove = (object) => {
    locState.modalDelete = object
}
const remove = () => {
    const id = locState.modalDelete?.id ?? null
    if (id) {
        locState.modalDelete = null
        router.delete(getRoute('passengers.delete', id), {
            onSuccess() {
                emit('cancel')
                AlertsManager.add('Passenger was deleted')
            }, onFinish() {
                locState.modalDelete = null
            }
        })
    }
};

const getShowButtons = computed(() => {

    if (props.showDeleteButton && backendPermissions?.allowDeletePassengers) {
        return 'delete,update'
    }
    return 'update'
})

const cancel = () => emit('cancel');

const getObjectData = () => {
    const dataObject = {}
    const {mainClientId, requestId} = props
    if (mainClientId) {
        dataObject.client_id = mainClientId
    }
    if (props.requestId) {
        dataObject.request_id = requestId
    }
    return dataObject
}

const afterSuccess = (hide = false, exit = false) => {
    locState.submitProcessing = false
    if (hide) {
        cancel()
    }
    if (exit) {
        router.get(getRoute('clients'))
    }
}

const save = (hide = false, exit = false) => {
    const dataObject = getObjectData()
    locState.submitProcessing = true
    ApiPassengerService.save(locState.form, {
        dataObject,
        afterSuccess: () => afterSuccess(hide, exit),
        afterError: () => locState.submitProcessing = false
    })
}

const update = (hide = false, exit = false) => {
    const dataObject = getObjectData()
    locState.submitProcessing = true
    ApiPassengerService.update(locState.form,
        {
            dataObject,
            afterSuccess: () => afterSuccess(hide, exit),
            afterError: () => locState.submitProcessing = false
        }
    )
}

</script>
<style scoped></style>
