export const sampleRequestForm = {

    // request_id: '353ksdf',

    peopleQuantity: {
        adults: 1,
        children: 0,
        infants: 0,
    },

    flightLegs: [
        {
            // from: null,
            // to: null,
            // dates_range: null,
            from: {id: 11177, iata: "KVL", title: "Kivalina Airport"},
            to: {id: 11177, iata: "KVL", title: "Kivalina Airport"},

            dates_range: [new Date, new Date],
            cabin_class: null,
        },
    ],

    flightPreferences: {
        airlines: null,
        number_of_stops_id: null,
        purpose_of_travel_id: null,
        max_client_budget: null,
        values: null,
        client_need_a_hotel: null,
    },

    notes: null,

};


export const demoClientsIndex = [
    {
        name: 'Amelia Gregory Crew',
        email: 'am*****@gmail.com',
        phone: '+1 (873) ******24',

        requests: 12,
        agent_status: 'New prospect',
        revenue: '159,0',
        added: '02/01/20',
        local_time: '02:30 AM'
    },
    {
        name: 'Raphaël Jorge Kiki',
        email: 'am*****@gmail.com',
        phone: '+1 (873) ******24',
        agent_status: 'Co-passenger',
        requests: 5,
        revenue: '0,0',
        added: '02/01/20',
        local_time: '02:30 AM'
    },
    {
        name: 'Raphaël Jorge Kiki',
        email: 'am*****@gmail.com',
        phone: '+1 (873) ******24',
        requests: 28,
        agent_status: 'New client',
        revenue: '0,0',
        added: '02/01/20',
        local_time: '02:30 AM'
    },
    {
        name: 'Raphaël Jorge Kiki',
        email: 'am*****@gmail.com',
        phone: '+1 (873) ******24',
        agent_status: 'Return prospect',
        requests: 5,
        revenue: '584,0',
        added: '02/01/20',
        local_time: '02:30 AM'
    },
    {
        name: 'Raphaël Jorge Kiki',
        email: 'am*****@gmail.com',
        phone: '+1 (873) ******24',
        requests: 28,
        agent_status: 'VIP client',
        revenue: '15321,0',
        added: '02/01/20',
        local_time: '02:30 AM'
    },
];

export const sampleLinkedContact = {

    names: {
        first_name: null,
        last_name: null,
        middle_name: null,
        no_name: false,
    },

    emails: null,

    phone_numbers: null,

    best_time_to_call: {
        from: null,
        to: null,
    },

    type_id: null,

    notes: null,
    ff_programs: null,

    gender_id: null, contact_by_id: null,
};

export const sampleClientDetails = {

    names: {
        first_name: '',
        last_name: '',
        middle_name: null,
        no_name: false,
    },

    emails: null,
    phone_numbers: null,

    best_time_to_call: {
        from: null,
        to: null,
    },

    gender_id: null,
    contact_by_id: null,

    referral_id: null,
    notes: null,

};

export const samplePassportData = {

    first_name: null,
    last_name: null,
    middle_name: null,

    passport_number: null,
    issuing_country_id: null,
    expiration_date: null,
    birth_date: null,

    gender_id: null,
    nationality: null,

    ktn: {
        type_id: null,
        code: null,
        redress: null
    },


    // first_name: null,
    // last_name: null,
    // middle_name: null,
    //
    // passport_number: null,
    // issuing_country: null,
    // expiration_date: null,
    // birth_date: null,
    //
    // gender: null,
    // nationality: null,
};

export const samplePassengerPassportData = {

    ...samplePassportData,

};

export const demoClient = {

    general: {
        names: {
            first_name: 'Alexander',
            last_name: 'Crew',
            middle_name: 'George',
        },
        gender: 'Male',
        contactBy: 'Whatsapp',
    },

    contacts: {
        emails: null,

        phoneNumbers: null,

        best_time_to_call: {
            from: null,
            to: null,
        },
    },

    locationAndTimezone: {
        country: null,
        state: null,
        city: null,
        timezone: null,
    },

    ffPrograms: null,

    passportData: {...samplePassportData},

    notes: 'Very busy businessman, prefer to be contacted through a secretary',

};


export const demoClientPassengersTableData = [
    {
        id: 1,
        fullname: 'Amelia Gregory Crew',
        type: 'Adult',
        redress: '43535354',
        ktn: '61616146767',
    },
    {
        id: 2,
        fullname: 'Alexandr George Crew',
        type: 'Adult',
    },
    {
        id: 3,
        fullname: 'Ralph Edwards Smith',
        type: 'Child',
    },
    {
        id: 4,
        fullname: 'Guy Hawkins Peterson',
        type: 'Adult',
    },
    {
        id: 5,
        fullname: 'Annette Black Johnson',
        type: 'Infant',
    },
    {
        id: 6,
        fullname: 'Theresa Webb Delivery',
        type: 'Adult',
    },
    {
        id: 7,
        fullname: 'Eleanor Pena Smith',
        type: 'Adult',
    },

]

export const demoClientRequestTableData = [

    {
        request_id: '353ksdf',
        created_at: '03/12/22, 02:30AM',
        time_passed: '1h',
        local_time: '09:30PM',
        flight_details: {date: '03/25/22', airports: ['NYC', 'FRA', 'KIV']},
        passengers: {adult: 12, child: 2, infant: 1},
        pq: null,
        cabin_class: 'Business',

    },
    {
        request_id: '546fgja',
        created_at: '03/12/22, 02:30AM',
        time_passed: '5h',
        local_time: '09:30PM',
        flight_details: {date: '15DEC21', airports: ['NYC', 'FRA', 'KIV', 'DXB']},
        passengers: {adult: 12},
        pq: ['DL', 'AA', '9U'],
        is_child: true,
        cabin_class: 'Premium Economy',
    },
    {
        request_id: '564jhta',
        created_at: '03/12/22, 02:30AM',
        time_passed: '6h',
        local_time: '09:30PM',
        flight_details: {date: '03/25/22', airports: ['NYC', 'FRA', 'KIV']},
        passengers: {adult: 12, 'infant': 1},
        pq: ['DL', 'AA', '9U'],
        is_child: true,
        cabin_class: 'First Class',
        child: true,
    },
    {
        request_id: '883wakq',
        created_at: '03/12/22, 02:30AM',
        time_passed: '24h',
        local_time: '09:30PM',
        flight_details: {date: '03/25/22', airports: ['NYC', 'FRA', 'KIV']},
        passengers: {adult: 2},
        pq: ['DL', 'AA', '9U'],
        cabin_class: 'Business',
        child: true,
    },
    {
        request_id: '120owal',
        created_at: '03/12/22, 02:30AM',
        time_passed: '199h',
        local_time: '09:30PM',
        flight_details: {date: '03/25/22', airports: ['NYC', 'FRA', 'KIV']},
        passengers: {adult: 2, 'child': 2, 'infant': 1},
        pq: null,
        cabin_class: 'Business',
    },
];


