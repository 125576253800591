import {ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import {getSettingsForUseForm} from "../../../services/FormService";

const defaultSendData = {
    limit: 20,
    search: null,
    column: null,
    direction: null,
    page: 1,
}
const defaultSendParams = {
    sendType: 'get',
    only: null
}
export default function (params) {
    const {
        route,
        sendData = {},
        sendParams = {}
    } = params
    const {
        // limit,
        // search,
        // column,
        // direction,
        // page
    } = sendData

    const {
        sendType,
        only
    } = sendParams


    const tableForm = ref({
        ...defaultSendData,
        ...sendData,
        // limit,
        // search,
        // column,
        // direction,
        // page
    })

    if (tableForm.value?.limit) {
        watch(() => tableForm.value?.limit, (val) => {
            tableForm.value.page = 1
        })
    }

    watch(tableForm, (val) => {
        const queryObject = {
            limit: tableForm.value.limit
        };
        ['search', 'column', 'direction', 'page'].map(key => {
            if (tableForm.value[key]) {
                queryObject[key] = tableForm.value[key]
            }
        })

        const getCorrectSendType = ['get', 'post'].includes(sendType) ? sendType : 'get'
        const form = useForm(queryObject)
        let formParams = {preserveState: true}

        if (only) {
            const params = getSettingsForUseForm({only})
            formParams = {...formParams, ...params}
        }

        form[getCorrectSendType](route, formParams)
    }, {deep: true})

    const tableSortHandle = (tableSort) => {
        tableForm.value.column = tableSort.column
        tableForm.value.direction = tableSort.direction
    }

    const defaultParamsForTableSorts = {
        limit: 20,
        search: '',
        column: null,
        direction: null,
        page: 1,
    }

    return {tableForm, tableSortHandle, defaultParamsForTableSorts}
}
