<template>
    <div class="box-ff-programs ">
        <template v-for="(item, key) in valueList">
            <div class="box-ff-programs-row d-flex">
                <acms-v-form-field
                    label="FF Program"
                    :name="'ffProgram_'+key"
                    placeholder="XXXXXXXXX"
                    :modeInputWithDropdown="{
                                     selectOptions:[],
                                     keySelect:'ff_program',
                                     optionKeyTitle:getOptionTitle,
                                     toggleKeyCode:['iata','title'],
                                     search:true,
                                     searchAsync: 'infohub.getAirlinesList',
                                     placeholderSelect:'AA',
                                     minSymbolsForSearch: 2
                                }"
                    @clickOnDropdownOption="(e)=>clickOnDropdownOption(e, key)"
                    v-model="valueList[key]"
                    :errors="getErrors(key)"
                />

                <div class="ms-4">
                    <acms-v-label/>
                    <acms-v-btn
                        icon="close"
                        class="bg-gray-light align-self-end btn-row-action"
                        @click="()=>removeRow(key)"
                    />
                </div>
            </div>
        </template>
        <acms-v-btn
            title="Add FF Program"
            preset="simple-primary"
            icon="plus"
            @click="add"
        />
    </div>
</template>

<script>
export default {name: 'BoxFFPrograms'};
</script>

<script setup="">
import useCommonEmailsAndPhones from './composables/useCommonEmailsAndPhones';
import {usePage} from "@inertiajs/vue3";

const emit = defineEmits(['update:modelValue', 'update:deleteIds']);

const selectOptions = [
    {id: 1, title: 'Advantage', code: 'AA' },
    {id: 2, title: 'Sky Miles', code: 'DL' },
];

const cleanFFProgram = {
    main: false,
    ff_program:null,
    text: '',
};

const getErrors = (key) => {
    const {errors} = props
    const codeErrorKey = `ff_programs.${key}.code`;
    const idErrorKey = `ff_programs.${key}.id`;
    const mileageIdErrorKey = `ff_programs.${key}.airline_id`;
    let errorsString = null;

    [codeErrorKey, idErrorKey, mileageIdErrorKey].forEach(key=>{
        if(errors?.[key]){
            errorsString = '' +errors[key]
        }
    })
    return errorsString
}

const props = defineProps({ modelValue: Array , initEmpty:Boolean, errors: Object });


const getOptionTitle = (option) => {
    const {title, iata} = option
    if (iata) {
        return `${title} (${iata})`
    }
    return `${title}`
}


const {
    valueList, clickOnDropdownOption,
    getOrderClass,
    add,
    getLabel,
    removeRow,
} = useCommonEmailsAndPhones({
    props,
    cleanItem: cleanFFProgram,
    selectOptions,
}, emit);
</script>

<style lang="scss">
.box-ff-programs{
    .acms-v-select-toggle, .acms-v-select-toggle-inner{
        max-width: 90px;
    }
    .acms-v-select-toggle-angle{
        position: absolute;
        right: 8px;
    }
    .acms-v-select-toggle-inner .code{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
