import {cloneDeep} from "lodash";

export const getFormattedPhoneForBackend = (item, list = []) =>{
    const {main, phoneCode, text, type, id} = item
    return {
        type_id: type.id,
        main: list?.length == 1 ? true : main,
        phone: text,
        dial_code: phoneCode?.id,
        id: id ?? null
    };
}

export const getFormattedEmailForBackend = (item, list = []) =>{
    const {type, main, text, id} = item
    return {
        type_id: type.id,
        main: list?.length == 1 ? true : main,
        email: text,
        id: id ?? null
    };
}

export const getFormattedPhoneNumbersFor = {
    backend(array = []) {
        const list = array?.data ?? array
        const formattedList = list.map((item) => {
            return  getFormattedPhoneForBackend(item, list)
        })
        return formattedList
    },
    frontend(val, {dropdownOptions, selectOptions, locState}) {
        const {dial_code, pivot, phone, id} = val

        return {
            phoneCode: selectOptions.find(item => item.code == dial_code),
            type: pivot ? dropdownOptions.find(item => item.id == pivot?.type_id) : locState.value.type,
            main: pivot ? pivot?.main : locState?.value?.main,
            text: phone,
            id
        }
    }
}

export const getFormattedEmailsFor = {
    backend(array = []) {
        const list = array?.data ?? array

        const formattedList = list.map((item) => {
           return  getFormattedEmailForBackend(item,list )

        })

        return  formattedList
    },
}

export const getFormattedRequestFlightLegsFor = {
    backend(array = []) {
        const object = {
            flight_leg: null,
            // from_airport_id: null,
            // from_city_id: null,
        }

        const timezone = 'Europe/Chisinau'; // Часовой пояс Кишинева

        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы отсчитываются с 0, поэтому добавляем 1
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }

        object.flight_leg = array.map((itemData, index) => {
            const {from, to, dates_range, cabin_class_id, id} = itemData

            if (from && index === 0 && typeof from != "string" && !id) {
                object.from_airport_id = !('airports' in from) ? from?.id : null;
                object.from_city_id = ('airports' in from) ? from?.id : null;
            }

            const result = {
                departure_airport_id: typeof from == "string" ? from : from?.iata,
                arrival_airport_id: typeof to == "string" ? to : to?.iata,
                flight_date_from: formatDate(dates_range[0]),
                flight_date_to: dates_range[1] ? formatDate(dates_range[1]) : null,
                cabin_class_id,
                id,
                from_id: from?.id ?? null,
                to_id: to?.id ?? null,
                from_type: null,
                to_type: null,
            }

            if (from) {
                result.from_type = from?.type ?? null
            }
            if (to) {
                result.to_type = to?.type ?? null
            }

            return result
        })
        return object
    },

    frontend(array = []) {

        const setIataIfNeed = (object, iata) => {
            let result = iata
            if (object?.id && !object?.iata) {
                result = object
                result.iata = iata
            } else if (object?.iata) {
                result = object
            }
            return result
        }
        return array.map(item => {
            const {
                departure_airport_object,
                arrival_airport_object,
                departure_airport_id,
                arrival_airport_id,
                flight_date_from,
                flight_date_to,
                cabin_class_id,
                id,
                from_type,
                to_type
            } = item


            const from = setIataIfNeed(departure_airport_object, departure_airport_id)
            let to = setIataIfNeed(arrival_airport_object, arrival_airport_id)

            return {
                from,
                fromObject: departure_airport_object,
                to,
                toObject: arrival_airport_object,
                dates_range: [flight_date_from ? new Date(flight_date_from) : null, flight_date_to ? new Date(flight_date_to) : null],
                cabin_class_id,
                id,
                from_type,
                to_type
            }
        })
    }
}

export const getFormattedBestTimeToCall = (best_time_to_call) => {
    const object = {
        best_time_call_from: null,
        best_time_call_to: null
    }
    if (best_time_to_call) {
        const {from, to} = best_time_to_call
        if (from && to) {
            if (!(from.type == 'AM' && to.type == 'AM' && from.time == '00:00' && to.time == '00:00')) {
                object.best_time_call_from = from;
                object.best_time_call_to = to;
            }
        }
    }
    return object
}

export const getFormattedLinkedContactItemFor = {
    backend(item, client_id = null) {
        const {
            best_time_call_from: best_from, best_time_call_to: best_to, // remove from rest
            phone, email, // remove from rest
            emails, phone_numbers, best_time_to_call, names, id, ff_programs,
            ...rest
        } = item;

        const {best_time_call_from, best_time_call_to} = getFormattedBestTimeToCall(best_time_to_call)

        return {
            ...names,
            id,
            client_id,
            best_time_call_from, best_time_call_to,
            emails: getFormattedEmailsFor.backend(emails),
            phone_numbers: getFormattedPhoneNumbersFor.backend(phone_numbers),
            ff_programs: getFormattedFFPrograms(ff_programs),
            ...rest
        }
    },
    frontend(item) {
        const {
            first_name,
            middle_name,
            best_time_to_call,
            type_id,
            last_name,
            pivot,
            ff_programs,
            id,
            ...rest
        } = cloneDeep(item)

        return {
            id,
            names: {
                first_name,
                middle_name,
                last_name,
            },
            emails: Array.isArray(item.email) ? item.email : [item.email],
            phone_numbers: Array.isArray(item.phone) ? item.phone : [item.phone],
            best_time_to_call,
            type_id: pivot?.type_id ?? type_id,
            ff_programs,
            ...rest,
        }
    }
}

export const getFormattedRequestFor = {
    backend(item, {mainClientId, parentId}) {
        const {
            flightPreferences,
            flightLegs,
            peopleQuantity,
            notes,
            parent_id,
            ...rest
        } = item

        const transformedFlightLegs = getFormattedRequestFlightLegsFor.backend(flightLegs)

        return {
            client_id: mainClientId,
            ...transformedFlightLegs,
            ...flightPreferences,
            ...peopleQuantity,
            ...rest,
            notes,
            parent_id: (parentId ?? item.parent_id) ?? null
        }
    },
    frontend(item) {
        if (Boolean(item?.flightPreferences || item?.peopleQuantity)) {
            return item
        }
        const {
            adults, children, infants,

            airlines, number_of_stops_id, purpose_of_travel_id,
            max_client_budget, values, seats, client_need_a_hotel,

            notes,
        } = item

        const object = {
            peopleQuantity: {
                adults, children, infants
            },
            flightLegs: getFormattedRequestFlightLegsFor.frontend(item.flight_legs),
            flightPreferences: {
                airlines, number_of_stops_id, purpose_of_travel_id,
                max_client_budget, values, client_need_a_hotel, seats
            },
            notes,
            id: item.id ?? null,
            parent_id: item.parent_id ?? null
        }

        if (item?.seats_array) {
            object.seats_array = item.seats_array
        }

        return object
    }
}

export const getFormattedFFPrograms = (array) => {
    return array?.map(({ff_program, text, id}) => {
        const object = {
            airline_id: ff_program?.id ?? null,
            code: text?.length > 0 ? text : null,
        }
        if (id) {
            object.id = id
        }

        return object;
    }) ?? []
}


