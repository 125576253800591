<template>
    <div class="acms-v-hint" :class="hintClass" style="min-height: 16px" v-html="getTitle"></div>
</template>

<script>
export default {
    name: 'AcmsVHint',
};
</script>

<script setup>

import {computed} from 'vue';

const props = defineProps({
    errors: [Array, Object, String,],
    hint: String,
    hintValid: Boolean,
    name: [String, Array],
    hintClass: {
        type: String,
        default: 'invalid-feedback'
    }
});
const getTitle = computed(() => {
    const {errors, hint, hintValid, name} = props;

    let existErrors = null

    if(errors){
        existErrors = typeof errors == "string" ? errors : Object?.keys(errors)?.length > 0
    }


    if (existErrors) {
        if (Array.isArray(errors) || typeof errors == 'string') {
            return errors
        }
        if (Array.isArray(name)) {
            return name.reduce((acc, nameKey) => {
                if (errors[nameKey]) {
                    acc.push(errors[nameKey])
                }
                return acc;
            }, []).join('<br>')
        }
        if (Array.isArray(errors?.[name])) {
            return errors[name].join(', ')
        }
        return errors[name];

    } else {
        return hint;
    }

});

</script>

<style lang="scss">
.acms-v-hint {
    display: block;
}
.default-feedback {
    color: #6C757D;
    font-size: .875rem;
    margin-top: 0.375rem;
    width: 100%;
}
.invalid-feedback {
    font-size: 14px;
    line-height: 16px;
    margin-top: 0;
}
</style>
