import {SELECT_VIEW_TEMPLATES} from '@components/ui/FormField/constants';

const commonProps = {
    modelValue: [Object, String, Number, Boolean],
    placeholder: {
        type: String,
        default: 'Select option'
    },
    outValueKey: [String, Number],
    initialValue: [Object, Array],
    optionKeyValue: {
        type: String,
        default: 'id',
    },
    optionKeyTitle: {
        type: [String,Function],
        default: 'title',
    },
    optionKeyCode: {
        type: String,
        default: 'code',
    },
    optionKeyImage: {
        type: String,
        default: 'image',
    },
    subOptionKeyTitle: {
        type: String,
        default: 'title',
    },
    optionKeyArray: {
        type: String,
        default: 'array',
    },
    subOptionKeyCode: {
        type: String,
        default: 'code',
    },
    //groups
    optionsListHasGroups:Boolean,
    optionsListGroupTitleKey: {
        type: String,
        default: 'title',
    },
    optionsListGroupItemsKey: {
        type: String,
        default: 'options',
    },
    keyPathForComparison: {
        type: String,
    },
    viewTemplate: {
        type: String,
        validator(value) {
            return SELECT_VIEW_TEMPLATES.includes(value)
        }
    },

    multiselect: [Boolean, Object],
    multi: [Boolean, Object],
    multiple: [Boolean, Object],


    selectOptionOnInitInParentFun:Function,
}

const commonToggleProps = {
    toggleTagKeyTitle: {
        type: [String, Array],
        default: 'title',
    },
    toggleKeyTitle: {
        type: [String, Array, Function],
        default: 'title',
    },
    toggleKeyCode: {
        type: [String, Array],
        default: 'code',
    },
    beforeToggleContent: String,
    toggleTitleAutoOverflow: Boolean,
}
const commonOptionProps = {
    beforeOptionContent: String,
    orderSelectedOptions: Boolean,
    hideSelectedOption: Boolean,
}

export const toggleProps = {
    options: {
        type: [Array, Object],
    },
    ...commonProps,
    ...commonToggleProps,
};

export const optionProps = {
    option: [Object, String, Number, Boolean],
    optionIndex: [String, Number],
    focusedOption: [String, Object],
    orderSelectedOptions: Boolean,
    beforeOptionContent: [String, Object],

    ...commonProps,
    ...commonOptionProps,
}

export const selectProps = {
    selectListShow: Boolean,
    placeholderSelect: String,
    selectedOptionOnInit: [Number, String, Object],  //{key, value}
    options: {
        type: [Array, Object],
    },
    optionTemplate: {
        type: String,
        // validator(value) {
        //     return SELECT_VIEW_TEMPLATES.includes(value)
        // }
    },
    toggleTemplate: {
        type: String,
        validator(value) {
            return SELECT_VIEW_TEMPLATES.includes(value)
        }
    },
    search: [Boolean, String],
    searchPlaceholder: String,
    searchAsync: String, // url
    searchAsyncParams: Object,
    optionsListPlacement: {
        type: String,
        default: 'bottom'
    },

    limit: [Number, String],
    minSymbolsForSearch: {
        type: [String, Number],
        default: 3
    },

    ...commonProps,
    ...commonOptionProps,
    ...commonToggleProps,
}

