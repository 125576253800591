<template>
    <acms-v-modal
        :show="show"
        size="medium"
        title="View Dump"
        @hide="$emit('update:show', false)"
    >
        <template #content>
            <template v-if="dumpCode">
                <acms-v-form-field
                    :modeTextarea="{autoHeight:true, minHeight:96, maxHeight:360}"
                    readonly
                    :modelValue="dumpCode"
                    element-class=" opacity-100"
                />
            </template>

        </template>

        <template #footer-buttons>
            <acms-v-btn title="Close" class="btn-primary btn-sm" @click="$emit('update:show', false)"/>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalViewDump"
}
</script>
<script setup="">
const props = defineProps({
    show:Boolean,
    dumpCode: String,
})
</script>
<style scoped>

</style>
