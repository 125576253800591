<template>
    <acms-v-modal
        :show="show"
        @hide="$emit('update:show', false)"
        size="small"
        title="Request phone edit"
    >
        <template #content>
            <div class="row">
                <acms-v-form-field
                    :modeTextarea="{minHeight:96, resize:true}"
                    label="Comment"
                    name="comment"
                    v-model="locState.form.comment"
                    :errors="locState.form.errors?.comment"
                />
            </div>
        </template>
        <template #footer="{hideHandle}">
            <button @click="sendHandle(hideHandle)" class="btn ms-auto btn-primary btn-sm">Send request</button>
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: "ModalRequestDeleteEmailOrPhone"
}
</script>
<script setup="">
import {reactive, ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
const emit = defineEmits(['update:show', ''])
const props = defineProps({
    show:Boolean,
    type:String,
    data:Object,
})

const locState = reactive({
    form:useForm({
        comment: null,
        type: props.type,
        id: props.data?.id ?? null
    } )
})

watch(()=>props.show, (val)=>{
    if(!val){
        locState.form.comment = null;
        locState.form.type = props.type;
    }else{
        locState.form.id = props.data?.id ?? null;
    }
})

const sendHandle = (hide) => {

    locState.form.post(getRoute('clients.contact.requestDelete'), {
        onSuccess(){
            hide?.()
        }
    })
    // hide?.()
}

</script>
<style scoped>

</style>
