import {obscureEmail, obscurePhone} from '@/helpers/stringHelpers';
import {cloneDeep} from "lodash";
import {usePage} from "@inertiajs/vue3";

export function isObject(item) {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

export const getFullName = (names, stubString = false) => {
    const copyNames = cloneDeep(names)
    let fullName = '';
    ['first_name', 'middle_name', 'last_name'].forEach(key => {
        if (copyNames?.[key]?.length > 0) {
            if (fullName.length == 0 || copyNames[key].length > 0) {
                fullName = fullName + ' ' + copyNames[key];
            }
        }
    });

    return fullName.trim().length > 0 ? fullName : stubString;
};

export const getSecurizedEmail = (emailsArray, secureAll = false) => {
    const emails = cloneDeep(Array.isArray(emailsArray) ? emailsArray : [emailsArray])
    const firstEmailText = emails?.[0]?.text;
    const firstEmail = emails?.[0]?.email;
    if (firstEmailText) {
        if (secureAll) {
            return emails.map(item => obscureEmail(item.text)) ?? []
        }
        return obscureEmail(firstEmailText);
    }
    if (firstEmail) {
        if (secureAll) {
            return emails.map(item => obscureEmail(item.email)) ?? []
        }
        return obscureEmail(firstEmail);
    }
    return '';
};

export const getSecurizedPhone = (phonesArray, withoutCode = false, secureAll = false) => {
    const phones = cloneDeep(Array.isArray(phonesArray) ? phonesArray : [phonesArray])
    if (phones?.length > 0) {
        const firstPhone = phones[0]?.phone ? phones[0] : null;
        const firstPhoneText = phones[0]?.text ? phones[0] : null;

        if (firstPhone) {
            const configurePhoneString = (phoneObj) => {
                const phoneCode = '+' + (phoneObj?.dial_code ?? '')
                return {
                    phoneCode,
                    phoneText: phoneObj.phone
                }

            }
            if (secureAll) {
                return phonesArray.map(item => {
                    const {phoneCode, phoneText} = configurePhoneString(item)
                    return phoneCode + ' ' + obscurePhone(phoneText);
                }) ?? []
            }
            const {phoneCode, phoneText} = configurePhoneString(firstPhone)
            return phoneCode + ' ' + obscurePhone(phoneText);
        }
        if (firstPhoneText) {

            const phoneCode = '+' + (firstPhoneText?.phoneCode?.code ?? '')
            let phoneString
            if (withoutCode) {
                phoneString = firstPhoneText.text
                return obscurePhone(phoneString);
            } else {
                phoneString = firstPhoneText.text
                return (phoneCode ?? '') + ' ' + obscurePhone(phoneString);
            }
        }
    }
    return '';
}


export const getHiddenPhone = (value, needArray = false) => {


    if (needArray && Array.isArray(value)) {
        return value.map(item => item?.phone_full)
    }

    return value?.[0]?.phone_full ?? value?.text ?? ''

}
export const getHiddenEmail = (value, needArray = false) => {

    if (needArray && Array.isArray(value)) {
        return value.map(item => item?.email)
    }
    return value?.[0]?.email ?? value?.text ?? ''

}
export const getClientRelationShipString = (id, stubTitle = 'Not Specified') => {
    const $page = usePage();
    const relationShipOptions = $page.props?.optionsForSelect?.relationshipOptions ?? []

    return relationShipOptions?.find(item => item.id == id)?.title ?? stubTitle
}

export const getCabinClassString = (id) => {
    const $page = usePage();
    const selectOptionsCabinClass = $page.props?.optionsForSelect?.cabinClassOptions ?? []
    return selectOptionsCabinClass?.find(item => item.id == id)?.title ?? null
}
