<template>
<div class="box-phone-numbers-n-best-time-to-call">
        <BoxPhoneNumbers
            class="mb-3"
            :modelValue="phoneNumbers"
            @update:modelValue="$emit('update:phoneNumbers', $event)"
            :errors="errors"
            :updatedData="updatedPhoneNumbers"
        />
        <BoxBestTimeToCall
            v-if="canShowBesTimeToCall"
            :modelValue="bestTimeToCall"
            @update:modelValue="$emit('update:bestTimeToCall', $event)"
            :errors="errors"
        />
</div>
</template>

<script>
export default {
    name: "BoxPhoneNumbersAndBestTimeToCall"
}
</script>

<script setup="">
import BoxPhoneNumbers from "./BoxPhoneNumbers.vue";
import BoxBestTimeToCall from "./BoxBestTimeToCall.vue";
import {computed} from "vue";

const props = defineProps({
      phoneNumbers:Array,
      updatedPhoneNumbers:[Array,Object],
      bestTimeToCall:Object,
      errors:Object,

})
const canShowBesTimeToCall = computed(()=>{
    const {phoneNumbers} = props
    return phoneNumbers?.length > 0 && phoneNumbers?.[0]?.text?.length > 2
})

</script>
<style scoped></style>
