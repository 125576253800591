<template>
    <div class="box-people-quantity row g-3">
            <acms-v-form-field
                label="Adults"
                class="box-people-quantity-item col-4 col-xl-2 "
                :modeQuantity="{min:1}"
                v-model="peopleQuantity.adults"
            />
            <acms-v-form-field
                label="Children"
                class="box-people-quantity-item col-4 col-xl-2"
                labelAfter="Aged 2-11"
                modeQuantity
                v-model="peopleQuantity.children"
            />

            <acms-v-form-field
                label="Infants"
                class="box-people-quantity-item col-4 col-xl-2"
                labelAfter="<2"
                modeQuantity
                v-model="peopleQuantity.infants"
            />
    </div>
</template>
<script>
export default {
    name: 'BoxPeopleQuantity',
};
</script>
<script setup="">
import { onBeforeMount, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
      modelValue:Object,
    errors:Object,
})
const initValue = {
    adults:0,
    children:0,
    infants:0
}
const peopleQuantity = ref({
   ...initValue
})

watch(() => peopleQuantity.value, (val) => {
    emit('update:modelValue', val);
}, { deep: true });

onBeforeMount(()=>{
    peopleQuantity.value = props.modelValue ?? {...initValue}
})
</script>

<style scoped lang="scss">
.box-people-quantity-item{
    @media only screen and (max-width: 1630px) {
        width: calc(100% / 3);
    }
}
</style>
