<template>
    <div class="box-request-client-details-actions d-flex flex-wrap " style="justify-content: center">
        <box-client-connection-actions
            :phone-count="phoneCount"
            :email-count="emailCount"
        />
        <acms-v-btn title="PQ" updatesCount="2" :pqCount="pqCount"
                    @click="$emit('pqClick')"
                    class="text-white bg-primary-dark-op p-1"/>

        <acms-v-btn :icon="getFollowUpState.icon"   icon-size="20"
                    @click="$emit('followUpClick')"
                    class=" p-1"
                    :class="getFollowUpState.classes"
        />
        <acms-v-btn title="PNR"
                    style="font-size: 14px;"
                    @click="$emit('pnrClick')"
                    class="text-primary-dark-op bg-gray-light fw-semibold p-1"/>
        <acms-v-ratinger title="Client rate" v-model="locState.clientRating"/>
        <acms-v-btn icon="voicemail"
                    icon-size="20"
                    class="  p-1"
                    :class="locState.voicemail  ? 'bg-danger-light text-danger' : 'text-primary-dark-op bg-gray-light'"
                    @click="locState.voicemail = !locState.voicemail"
        >
        </acms-v-btn>
        <acms-v-btn icon="shield"
                    icon-size="20"
                    @click="$emit('shieldClick')"
                    class="text-primary-dark-op bg-gray-light p-1"/>
    </div>
</template>

<script>
export default {
    name: 'RequestClientDetailsActions',
};
</script>

<script setup="">
import { computed, reactive, watch } from 'vue';
import { isAfter, isSameDay } from 'date-fns';

import AcmsVRatinger from '@ui/Ratinger.vue';
import BoxClientConnectionActions from '@boxes/client/BoxClientConnectionActions/BoxClientConnectionActions.vue';

const props = defineProps({
    emailCount: [Number, String],
    phoneCount: [Number, String],
    pqCount: [Number, String],
    followUpValue: [Date, String],
});

const emit = defineEmits([
    'emailClick', 'phoneClick', 'pqClick',
    'followUpClick', 'pnrClick', 'voicemailClick',
]);

const locState  = reactive({
    sendEmailModalShow:false,
    callLogsModalShow:false,
    clientRating: null,
    voicemail:false,
})

const getFollowUpState = computed(() => {
    const { followUpValue } = props;
    const configState = {
        icon: 'calendar-mark',
        classes:'text-primary-dark-op bg-gray-light'
    };
    if (followUpValue) {
        if (isAfter(new Date(), new Date(followUpValue))) {
            configState.icon = 'calendar-day';
            configState.classes = 'bg-danger-light text-danger';
        } else {
            configState.icon = 'calendar-set';
            configState.classes = 'bg-success-light text-success';
        }
    }
    return configState;
});
</script>
<style scoped>
.box-request-client-details-actions{
    gap: 11px;
}
</style>
