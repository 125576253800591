<template>
    <div class="field-select-fare-type  ">
        <acms-v-form-field
            label="Fare type"
            :modeSelect="{
                options:backendOptions.fareTypesOptions,
                outValueKey:'id'
            }"
            name="fare_type_id"
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            :errors="errors"
        />
    </div>
</template>

<script>
export default {
    name: "FieldSelectFareType"
}
</script>

<script setup="">
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({
    modelValue: [String, Number],
    errors: Object,
})
const {backendOptions} = useBackendMiddlewarePropsService()

</script>
<style scoped>

</style>
