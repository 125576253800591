import {computed, defineAsyncComponent} from 'vue';
import {MODES} from '@components/ui/FormField/constants';
import {startCase, camelCase} from 'lodash';

const componentsInFolder = ['Select'];
import Input from './elements/Input.vue'
import Datepicker from './elements/Datepicker.vue'
import InputWithDropdown from './elements/InputWithDropdown.vue'
import Quantity from './elements/Quantity.vue'
import Switcher from './elements/Switcher.vue'
import Textarea from './elements/Textarea.vue'
import TextEditor from './elements/TextEditor.vue'
import TimePicker from './elements/TimePicker.vue'
import Select from './elements/Select/Select.vue'

const fieldElementsComponents = {
    Input, Datepicker, InputWithDropdown, Quantity, Switcher, Textarea, TextEditor, TimePicker, Select
}
export default function ({props}) {
    const collectParamsForChildComponent = () => {
        const existMode = props.mode ? camelCase(props.mode).toLowerCase() : '';
        let params = {
            component: null,
            name: '',
            modeName: '',
        };

        const setParams = (componentName, name, modeName) => {
            // params.component =  defineAsyncComponent(() => import(/* @vite-ignore */ `./elements/${componentPath}`));
            params.component = fieldElementsComponents[componentName];
            params.name = name;
            params.modeName = modeName;
        };

        MODES.list.forEach(key => {
            const lowerKey = key.toLowerCase();
            const capitalKey = startCase(key).replaceAll(' ', '');
            const modePropName = 'mode' + capitalKey;

            if (props[modePropName] || existMode === lowerKey) {
                setParams(capitalKey, key, modePropName);
            }
        });

        if (!params.component) {

            setParams('Input', 'input', 'modeInput');
        }

        return params;
    };

    const childComponent = collectParamsForChildComponent();

    const getHint = computed(() => {
        const {errors, hint, hintValid, hintWarning, name} = props;
        const hasValidHint = hint && hintValid;
        const hasWarningHint = hint && hintWarning;

        const existErrors = typeof errors == "string" ? errors : errors?.[name]

        return {
            show: existErrors || hasValidHint || hasWarningHint,
            hintClass: existErrors ? 'invalid-feedback' :
                hasValidHint ? 'valid-feedback' :
                    hasWarningHint ? 'text-warning' : 'default-feedback',
            elementClass: existErrors ? 'is-invalid' :
                hasValidHint ? 'is-valid' :
                    hasWarningHint ? 'is-warning' : '',
        };
    });

    const getChildProps = computed(() => {

        const componentProps = props[childComponent.modeName];
        if (childComponent.modeName === 'modeSelect') {
            if (Array.isArray(componentProps)) {
                return {options: componentProps};
            }
        }
        if (childComponent.modeName === 'modeSwitcher') {
            if (typeof componentProps == 'string') {
                return {['type' + startCase(componentProps)]: true};
            }
        }
        return typeof componentProps == 'object' ? componentProps : {};
    });

    return {
        getHint,
        childComponent,
        getChildProps,

    };
}
