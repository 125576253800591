<template>
    <div class="acms-v-icon d-flex" :style="getColor.style" :class="getColor.classes" >
        <svg :height="size" :width="size" aria-hidden="true">
            <use :fill="getColor.color" :href="symbolId"/>
        </svg>
        <template v-if="quantity || quantityShow">
            <div class="acms-v-icon-quantity" :class="{'acms-v-icon-quantity--small':quantitySmall}">{{quantity}}</div>
        </template>
        <template v-if="$slots?.default">
            <div class="acms-v-icon-content ms-1">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AcmsVIcon',
};

</script>

<script setup>

import { computed, reactive } from 'vue';

const props = defineProps({
    prefix: {
        type: String,
        default: 'icon',
    },
    name: {
        type: String,
        default: 'no-icon',
    },
    color: {
        type: String,
    },
    size: {
        type: [String, Number],
        default: 16,
    },
    quantity:[String, Number],
    quantityShow:Boolean,
    quantitySmall:Boolean,
    disabled:Boolean,

});

const iconsKeysReplace ={
    'children' : 'child',
    'infants' : 'infant',
    'adults': 'adult'
}
const getCorrectName = computed(() =>{
    if(iconsKeysReplace?.[props.name]){
        return iconsKeysReplace[props.name]
    }
    return props.name
})

const symbolId = computed(() => `#${props.prefix}-${getCorrectName.value}`);

const getClass = computed(() => {
    return [props.className, `acms-v-icon-${props.name}`];
});

const getColor = computed(() => {
    const { color } = props;
    const iconColor = {
        style: '',
        color: '',
        classes: [`acms-v-icon-${props.name}`, {'opacity-50': props.disabled}],
    };
    if (color) {
        if (color.includes('#') || color.includes('rgb')) {
            iconColor.color = color;
            iconColor.style = { color };
        } else {
            iconColor.classes.push(color);
        }
    }
    return iconColor;
});

</script>

<style lang="scss">
.acms-v-icon{
    position: relative;
    width: max-content;
}
.acms-v-icon-search {
    position: absolute;
    right: 12px;
    top: 12px;
    bottom: 12px;
    height: 16px;
    width: 16px;
    display: flex;
}
.acms-v-icon-quantity{
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    background-color: #EA580C;
    border: 1px solid #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}
.acms-v-icon-quantity--small{
    width: 16px;
    height: 16px;
    line-height: 14px;
    top: -4px;
    right: -4px;
}
</style>
