import { defineStore } from 'pinia'


export const usePageRequestShowStore = defineStore('pageRequestEdit', {

    state: () => ({

        commonPQModalShow:false,

        revenuePQModalShow:false,
        awardPQModalShow:false,

        followUpCalendarShow: false,

        closeRequestModalShow:false,
        revenuePQDuplicateModalShow:false,
        awardExchangePQModalShow:false,
        requestPNRModalShow:false

    }),

    actions: {
        showAwardPQModal(bool=true,) {
            this.awardPQModalShow = bool
        },
        showRevenuePQModal(bool=true) {
            this.revenuePQModalShow = bool
        },

        showCommonPQModal(bool=true) {
            this.commonPQModalShow = bool
        },


        showFollowUp(bool=true) {
            this.followUpCalendarShow = bool
        },

        ////////
        showCloseRequestModal(bool=true) {
            this.closeRequestModalShow = bool
        },
        showRevenuePQDuplicateModal(bool=true) {
            this.revenuePQDuplicateModalShow = bool
        },
        showAwardExchangePQModal(bool=true) {
            this.awardExchangePQModalShow = bool
        },
        showRequestPNRModal(bool=true) {
            this.requestPNRModalShow = bool
        },

    },
})
