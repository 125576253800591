<template>
    <div class="box-linked-contact">
        <div class="linked-contacts-names mb-2">
            <BoxNames
                v-model="linkedContact.names"
                templateRow
                showMiddle
                toggleTitle="Contact with no name"
                :errors="errors"
            />
        </div>
        <div class="row g-3">
            <FieldSelectGender
                class="col-4"
                :errors="errors"
                v-model="linkedContact.gender_id"
            />
            <FieldSelectContactBy
                class="col-4"
                :errors="errors"
                v-model="linkedContact.contact_by_id"
            />
        </div>
        <div class="box-linked-contact-lists col-6 col-xl-4">
            <div class="linked-contacts-emails mb-2">

                <BoxEmailsList
                    v-model="linkedContact.emails"
                    :errors="errors"
                    :updatedData="modelValue?.email"
                />
            </div>
            <BoxPhoneNumbersAndBestTimeToCall
                v-model:phone-numbers="linkedContact.phone_numbers"
                :updated-phone-numbers="modelValue?.phone"
                v-model:best-time-to-call="linkedContact.best_time_to_call"
                :errors="errors"
            />
            <acms-v-form-field
                :modeSelect="{
                    options:$page.props?.optionsForSelect?.relationshipOptions ?? [],
                    outValueKey: 'id',
                    selectedOptionOnInit:{key: 'id', value: linkedContact?.pivot_type_id ?? null},
                }"
                label="Relationship"
                placeholder="Choose relationship"
                v-model="linkedContact.type_id"
            />
        </div>
            <acms-v-form-field
                :modeTextarea="{minHeight:96, resize:true}"
                placeholder="Enter notes here"
                label="Notes about client"
                v-model="linkedContact.notes"
            />
        <div class="col-4">
            <BoxFFPrograms
                v-model="linkedContact.ff_programs"
                initEmpty
                :errors="errors"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxLinkedContact',
};
</script>

<script setup="">
import {computed, onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
import {sampleLinkedContact } from '@demo/demoClient';

import BoxFFPrograms from './BoxFFPrograms.vue';
import BoxEmailsList from './BoxEmailsList.vue';
import BoxNames from './BoxNames.vue';
import {usePage} from "@inertiajs/vue3";
import FieldSelectGender from "@components/app/fields/FieldSelectGender.vue";
import FieldSelectContactBy from "@components/app/fields/FieldSelectContactBy.vue";
import {useBoxLinkedContactStore} from "@store/boxes/box-linked-contact-store";
import debounce from "@libs/debounce";
import BoxPhoneNumbersAndBestTimeToCall from "./BoxPhoneNumbersAndBestTimeToCall.vue";

const props = defineProps({
    modelValue:Object,
    errors:Object
})

const emit = defineEmits(['update:modelValue'])

const $page = usePage();

const BoxLinkedContactStore = useBoxLinkedContactStore()

const linkedContact = ref(null)

watch(linkedContact, (val)=>{
    emit('update:modelValue', val)

    debounce(()=>{
        BoxLinkedContactStore.setFormData(val)
    },100)()

}, {deep:true})
const getNames = computed(()=>{
    if(props.modelValue){
        const {first_name,middle_name,no_name, last_name } = props.modelValue
        return {first_name,middle_name,no_name, last_name}
    }
    return  null
})
onBeforeMount(()=>{
    if(props.modelValue){
        linkedContact.value = props.modelValue

    }else{
        linkedContact.value = sampleLinkedContact
    }
})

const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}
onMounted(()=>{
    window.onbeforeunload = alertYourChangesNotBeSaved
})
onUnmounted(()=>{
    window.onbeforeunload = null
})

</script>

<style scoped lang="scss">

.box-linked-contact-lists{
    @media only screen and (max-width: 1400px) {
        width: 50%;
    }
}


</style>
