function isJson (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const LocalStorageService  = {
      get:  (key) => {
        const item = localStorage.getItem(key);
        if (isJson(item)) {
            return JSON.parse(item);
        } else {
            return item;
        }
    },
      set :(key, value) =>{
        return localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    },

      remove: (key) => localStorage.removeItem(key),
};

export default LocalStorageService;
