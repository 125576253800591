export const NOT_FILLED_REQUEST_ALERT = {
    title: 'Request details missing',
    content: 'Your request is not finished! Exit?',
    exit:true,
}
export const NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT = {
    title: 'Request and linked contact details missing',
    content: 'Your request and linked contact is not finished!\n' +
        'Exit?',
    exit:true,
}
export const FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT = {
    title: 'Request details missing',
    content: 'Your request is not finished!\n' +
        'Save only linked contact?',
    exit:false,
}
