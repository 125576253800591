
export default function ( pushEvent = (listener) =>{} ){

        return {
            beforeMount (el, binding, vnode) {
                let vm = vnode.context;
                let callback = binding.value;

                const listenerClickEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        return callback.call(vm, event);
                    }

                };

                pushEvent(listenerClickEvent)

            },
            beforeUnmount(){

            }
        }
}


