<template>
    <acms-v-modal class="modal-client-call-center"
                  :show="show"
                  @hide="$emit('hide')"
                  size="large"
                  title="Make a call"
    >
        <template #header-right>
            <div class="client-time me-3">
                <img src="/images/phoneCodes/usa.png" alt="">
                <div class="text-success"> 4:38</div>
            </div>
            <div class="client-best-time-call me-4">
                02:30 AM - 11 PM
            </div>
        </template>
        <template #content>
            <div class="client-main-phone gap-3  d-flex align-items-center mb-5">
                <div class="w-100">
                    <acms-v-form-field
                        :modeSelect="demoPhonesList"
                        v-model="locState.mainPhone"
                        hide-hint
                    />
                </div>
                <div class="col white-space-nowrap">
                    <span class="text-warning">5 reveals left</span>
                </div>
                <div class="col ">
                    <acms-v-btn class="" icon-size="20" icon-color="text-primary-dark" icon="eye-off"  />
                </div>
                <div class="col ">
                    <acms-v-btn icon="phone" icon-size="20"
                                class="text-white bg-primary-dark-op p-1"/>
                </div>
            </div>
            <acms-v-table
                :data="demoClientCallsList"
                :columns="{
                'direction': 'Direction',
                'phone-number': 'Phone number',
                'call-id': 'Call ID',
                'date': 'Date',
                'time': 'Time',
                'play-call': 'Play call',
                'responded': ' '
                }"
                :columns-width="{
                'direction': '102px',
                'phone-number': '215px',
                'call-id': '205px',
                'date': '165px',
                'time': '225px',
                'play-call': '180px',
                'responded': '64px'
                }"
                :columns-title-class="{
                    'time': 'justify-content-end'
                }"
                :columns-cell-class="{
                    'time': 'justify-content-end'
                }"
            >
                <template #bodyCell-direction="{item}">
                    <acms-v-badge :title="item.direction"/>
                </template>
                <template #bodyCell-phone-number="{item}">
                    {{ item.phone }}
                </template>
                <template #bodyCell-call-id="{item}">
                    {{ item.call_id }}
                </template>

                <template #bodyCell-date="{item}">
                    {{ item.date }}
                </template>
                <template #bodyCell-time="{item}">
                    {{ item.time }}
                </template>
                <template #bodyCell-play-call="{item}">
                    <AcmsVAudioPlayer
                        :file="item.audio_record"
                        file-type="audio/mpeg"
                        players-group-id="a-new-multiple-id"
                        @updateFixedPlayer="fixedPlayer = $event"
                    />
                </template>
                <template #bodyCell-responded="{item}">
                    <acms-v-icon v-if="!item?.responded" size="24" name="phone-slash" color="text-danger"/>
                </template>
            </acms-v-table>
            <div class="d-flex w-100 justify-content-center mt-4  ">
                <acms-v-btn class="btn-sm btn-border btn-all-logs" title="All logs"></acms-v-btn>
            </div>
            <AcmsVAudioPlayerFixedBottom
                players-group-id="a-new-multiple-id"
                :player="fixedPlayer"
            />
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: 'ModalClientCallLogs',
};
</script>
<script setup="">

import { demoClientCallsList, demoPhonesList } from '@demo/demoPageRequestShow';
import { reactive, ref } from 'vue';
import AcmsVAudioPlayer from '@ui/AudioPlayer/AudioPlayer.vue';
import AcmsVAudioPlayerFixedBottom from '@ui/AudioPlayer/AudioPlayerFixedBottom.vue';
const props = defineProps({
    show: Boolean,
});
const emit = defineEmits(['update:modelValue', 'hide']);
const locState = reactive({
    mainPhone: '+1 (873)*****24',
});
const fixedPlayer = ref({})
</script>
<style lang="scss">
.modal-client-call-center {
    .client-time {
        align-items: center;
        display: flex;
        img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 4px;
        }
    }
}
.dark-mode{
    .acms-v-btn.btn-all-logs{
        color: #fff;
    }
}
</style>
