<template>
    <div class="acms-v-multi-row-crud  ">
        <div class="acms-v-multi-row-crud-list  ">
            <template v-for="(item, key) in locState.rowsList">
                <div class="acms-v-multi-row-crud-item" :class="getItemClass(item)">
                    <template v-if="item._rowStatus=='opened'" :key="key">
                        <div class="acms-v-multi-row-crud-item-opened  ">
                            <slot name="opened-header"
                                  :itemNumber="key+1"
                                  :item="item"
                                  :itemKey="key"
                                  :removeRow="(showAlert)=>tryRemoveRow(key,showAlert)"
                                  :itemsLength="locState.rowsList.length"
                            >
                            </slot>
                            <slot name="opened"
                                  :number="key+1"
                                  :item="item"
                                  :itemsLength="locState.rowsList.length"
                                  :key="key">
                            </slot>

                            <acms-v-btn
                                class="btn-primary ms-auto me-auto mb-4"
                                v-if="item?.id || (item?._rowEdit && hideAddRowButton())"
                                title="Update"
                                @click="()=>updateRow(key)"
                            />
                        </div>
                    </template>
                    <template v-if="item._rowStatus=='closed'" :key="key">
                        <div class="acms-v-multi-row-crud-item-closed  d-flex align-items-center  ">
                            <slot name="closed"
                                  :key="key"
                                  :number="key+1"
                                  :item="item"></slot>
                            <div class="acms-v-multi-row-crud-item-actions ms-auto d-flex bg-gray-light action-block-rad">
                                <acms-v-btn v-if="!item?.id"  icon="pen" color="text-primary-darker"
                                            class="bg-gray-light "
                                            style="padding: 7px"
                                            @click="()=>editRow(key)"
                                />
                                <acms-v-btn  v-if="!item?.id" icon="close"   color="text-primary-darker"
                                            class="bg-gray-light"
                                            style="padding: 7px"
                                             @click="()=>removeRow(key)"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div class="d-flex justify-content-center">
            <acms-v-btn
                v-if="locState.addRowButtonShow"
                :title="addTitle"
                class="btn-outline-primary   ms-auto me-auto mt-4 mb-4"
                icon="plus"
                @click="()=>addRow()"
            />
        </div>
        <AlertDelete v-if="alertDeleteType"
                     v-model:show="locState.deleteAlertShow"
                     :type="alertDeleteType"
                     @delete="removeRowByAlert"
        />
    </div>
</template>

<script>
export default {
    name: 'AcmsVMultiRowCrud',
};
</script>

<script setup="">

import {computed, nextTick, onBeforeMount, onMounted, reactive, watch} from 'vue';
import {cloneDeep} from 'lodash';
import {useForm} from "@inertiajs/vue3";
import AlertDelete from "@alerts/AlertDelete.vue";

const emit = defineEmits([
    'update:modelValue', 'removeItem', 'createItem',
    'updateItem', 'editItem',
    'cancel', 'removeOpenedItem',

    'addCleanItem',
    'saveAllItems',
    'initList'
]);

const props = defineProps({
    modelValue: Array,
    items: Array,
    addTitle: String,

    itemSample: Object,

    emptyState: Boolean,
    triggerSaveAllRows: Boolean,
    alertDeleteType: String,
});
const locState = reactive({
    deleteAlertShow: null,
    rowsList: [],
    addRowButtonShow: true,
});

const prepareRow = (item) => {
    item._rowEdit = false

    if (item?._rowStatus) {
        return item;
    } else {
        item._rowStatus = 'opened';
        return item;
    }
};

const prepareRowsOnInit = () => {
    if (!props.emptyState) {
        if (props.items?.length > 0) {
            locState.rowsList = props.items.map(prepareRow)
        } else {
            addRow()
        }
    }
};

const getItemClass = (item) => {
    return {
        'opened': item._rowStatus == 'opened',
        'closed': item._rowStatus == 'closed',
    };
};

const getSample = () => useForm(cloneDeep(props.itemSample));

watch(()=>props.items, (itemsVal) => {
    locState.rowsList = itemsVal;
}, {deep: true});

const appendCleanRow = () => {
    emit('addCleanItem', {item: prepareRow(getSample())}, {});
}

const removeRowByAlert = (index = -1) => {

    let removeIndex = null
    if (index > -1) {
        removeIndex = index
        locState.rowsList?.splice(index, 1)
    }

    if (locState.deleteAlertShow?.index > -1) {
        removeIndex = locState.deleteAlertShow?.index
        locState.deleteAlertShow = null
    }

    emit('removeOpenedItem', {
        removeIndex,
        afterRemoveRow() {
            locState.addRowButtonShow = true
        },
        openRow
    })
}

const hideAddRowButton = () => {
    locState.addRowButtonShow = false
    return true
}

const tryRemoveRow = (index, showAlert) => {
    if (showAlert) {
        locState.deleteAlertShow = {index}
    } else {
        removeRowByAlert(index)
    }
}

const collapseRow = (item) => {
    locState.addRowButtonShow = true
    item._rowStatus = 'closed'
    item._rowEdit = false;

    return item
}

const addRow = (close = false) => {
    if (locState.rowsList?.length > 0) {
        locState.rowsList.forEach((item, index) => {
            if (item._rowStatus != 'closed') {
                emit('createItem', {item, index},
                    {
                        closeRow: (item) => new Promise(resolve => {
                            item = collapseRow(item)
                            resolve({item})
                        }),
                        collapseRow: collapseRow,
                        appendRow: appendCleanRow,
                    });
            }
        });
        nextTick(() => {
            if (locState.rowsList.every(item => item._rowStatus == 'closed')) {
                appendCleanRow()
            }
        })
    } else {
        appendCleanRow()
    }

};

const closeRow = (index) => () => {
    return new Promise(resolve => {
        locState.rowsList[index]._rowStatus = 'closed';
        locState.addRowButtonShow = true
        resolve({item: locState.rowsList[index],})
    })
}

const findAndCloseOpenedRow = (index) => {
    const findOpenedRowIndex = locState.rowsList.findIndex(it => it._rowStatus == 'opened')

    if (findOpenedRowIndex > -1) {
        if (index !== findOpenedRowIndex) {
            locState.rowsList[findOpenedRowIndex]._rowStatus = 'closed';
            locState.rowsList[findOpenedRowIndex]._rowEdit = false;
            return {index: findOpenedRowIndex, item: locState.rowsList[findOpenedRowIndex]}
        }
    }
    return false
}

const openRowByIndex = (index) => {

    const item = locState.rowsList[index];

    if (item._rowStatus != 'opened') {
        locState.rowsList[index]._rowStatus = 'opened';
        locState.rowsList[index]._rowEdit = true;
    }

    return locState.rowsList[index]
}

const openRow = (index, closeOpened = false) => {
    if (closeOpened) {
        return {
            openRowByIndex,
            findAndCloseOpenedRow
        }
    }
    return openRowByIndex(index)
}

const editRow = (index) => {
    let existUnsavedRow = false
    const emitEdit = (item, index) => {
        emit('editItem', {item, index},
            {
                closeRow: () => ({
                    item,
                    closePrevRow: () => collapseRow(item),
                    openSelectedRow: () => {
                        locState.rowsList[index]._rowStatus = 'opened';
                        locState.rowsList[index]._rowEdit = true;
                    }
                }),
                appendRow: () => appendCleanRow,
            });
    }

    locState.rowsList = locState.rowsList.map((item, currentIndex) => {
        if (item?._rowStatus != 'closed') {
            existUnsavedRow = true
            emitEdit(item, index)
        }
        return item;
    });

    if (!existUnsavedRow) {
        emitEdit(locState.rowsList[index], index)
    }
};

const removeRow = (index) => {
    locState.addRowButtonShow = true
    emit('removeItem',
        {
            item: locState.rowsList[index],
            index,
        },
        {
            removeRow: () => {
                locState.rowsList.splice(index, 1)
                if (!props.emptyState && locState.rowsList.length == 0) {
                    appendCleanRow()
                }
            }
        },
    );
};

const updateRow = (index, close = true) => {
    emit('updateItem', {item: locState.rowsList[index], index}, {
        closeRow: () => closeRow(index),
        collapseRow,
        openRow,
    });
};

const cancel = (index = null) => {
    const clone = cloneDeep(locState.rowsList[index])
    delete clone['_rowStatus']
    emit('cancel', {item: index > -1 ? clone : null, index});

}

watch(() => props.triggerSaveAllRows, (triggered) => {
        if (triggered) {

            const findOpenedItemIndex = locState.rowsList.findIndex(i => i?._rowStatus == 'opened')
            const openedItem = findOpenedItemIndex > -1 ? locState.rowsList[findOpenedItemIndex] : null
            emit('saveAllItems', {
                list: locState.rowsList,
                openedItem,
                openedItemIndex: openedItem ? findOpenedItemIndex : null
            }, {
                editRow, collapseRow, openRow, closeRow: ()=>closeRow(findOpenedItemIndex)
            })
        }
    },
);

onBeforeMount(() => {
    prepareRowsOnInit();
});

onMounted(() => {
    emit('initList', locState.rowsList)
})
</script>

<style lang="scss">
.acms-v-multi-row-crud-item {
    padding: 24px 0 0;
    margin: 0 20px;

    &.closed {
        padding: 24px 0;
    }
    &:not(:last-child) {
        &.closed {
            border-bottom: 1px solid #CED4DA;
        }
    }
}
.acms-v-multi-row-crud-item-opened {
    position: relative;
    .acms-v-multi-row-crud-item-actions {
        position: absolute;
        right: 0;
    }
}
</style>
