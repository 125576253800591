<template>
    <div class="box-sale-additions">
        <div class="box-sale-additions-item" v-for="item in locState.items">
            <div class="box-sale-additions-item-title text-primary-dark mb-4">
                {{ item.title }}
            </div>
            <div class="box-sale-additions-item-content row">
                <div class="col-3">
                    <acms-v-form-field
                        label="Pax name"
                        v-model="item.pax_name"
                    />
                </div>
                <div class="col-3">
                    <acms-v-form-field
                        label="Ticket price, $"
                        v-model="item.ticket_price"
                    />
                </div>
                <div class="col-3">
                    <acms-v-form-field
                        label="Cost, $"
                        v-model="item.cost"
                    />
                </div>
                <div class="col-3">
                    <acms-v-form-field
                        label="Basic, %"
                        v-model="item.basic"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxSaleAdditions',
};
</script>
<script setup="">
import { reactive } from 'vue';

const props = defineProps({});
const locState = reactive({
    items: [
        {
            title: 'Travel protection service',
            pax_name: 'Gomez Elizabeth',
            ticket_price: '912,30',
            cost: '91,30',
            basic: '12',
        },
        {
            title: 'Bus transfer',
            pax_name: 'Gomez Elizabeth',
            ticket_price: '912,30',
            cost: '91,30',
            basic: '12',
        },
    ],
});
</script>
<style lang="scss">
.box-sale-additions-item {
    &:not(:last-child) {
        margin-bottom: 36px;
    }
}
.box-sale-additions-item-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}
</style>
