<template>
    <acms-v-dropdown
        :options="getOptions"
        class="me-3"
        toggle-class="btn-border btn-sm bg-gray-lighter"
        title="Next actions"
        @select="nextActionsSelectHandle"
    />
</template>

<script>
export default {
    name: "ElementDropdownNextActions"
}
</script>

<script setup="">
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import {computed} from "vue";
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({})
const pageRequestShowStore = usePageRequestShowStore()
const {
    showFollowUp,
    showCloseRequestModal,
} = pageRequestShowStore
const {backendPermissions} = useBackendMiddlewarePropsService()

const getOptions = computed(()=>{

    const array = [ 'Set Follow-up Date/Time', 'Send to Experts']


    if(backendPermissions?.allowCloseRequest){
        array.unshift('Close Request')
    }

    return array
})

const nextActionsSelectHandle = (option) => {
    if (option == 'Close Request') {
        showCloseRequestModal?.();
    }
    if (option == 'Set Follow-up Date/Time') {
        showFollowUp?.()
    }
};

</script>
<style scoped>

</style>
