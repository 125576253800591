<template>
    <acms-v-modal title="Add PNR"
                  :show="show"
                  @hide="$emit('hide')"
                  size="super-large"
    >
        <template #content>

            <div class="row">
                <acms-v-form-field
                    modeTextarea
                    label="Code"
                    name="code"
                    v-model="pnrData.pnrCode"
                />
            </div>
            <div class="flex-column d-flex mb-4">
                <div class="col-2">
                    <acms-v-form-field
                        label="PNR"
                        v-model="locState.pnr"
                    />
                </div>
                <div class="row">
                    <template v-for="item in locState.recordLocators">
                        <div class="col-2">
                            <acms-v-label label="Record locator"/>
                            <div class="d-flex">
                                <acms-v-select
                                    :style="{width: '100px'}"
                                    v-model="item.type"
                                    :options="[{title: 'TK', id: 1}, {title: 'AA', id: 2}]"
                                />
                                <acms-v-input
                                    v-model="item.code"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <acms-v-table
                :data="pnrData.passengersTable"
                show-number
                content-class="table-bordered"
                :columns="{
                        ptc:'PTC',
                        first_name:'First Name',
                        middle_name:'Middle Name',
                        last_name:'Last Name',
                        dob:'Dob',
                        gender:'Gender',
                        passport: 'Passport #',
                        expire: 'Expire',
                        issuing_country: 'Issuing country',
                        nationality: 'Nationality',
                        mileage: 'Mileage',
                        redress: 'Redress',
                        ktn: 'Ktn',
                        verification:'Verification',
                        action:''
                  }"
                :columns-width="{
                     ptc:'120px',
                     verification:'150px',
                     action:'50px',
                     issuing_country: '150px',
                     nationality: '150px',
                }"
                :dynamic-row-class="setDynamicClassForRow"
            >
                <template #bodyCell-ptc="{item}">
                    <acms-v-select
                        :options="['Child', 'Infant', 'Adult']"
                        placeholder="Select"
                        v-model="item.ptc"
                    />
                </template>
                <template #bodyCell-first-name="{item}">
                    <acms-v-form-field
                        v-model="item.first_name"
                        :elementClass="item?.error_keys?.includes('first_name') ? 'border-danger' : ''"
                        hide-hint
                        :hintIcon="item?.error_keys?.includes('first_name') ? 'exclamation' : null "
                        hint-icon-class="text-danger"
                        hintIconSize="12px"
                    />
                </template>
                <template #bodyCell-middle-name="{item}">
                    <acms-v-input
                        v-model="item.middle_name"
                    />
                </template>
                <template #bodyCell-last-name="{item}">
                    <acms-v-input
                        v-model="item.last_name"
                    />
                </template>
                <template #bodyCell-dob="{item}">
                    <acms-v-input
                        v-model="item.dob"
                    />
                </template>
                <template #bodyCell-gender="{item}">
                    <acms-v-select
                        :options="genderOptions"
                        placeholder="Select"
                        v-model="item.gender"
                    />
                </template>
                <template #bodyCell-passport="{item}">
                    <acms-v-input
                        v-model="item.passport"
                    />
                </template>

                <template #bodyCell-expire="{item}">
                    <acms-v-input
                        v-model="item.expire"
                    />
                </template>

                <template #bodyCell-issuing-country="{item}">
                    <acms-v-select
                        :options="['United States', 'Germany', 'India']"
                        placeholder="Select"
                        v-model="item.issuing_country"
                    />
                </template>
                <template #bodyCell-nationality="{item}">
                    <acms-v-select
                        :options="['United States', 'Germany', 'India']"
                        placeholder="Select"
                        v-model="item.nationality"
                    />
                </template>

                <template #bodyCell-mileage="{item}">
                    <acms-v-input
                        v-model="item.mileage"

                    >
                        <template #before>LH</template>
                    </acms-v-input>
                </template>

                <template #bodyCell-redress="{item}">
                    <acms-v-input
                        v-model="item.redress"
                    />
                </template>
                <template #bodyCell-ktn="{item}">
                    <acms-v-input
                        v-model="item.ktn"
                    />
                </template>
                <template #bodyCell-verification="{item}">

                    <template v-if="item.data_status == 'verified'">
                        <acms-v-btn class="btn btn-sm btn-alt-success  b-r-1 text-center"
                                    style="width: 120px;"
                                    icon-size="12"
                                    icon="exclamation">Verified
                        </acms-v-btn>
                    </template>

                    <template v-else-if="item.data_status == 'not-verified'">
                        <acms-v-btn class="btn btn-sm bg-gray-light b-r-1  text-center"
                                    style="width: 120px;"
                                    icon-size="12"
                                    icon="exclamation">Not Verified
                        </acms-v-btn>
                    </template>
                    <template v-else>
                        <acms-v-btn class="btn btn-sm btn-danger pe-4 ps-4 b-r-1 text-center white-space-nowrap"
                                    style="width: 120px;"
                                    icon-size="12"
                                    icon="exclamation">Check data
                        </acms-v-btn>
                    </template>
                </template>
                <template #bodyCell-action="{item}">
                    <acms-v-btn class="btn btn-sm  text-black  text-center"
                                icon="close"
                                style="height: 28px"
                                :class="item.data_status == 'check data' ? 'bg-danger-lighty' : 'bg-gray-light'"
                                @click="removeItemHandle(item)"
                    />
                </template>
            </acms-v-table>
            <div class="prn-passengers-actions  d-flex mt-4">
                <acms-v-btn title="Select from existing"  class="btn-sm btn-alt-secondary me-3 ms-auto" />
                <acms-v-btn title="Add new passenger" @click="addPassengerHandle" icon="plus" class="btn-sm btn-outline-primary" />
            </div>
        </template>
        <template #footer-buttons="{hideHandle}">
            <acms-v-btn
                @click="$emit('hide')"
                title="Cancel"
                class="btn-border"
            />
            <acms-v-btn
                title="Verify passenger data"
                class="btn-success"
            />
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: 'ModalAddPnr',
};
</script>

<script setup="">
import { onMounted, reactive, watch } from 'vue';
import { genderOptions } from '@demo/demoOptionsForSelect';
import AcmsVFormField from '@ui/FormField/FormField.vue';
import AcmsVSelect from "@ui/FormField/elements/Select/Select.vue";

const props = defineProps({
    show: Boolean,
    data: Object,
});

const emit = defineEmits(['update:modelValue', 'hide'])

const pnrData = reactive(props.data);

const locState = reactive({
    pnr:'123GK',
    recordLocators: [
        {type: 1, code: 'ZB123M'},
        {type: 2, code: 'ZB123M'},
    ],
})

const removeItemHandle = (item) => {
    const index = pnrData.passengersTable.findIndex(i => i.id == item.id);
    if (index > -1) {
        pnrData.passengersTable.splice(index, 1);
    }
};

const setDynamicClassForRow  = (item) => {
    if(item.data_status == 'check data'){
        return 'acms-v-table-row--danger'
    }
}
const addPassengerHandle = () => {
    const item = {
            id:'100',
            ptc:' ',
            first_name:' ',
            middle_name:' ',
            last_name:' ',
            dob:' ',
            gender:' ',
            passport: ' ',
            expire: ' ',
            issuing_country: ' ',
            nationality: ' ',
            mileage: ' ',
            redress: ' ',
            data_status: ' ',
            error_keys: ' '
        };
        pnrData.passengersTable.push(item)
        emit('update:data', pnrData)
}




</script>

<style  >

.bg-danger-lighty{
    background-color: #ECC9C9;
}
</style>
