<template>
    <div class="partial-linked-contact-table">
        <acms-v-table class="p-4"
                      v-bind="CLIENT_LINKED_CONTACTS_TABLE_SETTINGS"
                      @editAction="$emit('edit', $event)"
                      @deleteAction="$emit('delete', $event)"
                      :actions="getTableActions"
                      :data="linkedContacts.data"
                      :links="linkedContacts.meta.links"
                      v-model:page="tableForm.page"
                      @update:sort="tableSortHandle"
                      :search="tableForm.search"
                      :entriesLimit="linkedContacts.meta?.per_page"
                      :currentPage="linkedContacts.meta?.current_page"
        >
            <template #header>
                <AcmsVTableHeader
                    config="search,entries,add"
                    v-model:search="tableForm.search"
                    v-model:entries="tableForm.limit"
                    addTitle="Add contact"
                    @add="$emit('add')"
                />
            </template>
            <template #bodyCell-name="{item}">
                <button  @click="$emit('edit', item)"
                      class="text-primary fw-semibold text-start text-capitalize row-fullname-ellipsis">
                    {{ getFullName(item) }}
                </button>
            </template>
            <template #bodyCell-email="{item}">
                <span>{{ getHiddenEmail(item.email) }}</span>
            </template>
            <template #bodyCell-phone="{item}">
                <span class="text-uppercase">{{ getHiddenPhone(item.phone) }}</span>
            </template>
            <template #bodyCell-type-id="{item}">
                {{ getClientRelationShipString(item?.pivot_type_id) }}
            </template>
        </acms-v-table>
    </div>
</template>

<script>
export default {
    name: "PartialLinkedContactsTable"
}
</script>

<script setup="">
import {CLIENT_LINKED_CONTACTS_TABLE_SETTINGS} from "../constants/tablesAndTabsSettings";
import {
    getClientRelationShipString,
    getFullName, getHiddenEmail, getHiddenPhone,

} from "@helpers/commonHelpers";
import useTableSortable from "@ui/Table/useTableSortable";
import {getRoute} from "@plugins/useRoutes";
import AcmsVTableHeader from "../../../../ui/Table/partials/TableHeader.vue";
import {computed} from "vue";
import {useBackendMiddlewarePropsService} from "@services";

const emit = defineEmits(['edit', 'add', 'delete'])
const props = defineProps({
    linkedContacts: Object,
    clientId: [String, Number],
})

const {backendPermissions} = useBackendMiddlewarePropsService()

const {tableForm, tableSortHandle: tableSortHandle} = useTableSortable(
    {
        route: getRoute('clients.getClientCollections', {client: props.clientId}),
        sendParams: {
            sendType: 'post',
            only: ['linkedContacts']
        }
    })


const getTableActions = computed(() => {
    let actions = "email,phone,edit"

    if (backendPermissions?.allowDeleteLinkedContact) {
        actions = actions + ',delete'
    }
    return actions
})



</script>
<style scoped>

</style>
