<template>
    <div class="acms-v-form-field "
         :data-v-form-field="childComponent.name"
         :class="[{'acms-v-form-field--disabled': disabled}, {'acms-v-form-field--with-icon': withIcon}]"
         :data-name="name"
    >
        <template v-if="label">
                <acms-v-label :label="label" :after="labelAfter" :classes="labelClasses" :required="required"/>
        </template>
        <template v-if="!$slots?.custom">
            <div class="acms-v-form-field-wrap d-flex">
                <template v-if="childComponent.name.length > 0">
                    <component :is="childComponent.component"
                               v-bind="getChildProps"
                               class="acms-v-field-element"
                               :class="getHint.elementClass"
                               v-model="locState.value"
                               v-on="ELEMENT_LISTENERS(childComponent.name,$emit)"
                               data-v-form-element
                               :required="required"
                               :readonly="readonly"
                               :disabled="disabled"
                               :placeholder="placeholder"
                               :name="name"
                               :type="type"
                               :elementClass="elementClass"
                    >
                    </component>
                    <template v-if="hintWarning">
                        <acms-v-icon name="exclamation"  class="hint-warning-icon" color="text-warning"/>
                    </template>
                </template>
                <slot name="right"></slot>
                <template v-if="withIcon">
                    <div class="acms-v-form-field-right-icon input-group-text">
                        <acms-v-icon :class="iconClass" :name="icon"></acms-v-icon>
                    </div>
                </template>
                <template v-if="hintIcon">
                    <acms-v-icon class="acms-v-form-field-hint-icon" :name="hintIcon"
                                 :size="hintIconSize" :class="hintIconClass" />
                </template>
            </div>
            <template v-if="getHint.show && !hideHint">
                <AcmsVHint
                           v-bind="{errors, hint, hintValid, hintWarning, name, hintClass: getHint.hintClass}"
                />
            </template>
            <template v-else>
                <div v-if="!hideHint && !hideHintInit" style="min-height: 16px"></div>
            </template>
        </template>

        <slot name="custom"></slot>
        <template v-if="debug">
            <br>
            <pre>{{ locState.value }}</pre>
        </template>
    </div>
</template>
<script>
export default {
    name: 'AcmsVFormField',
};
</script>

<script setup>
import {onMounted, reactive, watch } from 'vue';
import fieldProps from './field.props';
import FieldComputed from './field.computed';
import { ELEMENT_LISTENERS } from '@components/ui/FormField/constants';
import AcmsVHint from './partials/Hint.vue'
const emit = defineEmits(['update:modelValue', 'onInput', 'clickOnDropdownOption']); //todo  need add current events
const props = defineProps(
    {
        ...fieldProps,
        labelAfter: String,
        defaultValue : [String, Number, Object],
    }
);

const locState = reactive({
    value: props.modelValue ? props.modelValue : props.defaultValue,
});

watch(() => locState.value, (val) => {
    emit('update:modelValue', val);
});
watch(() => props.modelValue, (val) => {
    locState.value = val
});

const {
    childComponent,
    getChildProps,
    getHint,
    getProps,
} = FieldComputed({ props });

</script>
<style lang="scss">
@import "style";
</style>
