<template>
    <acms-v-modal :show="show"
                  @hide="$emit('hide')"
                  title="Check passenger data"
    >
        <template #content>
            <table class="table table-striped table-vcenter  ">
                <thead>
                <tr>
                    <th>Pax 1</th>
                    <th>Original</th>
                    <th>OAF</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="([key,title]) in Object.entries(TABLE_KEYS)">
                    <tr class="position-relative"
                        :class="getRowClass(key)"
                    >
                        <td>{{ title }}</td>
                        <td>
                            <template v-if="locState.dangerRowsToCheck.includes(key)">
                                <acms-v-switcher
                                    typeRadio
                                    :title="locState.original[key]"
                                    :name="key"
                                    @update:modelValue="(e)=>radioHandle(e, key, locState.original[key])"
                                />
                            </template>
                            <template v-else>
                                {{ locState.original[key] }}
                            </template>
                        </td>
                        <td>
                            <template v-if="locState.dangerRowsToCheck.includes(key)">
                                <acms-v-switcher
                                    typeRadio
                                    :title="locState.oaf[key]"
                                    :name="key"
                                    @update:modelValue="(e)=>radioHandle(e, key, locState.oaf[key])"
                                />
                            </template>
                            <template v-else>
                                {{ locState.original[key] }}
                            </template>
                                <acms-v-icon v-if="locState.rowsClasses?.[key] == 'success'"
                                    class="table-row-icon text-success" name="circle-check"  ></acms-v-icon>
                            <acms-v-icon v-if="locState.rowsClasses?.[key] == 'danger'"
                                         class="table-row-icon text-danger" name="exclamation"  ></acms-v-icon>
                        </td>
                    </tr>
                </template>
                <tr>
                </tr>
                </tbody>
            </table>
        </template>
        <template #footer-buttons="{hideHandle}">
            <acms-v-btn
                @click="$emit('hide')"
                title="Cancel"
                class="btn-border"
            />
            <acms-v-btn
                title="Save"
                class="btn-primary"
            />
        </template>
    </acms-v-modal>
</template>

<script>
export default {
    name: 'ModalCheckPassengers',
};
</script>
<script setup="">
import { reactive } from 'vue';
import AcmsVSwitcher from '@ui/FormField/elements/Switcher.vue';

const props = defineProps({
    show: Boolean,
    data: Object,
});

const TABLE_KEYS = {
    first_name: 'First name',
    middle_name: 'Middle name',
    last_name: 'Last name',
    dob: 'DOB',
    ptc_type: 'PTC type',
    gender: 'Gender',
    passport: 'Passport #',
    expiration: 'Expiration',
    issuing_country: 'Issuing country',
    nationality: 'Nationality',
    mileage_airline: 'Mileage airline',
    mileage_number: 'Mileage number',
    redress_number: 'Redress number',
    email: 'Email',
};

const locState = reactive({
    rowsClasses:{},

    dangerRowsToCheck: ['first_name', 'dob', 'ptc_type', 'nationality'],
    successRows: {},
    warningRows: [],

    original: {
        first_name: 'Johny',
        middle_name: 'Smith',
        last_name: 'Sins',
        dob: '04/22/1965',
        ptc_type: 'infant',
        gender: 'Male',
        passport: '5936465452',
        expiration: '11/2024',
        issuing_country: 'United States',
        nationality: 'India',
        mileage_airline: 'LH',
        mileage_number: '123423453',
        redress_number: '12121321',
        email: 'ad****@gmail.com',
    },
    oaf: {
        first_name: 'John',
        middle_name: 'Smith',
        last_name: 'Sins',
        dob: '04/22/1965',
        ptc_type: 'child',
        gender: 'Male',
        passport: '5936465452',
        expiration: '11/2024',
        issuing_country: 'United States',
        nationality: 'United States',
        mileage_airline: 'LH',
        mileage_number: '123423453',
        redress_number: '12121321',
        email: '-',
    },
});



const getRowClass = (key) => {
    if(Object.keys(locState.successRows).includes(key)){
        locState.rowsClasses[key] = 'success'
        return 'acms-v-table-row--success'
    }
    if(locState.dangerRowsToCheck.includes(key)){
        locState.rowsClasses[key] = 'danger'
        return 'acms-v-table-row--danger'
    }
    if(Object.keys(locState.warningRows).includes(key)){
        locState.rowsClasses[key] = 'warning'
        return 'acms-v-table-row--warning'
    }
}


const radioHandle = (e, key, value) =>{
    locState.successRows[key] = value
}

</script>

<style scoped>
.acms-v-switcher {
    margin-left: -8px;
    padding-left: 0px;
    height: unset;
}
.table-row-icon{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    align-items: center;
    margin: auto
}
</style>
