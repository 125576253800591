<template>
    <div class="entity-request-client-info-table">
        <acms-v-table
            v-bind="tableProps"
            :data="dataObject.data"
            :links="dataObject?.meta?.links"
            v-model:page="tableForm.page"
            :empty-state-title="emptyStateTitle"

            @editAction="$emit('edit', $event)"
            @deleteAction="$emit('delete', $event)"
        >
            <template #bodyCell-name="{item}">
                <div :title="getFullName(item)" class="text-primary  fw-semibold cursor-pointer row-fullname-ellipsis"
                     @click="$emit('edit', item)">{{ getFullName(item) }}
                </div>
            </template>
            <template #bodyCell-email="{item}">

                <div class="text-end"
                     v-tooltip="{list:getHiddenEmail(item.email,true)}">{{ getHiddenEmail(item.email ) }}
                </div>
            </template>
            <template #bodyCell-phone="{item}">
                <div class="text-end" v-tooltip="{list: getHiddenPhone(item.phone,true  )}">
                    {{ getHiddenPhone(item.phone) }}
                </div>
            </template>
            <template #bodyCell-type="{item}">
                <div class="text-end">{{
                        isMainClient ? 'Client' : getClientRelationShipString(item?.pivot_type_id)
                    }}
                </div>
            </template>
            <template #bodyCell-status="{item}">
                <acms-v-badge :title="item.status"/>
            </template>
        </acms-v-table>
    </div>
</template>

<script>
export default {
    name: "EntityRequestContactInfoTable"
}
</script>
<script setup="">
import {
    getClientRelationShipString,
    getFullName, getHiddenEmail, getHiddenPhone,
} from "@helpers/commonHelpers";
import useTableSortable from "@ui/Table/useTableSortable";
import {getRoute} from "@plugins/useRoutes";

const props = defineProps({
    tableProps: Object,
    dataObject: Object,

    hasPaginate: Boolean,
    emptyStateTitle: String,

    currentRequestId: [Number, String],
    isMainClient: Boolean,
})
const {tableForm,} = useTableSortable(
    {
        route: getRoute('requests.getRequestCollections', {'clientRequest': props.currentRequestId}),
        sendParams: {
            sendType: 'post',
            only: ['linkedContactsRest']
        }
    })
</script>

<style scoped>

</style>
