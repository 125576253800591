import {reactive, defineEmits} from "vue";
import {router} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

export default function (params = {}) {
    const {
        props, methods = {
            afterCancel: () => {
            }
        }
    } = params
    const AlertsManager = useAlertsManagerStore()

    const linkedContactsState = reactive({
        modalDelete: null,
        modalAlert: false,
        list: [],
        formCreateShow: false,
        itemForm: null,

        formEditShow: false,
        formEditData: null,

        tableSearch: '',
        tableCount: 20,

        saveTrigger: false,
        saveAndExitTrigger: false,
        cancelTrigger: false,

    });

    const linkedContactsHandlers = {
        create() {
            linkedContactsState.formCreateShow = true
        },

        cancel(emitObject = {}) {
            if (emitObject?.isDirty) {
                linkedContactsState.modalAlert = true;
            } else {
                linkedContactsState.modalAlert = false;
                linkedContactsState.formCreateShow = false;
                linkedContactsState.formEditShow = false;
                linkedContactsState.formEditData = null

                linkedContactsState.cancelTrigger = true;
                setTimeout(() => {
                    linkedContactsState.cancelTrigger = false;
                }, 500);
            }

            methods.afterCancel()
        },
        wantsToDelete(...args) {
            linkedContactsState.modalDelete = args
        },
        delete() {

            if (!linkedContactsState.modalDelete) {
                return
            }

            const [item] = linkedContactsState.modalDelete

            if (!item) {return;}

            const {id} = item

            if (id) {
                router.delete(getRoute('clients.delete', id), {
                    preserveScroll: true,
                    onSuccess(response) {
                        AlertsManager.add('Linked contact successfully deleted')
                    },
                    onFinish() {
                        linkedContactsState.modalDelete = null
                    }
                })
            }

        },
        edit(object) {
            linkedContactsState.formEditData = object
            linkedContactsState.formEditShow = true
        },
        save(hide) {
            if (hide) {
                linkedContactsState.saveAndExitTrigger = true
                setTimeout(() => {
                    linkedContactsState.saveAndExitTrigger = false
                }, 500);
            } else {
                linkedContactsState.saveTrigger = true
                setTimeout(() => {
                    linkedContactsState.saveTrigger = false;

                }, 500);
            }
        }
    }

    return {
        linkedContactsState,
        linkedContactsHandlers
    }
}
