<template>
    <div class="acms-v-card block block-rounded">
        <KeepAlive>
            <div class="acms-v-card-inner">
                <slot name="header"></slot>
                <template v-if="!$slots?.['header']">
                    <div class="block-header block-header-default justify-content-between">
                        <template v-if="$slots?.['header-content']">
                            <slot name="header-content"></slot>
                        </template>
                        <template v-else>

                            <h3 class="block-title">{{ title }}</h3>
                            <slot name="header-right"></slot>
                        </template>
                    </div>
                </template>
                <div class="block-content" :class="contentClass">
                    <KeepAlive>
                        <slot></slot>
                    </KeepAlive>
                </div>
            </div>
        </KeepAlive>
    </div>

</template>

<script>
export default {
    name: 'AcmsVCard',
};
</script>

<script setup="">
const props = defineProps({
    title: String,
    contentClass: String,
});
</script>

<style lang="scss">
.acms-v-card {
    border-radius: 5px;
    .block-header{
        border-radius: 5px 5px 0  0;
    }
    .block-title {
        text-transform: unset;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: unset;
    }
    .block-content {
        padding-bottom: 24px;
    }
}


</style>
