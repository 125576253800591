<script setup>
import {useForm} from '@inertiajs/vue3'
import AcmsVFormField from "@ui/FormField/FormField.vue";
import {nextTick, reactive, watch} from "vue";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import AcmsVBtn from "@ui/Btn.vue";
import AcmsVIcon from "@ui/Icon.vue";
const AlertsManager = useAlertsManagerStore()
const props = defineProps({
    message:String,
    status:String,
})

const form = useForm({
    email: '',
    password: '',
})

const locState = reactive({

    showPassword: false,  // new state property
});
watch(() => props.message,  (value) => {
    if (value) {
        AlertsManager.add(props.message, 'danger')
    }
}, { immediate: true })
function toggleShowPassword() {
    locState.showPassword = !locState.showPassword;
}

const submitForm = () => {
    form.post('/admin/login', {
        preserveScroll: true,
        onSuccess: () => {
            form.reset('password')
        },
    })
}
</script>
<template>
    <div >
        <div class="row g-0 bg-primary-dark-op">
            <!-- Info Section -->
            <div style="background-image: url('/media/admin/main-bg.svg'); background-color: #ffffff; background-repeat: no-repeat; background-position: center center; width: 38.2%; " class="hero-static d-none d-lg-flex flex-column justify-content-center">
                <div class="p-4 p-xl-5 flex-grow-1 d-flex align-items-center">
                    <!--                    <div class="w-100">-->
                    <!--                        <p class="link-fx fw-semibold fs-2 text-white">-->
                    <!--                            Amigo-->
                    <!--                            <span class="fw-normal">CMS</span>-->
                    <!--                        </p>-->
                    <!--                        <p class="text-white-50 me-xl-8 mt-2 ">-->
                    <!--                            Welcome to the content management system!<br>-->
                    <!--                            Please login to access it.-->
                    <!--                        </p>-->
                    <!--                    </div>-->
                </div>
                <!--                <div class="p-4 p-xl-5 d-xl-flex justify-content-between align-items-center fs-sm">-->
                <!--                    <p class="fw-medium text-white-50 mb-0">-->
                <!--                        CMS by <a class="text-white-50 text-decoration-underline" href="https://amigo.studio/ro" target="_blank">amigo.studio</a>-->
                <!--                    </p>-->
                <!--                </div>-->
            </div><!-- END Info Section -->

            <!-- Main Section -->
            <div class="hero-static  d-flex flex-column align-items-center" style="background-color: #4f5c68; width: 61.8%">
                <!--                <div class="p-3 w-100 d-lg-none text-center">-->
                <!--                    <p class="link-fx fw-semibold fs-2 text-dark">-->
                <!--                        Amigo-->
                <!--                        <span class="fw-normal">CMS</span>-->
                <!--                    </p>-->
                <!--                </div>-->

                <!-- Content -->
                <div class="p-4 w-100 flex-grow-1 d-flex align-items-center">
                    <div class="w-100">

                        <!-- Header -->
                        <!--                        <div class="text-center mb-5">-->
                        <!--                          -->
                        <!--                        </div>&lt;!&ndash; END Header &ndash;&gt;-->



                        <!--                        <div class="row g-0 justify-content-center">-->

                        <!--                        </div>-->

                        <!-- Sign In Form -->
                        <div class="row g-0 justify-content-center">
                            <div class="col-xl-6" style="display: flex; flex-direction: column;">
                                <div class="mb-2 col-sm-12">
                                    <h1 class="fw-bold mb-2" style="color: white; text-align: center">
                                        F*CK AVERAGE, BE LEGENDARY
                                    </h1>
                                </div>
                                <div class="mb-2  col-sm-12 ">
                                    <acms-v-form-field
                                        element-class="form-control form-control-lg py-3"
                                        name="email"
                                        required
                                        placeholder="Email"
                                        v-model="form.email"
                                        :errors="form.errors?.email"
                                    ></acms-v-form-field>

                                </div>
                                <div class="col-sm-12" style="display: flex">
                                    <div class="col-sm-12">
                                        <acms-v-form-field
                                            element-class="form-control form-control-lg py-3"
                                            name="password"
                                            required
                                            :type="locState.showPassword ? 'text' : 'password'"
                                            placeholder="Password"
                                            v-model="form.password"
                                            :errors="form.errors?.['password']"
                                        ></acms-v-form-field>
                                    </div>
                                    <acms-v-icon class="ms-2 col-sm-2" style="align-self: center; padding-bottom: 15px" size="30" name="eye" :color="locState.showPassword ? '#000' : '#FFF'"   @click="toggleShowPassword"></acms-v-icon>

                                </div>
                                <div class="d-flex align-items-center mb-4 mt-2" style="justify-content: center; padding-right: 55px">
                                    <div>
                                        <button type="button" class="btn btn-lg" style="background-color: white;" @click="submitForm()"><i class="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i> Sign In</button>
                                    </div>
                                </div>

                            </div>
                        </div><!-- END Sign In Form -->

                    </div>
                </div><!-- END Content -->

                <!-- Version -->
                <!--                <div class="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">-->
                <!--                    <p class="fw-medium text-black-50 py-2 mb-0">-->
                <!--                        <strong>AmigoCMS v2.3.1</strong> ©-->
                <!--                        <span data-toggle="year-copy" class="js-year-copy-enabled">2023</span>-->
                <!--                    </p>-->
                <!--                </div>&lt;!&ndash; END Version &ndash;&gt;-->

            </div><!-- END Main Section -->
        </div>
        <div class="app-main-layout-alerts-manager">

            <transition-group name="slide">
                <template v-for="(item,index) in AlertsManager.list" :key="index">
                    <acms-v-alert
                        :content="item.content"
                        :type="item.type"
                        @remove="AlertsManager.remove(index)"
                    />
                </template>
            </transition-group>
        </div>
    </div>

</template>


<style>

.form-control::placeholder{
    color:  black!important;
}

</style>
