<template>
    <acms-v-modal-alert
        v-model:show="locState.show"
        title="Server Error"
        :text="locState.errorContent"
        hideLeftButton
        rightButtonTitle="Close"
        @rightButtonClick="locState.show = false"
        size="med-med"
    />
</template>

<script>
export default {
    name: "DebugModal"
}
</script>
<script setup="">
import {router, usePage} from "@inertiajs/vue3";
import {computed,  onBeforeUnmount, onMounted, reactive,} from "vue";

const $page = usePage()

const canShowModal = computed(()=>{
    return $page.props.flash?.error &&  import.meta.env?.VITE_APP_DEBUG == 'true'
})
const locState = reactive({
    show:false,
    errorContent:null,
    errorException:null
})

onMounted(()=>{

    locState.errorException =  router.on('success', (event) => {
        if(canShowModal.value){
           locState.errorContent = $page.props.flash?.error
           locState.show= true
        }
    })
})

onBeforeUnmount(()=>{
    locState.errorException?.()
})
</script>
<style scoped>

</style>
