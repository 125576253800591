export const serializeObject = (obj) => {

    const data = new FormData

    Object.entries(obj).forEach(([key,value])=>{

        if(Array.isArray(value)){
            value.forEach(item=>{
                data.append(key+'[]', item)
            })
        }else{
            data.append(key, value)
        }


    })

    return new URLSearchParams(data).toString().replace(/%2C/g, ",");
}
