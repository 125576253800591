<template>
    <acms-v-form-field
        :modeSelect="{
                         options:$page.props?.optionsForSelect?.contactSocialOptions ?? [],
                         outValueKey: 'id',
                     }"
        label="Contact by"
        name="contact_by_id"
        placeholder="Select"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :errors="errors"
    />
</template>

<script>
export default {
    name: "FieldSelectContactBy"
}
</script>

<script setup="">
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    modelValue:[String,Object,Number],
    errors:Object
})

const $page = usePage();
</script>

<style scoped>

</style>
