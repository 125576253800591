<template>
    <div class="acms-v-table-row-actions ms-auto d-flex">
            <div class="me-3 d-flex" v-if="actions?.includes('email') || actions?.includes('phone')">
                <acms-v-btn v-if="actions?.includes('email')" icon="email"  icon-size="20"
                            class="text-white bg-primary-dark-op p-1 me-2"
                            @click="$emit('clickAction', 'email')"
                />
                <acms-v-btn v-if="actions?.includes('phone')" icon="phone"  icon-size="20"
                            class="text-white bg-primary-dark-op p-1"
                            @click="$emit('clickAction', 'phone')"
                />
            </div>
            <div class="d-flex bg-gray-light action-block-rad">
                <acms-v-btn v-if="actions?.includes('edit')" icon="pen" color="text-primary-darker"
                            class="  bg-gray-light"
                            style="padding: 7px"
                            @click="$emit('clickAction', 'edit')"
                />
                <acms-v-btn v-if="actions?.includes('show')" icon="eye"   color="text-primary-darker"
                            class="  bg-gray-light"
                            style="padding: 7px"
                            @click="$emit('clickAction', 'show')"
                />
                  <template v-if="typeof published !== 'undefined'">
                      <acms-v-btn v-if="actions?.includes('publish')" icon-size="20" :icon=" published ? 'eye' : 'eye-off'" color="text-primary-darker"
                                  class="p-1 bg-gray-light"
                                  @click="$emit('clickAction', 'publish')"
                      />
                  </template>

                <acms-v-btn v-if="actions?.includes('delete')" icon="close" icon-size="20"
                            color="text-primary-darker" class="p-1 bg-gray-light"
                            @click="$emit('clickAction', 'delete')"
                />
            </div>
    </div>
</template>

<script>
export default {
    name: 'AcmsVTableActions',
};
</script>

<script setup="">
const props = defineProps({
    actions: String,
    published:[Boolean,Number],
});
</script>

<style scoped>
.action-block-rad{
    border-radius: 0.25rem;
}
.acms-v-table-row-actions{
    justify-content: center;
    width: 100%;
}
</style>
