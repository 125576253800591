<template>
    <PartialTabsCurrentSelectedRequest
        :requests="requests"
        :currentActiveRequestTab="currentActiveRequestTab"
        @changeActiveRequestTab="$emit('changeActiveRequestTab', $event)"
        :pqs="pqs"
    />
    <div class="partial-passengers-table-and-select">
        <acms-v-table v-if="show && getCurrentPassengers?.passengers"
                      v-bind="getCurrentPassengers.passengersCreatedExist ? TABLES_PROPS.TABLE_PASSENGERS_PROPS : TABLES_PROPS.TABLE_PASSENGERS_PROPS_EMPTY"
                      :data="getCurrentPassengers?.passengers"
                      @editAction="$emit('edit', $event)">
            <template #bodyCell-name="{item}">
                <template  v-if="item?.name">
                    <div class="text-primary-dark fw-semibold">{{item?.name}}</div>
                </template>
                <acms-v-btn v-else   @click="$emit('edit', item)"
                            class="text-primary fw-semibold p-0">
                    <div :title="getFullName(item)" class="text-start fz-14 row-fullname-ellipsis">{{ item?.name ?? getFullName(item) }}</div>
                </acms-v-btn>
            </template>
            <template #bodyCell-ktn="{item}">
                {{ item?.ktn?.code ?? '' }}
            </template>
            <template #bodyCell-redress="{item}">
                {{ item?.ktn?.redress ?? '' }}
            </template>
            <template #bodyCell-primary="{item}">
                <acms-v-badge :title="item?.is_main_passenger ? 'Primary' : null"
                              color="btn-primary"/>
            </template>
            <template #bodyCell-type="{item}">
              {{item.type}}
            </template>
            <template #bodyCell-actions="{item, index}">
                <template v-if="item?.name?.toLowerCase().includes('passenger') ">
                    <template v-if="allowCreate">
                        <acms-v-btn
                            v-if="item?.name?.toLowerCase().includes('passenger') && getCurrentPassengers?.existingPassengers.length > 0"
                            class="me-3 btn-sm"
                            title="Add existing"
                            icon="plus"
                            @click="passengersHandlers.openSelectModal"
                        />
                        <acms-v-btn class="btn-primary btn-sm ms-auto"
                                    @click=" $emit('create')"
                                    title="Add new"/>
                    </template>

                </template>
                <acms-v-btn v-else icon="pen" class="p-2 bg-gray btn-sm ms-auto"
                            @click="$emit('edit', item,index)"
                />
            </template>
        </acms-v-table>
        <acms-v-modal
            :show="passengersState.modalSelectShow"
            @hide="passengersState.modalSelectShow=false"
            title="Select Passenger"
            size="med-med"
        >
            <template #content>
                <acms-v-table v-if="getCurrentPassengers?.existingPassengers"
                              v-bind="TABLES_PROPS.TABLE_PASSENGERS_SELECT_PROPS"
                              :data="getCurrentPassengers?.existingPassengers"
                              @clickRow="passengersHandlers.select"
                              bodyRowClass="cursor-pointer">
                    <template #bodyCell-name="{item}">
                        <span class="fw-semibold text-primary">{{ getFullName(item) }}</span>
                    </template>
                    <template #bodyCell-main="{item}">

                        <acms-v-badge :title="item?.is_main_passenger ? 'Primary' : null "
                                      color="btn-primary"/>
                    </template>
                </acms-v-table>
            </template>
        </acms-v-modal>
    </div>
</template>

<script>
export default {
    name: "PartialPassengersTableAndSelect"
}
</script>

<script setup="">
import {router, usePage} from "@inertiajs/vue3";
import * as TABLES_PROPS from "../constants";
import {getFullName} from "@helpers/commonHelpers";
import {computed, reactive} from "vue";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getRoute} from "@plugins/useRoutes";
import {PartialTabsCurrentSelectedRequest} from "@pages/requests/partials/index";
import {getSettingsForUseForm} from "@services/FormService";

const AlertsManager = useAlertsManagerStore()

const emit = defineEmits(['edit', 'create', 'changeActiveRequestTab'])
const props = defineProps({
    show: Boolean,
    passengers: Object,
    existingPassengers: Object,
    requests: Array,
    clientId: [String, Number],
    currentActiveRequestTab: [String, Number],
    currentActiveRequestPqs: Array,
    pqs: Object,
    allowCreate:Boolean
})

const passengersState = reactive({
    modalSelectShow: false,
})
const $page = usePage()
const getCurrentPassengers = computed(() => {
    const {currentActiveRequestTab, passengers} = props
    if (currentActiveRequestTab) {
        const collection = passengers?.[currentActiveRequestTab]
        const passengersArr = collection.passengers.map(item => item?.data ?? item) ?? [];
        return {
            passengers:passengersArr,
            existingPassengers: collection.existingPassengers.data ?? [],
            passengersCreatedExist: passengersArr?.some(item=>item?.ktn)
        }
    }
    return {}
})

const passengersHandlers = {
    openSelectModal() {
        passengersState.modalSelectShow = true;
    },
    select({rowItem}) {
        const settings = getSettingsForUseForm({only: ['passengers']})
        router.post(getRoute('requests.addPassenger'), {
            request_id: props.currentActiveRequestTab,
            passenger_id: rowItem.id,
            is_main_passenger: rowItem?.is_main_passenger
        }, {
            ...settings,
            onSuccess() {
                const existServerError = $page.props.flash?.error;

                if (existServerError) {
                    AlertsManager.add('Passenger has server errors', 'danger')
                    return
                }
                AlertsManager.add('New passenger added successfully!')
                passengersState.modalSelectShow = false
            }
        })
    },
}

</script>
<style lang="scss">
.partial-passengers-table-and-select   {
    .row-fullname-ellipsis{
        width: 195px;
    }
}
</style>
