<template>
    <div class="acms-v-time-picker">
        <acms-v-select
            v-model="locState.value"
            optionKeyTitle="time"
            toggleKeyTitle="time"
            placeholder="00:00"
            :options="timeOptions"
        >
            <template #dropdownTop>
                <div class="manual-input">
                    <acms-v-label
                        label="Manual input"
                    />
                    <input type="text" class="form-control"
                           maxlength="4"
                           v-model="locState.manual"
                           @keypress="keypressHandle"
                           placeholder="00:00"
                    >
                </div>

            </template>
            <template #toggleRight>
                <div class="time-line-hor"></div>
                <span class="time-designation" style="width: 48px;" @click.stop="switchTimeTypeHandle">
                 {{ locState.currentType }}
            </span>
            </template>
        </acms-v-select>
    </div>
</template>

<script>
export default {
    name: 'AcmsVTimeSelect',
};

</script>
<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    timeType: {
        type: String,
        default: 'AM',
    },
    modelValue: [Object,Number,String],

});

const timeOptions = new Array(13).fill(null).map((item, index) => {
    return { time: (index < 10 ? '0' + index : index) + ':00' };
});

const initValue = { time: '00:00', type: props.timeType };
const locState = reactive({
    value: null,
    manual: '',
    currentType: props.timeType
});

watch(() => locState.value, (val) => {
    val.type = locState.currentType
    emit('update:modelValue', val);
    locState.manual = ''

});

watch(() => locState.manual, (val) => {
    if (locState.manual.length === 4) {
        let match = val.match(/(\d{0,2})(\d{0,2})/);
        if (Number(match[1]) < 13 && (match[2] != '' && Number(match[2]) < 60)) {
            let temp = match[1] + ':' + match[2];

            locState.value = { time: temp, type: locState.currentType };
            locState.manual = temp;
        } else {
        }
    }

});

const keypressHandle = (event) => {
    locState.manual = event.target.value.replace(/\D/g, '');
};
const switchTimeTypeHandle = ()=>{
    locState.currentType = locState.currentType == 'AM' ? 'PM' : 'AM'
    locState.value.type = locState.currentType
}
onBeforeMount(()=>{
    locState.value = props.modelValue && typeof props.modelValue == 'object' ? props.modelValue :  initValue
})

</script>
<style lang="scss" scoped>
.acms-v-select {
    min-width: 135px;
}
.manual-input {
    margin-right: 12px;
    margin-left: 12px;
}
.time-designation {
    padding: 8px 12px;
}
.time-line-hor {
    height: 22px;
    width: 2px;
    background-color: #CED4DA;
}

</style>
