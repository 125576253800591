
export const demoPqCodeFullTable = {
    "status": true,
    "gds": "Sabre",
    "pcc" : null,
    "pnr": null,

    "flightLegs": [
        {
            "order": "1",
            "airline": "LH",
            "flightNumber": "8919",
            "bookingClass": "M",
            "departureDate": "15SEP",
            "departureDayOfTheWeek": "F",
            "from": "RDU",
            "to": "IAD",
            "status": "SS",
            "quantity": "1",
            "timeFrom": "751P",
            "timeTo": "855P"
        },
        {
            "order": "2",
            "airline": "LH",
            "flightNumber": "9253",
            "bookingClass": "M",
            "departureDate": "15SEP",
            "departureDayOfTheWeek": "F",
            "from": "IAD",
            "to": "FRA",
            "status": "SS",
            "quantity": "1",
            "timeFrom": "1020P",
            "timeTo": "1210P",
            "arrivalDate": "16SEP",
            "arrivalDayOfTheWeek": "J"
        },
        {
            "order": "3",
            "airline": "OS",
            "flightNumber": "210",
            "bookingClass": "M",
            "departureDate": "16SEP",
            "departureDayOfTheWeek": "J",
            "from": "FRA",
            "to": "VIE",
            "status": "SS",
            "quantity": "1",
            "timeFrom": "140P",
            "timeTo": "305P"
        },
        {
            "order": "4",
            "airline": "OS",
            "flightNumber": "65",
            "bookingClass": "N",
            "departureDate": "26SEP",
            "departureDayOfTheWeek": "T",
            "from": "VIE",
            "to": "ORD",
            "status": "SS",
            "quantity": "1",
            "timeFrom": "1030A",
            "timeTo": "120P"
        },
        {
            "order": "5",
            "airline": "AA",
            "flightNumber": "2814",
            "bookingClass": "N",
            "departureDate": "26SEP",
            "departureDayOfTheWeek": "T",
            "from": "ORD",
            "to": "RDU",
            "status": "SS",
            "quantity": "1",
            "timeFrom": "330P",
            "timeTo": "622P"
        }
    ]
}
    // {
    //     common_time: '10:30',
    //     flights: [
    //         ['1','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
    //         ['2','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
    //
    //     ],
    // },
    // {
    //     common_time: '10:30',
    //     flights: [
    //         ['3','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
    //         ['4','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
    //         ['5','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '5:25'],
    //     ],
    // },


    // {
    //     standard:['1','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35'],
    //     plane_transfer: ''
    // },
    //
    // {
    //     standard:['1','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35'],
    //     plane_transfer: '2:20'
    // }

// ];


export const demoCloseRequestTable = [
        {
            title: 'Economy request',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Can’t reach the client',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Trip canceled',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Purchased elsewhere',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Asked not to be contacted',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Duplicate',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },
        {
            title: 'Need no sales assistance',
            '24H': 0,
            '<24H': 0,
            checked: false,
        },

    ];
export const demoPqTableData = [

    {
        id: 1,

        info: [
            { title: 'STATUS', content: 'New' },
            { title: 'PQ ID', content: '12345' },
            { title: 'CREATED', content: '05/11/23' },
            { title: 'CREATED BY', content: 'Eldar' },
            { title: 'ROLE', content: 'Expert' },
            { title: 'Remark Int.', content: 'Remark for me' },
            { title: 'Remark Ext.', content: 'No remarks' },
        ],

        passengersPrice: [
            {
                title: 'PTC',
                content: [{ icon: 'adult', count: 1 }, { icon: 'child', count: 2 }, { icon: 'infant', count: 1 }],
            },
            { title: 'NET', content: ['600', '500', '300'] },
            { title: 'SALE', content: ['1 584', '800', '500'] },
            { title: 'TOTAL', bgColor: '#FBDECE', content: '$2300', colspan: 3, class: 'fw-bold text-center' },
            { title: 'PROFIT', bgColor: '#E0EDCF', content: '$900', colspan: 3, class: 'fw-bold  text-center' },
        ],

        shortCode: {
            full: [
                {
                    common_time: '10:30',
                    flights: [
                        ['1','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
                        ['2','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                        ['3','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                    ],
                },
                {
                    common_time: '10:30',
                    flights: [
                        ['4','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
                        ['5','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                    ],
                },

            ],
            simple:[
                ['1','BA1515M', '26DEC', 'JFKLHR', 'SS1', '1029P', '1035A+*', 'MO/TU', '11', '7:06', '77W'],

                'OPERATED BY AMERICAN AIRLINES',

                ['2','BA 314M', '27DEC', 'LHRCDG', 'SS1', '105P', '3325P*', 'TU', '1', '1:20', '319'],

                ['3','BA1537N', '21JAN', 'CDGDFW', 'SS1', '1125A', '311P*', 'SA', '7', '10:46', '789'],

                ['4','BA1537N', '21JAN', 'CDGDFW', 'SS1', '1125A', '311P*', 'SA', '7', '10:46', '789'],

                'OPERATED BY AMERICAN AIRLINES'
            ]
        },

        additional: {
            gds: [
                { title: 'GDS', content: 'Sabre' },
                { title: 'PNR', content: '123GK' },
            ],
            pcc: [
                { title: 'PCC', content: '123GK' },
                { title: 'TK/RL', content: 'ZB123M' },
            ],
            fare: [
                { title: 'FARE', content: 'Private' },
                { title: 'AA/RL', content: 'MKLA2M' },
            ],
        },

    },
    {
        id: 2,

        info: [
            { title: 'STATUS', content: 'New' },
            { title: 'PQ ID', content: '12345' },
            { title: 'CREATED', content: '05/11/23' },
            { title: 'CREATED BY', content: 'Eldar' },
            { title: 'ROLE', content: 'Expert' },
            { title: 'Remark Int.', content: 'Remark for me' },
            { title: 'Remark Ext.', content: 'No remarks' },
        ],

        passengersPrice: [
            {
                title: 'PTC',
                content: [{ icon: 'adult', count: 1 }, { icon: 'child', count: 2 }, { icon: 'infant', count: 1 }],
            },
            { title: 'NET', content: ['600', '500', '300'] },
            { title: 'SALE', content: ['$1 584', '$800', '$500'] },
            { title: 'TOTAL', bgColor: '#FBDECE', content: '$2300', colspan: 3, class: 'fw-bold text-center' },
            { title: 'PROFIT', bgColor: '#E0EDCF', content: '$900', colspan: 3, class: 'fw-bold  text-center' },
        ],

        shortCode: {
            full: [
                {
                    common_time: '10:30',
                    flights: [
                        ['1','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
                        ['2','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                        ['3','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                    ],
                },
                {
                    common_time: '10:30',
                    flights: [
                        ['4','TK', '57', 'E', 'KIV', 'IST', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', ''],
                        ['5','TK', '57', 'E', 'IST', 'SFO', '04DEC', '9:20', '11:55', 'SU', 'E', '2:35', '2:35', '2:20'],
                    ],
                },

            ],
            simple:[
                ['1','BA1515M', '26DEC', 'JFKLHR', 'SS1', '1029P', '1035A+*', 'MO/TU', '11', '7:06', '77W'],

                'OPERATED BY AMERICAN AIRLINES',

                ['2','BA 314M', '27DEC', 'LHRCDG', 'SS1', '105P', '3325P*', 'TU', '1', '1:20', '319'],

                ['3','BA1537N', '21JAN', 'CDGDFW', 'SS1', '1125A', '311P*', 'SA', '7', '10:46', '789'],

                ['4','BA1537N', '21JAN', 'CDGDFW', 'SS1', '1125A', '311P*', 'SA', '7', '10:46', '789'],

                'OPERATED BY AMERICAN AIRLINES'
            ]
        },

        additional: {
            gds: [
                { title: 'GDS', content: 'Sabre' },
                { title: 'PNR', content: '123GK' },
            ],
            pcc: [
                { title: 'PCC', content: '123GK' },
                { title: 'TK/RL', content: 'ZB123M' },
            ],
            fare: [
                { title: 'FARE', content: 'Private' },
                { title: 'AA/RL', content: 'MKLA2M' },
            ],
        },

    }

];


export const demoSendEmailTemplates = [
    {
        title: 'We have new contacts',
        helpText: 'This mail can be sent to a client  after several days of not being\n' +
            'able to reach him just to remind \n' +
            'of yourself',
    },
    {
        title: 'Did you get a quoate?',
        helpText: 'This mail can be sent to a client  after several days of not being\n' +
            'able to reach him just to remind \n' +
            'of yourself',
    },
    {
        title: 'Do you still need tickets?',
        helpText: 'This mail can be sent to a client  after several days of not being\n' +
            'able to reach him just to remind \n' +
            'of yourself',
    },
     {
        title: 'Still going? We have a Pro ',
        helpText: 'This mail can be sent to a client  after several days of not being\n' +
            'able to reach him just to remind \n' +
            'of yourself',
    },
    {
        title: 'Prequest for Travel Data in',
        helpText: 'This mail can be sent to a client  after several days of not being\n' +
            'able to reach him just to remind \n' +
            'of yourself',
    },

];

export const demoEmailsList = [
    'ra*****@gmail.com',
    'am*****@gmail.com',
    'li*****@gmail.com',
]
export const demoPhonesList = [
    '+1 (873)*****24',
    '+1 (873) ******24',
    '+1 (873) ******24',
]

export const demoAirlinesOptionsList = [

    {
        id: '1',
        title: 'Miles and Smiles',
        cost:'9',
    },
    {
        id: '2',
        title: 'Air Canada',
        cost:'13',
    },
    {
        id: '4',
        title: 'United Airlines',
        cost:'15',
    },

    // 'Delta Airlines',
    // 'Air Moldova3','United Airlines1',
    // 'Delta Airlines1', 'Air Moldova1'
]

export const demoClientCallsList = [
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_3.mp3',
        responded: true
    },
    {
        direction: 'Incoming',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_3.mp3',
        responded: true
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_3.mp3',
        responded: false
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_1.mp3',
        responded: false
    },
    {
        direction: 'Incoming',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_1.mp3',
        responded: true
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_1.mp3',
        responded: true
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_2.mp3',
        responded: true,
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_1.mp3',
        responded: false,
    },
    {
        direction: 'Outgoing',
        phone: '+1 (873) ******24',
        call_id: 'B123-4668234',
        date: '02/01/20',
        time: '02:30 AM (02:30 AM CDT)',
        audio_record: '/audio/audio_record_2.mp3',
        responded: true,
    },
]

export const demoPqCodeSelectProgramOptions = ['Miles & Smiles', 'United Airlanes', 'Air Moldova', 'Revenue']


////
export const demoPqCodeText = '1 LH8919M 15SEP F RDUIAD*SS1 751P 855P /DCLH /E\n' +
    'OPERATED BY UNITED AIRLINESUNITED AIRLINES \n' +
    'SEC FLT PSGR DATA REQUIRED 72 HBD SSR DOCSUS LAW SEE GGAIRLH PNR ACCESS OR NEWS KEYWORDSPLS ADD PAX MOBILE CTC FOR IRREG COMMUNICATION \n' +
    '2 LH9253M 15SEP F IADFRA*SS1 1020P 1210P 16SEP J /DCLH /E\n' +
    'OPERATED BY UNITED AIRLINESUNITED AIRLINES \n' +
    'SEC FLT PSGR DATA REQUIRED 72 HBD SSR DOCSUS LAW SEE GGAIRLH PNR ACCESS OR NEWS KEYWORDSPLS ADD PAX MOBILE CTC FOR IRREG COMMUNICATION \n' +
    '3 OS 210M 16SEP J FRAVIE SS1 140P 305P /PENDING /E \n' +
    '4 OS 65N 26SEP T VIEORD SS1 1030A 120P /PENDING /EVIE CHECK-IN WITH AUSTRIAN STAR ALLIANCE TERMINAL 3 \n' +
    '5 AA2814N 26SEP T ORDRDU SS1 330P 622P /DCAA /EM3KB.L5BI*AMZ 1127/14MAR23'


export const initAwardPQData = {
    stepOneData: {
        code: '',
        mileage_program: null,
        revenue: false,
    },

    stepTwoData: {
        pqCodeObject: demoPqCodeFullTable,

        pqTableRowsSelectedPrograms: null,
        pqPassengersQuantity: {
            adult: 1,
            child: 2,
            infant: 1,
        },

        airlinesTaxes: null,

        revenueTaxes: {
            first: null,
            second: null,
            third: null,
        },

        sellTaxes: {
            first: null,
            second: null,
            third: null,
        },

        remarkInt: '',
        remarkExt: '',
    },

};
export const initAwardExchangePQData = {
    stepOneData: {
        code: demoPqCodeText,
        mileage_program: ['Air Canada', 'Miles and Smiles', 'United Airlines'],
        revenue: true,
    },

    stepTwoData: {
        pqCodeObject: demoPqCodeFullTable,

        pqTableRowsSelectedPrograms: null,
        pqPassengersQuantity: {
            adult: 1,
            child: 2,
            infant: 1,
        },

        airlinesTaxes: null,

        revenueTaxes: {
            first: null,
            second: null,
            third: null,
        },

        sellTaxes: {
            first: null,
            second: null,
            third: null,
        },

        remarkInt: '',
        remarkExt: '',
    },

};


export const demoAgentsData = [ {
    'agent-status': 'Sales agent',
    'name': 'Steven',
    'phone': '555-987-6543',
    'email': 'steven@mytickets.com',
    'team': 'Alpha Team',
    'supervisor': 'Michael',
    'taken': '07/03/2023 13:40',
    'worked': '3',
},
    {
        'agent-status': 'Sales agent',
        'name': 'John',
        'phone': '555-867-5309',
        'email': 'john@mytickets.com',
        'team': 'Delta Team',
        'supervisor': 'Peter',
        'taken': '05/03/2023 18:40',
        'worked': '5',
    },]
