import {onBeforeMount, onMounted} from 'vue';

export default function ({props, locState, computed, emit, methods},) {
    const {searchAsync, selectedOptionOnInit, modelValue, outValueKey, options, initialValue, selectOptionOnInitInParentFun} = props

    onBeforeMount(() => {
        locState.currentValueObject = initialValue ?? null
    })

    onMounted(() => {
        if (options?.length > 0) {
            locState.tempOptions = options
        }
        if(!modelValue || modelValue  < 0) {
            if (selectedOptionOnInit != undefined) {

                if (typeof props.selectedOptionOnInit == "object" && options?.length > 0) {
                    const {key, value} = props.selectedOptionOnInit
                    if (key && value) {
                        const find = props.options.find(item => item[key] == value)
                        if (find) {
                            methods.selectOptionHandle(find)
                        }
                    }
                } else {
                    if (typeof selectedOptionOnInit == 'number' || typeof selectedOptionOnInit == 'number' && options?.length > 0) {
                        if (modelValue) {
                            return
                        }

                        const needToSelectVal = props.options[props.selectedOptionOnInit]


                        if (modelValue != needToSelectVal) {
                            methods.selectOptionHandle(props.options?.[props.selectedOptionOnInit])
                        }

                    }
                }
            }

            if(selectOptionOnInitInParentFun){
                selectOptionOnInitInParentFun(methods.selectOptionHandle)
            }
        }
        if (searchAsync == '') {
            console.warn(`searchAsync: input url form component with name - ${props.name}`);
        }
    });
}
