<template>
    <div class="acms-v-tabs block block-rounded">
        <div class="acms-v-tabs-nav-wrap d-flex " :class="navWrapClass ?? 'bg-gray-lighter'">
            <ul class="acms-v-tabs-nav nav nav-tabs " :class="getNavTheme" role="tablist">
                <keep-alive>
                    <template v-for="[tabKey, tabData] in Object.entries(items)">
                        <li class="nav-item acms-v-tabs-nav-item d-flex"
                            @click="setActiveHandle(tabKey)"
                        >
                            <button class="nav-link d-flex align-items-center"
                                    :class="{active: locState.active == tabKey}"
                            >
                                <slot name="nav-btn"  :item="tabData" :isActive="locState.active == tabKey"></slot>

                                <template v-if="!$slots['nav-btn']">
                                    {{ tabData }}
                                </template>
                                <div class=" ms-1 circle-quantity" v-if="!!navQuantity?.[tabKey]">
                                    {{ navQuantity[tabKey] }}
                                </div>
                            </button>
                            <slot :name="'nav-item-bottom-'+tabKey"></slot>
                        </li>
                    </template>
                </keep-alive>
            </ul>
            <slot name="nav-right"></slot>
        </div>
        <div class="block-content acms-v-tabs-content" :class="getContentClass">
            <keep-alive>
                <template v-for="tabKey  in getTabsKeys">
                        <div class="tab-content show" v-if="locState.active === tabKey">
                            <slot :name="'tab-'+tabKey"></slot>
                        </div>
                </template>

            </keep-alive>
            <slot name="tabAlwaysShow"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AcmsVTabs',
};
</script>

<script setup="">

import { computed, onBeforeMount, reactive, watch } from 'vue';
const emit = defineEmits(['beforeChangeTab', 'afterChangeTab'])
const props = defineProps({
    items: {
        type: [Object, Array],
    },
    itemsContentClass: {
        type: Object,
    },
    navQuantity: {
        type: Object,
    },
    activeIndex: [Number, String],
    active: [Number, String],
    parentControl:Boolean,
    contentClass: String,
    activeClass: String,
    navWrapClass: String,
    navTheme:{
        type:String,
        default:'default'
    }

});
const getTabsKeys = computed(() => Object.keys(props.items));
const locState = reactive({
    active: getTabsKeys?.value[0] ?? '',
});

watch(()=>props.active,(val)=>{
    if(props.parentControl){
        locState.active = val;
    }
})

const getNavTheme= computed(()=>{
    const themes  ={
        'default' : 'nav-tabs-block',
        'alt' : 'nav-tabs-alt',
        'btn-group': 'nav-tabs-btn-group'
    }

    return  themes[props.navTheme]
})

const getContentClass = computed(()=>{
    const {itemsContentClass, contentClass} = props
    if(itemsContentClass){
        if(itemsContentClass?.[locState.active]){
            return [itemsContentClass?.[locState.active], contentClass]
        }
    }
    return  contentClass
})

const setActiveHandle = (key) => {
    emit('beforeChangeTab', key)

    if(!props.parentControl){
        locState.active = key;
    }

    emit('afterChangeTab', key)
};

onBeforeMount(() => {
    const { active, activeIndex, items } = props;
    const firstTabKey = getTabsKeys?.value[0];
    if (active) {
        locState.active = active in items ? active : firstTabKey;
    } else if (activeIndex) {
        locState.active = getTabsKeys?.value?.[Number(activeIndex)] ?? firstTabKey;
    } else {
        locState.active = firstTabKey;
    }
});

</script>
<style lang="scss">
.acms-v-tabs-nav{
    border-radius: 5px 5px 0 0 ;
}
.acms-v-tabs {
    .block-content {
        padding-bottom: 24px;
    }
    .nav-item.active {
        background: #fff;
    }
}
.acms-v-tabs-nav  {
    border-radius:5px 0 0 0 ;
}
.nav-item.acms-v-tabs-nav-item{
    &:first-child{
        .nav-link{
            //border-radius:5px 0 0 0 ;
        }
    }
}
.nav-tabs-alt .nav-item.show .nav-link, .nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-link:focus, .nav-tabs-alt .nav-link:hover{
    box-shadow: inset 0 -2px #4c78dd !important;
}
.acms-v-tabs-nav-item{
    &.active{
        background-color: #fff;
        &:focus{
            background-color: #fff;
        }
    }
}
.nav-tabs-btn-group{
    border: 0;
    border-radius: 5px;
    .nav-item {
        &:first-child {
            .nav-link {
                border-radius: 5px 0px 0px 5px;
            }
        }
        &:last-child{
            .nav-link{
                border-radius: 0px 5px 5px 0px
            }
        }
    }
    .nav-link, .tabs-tab-title{
        padding: 10px 12px;
        border: 1px solid #E9ECEF;
        text-align: left;
        font-size: 14px;
        color: #4B5563;
        border-radius: 0;
        &.active{
            background-color: #343A40;
            color: #fff;
            border: 1px solid #343A40;
        }
    }
}
</style>
