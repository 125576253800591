<template>
    <component :is="href ? 'a': 'button'"  class="acms-v-btn btn d-flex align-items-center"
            :class="getClass"
            :style="getStyle"
            :href="href"
            :disabled="submitting || disabled"
    >
        <template v-if="icon">
            <acms-v-icon  :size="iconSize" :name="icon"
                          :updatesCount="updatesCount"
                          :color="iconColor"
                          class="acms-v-btn-icon "/>
        </template>
            <template v-if="title">
                <div class="acms-v-btn-title" :class="getContentClass">
                {{ title }}
                </div>
            </template>
            <template v-else-if="$slots?.default">
                <div class="acms-v-btn-content " :class="getContentClass">
                    <slot name="default"></slot>
                </div>
            </template>
        <div class="acms-v-btn-updates" v-if="updatesCount ">{{updatesCount }}</div>
        <div class="acms-v-btn-loading-animation " v-if="submitting || loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'AcmsVBtn',
};
</script>

<script setup="">

import { computed } from 'vue';
import AcmsVIcon from '@ui/Icon.vue'

const props = defineProps({
    title: String,
    preset: String,
    width:String,
    icon:String,
    iconColor:String,
    iconSize:String,
    href:String,
    updatesCount:String,
    submitting:Boolean,
    loading:Boolean,
    contentClass:String,
    disabled:Boolean,

});
const getStyle  = computed(()=>{
    return {width: (props.width ?? null ) + 'px'}
})
const getClass = computed(() => {
    const {preset,submitting,loading} = props
    return {
        'submitting' : submitting,
        'loading' : loading,
        'acms-v-btn--simple text-primary': preset == 'simple-primary',
    };
});
const getContentClass = computed(()=>{
    const {icon,contentClass} = props
    return [icon  ? 'ms-1' : '', contentClass]
})
</script>
<style lang="scss">
.acms-v-btn {
    padding: 7px 12px; // 10px 16px;
    justify-content: center;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    &.btn-sm{
        padding: 4px 8px;
        font-size: 14px;
        line-height: 20px;
    }
    &.btn-row-action{
        height: 38px;
    }
    &.btn-border-black{
        border: 1px solid #4B5563;
        color: #4B5563;
    }
}

.acms-v-btn-icon {
    & + .acms-v-btn-title {
        margin-left: 4px;
    }
}

.acms-v-btn--simple {
    padding: 0 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
button.btn-border{
    border: 1px solid #E9ECEF;
}
.acms-v-btn-updates{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #0891B2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.acms-v-btn{
    &.submitting, &.loading{
        .lds-ring {
            display: inline-block;
            position: relative;
            width: 16px;
            height: 16px;
        }
        .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            margin: 2px;
            border: 2px solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes lds-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
