<template>
    <acms-v-dropdown
        :options="getOptions"
        title="More"
        toggleClass="btn-sm btn-border"
        hideAngle
        placement="left"
        optionClass="ps-3"
        @select="$emit('select', $event)"
    />
</template>

<script>
export default {
    name: "ElementDropdownMoreOptions"
}
</script>

<script setup="">

import {PQ_ROW_MORE_OPTIONS} from "../constants";
import {computed, watch} from "vue";
import {cloneDeep} from "lodash";

const emit = defineEmits(['select', ''])
const props = defineProps({
    additionalOption: String,
    isExpired: {
        type: Boolean,
        default: false, // задание значения по умолчанию, чтобы избежать неопределенности
    },
    pqHiddenStatus:Boolean,

})
const getOptions = computed(() => {
    const optionsObject = cloneDeep(PQ_ROW_MORE_OPTIONS)
    if (props.isExpired) {
        delete optionsObject.update;
        delete optionsObject.duplicate;
    }
    optionsObject.hidePQ = props.pqHiddenStatus ? 'Unhide PQ' : "Hide PQ";

    return [...Object.values(optionsObject), props.additionalOption]
})

</script>
<style scoped>

</style>
