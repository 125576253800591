<template>
    <acms-v-form-field
        :class="$attrs.class ?? 'col-3'"
        placeholder="ex. Doe"
        :modeInput="{capitalizeFirstCharacter:true}"
        label="Middle name"
        name="middle_name"
        :model-value="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :errors="errors"
        :hide-hint="hideHint"
    />

</template>

<script>
export default {
    name: "FieldMiddleName"
}
</script>
<script setup="">
const props = defineProps({
    modelValue:[String],
    errors:Object,
    hideHint:Boolean
})
</script>
<style scoped>

</style>
