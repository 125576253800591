import {nextTick, reactive, watch} from "vue";
import {router} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";

export default function useMultiRowCrudService({props, emit, constants = {}, addToLocState = {} , methods = {}}) {
    const locState = reactive({
        deleteShow: null,
        list: [],
        triggerRow: false,
        needExit: false,
        needExitToClientsIndex: false,
        triggerSaveRows: false,
        listForSave: [
            // { data: [], id: null}с
        ],
        saveModalAlert: false,
        submitProcessing: false,

        ...addToLocState,
    })

    const handlers = {
        ...methods,

        cancel(object = {}) {
            emit('cancel', object)
            locState.list = [];
        },
        wantsToDelete(...args) {
            locState.deleteShow = args
        },

        delete() {
            const [item, callbacks] = locState.deleteShow
            const {id} = item
            const {removeRow} = callbacks
            if (removeRow) {
                removeRow?.()
                locState.deleteShow = null
            }
        },
        edit({item: itemRow, index}, callbacks) {
            const {
                closePrevRow,
                openSelectedRow,
            } = callbacks.closeRow()
            closePrevRow?.()
            setTimeout(openSelectedRow, 100);
        },
        listItemActions(eventType, {item, index}, callbacks) {
            if (eventType == 'create') {
                const isValidated = handlers.preValidateItem(item, true)
                if (isValidated) {
                        locState.list[index] = callbacks.collapseRow(item)
                        callbacks.appendRow()
                }
            } else if (eventType == 'add') {
                locState.list.push(item)
            } else if (eventType == 'remove') {
                handlers.wantsToDelete(item, callbacks)
            } else if (eventType == 'cancel') {
                handlers.cancel({isDirty: locState.item.isDirty})
            }
        },
        openUnfinishedItem({removeIndex, afterRemoveRow, openRow}) {
            locState.list.splice(removeIndex, 1)
            afterRemoveRow?.()
            nextTick(() => {
                const unfinishedIndex = this.getUnfinishedItemIndex()
                if (unfinishedIndex > -1) {
                    openRow?.(unfinishedIndex)
                }
            })
        },
        getUnfinishedItemIndex() {
            return locState.list.findIndex(itm => !this.checkIsFilledRequiredFields(itm, true))
        },
        checkIfHasFilledFieldsForRemove(item, removeRow) {
            removeRow?.(this?.checkIfHasFilledFields(item))
        },
        trySave(needExit = false, needExitToClientsIndex = false) {
            locState.needExit = needExit;
            locState.needExitToClientsIndex = needExitToClientsIndex;

            if (locState.list.length > 0) {
                const hasFilledFields = this.checkIfHasFilledFields(locState.list?.[0])
                locState.saveModalAlertContent = null;

                if (!hasFilledFields) {
                    locState.saveModalAlertContent = constants?.notFilled ?? null
                    locState.saveModalAlert = true
                } else {
                    handlers.runSave()
                }
            } else {
                handlers.runSave()
            }
        },
        runSave() {
            locState.triggerSaveRows = true
            setTimeout(() => {
                locState.triggerSaveRows = false
            }, 500);
        },
        update({item: itemForm, index: itemIndex}, callbacks) {
            const isValidated = this.preValidateItem(itemForm, true)

            if (!isValidated) {
                return
            }

            if (!itemForm?.id) {
                locState.list[itemIndex] = callbacks.collapseRow(itemForm)

                const unfinishedIndex = this.getUnfinishedItemIndex()
                if (unfinishedIndex > -1) {
                    callbacks.openRow(unfinishedIndex)
                }
            }
        },

        ifNotParentControlRedirect( ) {
            if (!props.parentControl && locState.list.every(item => item?.id)) {
                if (locState.needExit) {
                    handlers.cancel()
                }
                if (locState.needExitToClientsIndex) {
                    router.get(getRoute('clients'))
                }
            }
        },
        exit() {
            locState.saveModalAlert = false
            setTimeout(() => {
                if (locState.needExitToClientsIndex) {
                    router.get(getRoute('clients'))
                } else {
                    handlers.cancel()
                }
            }, 100);
        },
    }

    const multiRowEvents = {
        createItem: (e, callbacks) => handlers.listItemActions('create', e, callbacks),
        addCleanItem: (e, callbacks) => handlers.listItemActions('add', e, callbacks),
        removeItem: (e, callbacks) => handlers.listItemActions('remove', e, callbacks),
        cancel: (e, callbacks) => handlers.listItemActions('cancel', e, callbacks),

        removeOpenedItem: (callbacks) => handlers.openUnfinishedItem(callbacks),
        updateItem: (e, callbacks) => handlers.update(e, callbacks),
        editItem: (e, callbacks) => handlers.edit(e, callbacks),
        saveAllItems: (e, callbacks) => handlers.save(e, callbacks),

        initList: (e) => {locState.list = e}
    }

    watch(() => props.saveTrigger, (val) => {
        if (val) {
            handlers.trySave()
        }
    })

    return {
        locState,
        handlers,
        multiRowEvents
    }
}
