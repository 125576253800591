export const genderOptions = [
    {id:1, title: 'Male'},
    {id:2, title: 'Female'},
];

export const passengerTypeOptions = ['Adult', 'Child', 'Infant']

export const  selectOptionsCabinClass = [
    {id:1, title: 'Business'},
    {id:2, title: 'Standard'},
    {id:3, title: 'Economy'} ];

export const inputDropdownOptions = [
    { 'divider': true, type:'divider' },
    {id:'1', 'title': 'Personal', 'toggleIcon': 'house', 'canSelect': true, type:'option' },
    {id:'2', 'title': 'Shared', 'toggleIcon': 'building', 'canSelect': true, type:'option' },
];

export const inputDropdownOptionsActions = [
    { 'divider': true, type:'divider' },
    { 'title': 'Request edit' , type:'action', action: 'edit'},
    { 'title': 'Request delete', 'className': 'text-danger', type:'action', action: 'delete'},
];



export default {
    genderOptions,
    contactByOptions: [{ id: 1, name: 'Eugen Crewner' }],

    selectOptionsCabinClass,

};
