<template>
    <acms-v-form-field
        :modeSelect="{
                    options:$page.props?.optionsForSelect?.genderOptions ?? [],
                    outValueKey: 'id'
                }"
        label="Gender"
        name="gender_id"
        placeholder="Select"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        :errors="errors"
        :required="required"
    />
</template>

<script>
export default {
    name: "FieldSelectGender"
}
</script>
<script setup="">
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    modelValue:[String,Object,Number],
    errors:Object,
    required:Boolean
})
const $page = usePage();
</script>
<style scoped>

</style>
