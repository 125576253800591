import {createPopper} from "@popperjs/core";
export default function ({toggleElRef, listElRef, placement}){
    return createPopper(toggleElRef.value, listElRef.value, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [4, 4],
                },
            },
        ],
    });
}
