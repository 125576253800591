<template>
    <acms-v-modal-alert
        :show="Boolean(show)"
        @update:show="$emit('update:show', $event)"
        :title="getTitleAndText.title"
        :text="getTitleAndText.text"
        :leftButtonTitle="'Continue' + (leftButtonCreate ? ' create' : ' editing')"
        rightButtonClass="btn-danger"
        rightButtonTitle="Yes, cancel"
        @leftButtonClick="$emit('update:show', null)"
        @rightButtonClick="$emit('cancel')"
    />
</template>

<script>export default {name: "AlertCancel",}</script>
<script setup="">
import {computed} from "vue";
import {ALERT_CANCEL_TITLE_TEXT} from "./constants";
const emit = defineEmits(['cancel', 'update:show'])
const props = defineProps({
    show: [Boolean,Array,Object],
    type: {
        type: String,
        validator(value) {
            return Object.keys(ALERT_CANCEL_TITLE_TEXT).includes(value)
        },
        default:'standard'
    },

    leftButtonCreate:Boolean
})
const getTitleAndText = computed(()=>{
    return ALERT_CANCEL_TITLE_TEXT[props.type] ?? {title:'', text:''}
})

</script>
<style scoped>

</style>
