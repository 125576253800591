<template>
    <div class="field-pcc-code">
        <template v-if="pccCode">
            <acms-v-form-field
                label="PCC code"
                name="pcc_code"
                readonly
                :modelValue="pccCode"
                :errors="errors"
            />
        </template>
        <acms-v-form-field v-else
                           label="PCC code"
                           :modeSelect="{
                            outValueKey: 'id',
                            search:true,
                            searchAsync: 'infohub.getPccs',
                            placeholderSelect:'Search',
                            minSymbolsForSearch:2,
                            optionKeyTitle:'pcc',
                            toggleKeyTitle:'pcc'
                           }"
                           name="pcc_code_id"
                           :modelValue="pccCodeId"
                           @update:modelValue="$emit('update:pccCodeId', $event)"
                           :errors="errors"
        />
    </div>
</template>

<script>
export default {
    name: "FieldPccCode"
}
</script>
<script setup="">
const props = defineProps({
      pccCode:String,
      pccCodeId:[String,Number],
      errors:Object,

})
</script>

<style scoped>

</style>
