<template>
    <div class="element-pq-label-block" :style="{'backgroundColor': item?.color}">
        <acms-v-icon size="20" :name="item?.icon" class="me-1 text-white"/>
        <div class="element-pq-label-block-title">
            {{ item?.title }}
        </div>

    </div>
</template>

<script>
export default {
    name: "ElementPqLabel"
}
</script>
<script setup="">
const props = defineProps({
    item: Object,
})
</script>

<style scoped lang="scss">

.element-pq-label-block {
    display: flex;
    align-items: center;
    padding: 8px 26px 8px 24px;

    width: max-content;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-right: 10px solid #fff;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.element-pq-label-block-title {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
}


</style>
