<template>
    <div class="acms-v-input d-flex align-items-center">
        <div class="acms-v-input-before" v-if="$slots?.before">
            <slot name="before"></slot>
        </div>
        <div class="acms-v-input-wrapper" :class="{'acms-v-input-wrapper--before-symbol': symbolBeforeContent}">
            <div class="acms-v-input-before-symbol" v-if="symbolBeforeContent">
                {{symbolBeforeContent}}
            </div>
            <input class="form-control acms-v-form-element-input"
                   :class="elementClass"
                   :required="required"
                   :disabled="disabled"
                   :placeholder="placeholder"
                   :name="name"
                   :type="type"
                   :readonly="readonly"
                   :value="modelValue"
                   :style="style"

                   @input="inputHandle"
                   @keydown="onKeydownHandle"
                   @wheel.stop.prevent="$event.target.blur()"
                   v-bind="getAttrs"
            >
        </div>
    </div>
</template>
<script>
export default {
    name: 'AcmsVInput',
};
</script>

<script setup>
import {computed, onMounted, } from 'vue';

import {defaultAttributesProps} from '../field.props';

const emit = defineEmits(['update:modelValue', 'onInput']);

const props = defineProps(
    {
        ...defaultAttributesProps,
        modelValue: [Number, String],
        elementClass: String,
        capitalizeFirstCharacter: Boolean,
        symbolBeforeContent: String,
        limitAfterDecimalPoint:Number,
    },
);
const getAttrs = computed(() => {
    const object = {}
    if (props.type == 'number') {
        object.min = 0;
    }
    return object
})

const capitalizeValueHandle = (value) => {
    return value.charAt(0).toUpperCase() + value.substring(1)
}
const onKeydownHandle = (e) => {

    const {type, limitAfterDecimalPoint} = props
    const currentValue = e.target.value

    if(type == 'number'){
        if(e.key == 'e'){
            e.preventDefault()
        }

        // if(e.key == '.'){
        //     e.preventDefault()
        // }

        if(limitAfterDecimalPoint){
            if(currentValue.includes('.')){
                if(e.key != 'Backspace'){
                    const [before, after] = currentValue.split('.')
                    if(after?.length == limitAfterDecimalPoint){
                        e.preventDefault()
                    }
                }
            }
        }
    }
}

const inputHandle = (e) => {
    const {type,capitalizeFirstCharacter} = props
    let value = e.target.value

    if (capitalizeFirstCharacter) {
        value = capitalizeValueHandle(value)
    }
    if(type === 'number'){
        // value = Number(value)
    }
    if(value == ''){
        value = null
    }


    emit('update:modelValue', value)
    emit('onInput', value)
}


</script>
<style lang="scss">
.acms-v-input{
    .acms-v-input-wrapper{
        position: relative;
        width: 100%;
        &.acms-v-input-wrapper--before-symbol{
            input{
                padding-left: 24px;
            }
        }
    }
    .acms-v-input-before-symbol{
        position: absolute;
        top: 0;
        bottom: 0;
        margin:auto;
        display: flex;
        height: 24px;
        left: 12px;
    }
    .acms-v-input {
        input {
            background-color: transparent;
        }
        .form-control:disabled {
            background-color: #fff;
        }
    }
}
.input {
    background-color: transparent;
}
.input-group-text {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
table {
    .acms-v-input {
        input {
            border: 1px solid #CED4DA;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
            padding: 4px 12px;
        }
    }
    .acms-v-input-before {
        padding: 4px 8px;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #CED4DA;
        border-radius: 4px 0 0 4px;
        border-right: 0;
        & + input {
            border-radius: 0 4px 4px 0;
        }
    }
}

</style>
