<template>
    <div class="acms-v-badge badge" :class="getClass" :style="getStyle">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'AcmsVBadge',
};
</script>
<script setup>

import { computed } from 'vue';

const DEFAULT_COLORS = ['primary']

const DEFAULT_BADGES = {
    'new prospect': 'btn-alt-primary',
    'outgoing': 'btn-alt-primary',

    'passenger' : 'btn-alt-primary',

    'co-passenger': 'btn-secondary',
    'contact': 'btn-secondary',

    'return prospect': 'btn-primary',
    'primary': 'btn-primary',

    'new client' : 'btn-alt-info',

    'vip client': 'btn-success',
    'incoming': 'bg-success-light text-success',
    'completed': 'bg-success-light text-success',
    'saved': 'bg-success-light text-success',
    'unfinished': 'bg-warning-light text-warning',
    'waiting to be saved': 'bg-warning-light text-warning',
    'ready for save': 'bg-warning text-white',
    'updated': 'bg-success-light text-success'
}

const props = defineProps({
    title: String,
    color: String,
});

const getClass = computed(() => {
    const { color, title } = props;
    if(title){
        return DEFAULT_BADGES?.[title?.toLowerCase() ?? null] ?? 'bg-primary'
    }
    return  'bg-primary'
});

const getStyle  =computed(()=>{
    const { color } = props;
    return color && (color.includes('#') || color.includes('rgb')) ? {'background-color': color} : '';
})

</script>
<style lang="scss">
.acms-v-badge{
    font-size: 14px;
    line-height: 20px;
    padding: 2px 6px;
}
</style>
