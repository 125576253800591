<template>
    <div class="box-client-requests-and-contacts">
        <BoxListClientRequests
            :main-client-id="mainClientId"
            :saveTrigger="triggerSaveRow.requests"
            :requestParentId="requestParentId"
            parent-control
            @cancel="$emit('cancel',$event)"
            :parentControl="parentControl"
        />
        <acms-v-card title="Linked contacts" content-class="p-0" class="card-linked-contacts">
            <BoxListLinkedContacts
                :main-client-id="mainClientId"
                :saveTrigger="triggerSaveRow.linkedContacts"
                :empty-state="linkedContactsEmpty"
                @cancel="$emit('cancel',$event)"
                :parentControl="parentControl"
            />
        </acms-v-card>
        <acms-v-bottom-actions v-if="!parentControl"
                               alertForCancel
                               modal-alert-cancel-left-button-create
                               @modalAlertCancel="handlers.cancel()"
                               @save="handlers.trySave(true)"
                               @saveAndExit="handlers.trySave(false, true)"
                               :disabledSave="PageClientCreateStore.submitProcessing"
        />
        <template v-if="!parentControl">
            <AcmsVModalAlert
                v-model:show="locState.saveModalAlert"
                :title="locState.saveModalAlertContent?.title ?? ''"
                :text="locState.saveModalAlertContent?.content ?? ''"
                @left-button-click="locState.saveModalAlert = false"
                @rightButtonClick="()=>isExitModal ? handlers.exit() :handlers.save()"
                left-button-title="Continue editing"
                :right-button-title="isExitModal ? 'Yes, exit' : 'Yes, save'"
                :right-button-class="isExitModal ? 'btn-danger' : 'btn-primary'"
            />
        </template>
    </div>
</template>
<script>
export default {
    name: 'BoxClientRequestsAndLinkedContacts',
}
</script>
<script setup="">
import {router} from "@inertiajs/vue3";
import BoxListLinkedContacts from '@boxes/common/BoxListLinkedContacts.vue';
import BoxListClientRequests from '@boxes/common/BoxListClientRequests.vue';
import AcmsVModalAlert from "@ui/ModalAlert.vue";
import {computed, onMounted, onUnmounted, reactive, watch} from "vue";
import useBoxClientRequestsAndLinkedContactsComponent from "./useBoxClientRequestsAndLinkedContacts.component";
import {storeToRefs} from "pinia";
import {useBoxListClientRequestsStore, useBoxListLinkedContactsStore} from "@store/boxes";
import {usePageClientCreateStore} from "@store/pages/page-create-client-store";
import {getRoute} from "@plugins/useRoutes";
import {
    NOT_FILLED_REQUEST_ALERT,
    NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT,
    FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT,
} from './constants'

const emit = defineEmits(['cancel', 'save'])
const props = defineProps({
    mainClientId: [String, Number],
    requestParentId: [String, Number],
    parentControl: Boolean,
    linkedContactsEmpty: Boolean,
    canSaveRequestsAndLinkedContacts: Boolean,
})

const locState = reactive({
    canSaveLinkedContacts: false,
    canSaveRequestsAndLinkedContacts: false,

    waitingToSave: [],
    wasSaved: [],

    needExit: false,
    needExitToClientsIndex: false,

    saveModalAlert: false,
    saveModalAlertContent: null,
})

const triggerSaveRow = reactive({
    requests: false,
    linkedContacts: false,
})

const BoxListClientRequestsStore = useBoxListClientRequestsStore()
const BoxListLinkedContactsStore = useBoxListLinkedContactsStore()
const PageClientCreateStore = usePageClientCreateStore()
const {queueIsFinished: requestsQueueIsFinished, list: requestsList} = storeToRefs(BoxListClientRequestsStore)
const {
    queueIsFinished: linkedContactsQueueIsFinished,
    list: linkedContactsList
} = storeToRefs(BoxListLinkedContactsStore)

const {checkIsFilledRequiredFields} = useBoxClientRequestsAndLinkedContactsComponent()
const isExitModal = computed(() => locState.saveModalAlertContent?.exit)

watch(() => props.canSaveRequestsAndLinkedContacts, (val) => {
    if (val) {
        locState.canSaveRequestsAndLinkedContacts = true;
        setTimeout(() => {
            locState.canSaveRequestsAndLinkedContacts = false
        }, 1000);
    }
})

watch(() => locState.canSaveRequestsAndLinkedContacts, (val) => {
    if (val) {
        handlers.tryToSaveRequestsAndLinkedContacts()
    }
})
const ifNeedExit = () => {
    if (!props.parentControl) {

        if (locState.needExit) {
            handlers.cancel()
        }
        if (locState.needExitToClientsIndex) {
            router.get(getRoute('clients'))
        }
    }
}

const emitSaved = () => {
    const needSave = locState.waitingToSave.sort().join(',')
    const saved = locState.wasSaved.sort().join(',')

    const allSaved = needSave.trim() === saved.trim()
    emit('save', allSaved)

    if (allSaved) {
        locState.waitingToSave = []
        locState.wasSaved = []
        ifNeedExit()
    }
}

watch(locState.wasSaved, emitSaved)

watch(requestsQueueIsFinished, (val) => {
    if (val) {
        toggleCanSendLinkedContacts(val)
        checkArraysSavedSuccessfully('requests')
    }
})
watch(linkedContactsQueueIsFinished, (val) => {
    if (val) {
        checkArraysSavedSuccessfully('linkedContacts')
    }
})

const toggleCanSendLinkedContacts = (val) => {
    locState.canSaveLinkedContacts = val
}

const checkArraysSavedSuccessfully = (arrayKey = '') => {
    if (arrayKey == 'requests') {
        const everySaved = requestsList.value?.every(item => item?.id)
        if (everySaved) {
            setWasSaved('requests')
        }
    }
    if (arrayKey == 'linkedContacts') {
        const everySaved = linkedContactsList.value?.every(item => item?.id)
        if (everySaved) {
            setWasSaved('linkedContacts')
        }
    }
}

const setWasSaved = (string) => {
    if (!locState.wasSaved.includes(string)) {
        locState.wasSaved.push(string)
    }
}

const setWaitingToSave = (string) => {
    if (!locState.waitingToSave.includes(string)) {
        locState.waitingToSave.push(string)
    }
}

const handlers = {
    saveRequests() {
        setWaitingToSave('requests')
        triggerSaveRow.requests = true
        setTimeout(() => {
            triggerSaveRow.requests = false;
        }, 500);
    },
    saveLinkedContacts() {
        let time
        let timeCount = 1

        setWaitingToSave('linkedContacts')

        time = setInterval(() => {
            if (locState.canSaveLinkedContacts) {
                clearInterval(time)
                setTimeout(() => {
                    locState.canSaveLinkedContacts = false
                }, 600);

                triggerSaveRow.linkedContacts = true
                setTimeout(() => {
                    triggerSaveRow.linkedContacts = false
                }, 500);
            }
            if (timeCount > 20) {
                clearInterval(time)
            }
            timeCount++
        }, 500)
    },

    toggleShowErrorsForRequestFields(val = true) {
        PageClientCreateStore.setErrorsForRequestFieldsShow(val)
    },
    toggleShowErrorsForLinkedContactFields(val = true) {
        PageClientCreateStore.setErrorsForLinkedContactFieldsShow(val)
    },

    trySave(needExit = false, needExitToClientsIndex = false) {
        const {
            requestIsFilled,
            linkedContactIsFilled,
            linkedContactIsFilledNotRequiredFields
        } = checkIsFilledRequiredFields?.()

        locState.needExit = needExit;
        locState.needExitToClientsIndex = needExitToClientsIndex;
        locState.saveModalAlertContent = null;

        if (requestIsFilled == false && linkedContactIsFilled) {
            locState.saveModalAlertContent = FILLED_LINKED_CONTACT_AND_NOT_FILLED_REQUEST_ALERT;
            handlers.toggleShowErrorsForRequestFields()
        } else if (requestIsFilled == false && linkedContactIsFilledNotRequiredFields) {
            locState.saveModalAlertContent = NOT_FILLED_REQUEST_AND_LINKED_CONTACT_ALERT;
            handlers.toggleShowErrorsForLinkedContactFields()
            handlers.toggleShowErrorsForRequestFields()
        } else if (requestIsFilled == false) {
            locState.saveModalAlertContent = NOT_FILLED_REQUEST_ALERT;
            handlers.toggleShowErrorsForRequestFields()
        } else {
            handlers.save()
        }
        if (locState.saveModalAlertContent) {
            locState.saveModalAlert = true
        }
    },
    exit() {
        locState.saveModalAlert = false

        setTimeout(() => {
            if (locState.needExitToClientsIndex) {
                router.get(getRoute('clients'))
            } else {
                handlers.cancel()
            }
        }, 100);
    },
    save() {
        locState.saveModalAlert = false
        if (props.mainClientId) {
            handlers.tryToSaveRequestsAndLinkedContacts()
        }
    },
    tryToSaveRequestsAndLinkedContacts() {
        const {
            requestIsFilled,
            linkedContactIsFilled,
            linkedContactIsFilledNotRequiredFields
        } = checkIsFilledRequiredFields?.()

        if (requestIsFilled && linkedContactIsFilled || requestIsFilled && linkedContactsList?.length > 0) {
            handlers.saveRequests()
            handlers.saveLinkedContacts()
            handlers.toggleShowErrorsForRequestFields(false)
            handlers.toggleShowErrorsForLinkedContactFields(false)
        } else if (requestIsFilled || linkedContactIsFilled) {
            if (requestIsFilled) {
                handlers.toggleShowErrorsForRequestFields(false)
                handlers.saveRequests()
                if (linkedContactIsFilledNotRequiredFields) {
                    handlers.saveLinkedContacts()
                    handlers.toggleShowErrorsForLinkedContactFields(false)
                }
            }
            if (linkedContactIsFilled) {
                toggleCanSendLinkedContacts(true)
                handlers.saveLinkedContacts()
                handlers.toggleShowErrorsForLinkedContactFields(false)
            }
        } else {
            emitSaved()
        }
    },
    cancel(object) {
        emit('cancel', object)
    },
}

const alertYourChangesNotBeSaved = function (e) {
    e.preventDefault()
}
onMounted(()=>{
    window.onbeforeunload = alertYourChangesNotBeSaved
})
onUnmounted(()=>{
    window.onbeforeunload = null
})
</script>
<style scoped>

</style>
