<template>
    <div class="pq-airline-taxes-item p-3">
        <div class="fw-bold mb-1">{{ title }}</div>
        <template v-for="([passengerKey,passengerValue ]) in passengers.entries">
            <template v-if="passengerValue > 0 && modelValue && passengerKey in modelValue">
                <template v-if="disabledInputs">
                    <acms-v-form-field
                        :label="label"
                        :placeholder="placeholder"
                        type="number"
                        :modelValue="modelValue[passengerKey]"
                        :mode-input="{symbolBeforeContent: modelValue[passengerKey] && String(modelValue[passengerKey])?.length > 0 ? '$' : ''}"
                        :name="getFieldName(passengerKey)"
                        :errors="errors"
                        disabled
                    />
                </template>
                <template v-else>
                    <acms-v-form-field
                                       :label="label"
                                       :placeholder="getPlaceholder(passengerKey)"
                                       type="string"
                                       :modelValue="modelValue[passengerKey]"
                                       @update:modelValue="(val)=>{
                                             val = String(val);
                                            if (val.includes(',')) {
                                        val = val.replace(',', '.');}
                                           updateValueHandle(parseFloat(val), passengerKey)}"
                                       :mode-input="{
                                             limitAfterDecimalPoint:2,
                                             symbolBeforeContent: modelValue[passengerKey] && String(modelValue[passengerKey])?.length > 0 ? '$' : ''
                                        }"
                                       :name="getFieldName(passengerKey)"
                                       :errors="errors"
                    />
                </template>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "PartialPqTaxesSingleItem"
}
</script>

<script setup="">
import numberHelpers from "@helpers/numberHelpers";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    passengers: Object,
    label: String,
    title: String,
    placeholder: String,
    modelValue: Object,
    rootName: String,
    errors: Object,
    disabledInputs: Boolean,
    minPricesForSell: Object,
})

const updateValueHandle = (val, key) => {
    const locValue = props.modelValue
    locValue[key] = val
    emit('update:modelValue', locValue)
}

const getFieldName = (passengerKey) => `${props.rootName}.${passengerKey}`;

const getPlaceholder = (key) => {
    if (props.minPricesForSell) {
        const newPlaceholder = numberHelpers.getFixed(Number(props.minPricesForSell?.[key]))
        return '$' + String(newPlaceholder).replace('.',',')
    }
    return props.placeholder
}
</script>
<style scoped>
</style>
