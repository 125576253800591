<template>
    <div class="box-best-time-to-call">
        <acms-v-label label="Best time to call"/>

        <div class="row align-items-baseline g-3">
            <acms-v-form-field
                class="col-6"
                :modeTimePicker="{
                               timeType: callTime.from?.type
                            }"
                v-model="callTime.from"
                :errors="errors?.best_time_call_from"
            />
            <div class="col-1" style="text-align: center">-</div>
            <acms-v-form-field
                class="col-5"
                :modeTimePicker="{
                                timeType: callTime.to?.type
                            }"
                v-model="callTime.to"
                :errors="errors?.best_time_call_from"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxBestTimeToCall',
};
</script>

<script setup="">
import { onBeforeMount, ref, watch } from 'vue';

const props = defineProps({
    modelValue: Object,
    errors:Object,
});
const initValue = {
    from: null,
    to: null,
};
const callTime = ref({...initValue});

const emit = defineEmits(['update:modelValue']);

watch(() => callTime.value, (val) => {
    emit('update:modelValue', val);
}, { deep: true });

onBeforeMount(()=>{
    callTime.value = props.modelValue ?? {...initValue}
})
</script>

<style scoped>

</style>
