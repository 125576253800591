<template>
    <acms-v-card title="Requests" class="card-requests" content-class="pb-0">
        <div class="box-list-requests">
            <acms-v-multi-row-crud
                add-title="Add another request"
                :items="locState.list"
                :itemSample="requestModel"
                :triggerSaveAllRows="locState.triggerSaveRows"
                v-on="multiRowEvents"
                alertDeleteType="requestFields"
            >
                <template #opened-header="{itemNumber,item, itemKey, itemsLength, removeRow, openRow}">
                    <div class="d-flex justify-content-between align-items-start">
                        <h3>
                            <template v-if="item?.id || item?._rowEdit">
                                Request
                                <template v-if="item?.id">ID:</template>
                                <template v-else>#</template>
                                {{ item?.id ?? itemNumber }}
                            </template>
                            <template v-else>
                                New request
                            </template>
                        </h3>
                        <acms-v-btn v-if="!item?.id && itemsLength > 1" icon="close" color="text-primary-darker"
                                    class="bg-gray-light"
                                    style="padding: 7px"
                                    @click="()=>handlers.checkIfHasFilledFieldsForRemove(item,removeRow)"
                        />
                    </div>
                </template>
                <template #opened="{number,item}">
                    <BoxClientRequest
                        :modelValue="item"
                        :errors="item?.errors"
                    />
                </template>
                <template #closed="{number,item}">
                    <div class="new-request-item-numb  fw-semibold ">
                        Request
                        <template v-if="item?.id">ID: {{ item?.id }}</template>
                        <template v-else>#{{ number }}</template>
                    </div>
                    <div class="vertical-line me-3 ms-3"></div>
                    <div class="row">
                        <div class=" new-request-item-airports text-center" style="width: 140px;" v-if="getRequestAirports(item.flightLegs)">
                            {{ getRequestAirports(item.flightLegs) }}
                        </div>
                        <div class="col-auto " v-if="!item?.id">
                            <template v-if="!handlers.checkIsFilledRequiredFields(item)">
                                <acms-v-badge title="UNFINISHED"/>
                            </template>
                            <template v-else>
                                <acms-v-badge title="Ready for save"/>
                            </template>
                        </div>
                        <div class="col " v-if="item?.id">
                            <acms-v-badge title="Saved"/>
                        </div>
                    </div>
                </template>
            </acms-v-multi-row-crud>
            <AlertDelete
                v-model:show="locState.deleteShow"
                type="request"
                @delete="handlers.delete"
            />
        </div>
    </acms-v-card>
</template>

<script>
export default {
    name: 'BoxListClientRequests',
};
</script>

<script setup="">
import {watch} from 'vue';
import {usePage} from "@inertiajs/vue3";
import debounce from "@libs/debounce";
import {cloneDeep} from "lodash";
import BoxClientRequest from '@boxes/common/BoxClientRequest.vue';
import AcmsVMultiRowCrud from "@ui/MultiRowCrud/MultiRowCrud.vue";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getRoute} from "@plugins/useRoutes";
import {getFormattedRequestFor} from "@services/FormattedDataForServerService";
import AlertDelete from "@components/app/alerts/AlertDelete.vue";
import {useBoxListClientRequestsStore} from "@store/boxes/box-list-client-requests-store";
import {usePageClientCreateStore} from "@store/pages/page-create-client-store";
import {requestModel, FLIGHT_LEG_KEYS, FLIGHT_LEG_KEYS_WITHOUT_CABIN} from "@models/request.model";
import useMultiRowCrudService from "@services/ui/MultiRowCrudService";

const props = defineProps({
    mainClientId: [String, Number],
    requestParentId:[String, Number],
    parentControl: Boolean,
    saveTrigger: Boolean,
});

const emit = defineEmits(['cancel',]);
const $page = usePage()
const AlertsManager = useAlertsManagerStore()

const BoxListClientRequestsStore = useBoxListClientRequestsStore()
const PageClientCreateStore = usePageClientCreateStore()

const methodsForHandlers = {
    checkIsFilledRequiredFields(itemForm) {
        const flightValues = itemForm?.flightLegs.reduce((acc, item) => {
            FLIGHT_LEG_KEYS.forEach(key => {
                acc.push(item[key])
            })

            return acc
        }, [])

        return flightValues?.every(item => {
            if (Array.isArray(item)) {
                return (item?.[0] != null && item?.[0] != '')
            }
            return item
        })
    },
    checkIfHasFilledFields(item) {
        const {flightLegs, flightPreferences, peopleQuantity, notes} = item
        const {adults, children, infants} = peopleQuantity
        const arrayOfBooleans = [adults > 1, children > 0, infants > 0, !!notes,]
        if (flightLegs.length > 1) {
            arrayOfBooleans.push(true)
        } else {
            const item = flightLegs?.[0]
            FLIGHT_LEG_KEYS_WITHOUT_CABIN.forEach(key => {
                if (Array.isArray(item[key])) {
                    arrayOfBooleans.push(!!item[key][0])
                } else {
                    arrayOfBooleans.push(!!item[key])
                }
            })
        }
        const {seats, ...restFlightPreferences} = flightPreferences
        arrayOfBooleans.push(...Object.values(restFlightPreferences).map(item => !!item))

        return arrayOfBooleans.some(item => item)
    },

    preValidateItem(itemForm, ) {
        itemForm?.clearErrors()
        const isFilledRequiredFields = this.checkIsFilledRequiredFields(itemForm)
        if (!isFilledRequiredFields) {
            const flightLegs = itemForm?.flightLegs;
            if (flightLegs?.length > 0) {
                flightLegs.forEach((item, index) => {
                    FLIGHT_LEG_KEYS.forEach(key => {
                        if (key == 'dates_range') {
                            const first = item[key]?.[0]
                            if (first == '' || first == null) {
                                itemForm.setError(`flight_leg.${index}.flight_date_from`, 'This field is required.',);
                            }
                        }
                        if (!item[key]) {
                            if (key == 'from') {
                                itemForm.setError(`flight_leg.${index}.departure_airport_id`, 'This field is required.');
                            }
                            if (key == 'to') {
                                itemForm.setError(`flight_leg.${index}.arrival_airport_id`, 'This field is required.');
                            }
                            if (key == 'cabin_class') {
                                itemForm.setError(`flight_leg.${index}.cabin_class_id`, 'This field is required.');
                            }
                        }
                    })
                })
            }
        }
        return isFilledRequiredFields
    },
    save({list, openedItem, openedItemIndex}, callbacks) {
        if (openedItem && openedItem?.id) {
            if (openedItem.id) {
                this.update({item: openedItem, index: openedItemIndex}, callbacks)
            }
        }

        locState.list = list
        locState.listForSave = [];
        locState.list.forEach(item => {
            locState.listForSave.push({
                id: item?.id ?? null,
                formData: cloneDeep(item),
            })
        })
        if (!props.mainClientId) {
            return
        }
        const findFirstFormWithoutId = () => {
            return locState.list.findIndex(item => !item?.id)
        }
        const sendHandle = (itemIndex = findFirstFormWithoutId()) => {
            const itemForm = locState.list[itemIndex]
            if (itemIndex < 0) {
                BoxListClientRequestsStore.setQueueFinishedStatus(true)
                return;
            }
            if (itemForm?.id) {
                return;
            }
            const isValidated = this.preValidateItem(itemForm, itemIndex)
            if (!isValidated) {
                locState.list[itemIndex] = callbacks.openRow(itemIndex)
                PageClientCreateStore.setSubmitProcessing(false)
                AlertsManager.add('Request has errors', 'danger')
                return
            }
            const route = getRoute('requests.store');
            PageClientCreateStore.setSubmitProcessing(true)
            itemForm?.transform(data => {
                return getFormattedRequestFor
                    .backend(data, {
                        mainClientId: props.mainClientId,
                        parentId: locState.requestParentId
                    })
            }).post(route, {
                preserveScroll: true,
                onSuccess() {
                    const {id, hash_id = ''} = $page.props.flash?.success?.request?.data;

                    const existServerError = $page.props.flash?.error;

                    if (existServerError) {
                        AlertsManager.add('Request has server errors', 'danger')
                        PageClientCreateStore.setSubmitProcessing(false)
                    }

                    if (!id) {
                        return
                    }
                    locState.list[itemIndex].id = id
                    locState.listForSave[itemIndex].id = id
                    locState.listForSave[itemIndex].formData.id = id

                    locState.list[itemIndex] = callbacks.collapseRow(locState.list[itemIndex])

                    // set parent id
                    if (itemIndex == 0 && !locState.requestParentId) {
                        locState.requestParentId = id
                        locState.list[itemIndex].parent_id = 0;
                    } else {
                        locState.list[itemIndex].parent_id = locState.requestParentId
                    }

                    AlertsManager.add(`Request ID:${hash_id} successfully created`)

                    if (locState.listForSave?.[itemIndex + 1]) {
                        sendHandle(itemIndex + 1)
                    } else {
                        BoxListClientRequestsStore.setQueueFinishedStatus(true)
                        PageClientCreateStore.setSubmitProcessing(false)
                    }
                },
                onError() {
                    PageClientCreateStore.setSubmitProcessing(false)
                    AlertsManager.add('Request has errors', 'danger')

                    const {openRowByIndex, findAndCloseOpenedRow} = callbacks.openRow(itemIndex, true)
                    findAndCloseOpenedRow?.(itemIndex)

                    setTimeout( () => {
                        openRowByIndex?.(itemIndex)
                    }, 0);
                }
            })
        }
        sendHandle()
    },
}

const {locState, handlers, multiRowEvents} = useMultiRowCrudService(
    {
        emit,
        props,
        addToLocState: {
            requestParentId: props.requestParentId ?? null,
        },
        methods: methodsForHandlers
    })

const debounceSetList =  debounce((val) => {
    BoxListClientRequestsStore.setList(val)
}, 100, )

watch(() => locState.list, (val) => {
        debounceSetList(val)
}, {deep: true})

watch(() => PageClientCreateStore.errorsForRequestFieldsShow, (val) => {
    if(val ){
        handlers.preValidateItem(locState.list[0], 1)
    }else if(val == false){
        locState.list?.[0]?.clearErrors?.()
    }
})

const getRequestAirports = (flightLegs) => {
    const first = flightLegs?.[0];
    if (first) {
        const {from, to} = first
        if (from && to) {
            const {code: fromCode, iata: fromIata} = from
            const {code: toCode, iata: toIata} = to
            return `${fromCode ? fromCode : fromIata ? fromIata : 'Not iata'} - ${toCode ? toCode : toIata ? toIata : 'Not iata'}`
        }
    }
    return ''
}
</script>

<style scoped></style>
