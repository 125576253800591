<template>
    <div class="box-passport-data-list">
        <acms-v-multi-row-crud
            add-title="Add another passenger"
            :itemSample="clientPassportDataModel"
            :items="locState.list"
            v-on="multiRowEvents"
            :triggerSaveAllRows="locState.triggerSaveRows"
            alertDeleteType="passengerFields"
        >
            <template #opened-header="{itemNumber,item, itemKey, itemsLength, removeRow, openRow}">
                <div class="d-flex justify-content-between align-items-start">
                    <h3>Passport data</h3>
                    <acms-v-btn v-if="!item?.id && itemsLength > 1"  icon="close"  color="text-primary-darker"
                                class="bg-gray-light" style="padding: 7px"
                                @click="()=>handlers.checkIfHasFilledFieldsForRemove(item,removeRow)"
                    />
                </div>
            </template>
            <template #opened="{item }">
                <BoxPassportData
                    :modelValue="item"
                    :errors="item?.errors"
                    hideHead
                />
            </template>
            <template #closed="{key,item}">
                <div class="text-primary  fw-semibold col-auto row-fullname-ellipsis" :title="getFullName(item, 'No name')">

                     {{ getFullName(item, 'No name') }}
                </div>
                <div class="vertical-line me-3 ms-3"></div>
                <div class="row w-100">

                    <div class="col-auto" v-if="!item?.id">
                        <template v-if="!handlers.checkIsFilledRequiredFields(item)">
                            <acms-v-badge title="UNFINISHED"/>
                        </template>
                        <template v-else>
                            <acms-v-badge title="Ready for save"/>
                        </template>
                    </div>
                    <div class="col" v-if="item?.id">
                        <acms-v-badge title="Saved"/>
                    </div>
                </div>

            </template>
        </acms-v-multi-row-crud>

        <template v-if="backendPermissions?.allowCreatePassengers">
            <acms-v-bottom-actions
                v-if="!parentControl"
                alertForCancel
                @modalAlertCancel="handlers.cancel()"
                modal-alert-cancel-left-button-create
                modal-alert-cancel-type="passenger"
                @save="handlers.trySave(true)"
                @saveAndExit="handlers.trySave(false,true)"
                :disabledSave="locState.submitProcessing"
            />
        </template>

        <AlertDelete
            v-model:show="locState.deleteShow"
            type="passenger"
            @delete="handlers.delete"
        />

        <AcmsVModalAlert
            v-model:show="locState.saveModalAlert"
            :title="locState.saveModalAlertContent?.title ?? ''"
            :text="locState.saveModalAlertContent?.content ?? ''"
            @left-button-click="locState.saveModalAlert = false"
            @rightButtonClick="()=>isExitModal ? handlers.exit() : handlers.save()"
            left-button-title="Continue editing"
            :right-button-title="isExitModal ? 'Yes, exit' : 'Yes, save'"
            :right-button-class="isExitModal ? 'btn-danger' : 'btn-primary'"
        />
    </div>
</template>

<script>

const NOT_FILLED_PASSPORT_DATA_ALERT = {
    title: 'Passenger details missing',
    content: 'Your passenger details is not finished! Exit?',
    exit:true,
}
</script>

<script setup="">
import {computed} from 'vue';
import BoxPassportData from './BoxPassportData.vue';
import {getFullName} from '@/helpers/commonHelpers';
import AcmsVMultiRowCrud from "@ui/MultiRowCrud/MultiRowCrud.vue";
import {usePage} from "@inertiajs/vue3";
import {getRoute} from "@plugins/useRoutes";
import {cloneDeep} from "lodash";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import AlertDelete from "@components/app/alerts/AlertDelete.vue";
import {clientPassportDataModel} from "@models/client.model";
import useMultiRowCrudService from "@services/ui/MultiRowCrudService";
import AcmsVModalAlert from "@ui/ModalAlert.vue";
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({
    mainClientId: [String, Number],
    parentControl: Boolean,
    saveTrigger: Boolean,
});

const emit = defineEmits(['cancel']);
const $page = usePage()
const AlertsManager = useAlertsManagerStore()
const {backendPermissions} = useBackendMiddlewarePropsService()
const methodsForHandlers = {
    checkIsFilledRequiredFields(itemForm) {
        const {first_name, last_name, birth_date, gender_id} = itemForm
        return [first_name, last_name, birth_date, gender_id].every(item => item)
    },
    checkIfHasFilledFields(item) {
        const {ktn, ...rest} = item.data()

        const {type_id, code, redress} = ktn
        const arrayOfBooleans = [
            !!type_id, !!code, !!redress,
            ...Object.keys(rest).map(key => !!item[key]),
        ]

        return  arrayOfBooleans.some(item => item)
    },

    preValidateItem(itemForm, fullCheck =false) {
        itemForm?.clearErrors()

        const isFilledRequiredFields = this.checkIsFilledRequiredFields(itemForm)

        if (!isFilledRequiredFields) {
            const {first_name, last_name, birth_date, gender_id} = itemForm

            if (!first_name || first_name?.length == 0) {
                itemForm.setError(`first_name`, 'This field is required.',);
            }
            if (!last_name || last_name?.length == 0) {
                itemForm.setError(`last_name`, 'This field is required.',);
            }
            if (!birth_date) {
                itemForm.setError(`birth_date`, 'This field is required.',);
            }
            if (!gender_id) {
                itemForm.setError(`gender_id`, 'This field is required.',);
            }
        }

        return isFilledRequiredFields
    },

    save({list, openedItem, openedItemIndex}, callbacks) {
        if (openedItem && openedItem?.id) {
            if (openedItem.id) {
                this.update({item: openedItem, index: openedItemIndex}, callbacks)
            }
        }
        locState.list = list
        locState.listForSave = [];
        locState.list.forEach(item => {
            locState.listForSave.push({
                id: item?.id ?? null,
                formData: cloneDeep(item),
            })
        })

        if (!props.mainClientId) {
            return
        }

        this.ifNotParentControlRedirect()

        const findFirstFormWithoutId = () => {
            return locState.list.findIndex(item => !item?.id)
        }

        const sendHandle = (itemIndex = findFirstFormWithoutId()) => {
            const itemForm = locState.list[itemIndex]

            if (itemIndex < 0) {
                return;
            }

            if (itemForm?.id) {
                return;
            }

            const isValidated = this.preValidateItem(itemForm, itemIndex)

            if (!isValidated) {
                locState.list[itemIndex] = callbacks.openRow(itemIndex)
                locState.submitProcessing = false
                AlertsManager.add('Passenger has errors', 'danger')
                return
            }

            const route = getRoute('passengers.store', props.mainClientId);
            locState.submitProcessing = true;

            itemForm?.transform(data => {
                return {
                    ...data,
                    client_id: props.mainClientId
                }
            })
                .post(route, {
                    preserveScroll: true,
                    onSuccess() {
                        const id = $page.props.flash.success?.passenger?.data?.id
                        const existServerError = $page.props.flash?.error
                        if(existServerError){
                            locState.submitProcessing = false
                        }

                        if (!id) {
                            return
                        }
                        locState.list[itemIndex] = cloneDeep(itemForm)
                        locState.list[itemIndex].id = id
                        locState.list[itemIndex] = callbacks.collapseRow(locState.list[itemIndex])
                        AlertsManager.add(`Passenger ID:${id} successfully created.`)

                        if (locState.listForSave?.[itemIndex + 1]) {
                            sendHandle(itemIndex + 1)
                        } else {
                            locState.submitProcessing = false

                            handlers.ifNotParentControlRedirect()
                        }
                    },
                    onError() {
                        locState.submitProcessing = false
                        AlertsManager.add('Passenger has errors', 'danger')

                        const {openRowByIndex, findAndCloseOpenedRow} = callbacks.openRow(itemIndex, true)
                        const findToClose = findAndCloseOpenedRow?.(itemIndex)

                        if (findToClose && findToClose?.item) {
                            locState.list[findToClose.index] = findToClose.item
                        }

                        locState.list[itemIndex] = openRowByIndex?.(itemIndex)

                    }
                })
        }
        sendHandle()
    },
}

const {locState, handlers, multiRowEvents} = useMultiRowCrudService(
    {
        emit,
        props,
        constants: {notFilled: NOT_FILLED_PASSPORT_DATA_ALERT},
        methods:methodsForHandlers
    })

const isExitModal = computed(() => {
    return locState.saveModalAlertContent?.exit
})

</script>

<style lang="scss">
.box-passport-data {
    padding: 20px;
}
</style>
