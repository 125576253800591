import {watch} from "vue";

function initClientEditWatchers ({clientPassportForm,clientInfoForm }){
    watch(clientInfoForm.generalInfo, (val) => {
        clientPassportForm.last_name = val.last_name
        clientPassportForm.middle_name = val.middle_name
        clientPassportForm.gender_id = val.gender_id
    }, {deep: true})
    watch(() => clientPassportForm, (val) => {
        clientInfoForm.generalInfo.last_name = val.last_name
        clientInfoForm.generalInfo.middle_name = val.middle_name
        clientInfoForm.generalInfo.gender_id = val.gender_id
    }, {deep: true})
}
export default initClientEditWatchers
