<template>
    <div class="pq-airline-taxes-item pq-airline-taxes-item-millage p-3">
        <div class="fw-bold mb-1 overflow-text-content">{{ titlePrev ? titlePrev + ' ' : '' }}{{ getTitle }}</div>
        <template v-for="([passengerKey,passengerValue ]) in passengers.entries">
            <template v-if="passengerValue > 0">
                <div class="pq-airline-texes-adult d-flex gap-2">
                    <template v-for="taxLabel in MODEL_PQ_AWARD_TAXES_LABELS">
                        <acms-v-form-field
                            :label="taxLabel"
                            :class="taxLabel != 'Miles' ? 'pq-airline-taxes-item-input': ''"
                            type="number"
                            :modelValue="modelValue[passengerKey][taxLabel.toLowerCase()]"
                            @update:modelValue="(val)=>updateValueHandle(val, passengerKey, taxLabel.toLowerCase())"
                            :name="getFieldName(passengerKey,taxLabel.toLowerCase())"
                            :disabled="taxLabel == 'Net'"
                            :mode-input="{
                                limitAfterDecimalPoint:2,
                                symbolBeforeContent: getSymbolForInput(passengerKey, taxLabel.toLowerCase())}"
                            :errors="errors"
                        />
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<script>

export default {
    name: 'PartialPqAirlineTaxesItem',
};
</script>
<script setup="">

import {computed, onBeforeMount, watch} from 'vue';

import {MODEL_PQ_AWARD_TAXES_LABELS} from "@models/priceQuote.model";
import numberHelpers from "@helpers/numberHelpers";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    passengers: Object,
    priceItem: Object,
    priceItemIndex: [Number, String],

    modelValue: Object,
    errors: Object,
    rootName: String,

    titlePrev: String,
});


const getTitle = computed(() => {
    return props.priceItem?.mileage?.title;
});

const refreshCalculate = () => {
    props.passengers.entries.forEach(([passengerKey, passengerValue]) => {
        if (passengerValue > 0) {
            MODEL_PQ_AWARD_TAXES_LABELS.forEach(taxLabel => {
                const val = props.modelValue?.[passengerKey]?.[taxLabel.toLowerCase()]
                if (val) {
                    updateValueHandle(val, passengerKey, taxLabel.toLowerCase())
                }
            })
        }
    })
}


watch(() => props.priceItem, (val) => {
    refreshCalculate()
}, {deep: true})


const getFieldName = (passengerKey, taxKey) => {
    const {priceItemIndex, rootName} = props
    return `${rootName}.${passengerKey}.${taxKey}`
}

const getAirlineCost = computed(() => {
    const cost = props.priceItem?.mileage?.cost;
    return cost ? Number(cost) : 10
})

const getSymbolForInput = (passengerKey, taxKey) => {
    if (['taxes', 'net'].includes(taxKey)) {
        const value = props.modelValue[passengerKey]?.[taxKey]
        return value && String(value)?.length > 0 ? '$' : ''
    }
}

const updateValueHandle = (value, passengerKey, taxKey) => {
    const locValue = props.modelValue

    if (taxKey != 'net') {
        const object = locValue[passengerKey]

        locValue[passengerKey][taxKey] = value

        let netValue = Number(object?.miles ?? 0) * getAirlineCost.value + Number(object.taxes)

        netValue = numberHelpers.getRound(netValue, true)
        netValue = numberHelpers.getFixed(netValue)

        locValue[passengerKey]['net'] = netValue > 0 ? netValue : null
    }

    emit('update:modelValue', locValue)
}

onBeforeMount(() => {


});


</script>

<style lang="scss">
@import "@scssVars";


.pq-airline-taxes-item{
    border: 1px solid $c-gray;
    border-radius: 4px;
}

.pq-airline-taxes-item-millage {
    max-width: 272px;
    min-width: 100px;
    input{
        min-width: 86px;
        max-width: 86px;
    }
}

.pq-airline-taxes-item-input{
    width:100%;
    input{
        max-width:100%;
        min-width: 106px;
    }


}

</style>
