<template>
    <acms-v-btn icon="email" class="btn-sm  "
                :submitting="loading"
                :class="loading ? 'btn-primary' : 'btn-outline-primary'"
                :disabled="!emailsData.show"
                @click="$emit('click')">
        Send
        <template v-if="emailsData.show">
            ({{ emailsData.count }})
        </template>
    </acms-v-btn>
</template>

<script>
export default {
    name: "ElementSendPqMulti"
}
</script>
<script setup="">
const emit = defineEmits(['click', ''])
const props = defineProps({
      loading:Boolean,
      emailsData:Object,
})
</script>
<style scoped>

</style>
