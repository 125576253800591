<template>
    <div class="acms-v-btn-group">
        <template v-for="item in items">
            <acms-v-btn class="acms-v-btn-group-item"
                        :class="{active: locState.active == item?.id}"
                        @click="()=>clickHandle(item)">
                <template v-if="!$slots['button-content']">
                    {{item?.title}}
                </template>
                <slot name="button-content" :item="item" :isActive="locState.active == item?.id"></slot>
            </acms-v-btn>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AcmsVBtnGroup',
};
</script>

<script setup="">
import { reactive, ref } from 'vue';
import AcmsVBtn from './Btn.vue'

const emit = defineEmits(['change', ''])
const props = defineProps({
      items:Array,
      active:String
})
const locState = reactive({
    active : props.active
})

const clickHandle = (item) => {
    locState.active = item.id
    emit('change', locState.active)
}

</script>

<style lang="scss">
.acms-v-btn-group{
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    &-item {
        padding: 10px 12px;
        border-radius: 0;
        border: 1px solid #E9ECEF;
        text-align: left;
        font-size: 14px;
        &:first-child{
            border-radius: 5px 0px 0px 5px ;
        }
        &:last-child{
            border-radius: 0px 5px 5px 0px
        }
        &.active{
            background-color: #343A40;
            color: #fff;
            border: 1px solid #343A40;
        }
    }
}
</style>
