export const CLIENTS_TABLE_INDEX = {
    showNumber:true,
    columns: {
        'id': '#',
        'first-name': 'Name',
        'email': 'Email',
        'phone': 'Phone',
        'requests': 'Requests',
        'agent-status': 'Agent Status',
        'revenue': 'Revenue, USD',
        'added': 'Added',
        'local-time': 'Local time',
        // 'actions': 'Actions',
    },
    columnsWidth: {
        'id': '52px',
        'first-name': '290px',
        'email': '240px',
        'phone': '180px',
        'requests': '120px',
        'agent-status': '170px',
        'revenue': '200px',
        'added': '120px',
        'local-time': '140px',
        // 'actions': '120px'
    }
}


export const CLIENT_LINKED_CONTACTS_TABLE_SETTINGS = {
    showNumber: true,

    columns: {
        'name': 'Name',
        'email': 'Email',
        'phone': 'Phone',
        'type-id': 'Relationship',

        'actions': 'Actions',
    },
    'columns-width': {
        '#': '52px',
        'name': '240px',
        'email': '190px',
        'phone': '160px',
        'type-id': '190px',

        'actions': '170px'
    },
    sortKeys: true,
    emptyStateTitle:"Linked contacts"

}

export const CLIENT_PASSENGERS_TABLE_SETTINGS = {
    showNumber: true,

    columns: {
        'name': 'Name',
        'type': 'Type',
        'redress': 'Redress',
        'ktn': 'Ktn',
        'actions': 'Actions',
    },
    'columns-width': {
        '#': '52px',
        'name': '250px',
        'type': '100px',
        'redress': '180px',
        'ktn': '150px',
        'actions': '100px',
    },
    sortKeys:true,
    emptyStateTitle:"Passengers"
}

export const CLIENT_REQUESTS_TABLE_SETTINGS = {
    showNumber: true,

    columns: {
        'id': 'Request ID',
        'creation-date': 'Creation date',
        'time-passed': 'time passed',
        'local-time': 'Local time',
        'flight-details': 'Flight details',
        'passengers': 'Passengers',
        'pq': 'PQ',
        'cabin-class': 'Cabin class'
    },
    'columns-width': {
        '#': '52px',
        'id': '140px',
        'creation-date': '170px',
        'time-passed': '150px',
        'local-time': '140px',
        'flight-details': '310px',
        'passengers': '200px',
        'pq': '120px',
        'cabin-class': '160px'
    }

}


export const CLIENT_TABS_SETTINGS = {

    itemsContentClass: {
        'client-info': 'position-relative'
    },
    parentControl: true,
    contentClass: "p-0"
}
